import { DeleteOutline, Download, PendingOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import Table from "components/Table";
import { saveAs } from "file-saver";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { GlobalState, authorizedFetch, titleCase } from "utils";
import { NEW_REPORTS_URL } from "utils/constants";
import DownloadBar from "./DownloadBar";

const Reports = () => {
  const { vehicleReports, reports, activeSection } = useSelector(
    (state: GlobalState) => state.global,
  );

  let isCMS = activeSection === "charger";

  const [tab, setTab] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const first = pageSize;
  const skip = pageSize * (page - 1);

  const { isLoading, data } = useQuery(["get reports", first, skip], () =>
    authorizedFetch(`${NEW_REPORTS_URL}/reports?first=${first}&skip=${skip}`),
  );

  return (
    <Box
      sx={{
        px: { xs: 2, md: 4 },
        pt: { xs: 2, md: 4 },
        pb: { xs: 3, md: 6 },
      }}
    >
      <DownloadBar />
      <Paper
        sx={{
          pt: 3,
          px: 3,
          width: 1,
          boxShadow: (theme) => theme.customShadows.card,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            mb: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box width="fit-content">
            <Tabs
              className="dense"
              value={tab}
              onChange={(e, value) => setTab(value)}
              sx={{
                "& .MuiTab-root": {
                  fontSize: 16,
                  fontWeight: 600,
                  letterSpacing: "normal",
                },
              }}
            >
              <Tab label="Download Requests" />
              <Tab label="Download History" />
            </Tabs>
          </Box>
        </Box>
        {tab === 0 ? (
          <Table
            px={0}
            loading={isLoading}
            serverSidePagination
            activePage={page}
            onPageChange={setPage}
            activePageSize={pageSize}
            onPageSizeChange={setPageSize}
            rowCount={data?.meta?.totalCount || 0}
            rows={data?.data?.constructor === Array ? data.data : []}
            columns={[
              {
                key: "createdAt",
                label: "Requested At",
                format: (value) => moment(value).format("MMM DD, YYYY hh:mm a"),
              },
              {
                key: "reportType",
                label: "Report Type",
                format: (value) =>
                  value
                    .split("_")
                    .map((el: string) =>
                      RegExp(/esg/i).test(el)
                        ? el.toUpperCase()
                        : titleCase(el),
                    )
                    .join(" "),
              },
              {
                key: "format",
                label: "Format",
                format: (value) => value.toUpperCase(),
              },
              {
                key: "filters.startTime",
                label: "From",
                format: (value) => moment(value).format("MMM DD, YYYY hh:mm a"),
              },
              {
                key: "filters.endTime",
                label: "To",
                format: (value) => moment(value).format("MMM DD, YYYY hh:mm a"),
              },
              {
                key: "actions",
                label: "Actions",
                Render: (row) => (
                  <Tooltip
                    title={
                      row.link === "In Progress" ? "In Progress" : "Download"
                    }
                  >
                    <IconButton
                      size="small"
                      sx={{ color: (theme) => theme.customColors.grey }}
                      onClick={() => {
                        if (row.link !== "In Progress")
                          window.open(row.link, "_blank");
                      }}
                      children={
                        row.link === "In Progress" ? (
                          <PendingOutlined fontSize="small" />
                        ) : (
                          <Download fontSize="small" />
                        )
                      }
                    />
                  </Tooltip>
                ),
              },
            ]}
          />
        ) : (
          <Table
            px={0}
            rows={isCMS ? reports || [] : vehicleReports || []}
            columns={[
              {
                key: "name",
                label: "Name",
                Render: (row) => (
                  <Tooltip title={row.name}>
                    <Typography variant="body2" maxWidth={360} noWrap>
                      {row.name}
                    </Typography>
                  </Tooltip>
                ),
              },
              {
                key: "createdAt",
                label: "Created At",
                format: (value) => moment(value).format("MMM DD, YYYY"),
              },
              {
                key: "actions",
                label: "Actions",
                Render: (row) => (
                  <Tooltip title="Download Again">
                    <IconButton
                      size="small"
                      sx={{ color: (theme) => theme.customColors.grey }}
                      onClick={() => {
                        saveAs(row.url, row.name);
                      }}
                      children={<Download fontSize="small" />}
                    />
                  </Tooltip>
                ),
              },
            ]}
            toolbar={() => (
              <>
                <Button startIcon={<DeleteOutline />}>Delete</Button>
              </>
            )}
          />
        )}
      </Paper>
    </Box>
  );
};

export default Reports;
