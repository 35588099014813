import { Box } from "@mui/material";

const InfoLabel = ({ live, label, value, dense, ml, mr }: any) => {
  return (
    <Box
      sx={{
        ml: ml || 0,
        mr: mr || 0,
        px: dense ? 1.5 : 2.75,
        py: 0.75,
        display: "flex",
        alignItems: "center",
        fontSize: { xs: 10, md: 12 },
        fontWeight: 700,
        lineHeight: "16.37px",
        background: "#C4C4C421",
        borderRadius: 10,
        whiteSpace: "nowrap",
        "& .label": {
          color: "text.primary",
        },
        "& .value": {
          ml: 1,
          color: (theme) => theme.customColors.text.grey,
        },
      }}
    >
      {live ? (
        <>
          <Box
            component="span"
            display="inline-block"
            height={8}
            width={8}
            bgcolor="#EB5C5C"
            borderRadius="50%"
            mr={0.75}
          />
          <span className="label">Live Data</span>
        </>
      ) : (
        <>
          {label && <span className="label">{label}</span>}
          <span className="value">{value}</span>
        </>
      )}
    </Box>
  );
};

export default InfoLabel;
