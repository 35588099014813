import {
  Box,
  MenuItem,
  Paper,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import CircularLoader from "components/CircularLoader";
import { useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useQuery } from "react-query";
import { authorizedFetch, lerp } from "utils";
import { CMS_STATS_URL } from "utils/constants";

const filterLabels: any = {
  booking_count: "Bookings",
  consumption: "Energy Dispensed",
  revenue: "Revenue",
};

const TopChargers = ({ params }: any) => {
  const theme = useTheme();
  const {
    startTime,
    endTime,
    vehicleType,
    chargerType,
    accessType,
    city,
    masterView,
  } = params;
  const [orderBy, setOrderBy] = useState("booking_count");

  const { isLoading, data } = useQuery(
    [
      "GET cms-stats /tabular/top_chargers",
      startTime,
      endTime,
      vehicleType,
      chargerType,
      accessType,
      city,
      orderBy,
      masterView,
    ],
    () =>
      authorizedFetch(
        `${CMS_STATS_URL}/tabular/top_chargers?start_time=${startTime}&end_time=${endTime}${vehicleType}${chargerType}${accessType}${city}&order_by=${orderBy}&master=${masterView}`,
      ),
  );

  const chartData = useMemo(() => {
    if (!data) return [];
    let array = Object.entries(data.data || {})
      .map(([key, value]: any) => ({
        x: key,
        y: value[orderBy],
      }))
      .sort((a: any, b: any) => b.y - a.y);

    // Keep only the first 9, rest will be grouped as "Others"
    // const others = array.slice(9).reduce((acc: any, curr: any) => {
    //   return acc + curr.y;
    // }, 0);
    // array = array.slice(0, 9);
    // if (others > 0) array.push({ x: "Others", y: others });

    // Show top 10 only
    array = array.slice(0, 10);

    return array;
  }, [data, orderBy]);

  return (
    <Paper
      sx={{
        p: { xs: 3, md: 3.5 },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          mb: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3" mr={1.25}>
          Top Chargers
        </Typography>
        <Select
          className="rounded"
          sx={{
            minWidth: 180,
            color: (theme) => theme.customColors.text.plain,
          }}
          size="small"
          value={orderBy}
          onChange={(e) => setOrderBy(e.target.value)}
        >
          {Object.keys(filterLabels).map((el, i) => (
            <MenuItem key={i} value={el}>
              {filterLabels[el]}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            minHeight: 0,
          }}
        >
          <Bar
            data={{
              labels: chartData?.map((el: any) => el.x) || [],
              datasets: [
                {
                  data: chartData?.map((el: any) => el.y) || [],
                  borderWidth: 0,
                  backgroundColor(ctx: any) {
                    const maxIndex = chartData?.length;
                    const alpha = lerp(1, 0.5, ctx.dataIndex / maxIndex);
                    return `rgba(31, 107, 186, ${alpha})`;
                  },
                  borderRadius: 1,
                  maxBarThickness: 12,
                },
              ],
            }}
            options={{
              indexAxis: "y",
              scales: {
                yAxis: {
                  grid: {
                    display: false,
                    tickWidth: 0,
                    tickLength: 16,
                    drawBorder: false,
                  },
                  ticks: {
                    display: true,
                    color: theme.palette.text.primary,
                  },
                },
                xAxis: {
                  title: {
                    display: true,
                    text:
                      filterLabels[orderBy] +
                      (orderBy === "amount" ? " (₹)" : ""),
                    padding: {
                      top: 8,
                      bottom: 0,
                    },
                    color: theme.customColors.text.grey,
                    font: {
                      weight: "400",
                      size: 12,
                    },
                  },
                  ticks: {
                    display: true,
                    stepSize: 1,
                  },
                  grid: {
                    borderDash: [5],
                    tickWidth: 0,
                    tickLength: 16,
                    drawBorder: false,
                  },
                },
              },
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  caretSize: 5,
                  mode: "y",
                  intersect: false,
                  yAlign: "center",
                  displayColors: false,
                  caretPadding: 16,
                  titleFont: {
                    weight: "400",
                  },
                  bodyFont: {
                    weight: "500",
                  },
                },
              },
              interaction: {
                mode: "y",
                intersect: false,
              },
            }}
          />
        </Box>
      )}
    </Paper>
  );
};

export default TopChargers;
