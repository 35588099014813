import { AddRounded, FilterAltOutlined } from "@mui/icons-material";
import { Box, Button, Hidden, IconButton, Typography } from "@mui/material";
import RangeSelector from "components/RangeSelector";
import { useState } from "react";
import { useQuery } from "react-query";

import { authorizedFetch, getPermissions } from "utils";
import { LEASE_URL } from "utils/constants";
import FiltersDrawer from "./FiltersDrawer";

const AppBar = ({
  setAddVendorDialog,
  filters,
  setFilters,
  filterLabels,
}: any) => {
  const { canWrite } = getPermissions("rental:vehicles");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const vendorsUrl = `${LEASE_URL}/company/vendors`;

  const {
    // isLoading: vendorsLoading,
    data: vendorsData,
    // refetch: refetchVendors,
  } = useQuery(["getVendors"], () => authorizedFetch(vendorsUrl));

  return (
    <Box
      sx={{
        width: 1,
        py: 3,
        px: { xs: 2, md: 7 },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h2">
        Vendors
        <span style={{ fontSize: 18, marginLeft: 10 }}>
          ({vendorsData?.data?.count || 0})
        </span>
      </Typography>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(:first-of-type)": {
              ml: 3,
            },
            mr: 3,
          }}
        >
          {canWrite && (
            <Button
              sx={{
                // width: { md: 300 },
                px: 6,
                py: 1,
                borderRadius: 10,
                borderWidth: "1.5px !important",
                borderColor: (theme) => theme.palette.primary.main,
                textTransform: "none",
                fontWeight: 500,
              }}
              variant="outlined"
              startIcon={<AddRounded />}
              onClick={() => setAddVendorDialog(true)}
            >
              Add New
            </Button>
          )}
        </Box>
        <Hidden implementation="css" mdUp>
          <IconButton onClick={() => setDrawerOpen(true)}>
            {/* <Badge
            color="primary"
            variant="dot"
            invisible={
              ![filters.access, filters.health, filters.locations].some(
                (el) => el.length > 0
              )
            }
          > */}
            <FilterAltOutlined />
            {/* </Badge> */}
          </IconButton>
          <FiltersDrawer
            {...{
              open: drawerOpen,
              onClose: () => setDrawerOpen(false),
              filters,
              setFilters,
              filterLabels,
            }}
          />
        </Hidden>
        <Hidden implementation="css" mdDown>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              alignItems: "center",
              "& > :not(:first-of-type)": {
                ml: 3,
              },
            }}
          >
            <RangeSelector
              setRange={(range: any) => {
                setFilters((prev: any) => ({
                  ...prev,
                  time: range,
                }));
              }}
            />
          </Box>
        </Hidden>
      </Box>
    </Box>
  );
};

export default AppBar;
