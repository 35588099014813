import { EditOutlined, ErrorOutline, HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Step,
  StepButton,
  Stepper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { queryClient } from "index";
import { useEffect, useRef, useState } from "react";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { AUTH_URL, RETAIL_URL } from "utils/constants";
import { ReactComponent as UploadIcon } from "assets/images/upload.svg";
import { ReactComponent as FileUploadedIcon } from "assets/images/file-uploaded.svg";
import { useQuery } from "react-query";

const inputObj = {
  company: "",
  vehicleModel: "",
  company2: "",
  vehicleModel2: "",
  type: "",
  primaryPrerequisite: "",
  secondaryPrerequisite: "",
  primaryFileName: "",
  secondaryFileName: "",
  notes: "",
  primaryMinor: 0,
  primaryMajor: 0,
  primaryPatch: 0,
  secondaryMinor: 0,
  secondaryMajor: 0,
  secondaryPatch: 0,
  json: "",
  speedometerType: "",
};

const filesObj = {
  primary: null,
  secondary: null,
  batteryAdcLog: null,
  log: null,
  read: null,
  vehicleControl: null,
  write: null,
};

const AddFileOTADialog = ({ open, handleClose }: any) => {
  const [input, setInput] = useState({ ...inputObj });
  const steps = ["Basic Details", "Firmware Details", "File Upload", "Review"];
  const [step, setStep] = useState(0);
  const [primaryPrerequisites, setPrimaryPrerequisites] = useState([]);
  const [secondaryPrerequisites, setSecondaryPrerequisites] = useState([]);
  const [checkboxEnabled, setCheckboxEnabled] = useState(false);

  const [markedFirmwareForTesting, setMarkedFirmwareForTesting] =
    useState(false);

  const fileInputRefs: any = {
    primary: useRef(null),
    secondary: useRef(null),
    batteryAdcLog: useRef(null),
    log: useRef(null),
    read: useRef(null),
    vehicleControl: useRef(null),
    write: useRef(null),
  };

  const [files, setFiles] = useState<
    Record<string, File> | Record<string, null>
  >({ ...filesObj });

  const isPrimaryMainFileValid = () => {
    if (!files.primary) return false;
    const fileType = files.primary.name.split(".").pop()?.toLowerCase();

    if (input.type === "PRIMARY" && fileType !== "bin") return false;
    // if (input.type === "SECONDARY" && fileType !== "pkg") return false;
    if (input.type === "BOTH" && fileType !== "bin" && fileType !== "pkg")
      return false;

    return true;
  };

  const isSecondaryMainFileValid = () => {
    if (!files.secondary) return false;
    const fileType = files.secondary.name.split(".").pop()?.toLowerCase();

    // if (input.type === "PRIMARY" && fileType !== "bin") return false;
    if (input.type === "SECONDARY" && fileType !== "pkg") return false;
    if (input.type === "BOTH" && fileType !== "bin" && fileType !== "pkg")
      return false;

    return true;
  };

  // const handleFileChange = (type: string, event: any) => {
  //   setFiles((prevState) => ({
  //     ...prevState,
  //     [type]: event.target.files[0],
  //   }));
  // };

  const handleFileChange = (type: string, event: any) => {
    const file = event.target.files[0];

    if (file) {
      const fileName = file.name;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      console.log(extFile, type, "extFile");
      if (type === "primary" && extFile !== "bin") {
        snackbar.error("Only .bin files are allowed for PRIMARY!");
        return;
      } else if (type === "secondary" && extFile !== "pkg") {
        snackbar.error("Only .pkg files are allowed for SECONDARY!");
        return;
      } else if (
        ["batteryAdcLog", "log", "read", "vehicleControl", "write"].includes(
          type
        ) &&
        extFile !== "json"
      ) {
        snackbar.error("Only .json files are allowed");
        return;
      } else {
        setFiles((prevState) => ({
          ...prevState,
          [type]: event.target.files[0],
        }));
      }
    }
  };

  const handleSave = () => {
    const formData = new FormData();

    for (const jsonType in files) {
      const file = files[jsonType as keyof typeof files];
      if (file) {
        const fileType = file.name.split(".").pop()?.toLowerCase();
        console.log(fileType, jsonType, "fileType");
        if (fileType === "json") formData.append(jsonType, file);
      }
    }

    if (isPrimaryMainFileValid() && files.primary) {
      formData.append("primary", files.primary);
    }

    if (isSecondaryMainFileValid() && files.secondary) {
      formData.append("secondary", files.secondary);
    }

    let requestBody: any = {
      ...((input.type === "PRIMARY" || input.type === "BOTH") && {
        "primary[major]": input.primaryMajor,
        "primary[minor]": input.primaryMinor,
        "primary[patch]": input.primaryPatch,
        "primary[fileName]": input.primaryFileName,
        ...(input.primaryPrerequisite?.length > 0 && {
          "primary[preRequisite]": input.primaryPrerequisite,
        }),
      }),
      ...((input.type === "SECONDARY" || input.type === "BOTH") && {
        "secondary[major]": input.secondaryMajor,
        "secondary[minor]": input.secondaryMinor,
        "secondary[patch]": input.secondaryPatch,
        "secondary[fileName]": input.secondaryFileName,
        ...(input.secondaryPrerequisite?.length > 0 && {
          "secondary[preRequisite]": input.secondaryPrerequisite,
        }),
      }),
      ...(!markedFirmwareForTesting
        ? {
            company: input.company,
            vehicleModel: input.vehicleModel,
          }
        : {
            company: input.company2,
            vehicleModel: input.vehicleModel2,
            testing: true,
            "production[company]": input.company,
            "production[vehicleModel]": input.vehicleModel,
          }),
      json: input.json,
      notes: input.notes,
    };

    for (const key in requestBody) {
      formData.append(key, requestBody[key]);
    }

    if (input.type === "BOTH") {
      if (!(files.primary && files.secondary)) {
        snackbar.error("Both files need to be upload");
      }
    }

    authorizedFetch(`${RETAIL_URL}/inventory/ota/upload`, {
      method: "POST",
      onlyBody: formData,
    })
      .then((res) => {
        setLoader(false);
        if (res.meta.success === true) {
          snackbar.success(`Success. Firmware file added sucessfully`);
          queryClient.resetQueries("getOtaData");
          handleClose();
        } else {
          console.error(res);
          snackbar.error(res.msg || "An error occured");
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error("An error occured");
      });
  };

  useEffect(() => {
    if (open) return;
    setInput({ ...inputObj });
    setFiles({ ...filesObj });
    setCheckboxEnabled(false);
    setMarkedFirmwareForTesting(false);
    setStep(0);
  }, [open]);

  const { isLoading: companiesLoading, data: companiesData } = useQuery(
    "getCompanies",
    () => authorizedFetch(`${AUTH_URL}/company/list`)
  );
  const companies =
    companiesData?.data?.constructor === Array ? companiesData.data : [];

  const { isLoading: isVehicleModelsLoading, data: vehicleModelsData } =
    useQuery(
      ["getVehicleModels", input.company],
      () =>
        authorizedFetch(
          `${RETAIL_URL}/inventory/ota/vehicle-models?companyId=${input.company}`
        ),
      {
        enabled: !!input.company,
      }
    );
  const vehicleModels =
    vehicleModelsData?.data?.constructor === Array
      ? vehicleModelsData.data
      : [];

  const { isLoading: isVehicleModels2Loading, data: vehicleModels2Data } =
    useQuery(
      ["getVehicleModels", input.company2],
      () =>
        authorizedFetch(
          `${RETAIL_URL}/inventory/ota/vehicle-models?companyId=${input.company2}`
        ),
      {
        enabled: !!input.company2,
      }
    );
  const vehicleModels2 =
    vehicleModels2Data?.data?.constructor === Array
      ? vehicleModels2Data.data
      : [];

  const { isLoading: isJsonVersionsLoading, data: jsonVersionsData } = useQuery(
    ["getJsonVersions", input.company, input.vehicleModel],
    () =>
      authorizedFetch(
        `${RETAIL_URL}/inventory/ota/json-versions?companyId=${input.company}&vehicleModel=${input.vehicleModel}`
      ),
    {
      enabled: !!input.company && !!input.vehicleModel,
    }
  );
  const jsonVersions =
    jsonVersionsData?.data?.constructor === Array ? jsonVersionsData.data : [];

  const getPrimaryPreRequisite = () => {
    authorizedFetch(
      `${RETAIL_URL}/inventory/ota/prerequisites?companyId=${input.company}&vehicleModel=${input.vehicleModel}&type=PRIMARY`
    )
      .then((res) => setPrimaryPrerequisites(res?.data))
      .catch((err) => console.error(err));
  };

  const getSecondaryPreRequisite = () => {
    authorizedFetch(
      `${RETAIL_URL}/inventory/ota/prerequisites?companyId=${input.company}&vehicleModel=${input.vehicleModel}&type=SECONDARY`
    )
      .then((res) => setSecondaryPrerequisites(res?.data))
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (input.company && input.vehicleModel && input.type) {
      if (input.type === "PRIMARY") {
        getPrimaryPreRequisite();
      } else if (input.type === "SECONDARY") {
        getSecondaryPreRequisite();
      } else if (input.type === "BOTH") {
        getPrimaryPreRequisite();
        getSecondaryPreRequisite();
      }
    }
    // eslint-disable-next-line
  }, [input.company, input.vehicleModel, input.type]);

  const validateVersion = (version: any) => {
    const re = /^\d+\.\d+$/;
    return re.test(version);
  };
  function handleNext() {
    if (step === steps.length - 1) {
      handleSave();
    } else setStep(step + 1);
  }
  function handleBack() {
    setStep(step - 1);
  }

  const primaryCheck =
    input.type === "PRIMARY" || input.type === "BOTH"
      ? input.primaryMajor !== 0
      : true;

  const secondaryCheck =
    input.type === "SECONDARY" || input.type === "BOTH"
      ? input.secondaryMajor !== 0
      : true;

  const primaryFileNameCheck =
    input.type === "PRIMARY" || input.type === "BOTH"
      ? input.primaryFileName !== ""
      : true;

  const secondaryFileNameCheck =
    input.type === "SECONDARY" || input.type === "BOTH"
      ? input.secondaryFileName !== ""
      : true;

  const primaryFileCheck =
    input.type === "PRIMARY" || input.type === "BOTH"
      ? files.primary !== null
      : true;

  const secondaryFileCheck =
    input.type === "SECONDARY" || input.type === "BOTH"
      ? files.secondary !== null
      : true;

  const JSONFileCheck = checkboxEnabled
    ? files.batteryAdcLog !== null &&
      files.log !== null &&
      files.read !== null &&
      files.vehicleControl !== null &&
      files.write !== null
    : true;

  function isComplete(step: number) {
    switch (step) {
      case 0:
        return (
          ![
            input.company,
            input.vehicleModel,
            // input.speedometerType,
            input.type,
          ].includes("") && input.speedometerType !== undefined
        );
      case 1:
        return primaryCheck && secondaryCheck && input.json !== "";
      case 2:
        return (
          primaryFileCheck &&
          secondaryFileCheck &&
          JSONFileCheck &&
          primaryFileNameCheck &&
          secondaryFileNameCheck
        );
      default:
        break;
    }
  }

  // useEffect(()=>{
  //   setInput({...input,vehicleModel2: input.vehicleModel})
  // },[input.vehicleModel])

  const disabled =
    step === 0
      ? [
          input.company,
          input.vehicleModel,
          // input.speedometerType,
          input.type,
        ].includes("") || input.speedometerType === undefined
      : step === 1
      ? !primaryCheck || !secondaryCheck || input.json === ""
      : step === 2
      ? !primaryFileCheck ||
        !secondaryFileCheck ||
        !JSONFileCheck ||
        !primaryFileNameCheck ||
        !secondaryFileNameCheck
      : false;

  function convertToTitleCase(inputString: any) {
    const words = inputString.split(/(?=[A-Z])/);

    const titleCaseString = words
      .map((word: any) => {
        const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
        return capitalizedWord;
      })
      .join(" ");

    return titleCaseString;
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          Files
        </Box>
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent>
        <Stepper
          sx={{ mt: 1, mb: 3, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          // nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton
                disabled
                // onClick={() => setStep(i)}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>

        <Box
          sx={{
            maxWidth: { xs: 280, sm: 660 },
            mx: "auto",
            py: 2,
            display: "grid",
            gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
            gap: 5,
            "& .required": {
              color: "red",
            },
          }}
        >
          {step === 0 && (
            <>
              <Box>
                <Typography>
                  Company Name <span className="required">&#x2a;</span>
                </Typography>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <Select
                    sx={{ borderRadius: 10 }}
                    value={input.company}
                    onChange={(e: any) => {
                      // setInput({ ...input, company: e.target.value });
                      const selectedCompany = e.target.value;
                      setInput((prevInput) => ({
                        ...prevInput,
                        company: selectedCompany,
                        vehicleModel: "",
                      }));
                    }}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <em>Select your company name</em>
                    </MenuItem>
                    {companiesLoading ? (
                      <MenuItem disabled>Loading...</MenuItem>
                    ) : (
                      companies.map((company: any) => (
                        <MenuItem key={company._id} value={company._id}>
                          {company.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography>
                  Vehicle Model <span className="required">&#x2a;</span>
                </Typography>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <Select
                    sx={{ borderRadius: 10 }}
                    disabled={input.company?.length === 0}
                    value={input.vehicleModel}
                    onChange={(e: any) => {
                      const modelObject: any = vehicleModels.filter(
                        (model: any) => model._id === e.target.value
                      )?.[0];
                      const components = modelObject?.components;
                      const filteredCluster = components.filter(
                        (el: any) => el.category === "CONTROLLER"
                      )?.[0];
                      const clusterKey = filteredCluster?.modelId?.[0]?.key;
                      setInput({
                        ...input,
                        speedometerType: clusterKey,
                        vehicleModel: e.target.value,
                      });
                    }}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <em>Select your vehicle model</em>
                    </MenuItem>
                    {isVehicleModelsLoading ? (
                      <MenuItem disabled>Loading...</MenuItem>
                    ) : (
                      vehicleModels.map((model: any) => (
                        <MenuItem key={model._id} value={model._id}>
                          {model.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography>
                  Speedometer Type <span className="required">&#x2a;</span>
                </Typography>

                <TextField
                  fullWidth
                  InputProps={{ sx: { borderRadius: 10, mt: 1 } }}
                  placeholder="Will be autofilled"
                  value={
                    input.speedometerType
                      ? input.speedometerType
                      : "No Speedometer found"
                  }
                  disabled
                />
              </Box>
              <Box>
                <Typography>
                  Update Type <span className="required">&#x2a;</span>
                </Typography>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <Select
                    sx={{ borderRadius: 10 }}
                    disabled={
                      input.company?.length === 0 ||
                      input.vehicleModel?.length === 0
                    }
                    value={input.type}
                    onChange={(e) =>
                      setInput({ ...input, type: e.target.value })
                    }
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      <em>Select update type</em>
                    </MenuItem>
                    <MenuItem value="PRIMARY">PRIMARY</MenuItem>
                    {input.speedometerType !== "SLED" && (
                      <MenuItem value="SECONDARY">SECONDARY</MenuItem>
                    )}
                    {input.speedometerType !== "SLED" && (
                      <MenuItem value="BOTH">BOTH</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  width: 1,
                  mb: 1,
                  p: 2,
                  gridColumn: "1 / span 2",
                  borderRadius: 2,
                  border: (theme) => `2px solid ${theme.customColors.border}`,
                }}
              >
                <Box
                  sx={{
                    width: 1,
                    mt: -1,
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>
                    <b>Mark this Firmware for Testing?</b>
                  </Typography>
                  <Switch
                    value={markedFirmwareForTesting}
                    onChange={(e: any) =>
                      setMarkedFirmwareForTesting(e.target.checked)
                    }
                  />
                </Box>
                <Typography variant="body2" sx={{ mr: 3, color: "#808080" }}>
                  Firmware that is marked as "In Testing" will show as belonging
                  to the selected company until the status is changed to "In
                  Production"
                </Typography>
              </Box>
              {markedFirmwareForTesting && (
                <>
                  <Box>
                    <Typography>
                      Company Name <span className="required">&#x2a;</span>
                    </Typography>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                      <Select
                        sx={{ borderRadius: 10 }}
                        value={input.company2}
                        onChange={(e: any) => {
                          const selectedCompany = e.target.value;
                          setInput((prevInput) => ({
                            ...prevInput,
                            company2: selectedCompany,
                            vehicleModel2: "",
                          }));
                        }}
                        displayEmpty
                      >
                        <MenuItem disabled value="">
                          <em>Select your company name</em>
                        </MenuItem>
                        {companiesLoading ? (
                          <MenuItem disabled>Loading...</MenuItem>
                        ) : (
                          companies.map((company: any) => (
                            <MenuItem key={company._id} value={company._id}>
                              {company.name}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    <Typography>
                      Vehicle Model <span className="required">&#x2a;</span>
                    </Typography>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                      <Select
                        sx={{ borderRadius: 10 }}
                        disabled={input.company2?.length === 0}
                        value={input.vehicleModel2}
                        onChange={(e: any) => {
                          setInput({ ...input, vehicleModel2: e.target.value });
                        }}
                        displayEmpty
                      >
                        <MenuItem disabled value="">
                          <em>Select your vehicle model</em>
                        </MenuItem>
                        {isVehicleModels2Loading ? (
                          <MenuItem disabled>Loading...</MenuItem>
                        ) : (
                          vehicleModels2.map((model: any) => (
                            <MenuItem key={model._id} value={model._id}>
                              {model.name}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                </>
              )}
            </>
          )}

          {step === 1 && (
            <>
              {(input.type === "PRIMARY" || input.type === "BOTH") && (
                <>
                  <Box>
                    <Typography>
                      Firmware Version (Primary)
                      <span className="required">&#x2a;</span>
                    </Typography>
                    <Box sx={{ display: "flex", gap: 3, mt: 1 }}>
                      <TextField
                        type="tel"
                        value={input.primaryMajor || 0}
                        InputProps={{
                          sx: { borderRadius: 10 },
                        }}
                        inputProps={{ style: { textAlign: "center" } }}
                        onChange={(e) => {
                          if (e.target.value.length <= 2) {
                            setInput({
                              ...input,
                              primaryMajor: parseInt(e.target.value),
                            });
                          }
                        }}
                      />
                      <TextField
                        // label="Minor"
                        type="tel"
                        value={input.primaryMinor || 0}
                        onChange={(e) => {
                          if (e.target.value.length <= 2) {
                            setInput({
                              ...input,
                              primaryMinor: parseInt(e.target.value),
                            });
                          }
                        }}
                        InputProps={{
                          sx: { borderRadius: 10 },
                        }}
                        inputProps={{ style: { textAlign: "center" } }}
                      />

                      <TextField
                        // label="Patch"
                        type="tel"
                        value={input.primaryPatch || 0}
                        onChange={(e) => {
                          if (e.target.value.length <= 2) {
                            setInput({
                              ...input,
                              primaryPatch: parseInt(e.target.value),
                            });
                          }
                        }}
                        InputProps={{
                          sx: { borderRadius: 10 },
                        }}
                        inputProps={{ style: { textAlign: "center" } }}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Typography>Previous F/W Requirements (Primary)</Typography>
                    <Select
                      sx={{ borderRadius: 10, mt: 1, width: "100%" }}
                      value={input.primaryPrerequisite}
                      onChange={(e: any) =>
                        setInput({
                          ...input,
                          primaryPrerequisite: e.target.value,
                        })
                      }
                      displayEmpty
                    >
                      <MenuItem disabled value="">
                        <em>Select your primary prerequisites</em>
                      </MenuItem>
                      {primaryPrerequisites?.map((model: any) => (
                        <MenuItem key={model._id} value={model._id}>
                          {model.version}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </>
              )}
              {(input.type === "SECONDARY" || input.type === "BOTH") && (
                <>
                  <Box>
                    <Typography>
                      Firmware Version (Secondary)
                      <span className="required">&#x2a;</span>
                    </Typography>
                    <Box sx={{ display: "flex", gap: 3, mt: 1 }}>
                      <TextField
                        type="tel"
                        value={input.secondaryMajor || 0}
                        InputProps={{
                          sx: { borderRadius: 10 },
                        }}
                        inputProps={{ style: { textAlign: "center" } }}
                        onChange={(e) => {
                          if (e.target.value.length <= 2) {
                            setInput({
                              ...input,
                              secondaryMajor: parseInt(e.target.value),
                            });
                          }
                        }}
                      />
                      <TextField
                        // label="Minor"
                        type="tel"
                        value={input.secondaryMinor || 0}
                        onChange={(e) => {
                          if (e.target.value.length <= 2) {
                            setInput({
                              ...input,
                              secondaryMinor: parseInt(e.target.value),
                            });
                          }
                        }}
                        InputProps={{
                          sx: { borderRadius: 10 },
                        }}
                        inputProps={{ style: { textAlign: "center" } }}
                      />

                      <TextField
                        // label="Patch"
                        type="tel"
                        value={input.secondaryPatch || 0}
                        onChange={(e) => {
                          if (e.target.value.length <= 2) {
                            setInput({
                              ...input,
                              secondaryPatch: parseInt(e.target.value),
                            });
                          }
                        }}
                        InputProps={{
                          sx: { borderRadius: 10 },
                        }}
                        inputProps={{ style: { textAlign: "center" } }}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Typography>
                      Previous F/W Requirements (Secondary)
                    </Typography>
                    <Select
                      sx={{ borderRadius: 10, mt: 1, width: "100%" }}
                      value={input.secondaryPrerequisite}
                      onChange={(e: any) =>
                        setInput({
                          ...input,
                          secondaryPrerequisite: e.target.value,
                        })
                      }
                      displayEmpty
                    >
                      <MenuItem disabled value="">
                        <em>Select your primary prerequisites</em>
                      </MenuItem>
                      {secondaryPrerequisites?.map((model: any) => (
                        <MenuItem key={model._id} value={model._id}>
                          {model.version}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </>
              )}

              <Box>
                <Typography>
                  JSON Version
                  <span className="required">&#x2a;</span>
                </Typography>

                <Select
                  sx={{ borderRadius: 10, mt: 1, width: "100%" }}
                  disabled={
                    checkboxEnabled ||
                    input.company?.length === 0 ||
                    input.vehicleModel?.length === 0
                  }
                  value={checkboxEnabled ? "" : input.json}
                  onChange={(e: any) =>
                    setInput({ ...input, json: e.target.value })
                  }
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>Select JSON Version</em>
                  </MenuItem>
                  {isJsonVersionsLoading ? (
                    <MenuItem disabled>Loading...</MenuItem>
                  ) : (
                    jsonVersions.map((el: any) => (
                      <MenuItem key={el} value={el}>
                        {el}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </Box>

              <Box sx={{ mt: -1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={checkboxEnabled}
                      onChange={(e: any) =>
                        setCheckboxEnabled(e.target.checked)
                      }
                    />
                  }
                  label="Update Required"
                />

                <TextField
                  fullWidth
                  label="Updated JSON Version"
                  value={!checkboxEnabled ? "" : input.json}
                  error={!!input.json && !validateVersion(input.json)}
                  onChange={(e: any) => {
                    if (
                      /^[0-9.]{0,3}$/.test(e.target.value) ||
                      e.target.value.length < input.json.length
                    )
                      setInput({ ...input, json: e.target.value });
                  }}
                  disabled={!checkboxEnabled}
                  sx={{ mt: -0.5 }}
                  InputProps={{
                    sx: { borderRadius: 10 },
                  }}
                />
              </Box>
              <Box
                sx={{
                  gridColumn: "1 / span 2",
                  gridRow: "auto",
                }}
              >
                <Typography className="label" mb={1}>
                  Notes
                </Typography>
                <TextField
                  sx={{
                    width: 1,
                    maxWidth: "100%",
                    "& textarea": { p: 1 },
                  }}
                  multiline
                  rows={3}
                  placeholder="Text goes here"
                  value={input.notes}
                  onChange={(e: any) =>
                    setInput({ ...input, notes: e.target.value })
                  }
                />
              </Box>
            </>
          )}

          {step === 2 && (
            <>
              {(input.type === "PRIMARY" || input.type === "BOTH") && (
                <>
                  <Box>
                    <Typography className="label">
                      File Name (Primary)
                      <span className="required">&#x2a;</span>
                    </Typography>
                    {console.log(input.primaryFileName, "input?")}
                    <TextField
                      fullWidth
                      InputProps={{
                        sx: { borderRadius: 10, mt: 1, width: "100%" },
                      }}
                      size="small"
                      placeholder="Enter your file name"
                      value={input.primaryFileName || ""}
                      onChange={(e: any) => {
                        const inputValue = e.target.value;
                        setInput({
                          ...input,
                          primaryFileName: inputValue,
                        });
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography>
                      Primary OTA File
                      <span className="required">&#x2a;</span>
                    </Typography>
                    <>
                      {files?.primary === null ? (
                        <FormControl
                          sx={{
                            margin: "20px 0px",
                            width: input.type === "BOTH" ? "100%" : "50%",
                          }}
                        >
                          <Button
                            variant="outlined"
                            component="label"
                            sx={{ gap: 2, height: 40, borderRadius: 10 }}
                          >
                            <UploadIcon />
                            Upload
                            <input
                              type="file"
                              hidden
                              ref={fileInputRefs.primary}
                              onChange={(e) => handleFileChange("primary", e)}
                              accept={
                                input.type === "BOTH"
                                  ? ".bin"
                                  : input.type === "PRIMARY"
                                  ? ".bin"
                                  : input.type === "SECONDARY"
                                  ? ".pkg"
                                  : ""
                              }
                            />
                          </Button>
                        </FormControl>
                      ) : (
                        <>
                          {files["primary"] && (
                            <Box
                              display="flex"
                              mt={1}
                              sx={{ alignItems: "center" }}
                            >
                              <FileUploadedIcon />
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  color: "#366AB4",
                                  width: "50%",
                                  wordWrap: "break-word",
                                  ml: 1,
                                }}
                              >
                                {files["primary"]?.name}
                              </Typography>
                              <Button
                                variant="text"
                                sx={{ color: "#EB5C5C" }}
                                onClick={(e) =>
                                  setFiles((prevState) => ({
                                    ...prevState,
                                    primary: null,
                                  }))
                                }
                              >
                                Remove File
                              </Button>
                            </Box>
                          )}
                        </>
                      )}
                    </>
                  </Box>
                </>
              )}
              {(input.type === "SECONDARY" || input.type === "BOTH") && (
                <>
                  <Box>
                    <Typography className="label">
                      File Name (Secondary)
                      <span className="required">&#x2a;</span>
                    </Typography>
                    <TextField
                      fullWidth
                      InputProps={{
                        sx: { borderRadius: 10, mt: 1, width: "100%" },
                      }}
                      size="small"
                      placeholder="Enter your file name"
                      value={input.secondaryFileName || ""}
                      onChange={(e: any) => {
                        const inputValue = e.target.value;
                        setInput({
                          ...input,
                          secondaryFileName: inputValue,
                        });
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography>
                      Secondary OTA File
                      <span className="required">&#x2a;</span>
                    </Typography>
                    <>
                      {files?.secondary === null ? (
                        <FormControl
                          sx={{
                            margin: "20px 0px",
                            width: input.type === "BOTH" ? "100%" : "50%",
                          }}
                        >
                          <Button
                            variant="outlined"
                            component="label"
                            sx={{ gap: 2, height: 40, borderRadius: 10 }}
                          >
                            <UploadIcon />
                            Upload
                            <input
                              type="file"
                              hidden
                              ref={fileInputRefs.secondary}
                              onChange={(e) => handleFileChange("secondary", e)}
                              // accept={
                              //   input.type === "SECONDARY"
                              //     ? ".pkg"
                              //     : input.type === "BOTH" ||
                              //       input.type === "PRIMARY"
                              //     ? ".bin"
                              //     : ""
                              // }
                              accept={
                                input.type === "BOTH"
                                  ? ".pkg"
                                  : input.type === "SECONDARY"
                                  ? ".pkg"
                                  : input.type === "PRIMARY"
                                  ? ".bin"
                                  : ""
                              }
                            />
                          </Button>
                        </FormControl>
                      ) : (
                        <>
                          {files["secondary"] && (
                            <Box
                              display="flex"
                              mt={1}
                              sx={{ alignItems: "center" }}
                            >
                              <FileUploadedIcon />
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  color: "#366AB4",
                                  width: input.type === "BOTH" ? "50%" : "auto",
                                  wordWrap: "break-word",
                                  ml: 1,
                                }}
                              >
                                {files["secondary"]?.name}
                              </Typography>
                              <Button
                                variant="text"
                                sx={{ color: "#EB5C5C", ml: 1 }}
                                onClick={(e) =>
                                  setFiles((prevState) => ({
                                    ...prevState,
                                    secondary: null,
                                  }))
                                }
                              >
                                Remove File
                              </Button>
                            </Box>
                          )}
                        </>
                      )}
                    </>
                  </Box>
                </>
              )}
              {checkboxEnabled && (
                <>
                  <Box
                    sx={{
                      gridColumn: { sm: "span 2" },
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      JSON (Update Required)
                    </Typography>
                  </Box>
                  {[
                    "batteryAdcLog",
                    "log",
                    "read",
                    "vehicleControl",
                    "write",
                  ].map((type, index) => (
                    <Box key={index}>
                      <Typography>
                        Upload {convertToTitleCase(type)}
                        <span className="required">&#x2a;</span>
                      </Typography>
                      {files[type] === null ? (
                        <FormControl sx={{ margin: "20px 0px", width: "100%" }}>
                          <Button
                            variant="outlined"
                            component="label"
                            sx={{ gap: 2, height: 60, borderRadius: 10 }}
                          >
                            <UploadIcon />
                            Upload
                            <input
                              type="file"
                              hidden
                              ref={fileInputRefs[type]}
                              onChange={(e) => handleFileChange(type, e)}
                              // accept={
                              //   input.type === "PRIMARY"
                              //     ? ".bin"
                              //     : input.type === "SECONDARY"
                              //     ? ".pkg"
                              //     : ".bin, .pkg"
                              // }
                              accept={".json"}
                            />
                          </Button>
                        </FormControl>
                      ) : (
                        <>
                          {files[type] && (
                            <Box
                              display="flex"
                              mt={1}
                              sx={{ alignItems: "center" }}
                            >
                              <FileUploadedIcon />
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  color: "#366AB4",
                                  width: "50%",
                                  wordWrap: "break-word",
                                  ml: 1,
                                }}
                              >
                                {files[type]?.name}
                              </Typography>
                              <Button
                                variant="text"
                                sx={{ color: "#EB5C5C" }}
                                onClick={(e) =>
                                  setFiles((prevState) => {
                                    const updatedFiles = { ...prevState };
                                    updatedFiles[type] = null;
                                    return updatedFiles;
                                  })
                                }
                              >
                                Remove File
                              </Button>
                            </Box>
                          )}
                        </>
                      )}
                    </Box>
                  ))}
                </>
              )}
            </>
          )}

          {step === 3 && (
            <Box
              sx={{
                width: 650,
                mx: "auto",
                "& .table": {
                  borderCollapse: "collapse",
                  width: 1,
                  fontSize: 14,
                  lineHeight: "16px",
                  "& td": {
                    py: 1.25,
                    px: 2,
                  },
                  "& .bold": {
                    fontWeight: 500,
                  },
                  "& .header": {
                    px: 2,
                    py: 1,
                    position: "relative",
                    "& td": {
                      position: "absolute",
                      verticalAlign: "middle",
                      bgcolor: (theme) => theme.customColors.header,
                      width: 1,
                      borderRadius: "4px",
                      fontSize: 16,
                      fontWeight: 600,
                      "& span": {
                        display: "inline-block",
                        transform: "translateY(1px)",
                      },
                    },
                  },
                  "& .first > td": {
                    pt: 9,
                  },
                  "& .last > td": {
                    pb: 3,
                  },
                },
              }}
            >
              <table className="table">
                <tbody>
                  {[
                    { header: "Basic Details", onEdit: () => setStep(0) },
                    {
                      label: "Company Name",
                      value: companies
                        .filter((el: any) => el._id === input.company)
                        .map((el: any) => el.name)[0],
                      required: true,
                    },
                    {
                      label: "Vehicle Model",
                      value: vehicleModels
                        .filter((el: any) => el._id === input.vehicleModel)
                        .map((el: any) => el.name)[0],
                      required: true,
                    },
                    {
                      label: "Speedometer Type",
                      value: input.speedometerType,
                      required: true,
                    },
                    { label: "Update Type", value: input.type, required: true },
                    { header: "Firmware Details", onEdit: () => setStep(1) },
                    ...(markedFirmwareForTesting
                      ? [
                          {
                            label: "Version Marked For",
                            value: "Testing",
                          },
                        ]
                      : []),
                    ...(input.type === "PRIMARY" || input.type === "BOTH"
                      ? [
                          {
                            label: "Primary Firmware Version",
                            value: `${input.primaryMajor}.${input.primaryMinor}.${input.primaryPatch}`,
                            required: true,
                          },
                        ]
                      : []),
                    ...(input.type === "PRIMARY" || input.type === "BOTH"
                      ? [
                          {
                            label: "Previous F/W Requirement (Primary)",
                            value: primaryPrerequisites
                              .filter(
                                (el: any) =>
                                  el._id === input.primaryPrerequisite
                              )
                              .map((el: any) => el.version)[0],
                          },
                        ]
                      : []),
                    ...(input.type === "SECONDARY" || input.type === "BOTH"
                      ? [
                          {
                            label: "Secondary Firmware Version",
                            value: `${input.secondaryMajor}.${input.secondaryMinor}.${input.secondaryPatch}`,
                            required: true,
                          },
                        ]
                      : []),
                    ...(input.type === "SECONDARY" || input.type === "BOTH"
                      ? [
                          {
                            label: "Previous F/W Requirement (Secondary)",
                            value: secondaryPrerequisites
                              .filter(
                                (el: any) =>
                                  el._id === input.secondaryPrerequisite
                              )
                              .map((item: any) => item.version)[0],
                          },
                        ]
                      : []),

                    {
                      label: `JSON Version ${
                        checkboxEnabled ? "Update Required" : ""
                      }`,
                      value: input.json,
                      required: true,
                    },
                    {
                      label: "Notes",
                      value: input.notes,
                    },
                    { header: "File Upload", onEdit: () => setStep(2) },

                    ...(input.type === "PRIMARY" || input.type === "BOTH"
                      ? [
                          {
                            label: "File Name (Primary)",
                            value: input.primaryFileName,
                            required: true,
                          },
                          {
                            label: "Primary OTA File",
                            value: files.primary?.name,
                            required: true,
                          },
                        ]
                      : []),
                    ...(input.type === "SECONDARY" || input.type === "BOTH"
                      ? [
                          {
                            label: "File Name (Secondary)",
                            value: input.secondaryFileName,
                            required: true,
                          },
                          {
                            label: "Secondary OTA File",
                            value: files.secondary?.name,
                            required: true,
                          },
                        ]
                      : []),
                    ...(checkboxEnabled
                      ? [
                          {
                            label: "BATTERYADCLOG",
                            value: files.batteryAdcLog?.name,
                            required: true,
                          },
                          {
                            label: "LOG",
                            value: files.log?.name,
                            required: true,
                          },
                          {
                            label: "READ",
                            value: files.read?.name,
                            required: true,
                          },
                          {
                            label: "VEHICLECONTROL",
                            value: files.vehicleControl?.name,
                            required: true,
                          },
                          {
                            label: "WRITE",
                            value: files.write?.name,
                            required: true,
                          },
                        ]
                      : []),
                  ].map(
                    ({ header, onEdit, label, value, required }, i, arr) => {
                      const isFirst = arr[i - 1]?.header;
                      const isLast = !arr[i + 1] || arr[i + 1].header;

                      return (
                        <tr
                          key={i}
                          className={
                            header
                              ? "header"
                              : `${isFirst ? "first" : ""} ${
                                  isLast ? "last" : ""
                                }`
                          }
                        >
                          {header ? (
                            <td colSpan={2}>
                              <span>{header}</span>
                              <IconButton
                                sx={{ ml: 1.5 }}
                                children={<EditOutlined />}
                                color="primary"
                                size="small"
                                onClick={onEdit}
                              />
                            </td>
                          ) : (
                            <>
                              <td>{label}</td>
                              <td className="bold">
                                {value ||
                                  (required && (
                                    <Box display="flex" alignItems="center">
                                      <ErrorOutline
                                        fontSize="small"
                                        color="error"
                                        style={{ marginRight: 8 }}
                                      />
                                      Required
                                    </Box>
                                  ))}
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {step !== 0 && (
          <Button
            variant="outlined"
            onClick={handleBack}
            sx={{ borderRadius: 10 }}
          >
            Back
          </Button>
        )}
        {step === 0 && (
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{ borderRadius: 10 }}
          >
            Cancel
          </Button>
        )}
        <Button
          sx={{ borderRadius: 10 }}
          onClick={handleNext}
          variant={
            isComplete(step) || step === steps.length - 1
              ? "contained"
              : "outlined"
          }
          disableElevation
          disabled={
            disabled ||
            (markedFirmwareForTesting &&
              (input.company2 === "" || input.vehicleModel2 === ""))
          }
        >
          {step === steps.length - 1 ? "Save" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFileOTADialog;
