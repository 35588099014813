import { Wrapper } from "@googlemaps/react-wrapper";
import { useEffect, useRef, useState } from "react";
import { Box, TextField, InputAdornment, IconButton } from "@mui/material";
import { Clear, Search } from "@mui/icons-material";

const MapWrapper = ({
  lat,
  lng,
  setAddress,
  setLocation,
  location,
  isFirstTime,
  type,
}: any) => {
  return (
    <Wrapper
      libraries={["visualization", "places", "drawing", "geometry"]}
      apiKey={"AIzaSyDqq6Ywsf6L3lrgg_P8BI1Z7hvNbsjMQzY"}
    >
      <ReverseGeocodingComponent
        lat={lat}
        lng={lng}
        setAddress={setAddress}
        setLocation={setLocation}
        location={location}
        isFirstTime={isFirstTime}
        type={type}
      />
    </Wrapper>
  );
};

const ReverseGeocodingComponent = ({
  lat,
  lng,
  setAddress,
  setLocation,
  location,
  isFirstTime,
  type,
}: any) => {
  const [inputValue, setInputValue] = useState("");
  const [tableValue, setTableValue] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const extractAreaName = (addressComponents: any) => {
    const typesOrder = [
      "neighborhood",
      "sublocality",
      "establishment",
      "locality",
    ];

    for (const type of typesOrder) {
      const component = addressComponents.find((comp: any) =>
        comp.types.includes(type)
      );
      if (component) {
        return component.short_name;
      }
    }

    return "N/A";
  };

  useEffect(() => {
    if (!inputRef.current) return;
    setLocation({ ...location, loaded: false, isFirstTime: false });

    const autocomplete = new google.maps.places.Autocomplete(inputRef.current);
    autocomplete.addListener("place_changed", () => {
      const place: any = autocomplete.getPlace();

      if (!place?.geometry) {
        setInputValue("N/A");
        setAddress("N/A");
        return;
      }
      const newLat = place?.geometry?.location.lat();
      const newLng = place?.geometry?.location.lng();

      setAddress(place.name);
      setInputValue(place.name);
      setLocation({ loaded: true, coordinates: { lat: newLat, lng: newLng } });
    });
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const geocoder = new google.maps.Geocoder();

    if (type === "search") {
      if (!isFirstTime) return;

      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK") {
          if (results![0]) {
            setAddress(extractAreaName(results![0].address_components));
          } else {
            setAddress("N/A");
          }
        } else {
          setAddress("N/A");
        }
      });
    } else {
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK") {
          if (results![0]) {
            setTableValue(extractAreaName(results![0].address_components));
          } else {
            setTableValue("N/A");
          }
        } else {
          setTableValue("N/A");
        }
      });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [lat, lng, isFirstTime, type]);

  return type === "search" ? (
    <Box sx={{ mr: 2 }}>
      <style>
        {`
          .pac-container {
            width: 295px !important; 
            margin-left: -50px
          }
        `}
      </style>
      <TextField
        inputRef={inputRef}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderRadius: 100,
            },
          },
        }}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        size="small"
        placeholder="Search a Location..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setInputValue("")}>
                <Clear sx={{ opacity: 0.5 }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  ) : (
    <div>{tableValue}</div>
  );
};

export default MapWrapper;
