import {
  EditOutlined,
  ErrorOutline,
  HighlightOff,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar } from "utils";
// import { LEASE_URL } from "utils/constants";
import PhoneTextField from "components/PhoneTextField";
import validator from "validator";
import Map from "./Map";
import MapComponent from "components/Map";
import { LEASE_URL } from "utils/constants";

interface Props {
  open: boolean;
  handleClose: () => void;
  refetchHubs: any;

  setMarkerPosition: any;
  markerPosition: any;
}

type inputData = {
  hubInfo: {
    name: string;
    address: {
      pincode: string;
      district: string;
      state: any;
      country: any;
      local: string;
    };
  };
  userInfo: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
};

const AddHubDialog: React.FC<Props> = ({
  open,
  handleClose,
  refetchHubs,
  markerPosition,
  setMarkerPosition,
}) => {
  const [step, setStep] = useState(0);
  const steps = ["Hub Info", "User Info", "Finish"];

  const [input, setInput] = useState<inputData>({
    hubInfo: {
      name: "",
      address: {
        pincode: "",
        district: "",
        state: "",
        country: "",
        local: "",
      },
    },
    userInfo: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
  });

  const {
    hubInfo: {
      name,
      address: { pincode, local, district, state },
    },
    userInfo: { firstName, lastName, email, phone },
  } = input;

  const statesUrl = `https://geocode.revos.in/place/states?country=India&input=`;
  const { isLoading: statesLoading, data: statesData } = useQuery(
    ["getStates"],
    () => authorizedFetch(statesUrl)
  );

  const citiesUrl = `https://geocode.revos.in/place/cities?country=India&state=${state}&input=`;
  const { isLoading: citiesLoading, data: citiesData } = useQuery(
    ["getCities", state],
    () => authorizedFetch(citiesUrl),
    {
      enabled: Boolean(state),
    }
  );

  const addHubURL = `${LEASE_URL}/company/hubs/create`;

  const mutation = useMutation(
    `addHub`,
    () =>
      authorizedFetch(addHubURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          name,
          address: {
            pincode,
            local,
            district,
            state,
            country: "India",
          },
          location: [markerPosition.lng, markerPosition.lat],
          user: {
            firstName,
            lastName,
            phone: phone.replace(/^\+91/, ""),
            email,
          },
        },
      }),
    {
      onSuccess: (data) => {
        if (data.message === "Hub created successfully") {
          snackbar.success(`Hub Added`);
          refetchHubs();
        } else {
          snackbar.error(data.message);
        }
        setLoader(false);
      },
      onError: () => {
        snackbar.error(`Error adding hub`);
      },
    }
  );

  const validateEmail = (email: any) => {
    return validator.isEmail(email);
  };

  function handleChange(path: string, value: string | number[]) {
    setInput((prevInput: inputData) => {
      const updateNestedState = (obj: any, keys: string[], val: any): any => {
        let key = keys[0];

        if (keys.length === 1) {
          // update value in first object level
          return { ...obj, [key]: val };
        } else {
          // go deeper
          return {
            ...obj,
            [key]: updateNestedState(obj[key] || {}, keys.slice(1), val),
          };
        }
      };
      return updateNestedState(prevInput, path.split("."), value);
    });
  }

  const handleSave = () => {
    setLoader(true);
    mutation.mutate();
    handleClose();
  };

  function handleNext() {
    if (step === steps.length - 1) {
      handleSave();
    } else setStep(step + 1);
  }
  function handleBack() {
    setStep(step - 1);
  }

  function isComplete(step: number) {
    switch (step) {
      case 0:
        if (markerPosition.lat === null || markerPosition.lng === null)
          return false;
        if (state === null || district === null) return false;
        if (pincode === "" || pincode.length !== 6) return false;
        return ![name, local, state, district].includes("");
      case 1:
        if (!isValidPhoneNumber(phone)) return false;
        if (!validateEmail(email)) return false;
        return ![firstName, lastName, phone, email].includes("");

      default:
        break;
    }
  }

  function isDisabled() {
    if (step === 0 || step === 2) {
      return !isComplete(0);
    }
    if (step === 1 || step === 2) {
      return !isComplete(1);
    }
  }

  useEffect(() => {
    if (!open) {
      setInput({
        hubInfo: {
          name: "",
          address: {
            pincode: "",
            district: "",
            state: "",
            country: "",
            local: "",
          },
        },
        userInfo: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
        },
      });
      setStep(0);
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      setMarkerPosition({ lat: 12.9121181, lng: 77.6445548 });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [open]);

  console.log(input.hubInfo);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 800,
          width: 1,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 1,
            p: "3.5px 5px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Add Hub
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent sx={{ pb: "16px !important" }}>
        <Stepper
          sx={{ my: 4, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton onClick={() => setStep(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {step === 0 && (
          <>
            <Box sx={{ maxWidth: { xs: 280, sm: 560 }, mx: "auto" }}>
              <Typography sx={{ fontSize: "0.875rem" }}>
                All fields with ( * ) are required
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: { xs: 280, sm: 560 },
                mx: "auto",
                py: 2,
                display: "grid",
                gridTemplateColumns: {
                  xs: "1fr",
                  sm: "1fr 1fr",
                },
                gap: 3,
                "& .required": {
                  color: "red",
                },
              }}
            >
              <Box sx={{ gridColumn: "span 2" }}>
                <Typography className="label">
                  Hub Name <span className="required">&#x2a;</span>
                </Typography>
                <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  value={name}
                  placeholder="Hub Name"
                  onChange={(e) => {
                    handleChange(
                      "hubInfo.name",
                      e.target.value?.length > 0
                        ? e.target.value.replace(/[^a-zA-Z0-9-_ ]/, "")
                        : e.target.value
                    );
                  }}
                  inputProps={{ maxLength: 25 }}
                />
              </Box>
              <Box sx={{ gridColumn: "span 2" }}>
                <Typography className="label">
                  Address 1 <span className="required">&#x2a;</span>
                </Typography>
                <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  value={local}
                  placeholder="Address 1"
                  onChange={(e) => {
                    handleChange("hubInfo.address.local", e.target.value);
                  }}
                  inputProps={{ maxLength: 60 }}
                />
              </Box>
              <Box>
                <Typography className="label">
                  State <span className="required">&#x2a;</span>
                </Typography>
                <Autocomplete
                  loading={statesLoading}
                  size="small"
                  popupIcon={<KeyboardArrowDown />}
                  value={state}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "clear") {
                      handleChange("hubInfo.address.state", "");
                      handleChange("hubInfo.address.district", "");
                      return;
                    } else {
                      handleChange("hubInfo.address.state", newInputValue);
                    }
                  }}
                  onChange={(e, newValue: any) => {
                    handleChange("hubInfo.address.state", newValue);
                  }}
                  options={(statesData?.data?.constructor === Array
                    ? statesData.data
                    : []
                  ).map((el: any) => el.name)}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select..." />
                  )}
                />
              </Box>
              <Box>
                <Typography className="label">
                  City/District <span className="required">&#x2a;</span>
                </Typography>
                <Autocomplete
                  disabled={!state}
                  loading={citiesLoading}
                  size="small"
                  popupIcon={<KeyboardArrowDown />}
                  value={district}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "clear") {
                      handleChange("hubInfo.address.district", "");
                      return;
                    } else {
                      handleChange("hubInfo.address.district", newInputValue);
                    }
                  }}
                  onChange={(e, newValue: any) => {
                    handleChange("hubInfo.address.district", newValue);
                  }}
                  options={(citiesData?.data?.constructor === Array
                    ? citiesData.data
                    : []
                  ).map((el: any) => el.name)}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select..." />
                  )}
                />
              </Box>

              <Box>
                <Typography className="label">
                  Pincode <span className="required">&#x2a;</span>
                </Typography>
                <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  value={pincode}
                  placeholder="Pincode"
                  onChange={(e: any) => {
                    if (/^[0-9]{0,6}$/.test(e.target.value)) {
                      handleChange("hubInfo.address.pincode", e.target.value);
                    }
                  }}
                />
              </Box>

              <Box sx={{ gridColumn: "span 2" }}>
                <Typography className="label">
                  Location <span className="required">&#x2a;</span>
                </Typography>
                <Map
                  setMarkerPosition={setMarkerPosition}
                  markerPosition={markerPosition}
                />
              </Box>
            </Box>
          </>
        )}
        {step === 1 && (
          <>
            <Box sx={{ maxWidth: { xs: 280, sm: 560 }, mx: "auto" }}>
              <Typography sx={{ fontSize: "0.875rem" }}>
                All fields with ( * ) are required
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: { xs: 280, sm: 560 },
                mx: "auto",
                py: 2,
                display: "grid",
                gridTemplateColumns: {
                  xs: "1fr",
                  sm: "1fr 1fr",
                },
                gap: 3,
                "& .required": {
                  color: "red",
                },
              }}
            >
              <Box>
                <Typography className="label">
                  First Name <span className="required">&#x2a;</span>
                </Typography>
                <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  value={firstName}
                  placeholder="First Name"
                  onChange={(e) => {
                    handleChange("userInfo.firstName", e.target.value);
                  }}
                />
              </Box>
              <Box>
                <Typography className="label">
                  Last Name <span className="required">&#x2a;</span>
                </Typography>
                <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  value={lastName}
                  placeholder="Last Name"
                  onChange={(e) => {
                    handleChange("userInfo.lastName", e.target.value);
                  }}
                />
              </Box>
              <Box>
                <Typography className="label">
                  Phone Number <span className="required">&#x2a;</span>
                </Typography>
                <Box>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={phone}
                    onChange={(value: any) =>
                      handleChange("userInfo.phone", value)
                    }
                    defaultCountry="IN"
                    international
                    limitMaxLength
                    inputComponent={PhoneTextField}
                    error={!!phone && !isValidPhoneNumber(phone)}
                  />
                </Box>
              </Box>
              <Box>
                <Typography className="label">
                  Email <span className="required">&#x2a;</span>
                </Typography>
                <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => {
                    handleChange("userInfo.email", e.target.value);
                  }}
                  error={!!email && !validateEmail(email)}
                  helperText={
                    !!email && !validateEmail(email)
                      ? "Invalid email"
                      : undefined
                  }
                />
              </Box>
            </Box>
          </>
        )}
        {step === 2 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    bgcolor: (theme) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {[
                  {
                    header: "Hub Info",
                    onEdit: () => setStep(0),
                  },
                  {
                    label: "Hub Name",
                    value: name,
                    required: true,
                  },
                  {
                    label: "Address 1",
                    value: local,
                    required: true,
                  },
                  {
                    label: "City",
                    value: district,
                    required: true,
                  },
                  {
                    label: "State",
                    value: state,
                    required: true,
                  },
                  {
                    label: "Pincode",
                    value: pincode,
                    required: true,
                  },
                  {
                    label: "Location",
                    value: markerPosition,
                    required: true,
                  },
                  {
                    header: "User Info",
                    onEdit: () => setStep(1),
                  },
                  {
                    label: "First Name",
                    value: firstName,
                    required: true,
                  },
                  {
                    label: "Last Name",
                    value: lastName,
                    required: true,
                  },
                  {
                    label: "Phone",
                    value: phone,
                    required: true,
                  },
                  {
                    label: "Email",
                    value: email,
                    required: true,
                  },
                ].map(({ header, onEdit, label, value, required }, i, arr) => {
                  const isFirst = arr[i - 1]?.header;
                  const isLast = !arr[i + 1] || arr[i + 1].header;

                  return (
                    <tr
                      key={i}
                      className={
                        header
                          ? "header"
                          : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                      }
                    >
                      {header ? (
                        <td colSpan={2}>
                          <span>{header}</span>
                          <IconButton
                            sx={{ ml: 1.5 }}
                            children={<EditOutlined />}
                            color="primary"
                            size="small"
                            onClick={onEdit}
                          />
                        </td>
                      ) : (
                        <>
                          <td>{label}</td>
                          <td className="bold">
                            {label === "Location" ? (
                              <Box height={150} width={1}>
                                <MapComponent
                                  loading={false}
                                  type="hub"
                                  borderRadius={1}
                                  location={{
                                    latitude: markerPosition?.lat,
                                    longitude: markerPosition?.lng,
                                  }}
                                  showControls={false}
                                />
                              </Box>
                            ) : (
                              value ||
                              (required && (
                                <Box display="flex" alignItems="center">
                                  <ErrorOutline
                                    fontSize="small"
                                    color="error"
                                    style={{
                                      marginRight: 8,
                                    }}
                                  />
                                  Required
                                </Box>
                              ))
                            )}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {step !== 0 && (
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        )}
        {step === 0 && (
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        )}
        <Button
          onClick={handleNext}
          variant={
            isComplete(step) || step === steps.length - 1
              ? "contained"
              : "outlined"
          }
          disableElevation
          disabled={isDisabled()}
        >
          {step === steps.length - 1 ? "Save" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddHubDialog;
