import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { gql, useMutation } from "@apollo/client";
import { snackbar, setLoader } from "utils";
import { queryClient } from "index";

const DeleteVendor = ({ open, handleClose, vendor }: any) => {
  const UPDATE_STATUS = gql`
    mutation DeleteSubCompany($where: CompanyDeleteInput!) {
      company {
        deleteSubCompany(where: $where) {
          id
          status
        }
      }
    }
  `;
  const [deleteVendor] = useMutation(UPDATE_STATUS, {
    onCompleted: () => {
      ["getVendors"].forEach((key) => {
        queryClient.resetQueries(key, { exact: true });
      });
      setLoader(false);
      snackbar.success("Vendor deleted");
      handleClose();
    },
    onError: () => {
      setLoader(false);
      snackbar.error("Error deleting vendor");
    },
  });
  function confirm() {
    setLoader(true);
    deleteVendor({
      variables: {
        where: {
          id: [vendor?._id],
        },
      },
    });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete Vendor</DialogTitle>
      <DialogContent className="py-1">
        Delete <b>{vendor?.name}</b>?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={confirm}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteVendor;
