import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
// import is from "date-fns/esm/locale/is/index.js";
// import RangePicker from "components/RangePicker";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import useInterval from "use-interval";
import { authorizedFetch, GlobalState } from "utils";
import { RETAIL_URL } from "utils/constants";
import Chart from "./Chart";
import Gauge from "./Gauge";
import Stats from "./Stats";
import VehicleMap from "./VehicleMap";
// const io = require("oldSocket/dist/socket.io");
const { io: newio } = require("socket.io-client")

// const LiveButton = ({ isLive, setIsLive }: any) => {
//   const [hover, setHover] = useState(false);

//   return (
//     <Button
//       size="small"
//       variant="outlined"
//       color="secondary"
//       sx={{
//         ml: 2,
//         // color: theme => theme.customColors.grey,
//       }}
//       startIcon={
//         isLive && hover ? (
//           <Pause />
//         ) : (
//           <FiberManualRecord color={isLive ? "error" : "disabled"} />
//         )
//       }
//       onClick={() => {
//         setIsLive(!isLive);
//         setHover(false);
//       }}
//       onMouseEnter={() => setHover(true)}
//       onMouseLeave={() => setHover(false)}
//     >
//       Live
//     </Button>
//   );
// };

const VehicleMonitoring = ({
  vehicle,
  trip,
  setTrip,
  isTripsPage = false,
  setIsLive,
  isLive,
  setDataSource,
  setTimestamp,
  setTimeDifference,
}: any) => {
  const { vin } = vehicle;
  // const vin = "JOY_BLACK1"
  // trip = {
  //   tripId: "1664537543572",
  //   vin: "KM12CONSOLEFALCON"
  // }

  const socketRef = useRef<any>(null);
  const { activeSection } = useSelector((state: GlobalState) => state.global);
  const isFMS = activeSection === "rental";

  const [vehicleSnapshot, setVehicleSnapshot] = useState<any>(null);
  const [vehicleLogs, setVehicleLogs] = useState<any>(null);
  const [activeTime, setActiveTime] = useState<any>();

  // const [isLive, setIsLive] = useState(trip ? false : true);
  const [range, setRange] = useState<any>(null);

  let userEmail = useSelector((state: GlobalState) => state.global.user.email);
  const shouldHideFields = userEmail === "srikhantan.s@bounceshare.com";

  const vmsTripUrl = `${RETAIL_URL}/trips/${trip?.tripId}?vin=${trip?.vin}`;
  const { isLoading: vmsTripLoading, data: vmsTripData } = useQuery(
    ["getVMSTrip", trip?.tripId, trip?.vin],
    () => authorizedFetch(vmsTripUrl),
    {
      enabled: isTripsPage && Boolean(trip?.tripId) && Boolean(trip?.vin),
    }
  );

  // const fmsTripUrl = `${DATAFEED_URL}/tripV2?token=${token}&id=${company.id}&tripid=${trip?.tripId}&vin=${vin}`;
  // const { isLoading: fmsTripLoading, data: fmsTripData } = useQuery(
  //   ["getFMSTrip", trip?.tripId],
  //   () => authorizedFetch(fmsTripUrl),
  //   { enabled: isTripsPage && isFMS && Boolean(trip?.tripId) },
  // );

  function getSocketDataNew() {
    const socket = socketRef.current;
    if (socket === null) return;
    // let search = {
    //   vin,
    //   startTime:
    //     isLive || !range ? "now-30m" : moment(range[0]).valueOf().toString(),
    //   endTime: isLive || !range ? "now" : moment(range[1]).valueOf().toString(),
    // };
    socket.emit(
      "REQUEST:VEHICLE_LIVE_LOGS",
      JSON.stringify({
        vin,
        desktop: true,
      })
    );
    socket.emit(
      "REQUEST:VEHICLE_SNAPSHOT",
      JSON.stringify({
        vin,
      })
    );
  }

  useEffect(() => {
    if (isTripsPage) {
      socketRef.current?.close();
    } else if (socketRef.current === null) {
      socketRef.current = newio(RETAIL_URL, {
        path: "/socket.io",
        transports: ["websocket", "xhr-polling", "jsonp-polling", "polling"],
      });
      const socket = socketRef.current;
      socket.on("RESPONSE:VEHICLE_LIVE_LOGS", (data: any) => {
        // console.log("VEHICLE_LOGS_New", data.responseData);
        setVehicleLogs(data.responseData);
      });
      socket.on("RESPONSE:VEHICLE_SNAPSHOT", (data: any) => {
        // console.log("VEHICLE_SNAPSHOT_New", data.responseData);
        // let rideSelected = false;
        // if (isLive || rideSelected) {
        setVehicleSnapshot(data.responseData);
        // }
      });
      getSocketDataNew();
    }

    return () => {
      socketRef.current?.close();
    };
    // eslint-disable-next-line
  }, [isTripsPage]);

  useInterval(
    () => {
      getSocketDataNew();
    },
    isLive ? 5000 : null
  );

  useEffect(() => {
    if (isLive) {
      setRange(null);
    }
    getSocketDataNew();
    // eslint-disable-next-line
  }, [isLive]);

  useEffect(() => {
    if (range !== null) setIsLive(false);
    getSocketDataNew();
    // eslint-disable-next-line
  }, [range]);

  useEffect(() => {
    if (!isTripsPage) return;
    // if (isFMS && fmsTripData) {
    //   setVehicleLogs(fmsTripData);
    // } else if (!isFMS && vmsTripData) {
    setVehicleLogs({
      ...vmsTripData?.data[0],
      battery: vmsTripData?.data[0]?.batteryVoltageAdc,
    });
    // }
    // eslint-disable-next-line
  }, [isTripsPage, vmsTripData]);

  useEffect(() => {
    if (isTripsPage) return;
    if (vehicleLogs) {
      const timestamp =
        vehicleLogs?.can && vehicleLogs?.can?.length !== 0
          ? vehicleLogs?.can?.[0]?.timestamp
          : vehicleLogs?.uart && vehicleLogs?.uart[0]?.timestamp
          ? vehicleLogs?.uart[0]?.timestamp
          : vehicleLogs?.battery[0]?.timestamp;
      const currentDateTime = moment();
      const pastDateTime = moment(timestamp);
      const difference = timestamp
        ? currentDateTime.diff(pastDateTime, "minutes")
        : "N/A";
      setTimestamp(timestamp);
      setTimeDifference(difference);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [isTripsPage, vehicleLogs]);

  let duration: any = range
    ? moment.duration(moment(range[1]).diff(moment(range[0])))
    : null;
  let isDaily = duration ? duration.asDays() >= 1 : false;

  useEffect(() => {
    if (isTripsPage) return;
    if (vehicleLogs) {
      const source =
        vehicleLogs?.can?.length > 0
          ? "CAN"
          : vehicleLogs?.can?.length === 0 && vehicleLogs?.uart?.length > 0
          ? "UART"
          : "Other";
      setDataSource(source);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [vehicleLogs, isTripsPage]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        {trip ? (
          <Box display="flex" alignItems="center">
            <IconButton
              size="small"
              sx={{ mr: 0.5 }}
              onClick={() => {
                setTrip(null);
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography>Trip ID:&nbsp;</Typography>
            <Typography fontWeight={500}>{trip.tripId}</Typography>
          </Box>
        ) : (
          // <Box display="flex" alignItems="center">
          //   <Typography variant="h6" mr={2}>
          //     {vin}
          //   </Typography>
          //   {parseInt(vehicleSnapshot?.ignition?.lock || "") === 1 ||
          //     parseInt(vehicleSnapshot?.ignition?.ignition || "") === 0 ? (
          //     <Tooltip title="Unlocked">
          //       <LockOpenOutlined fontSize="small" color="action" />
          //     </Tooltip>
          //   ) : parseInt(vehicleSnapshot?.ignition?.lock || "") === 0 ||
          //     parseInt(vehicleSnapshot?.ignition?.ignition || "") === 1 ? (
          //     <Tooltip title="Locked">
          //       <Lock fontSize="small" color="action" />
          //     </Tooltip>
          //   ) : null}
          // </Box>
          // {!isTripsPage && (
          //   <Box display="flex" alignItems="center">
          //     {/* <RangePicker
          //       range={range}
          //       setRange={setRange}
          //       presets={["12H", "1D", "4D"]}
          //       initialRange={isLive ? undefined : "12H"}
          //     /> */}
          //     <LiveButton isLive={isLive} setIsLive={setIsLive} />
          //   </Box>
          // )}
          <Box />
        )}
      </Box>

      <Box
        sx={{
          width: 1,
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            lg: "1fr",
          },
          gap: { xs: 2, md: 5 },
          "& .MuiPaper-root": {
            width: 1,
            borderRadius: 2,
            border: 1,
            borderColor: (theme) => theme.customColors.border,
            boxShadow: (theme) => theme.customShadows.small,
            overflow: "hidden",
          },
        }}
      >
        <Stats
          loading={
            isTripsPage
              ? isFMS
                ? vmsTripLoading
                : vmsTripLoading
              : vehicleSnapshot === null
          }
          vehicle={vehicle}
          vehicleSnapshot={vehicleSnapshot}
          vehicleLogs={vehicleLogs}
          activeTime={activeTime}
          tripData={
            isTripsPage
              ? isFMS
                ? vmsTripData?.data?.[0]
                : vmsTripData?.data?.[0]
              : null
          }
          isTripsPage={isTripsPage}
        />
        <Box
          sx={{
            width: 1,
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              md: "3fr 0.92fr",
            },
            gap: 5,
            height: 400,
          }}
        >
          <VehicleMap
            loading={
              isTripsPage
                ? isFMS
                  ? vmsTripLoading
                  : vmsTripLoading
                : [vehicleSnapshot, vehicleLogs].includes(null)
            }
            vehicleSnapshot={vehicleSnapshot}
            vehicleLogs={vehicleLogs}
            trip={trip}
            tripData={
              isTripsPage
                ? isFMS
                  ? vmsTripData?.data?.[0]
                  : vmsTripData?.data?.[0]
                : null
            }
            isFMS={isFMS}
            setVehicleSnapshot={setVehicleSnapshot}
            activeTime={activeTime}
            setActiveTime={setActiveTime}
          />
          <Gauge
            vehicle={vehicle}
            vehicleSnapshot={vehicleSnapshot}
            activeTime={activeTime}
            isTripsPage={isTripsPage}
            vehicleLogs={vehicleLogs}
            tripData={
              isTripsPage
                ? isFMS
                  ? vmsTripData?.data?.[0]
                  : vmsTripData?.data?.[0]
                : null
            }
          />
        </Box>

        {/* <Chart type="voltage" vehicleLogs={vehicleLogs} isDaily={isDaily} />
        <Chart type="speed" vehicleLogs={vehicleLogs} isDaily={isDaily} /> */}
        {!shouldHideFields && (
          <Chart
            isLoading={
              isTripsPage
                ? isFMS
                  ? vmsTripLoading
                  : vmsTripLoading
                : vehicleLogs === null
            }
            vehicle={vehicle}
            vehicleLogs={vehicleLogs}
            isDaily={isDaily}
            isTripsPage={isTripsPage}
            isVMS={!isFMS}
          />
        )}
      </Box>
    </>
  );
};

export default VehicleMonitoring;
