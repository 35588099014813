import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { appBar, authorizedFetch, drawer, GlobalState } from "utils";
import { AUTH_URL, BOLT_URL } from "utils/constants";
import AddDialog from "./AddDialog";
import AppBar from "./AppBar";
import Cards from "./Cards";
import List from "./List";
import PaymentDialog from "../Users/PaymentDialog";

const Vendors = () => {
  const [addDialog, setAddDialog] = useState(false);
  const [paymentDialog, setPaymentDialog] = useState({
    open: false,
    data: null,
  });

  const { masterView } = useSelector((state: GlobalState) => state.global);
  const [search, setSearch] = useState("");
  const [locations, setLocations] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const first = pageSize;
  const skip = pageSize * (page - 1);
  const cities = locations.join("_");

  const statsUrl = `${BOLT_URL}/company/stats/all?city=${locations}`;
  const { isLoading: statsLoading, data: statsData } = useQuery(
    ["getAllStats", masterView, locations],
    () =>
      authorizedFetch(statsUrl, {
        headers: {
          cache: "true",
          master: masterView,
        },
      })
  );

  const vendorsUrl = `${AUTH_URL}/company/vendor/list?orderBy=CREATED_AT_DESC&first=${first}&skip=${skip}&city=${cities}&search=${search}`;
  const { isLoading: vendorsLoading, data: vendorsData } = useQuery(
    ["getVendors", first, skip, cities, search],
    () => authorizedFetch(vendorsUrl)
  );

  useEffect(() => {
    setPage(1);
  }, [pageSize, cities, search]);

  useEffect(() => {
    appBar.open(
      <AppBar
        {...{
          locations,
          setLocations,
          onAddVendorClick: () => setAddDialog(true),
        }}
      />
    );
    return () => {
      appBar.close();
      drawer.close();
    };
  }, [locations]);

  return (
    <>
      <AddDialog
        open={addDialog}
        handleClose={() => setAddDialog(false)}
        setPaymentDialog={setPaymentDialog}
      />
      <PaymentDialog
        open={paymentDialog.open}
        data={paymentDialog.data}
        handleClose={() =>
          setPaymentDialog((prev: any) => ({ ...prev, open: false }))
        }
      />
      <Box
        sx={{
          px: { xs: 2, md: 7 },
          pt: { xs: 2, md: 4.75 },
          pb: { xs: 3, md: 7 },
        }}
      >
        <Cards
          {...{
            statsLoading,
            statsData,
            vendorsLoading,
            vendorsData,
          }}
        />
        <List
          {...{
            vendorsLoading,
            vendorsData,
            setSearch,
            page,
            pageSize,
            setPage,
            setPageSize,
          }}
        />
      </Box>
    </>
  );
};

export default Vendors;
