import { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  Slider,
  Typography,
} from "@mui/material";
import { Wrapper } from "@googlemaps/react-wrapper";
// import { GMAPS_API_KEY } from "utils/constants";
import VehiclePinIcon from "assets/images/bike-pin.svg";
import { useSelector } from "react-redux";
import {
  exists,
  getDarkModePreference,
  GlobalState,
  validateLatLng,
} from "utils";
import { alpha } from "@mui/system";
import {
  PauseCircleOutline,
  PlayCircleOutline,
  Replay,
} from "@mui/icons-material";
import moment from "moment";
import useInterval from "use-interval";

function getBounds(path: any) {
  var bounds = new google.maps.LatLngBounds();
  path.getPath().forEach((el: any) => {
    bounds.extend(el);
  });
  return bounds;
}

function getLatLng(lat: any, lng: any) {
  return {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  };
}

const VehicleMap = (props: any) => {
  const {
    loading,
    trip,
    tripData,
    isFMS,
    setVehicleSnapshot,
    activeTime,
    setActiveTime,
  } = props;

  const locationArray = useMemo(() => {
    return tripData?.location?.constructor === Array
      ? tripData?.location
      : tripData?.uart?.constructor === Array
        ? tripData?.uart
        : [];
  }, [tripData]);

  const { startTime, endTime } = useMemo(() => {
    let array = locationArray;
    array = array.sort(
      (a: any, b: any) =>
        moment(a.timestamp).valueOf() - moment(b.timestamp).valueOf(),
    );
    return {
      startTime:
        array?.length > 0
          ? moment(array[0].timestamp).valueOf()
          : moment(trip?.startTime || 0).valueOf(),
      endTime:
        array?.length > 0
          ? moment(array[array.length - 1].timestamp).valueOf()
          : 0,
    };
  }, [trip, locationArray]);

  useEffect(() => {
    setActiveTime(startTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime]);

  const [playing, setPlaying] = useState<any>(false);
  const [speed, setSpeed] = useState<any>(5);

  const interval = 100;

  useInterval(
    () => {
      const nextFrame = activeTime + speed * interval;
      if (nextFrame >= endTime) {
        setPlaying("ended");
        setActiveTime(endTime);
      } else {
        setActiveTime(nextFrame);
      }
    },
    playing === true ? interval : null,
  );

  useEffect(() => {
    if (!tripData) return;

    function findClosest(array: any[]) {
      return array?.length === 0
        ? null
        : array?.reduce((a: any, b: any) => {
            return Math.abs(moment(b.timestamp).valueOf() - activeTime) <
              Math.abs(moment(a.timestamp).valueOf() - activeTime)
              ? b
              : a;
          });
    }

    const vehicleSnapshot = {
      ignition: findClosest(tripData.ignition) || {},
      uart: findClosest(tripData.uart || tripData.metrics) || {},
      location: findClosest(tripData.location) || {},
      battery:
        findClosest(tripData[isFMS ? "battery" : "batteryVoltageAdc"]) || {},
      alarm: findClosest(tripData.alarm) || {},
    };

    setVehicleSnapshot(vehicleSnapshot);

    // eslint-disable-next-line
  }, [tripData, activeTime]);

  const isLoading = loading || (trip && !tripData);

  return (
    <Box
      sx={{
        gridRow: "span 2",
        position: "relative",
        height: 400,
        minHeight: 300,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 2,
        overflow: "hidden",
        border: 1,
        borderColor: (theme) => theme.customColors.border,
        boxShadow: (theme) => theme.customShadows.small,
      }}
    >
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          animation="wave"
        />
      ) : (
        <Wrapper
          libraries={["visualization", "places", "drawing", "geometry"]}
          apiKey={"AIzaSyDqq6Ywsf6L3lrgg_P8BI1Z7hvNbsjMQzY"}
        >
          <GoogleMap {...props} />
        </Wrapper>
      )}
      {!loading && trip && (
        <Box
          sx={{
            background: (theme) =>
              `linear-gradient(${alpha(theme.palette.background.paper, 0.7)}, ${
                theme.palette.background.paper
              })`,
            position: "absolute",
            bottom: 0,
            width: 1,
            p: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              width: 1,
              maxWidth: 110,
              overflow: "hidden",
              fontWeight: 500,
            }}
          >
            {moment(activeTime).format("DD MMM YYYY HH:mm:ss")}
          </Typography>
          <IconButton
            disabled={tripData?.location?.length < 1}
            color="primary"
            onClick={() => {
              if (playing === "ended") {
                setActiveTime(startTime);
                setPlaying(true);
              } else setPlaying(!playing);
            }}
          >
            {playing === true ? (
              <PauseCircleOutline />
            ) : playing === false ? (
              <PlayCircleOutline />
            ) : (
              <Replay />
            )}
          </IconButton>
          <Slider
            disabled={tripData?.location?.length < 1}
            sx={{ mx: 2 }}
            value={activeTime}
            onChange={(e, val) => setActiveTime(val)}
            size="small"
            min={startTime}
            max={endTime}
          />
          <Select
            disabled={tripData?.location?.length < 1}
            sx={{ ml: 1 }}
            value={speed}
            onChange={(e) => setSpeed(e.target.value)}
            size="small"
          >
            <MenuItem value={1}>1x</MenuItem>
            <MenuItem value={5}>5x</MenuItem>
            <MenuItem value={10}>10x</MenuItem>
            <MenuItem value={25}>25x</MenuItem>
          </Select>
        </Box>
      )}
    </Box>
  );
};

const GoogleMap = ({ vehicleSnapshot, vehicleLogs, trip, tripData }: any) => {
  let location: any = {
    latitude: exists(vehicleSnapshot.location.latitude)
      ? vehicleSnapshot.location.latitude
      : exists(vehicleSnapshot.uart.latitude)
        ? vehicleSnapshot.uart.latitude
        : null,
    longitude: exists(vehicleSnapshot.location.longitude)
      ? vehicleSnapshot.location.longitude
      : exists(vehicleSnapshot.uart.longitude)
        ? vehicleSnapshot.uart.longitude
        : null,
  };

  if (validateLatLng(location?.latitude, location?.longitude))
    location = {
      latitude: parseFloat(location?.latitude),
      longitude: parseFloat(location?.longitude),
    };
  else location = null;

  const biggestArrayLength = Math.max(
    vehicleLogs?.location?.length || 0,
    vehicleLogs?.uart?.length || 0,
    tripData?.location?.length || 0,
  );
  let data: any = [];
  if (biggestArrayLength === vehicleLogs?.location?.length) {
    data = vehicleLogs?.location || [];
  } else if (biggestArrayLength === vehicleLogs?.uart?.length) {
    data = vehicleLogs?.uart || [];
  } else if (biggestArrayLength === tripData?.location?.length) {
    data = tripData?.location || [];
  }
  data = data.filter((cur: any) => validateLatLng(cur.latitude, cur.longitude));

  let coordinates = data.map((cur: any) => ({
    ...getLatLng(cur.latitude, cur.longitude),
  }));

  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state),
  );
  const ref = useRef<HTMLElement | null>(null);

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);

  const [startPoint, setStartPoint] = useState<google.maps.Marker | null>(null);
  const [endPoint, setEndPoint] = useState<google.maps.Marker | null>(null);
  const [path, setPath] = useState<google.maps.Polyline | null>(null);

  useEffect(() => {
    setMap(
      new window.google.maps.Map(
        ref.current as HTMLElement,
        {
          mapId: isDarkMode ? "e2d8edaa01f13e66" : "ad2188b39fd828f6",
          center: location
            ? {
                lat: location.latitude,
                lng: location.longitude,
              }
            : { lat: 12.9716, lng: 77.5946 },
          zoom: 12,
          disableDefaultUI: true,
          // zoomControl: false,
          // streetViewControl: false,
          // fullscreenControl: false,
          // zoomControlOptions: {
          //   position: google.maps.ControlPosition.LEFT_BOTTOM,
          // },
        } as google.maps.MapOptions,
      ),
    );
    // eslint-disable-next-line
  }, [isDarkMode]);

  useEffect(() => {
    if (!map) return;

    if (marker && location)
      marker.setPosition({
        lat: location.latitude,
        lng: location.longitude,
      });
    else if (location)
      setMarker(
        new google.maps.Marker({
          position: {
            lat: location.latitude,
            lng: location.longitude,
          },
          icon: {
            url: VehiclePinIcon,
            anchor: new google.maps.Point(20, 48),
          },
          map,
        }),
      );

    if (trip && data?.length > 0) {
      let start = getLatLng(data[0].latitude, data[0].longitude);
      let end = getLatLng(
        data[data.length - 1].latitude,
        data[data.length - 1].longitude,
      );
      if (startPoint) {
        startPoint.setPosition(start);
        startPoint.setMap(map);
      } else
        setStartPoint(
          new google.maps.Marker({
            position: start,
            icon: {
              url: "http://maps.google.com/mapfiles/kml/paddle/go.png",
              scaledSize: new google.maps.Size(32, 32),
            },
            map: map,
            zIndex: 3,
          }),
        );
      if (endPoint) {
        endPoint.setPosition(end);
        endPoint.setMap(map);
      } else
        setEndPoint(
          new google.maps.Marker({
            position: end,
            icon: {
              url: "http://maps.google.com/mapfiles/kml/paddle/stop.png",
              scaledSize: new google.maps.Size(32, 32),
            },
            map: map,
            zIndex: 3,
          }),
        );
    } else {
      if (startPoint) startPoint.setMap(null);
      if (endPoint) endPoint.setMap(null);
    }
    // eslint-disable-next-line
  }, [map, location]);

  useEffect(() => {
    if (!map) return;
    if (path) {
      path.setPath(coordinates);
      if (
        coordinates.length > 1 &&
        coordinates.find((el: any) => el.lat !== coordinates[0].lat)
      ) {
        map.fitBounds(getBounds(path));
      }
    } else if (data.length > 1) {
      setPath(
        new google.maps.Polyline({
          path: coordinates,
          strokeColor: "#0062FF",
          strokeOpacity: 1.0,
          strokeWeight: 4,
          map: map,
          zIndex: 2,
        }),
      );
    } else {
      setPath(null);
    }
    // eslint-disable-next-line
  }, [map, path, trip, data]);

  return (
    <Box
      ref={ref}
      sx={{ position: "absolute", width: 1, height: 1, color: "black" }}
    />
  );
};

export default VehicleMap;
