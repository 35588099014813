import {
    Avatar,
    Box,
    Button,
    FormControlLabel,
    Radio,
    RadioGroup,
    Skeleton,
    Typography,
} from "@mui/material";
import moment from "moment";
import { Fragment, useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
    authorizedFetch,
    getDuration,
    setLoader,
    snackbar,
    titleCase,
} from "utils";
import { getModelConfigLabel } from "views/Retail/Vehicles/components/ModelDrawer";
import MarkEligible from "./MarkEligible";
import { VEHICLE_SUBSCRIPTION_URL } from "utils/constants";

function getComponentSpecLabel(key: string) {
    switch (key) {
        case "macId":
            return "MAC ID";
        default:
            return key;
    }
}
const Info = ({ vehicle, isRetail }: any) => {
    const [markEligibleDialog, setMarkEligibleDialog] = useState({
        open: false,
        sequence: "A",
    });

    const subscriptionsURL = `${VEHICLE_SUBSCRIPTION_URL}/v1/subscription?vin=${vehicle?.vin}`;
    const { isLoading: subscriptionsLoading, data: subscriptionsData } =
        useQuery(["getSubscriptions", vehicle?.vin], () =>
            authorizedFetch(subscriptionsURL)
        );

    let plansURL = `${VEHICLE_SUBSCRIPTION_URL}/v1/plans?vin=${vehicle?.vin}`;
    const {
        isLoading: plansLoading,
        data: plansData,
        refetch: refetchPlans,
    } = useQuery(["getPlans", vehicle?.vin], () => authorizedFetch(plansURL));

    const infoDetails = useMemo(() => {
        let components: any = {};

        vehicle?.components?.forEach((component: any) => {
            let info =
                `Type: ${component?.id?.parentModel?.key}\n` +
                `UID: ${component?.id?.uid}\n`;

            let specs = Object.keys(component?.id?.specs || {})
                .map(
                    (spec: any) =>
                        `${getComponentSpecLabel(spec)}: ${
                            component?.id?.specs?.[spec]
                        }\n`
                )
                .join("");
            components[titleCase(component.category)] = info + specs;
        });

        const formatLastPing = (value: any) => {
            let lastPing: any;
            value
                ? (moment().valueOf() - moment(value).valueOf()) / 60000 < 360
                    ? (lastPing = getDuration(
                          Math.abs(
                              moment().valueOf() - moment(value).valueOf()
                          ) / 60000
                      ))
                    : (lastPing = "Dormant")
                : (lastPing = "Dormant");

            return lastPing;
        };

        let details: any = {
            "Basic Details": {
                VIN: vehicle.vin,
                "Vehicle Model": vehicle.model?.name,
                Protocol: vehicle.model?.protocol,
                ...(!isRetail
                    ? { "Vehicle Status": vehicle.rentalStatus }
                    : {}),
                ...(isRetail
                    ? { Distributor: vehicle?.distributor?.name }
                    : {}),
                Color: vehicle?.specs?.color,
                Health: vehicle?.health
                    ? titleCase(vehicle?.health)
                    : "Inactive",

                "Last Ping": formatLastPing(vehicle?.lastPinged),
            },
            ...(isRetail
                ? {
                      "User Details": {
                          Name: vehicle?.buyer
                              ? vehicle?.buyer?.firstName +
                                " " +
                                (vehicle?.buyer?.lastName || "")
                              : "-",
                          Phone: vehicle?.buyer?.phone || "-",
                          Email: vehicle?.buyer?.email || "-",
                          Address: vehicle?.buyer?.address
                              ? typeof vehicle.buyer.address === "string"
                                  ? vehicle.buyer.address
                                  : (vehicle.buyer.address.local
                                        ? vehicle.buyer.address.local + "\n"
                                        : "") +
                                    `${vehicle.buyer.address.district || ""}, ${
                                        vehicle.buyer.address.state || ""
                                    }\n` +
                                    (vehicle.buyer.address.pincode || "")
                              : "-",
                      },
                  }
                : {}),
            "Vehicle Configuration":
                vehicle?.config?.vehicleConfigValues ||
                Object.entries(vehicle?.model?.config?.configs).reduce(
                    (obj: any, [key, value]: any) =>
                        value == null ? obj : ((obj[key] = value), obj),
                    {}
                ) ||
                {},
            ...(JSON.stringify(components) !== "{}"
                ? {
                      Devices: components,
                  }
                : {}),
            ...(vehicle.device
                ? {
                      Device: {
                          "Device ID": vehicle.device.deviceId,
                          "SIM Number": vehicle.device.phone,
                          Type: vehicle.device.type,
                      },
                  }
                : {}),
            ...(isRetail && subscriptionsData?.data !== null
                ? {
                      "Subscription Details": {
                          Name: subscriptionsData?.data?.name,
                          Status: subscriptionsData?.data?.status,
                          Validity: moment(
                              subscriptionsData?.data?.validity
                          )?.format("MMM DD, YYYY"),
                      },
                  }
                : {}),
        };
        return details;
    }, [vehicle, isRetail, subscriptionsData]);

    function disablePlan() {
        setLoader(true);
        let url = `${VEHICLE_SUBSCRIPTION_URL}/v1/${vehicle.vin}/disable-plan/647585d1aa75915373a3d68f`;
        authorizedFetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                setLoader(false);
                if (res.msg) {
                    snackbar.success(`Plan disabled for ${vehicle.vin}`);
                    refetchPlans();
                    // queryClient.resetQueries("getSCMData");
                } else {
                    console.error(res);
                    snackbar.error("An error occurred");
                }
            })
            .catch((err) => {
                setLoader(false);
                console.error(err);
                snackbar.error("An error occurred");
            });
    }

    return (
        <Box
            sx={{
                pt: isRetail ? 2 : 0,
                px: 2,
                mb: 3,
                "& .table": {
                    borderCollapse: "collapse",
                    width: 1,
                    fontSize: 14,
                    lineHeight: "16px",
                    "& td": {
                        py: 1.25,
                        px: 2,
                        whiteSpace: "pre-line",
                    },
                    "& .bold": {
                        fontWeight: 500,
                    },
                    "& .header": {
                        px: 2,
                        py: 1,
                        position: "relative",
                        "& td": {
                            position: "absolute",
                            verticalAlign: "middle",
                            bgcolor: (theme: any) => theme.customColors.header,
                            width: 1,
                            borderRadius: "4px",
                            fontSize: 16,
                            fontWeight: 600,
                            "& .label": {
                                display: "inline-block",
                                transform: "translateY(1px)",
                                py: 1.125,
                            },
                        },
                    },
                    "& .first > td": {
                        pt: 9,
                    },
                    "& .last > td": {
                        pb: 2.75,
                    },
                },
            }}
        >
            {isRetail && subscriptionsLoading ? (
                <Skeleton
                    variant="rounded"
                    animation="wave"
                    height={82}
                    sx={{ mb: 2 }}
                />
            ) : (
                subscriptionsData?.data === null && (
                    <Box
                        sx={{
                            bgcolor: (theme) => theme.customColors.header,
                            px: 2,
                            py: 1,
                            width: 1,
                            borderRadius: "4px",
                            mb: 2,
                        }}
                    >
                        <Typography
                            mb={0.5}
                            fontWeight={600}
                            display="flex"
                            alignItems="center"
                        >
                            Subscription Plan
                            <Typography
                                ml={1}
                                variant="overline"
                                fontSize={14}
                                lineHeight="1em"
                            >
                                (Pro Rider)
                            </Typography>
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <RadioGroup row>
                                <FormControlLabel
                                    value="sequenceA"
                                    control={<Radio size="small" />}
                                    label="Sequence A"
                                    onClick={() =>
                                        setMarkEligibleDialog({
                                            open: true,
                                            sequence: "A",
                                        })
                                    }
                                />
                                <FormControlLabel
                                    value="sequenceB"
                                    control={<Radio size="small" />}
                                    label="Sequence B"
                                    onClick={() =>
                                        setMarkEligibleDialog({
                                            open: true,
                                            sequence: "B",
                                        })
                                    }
                                />
                            </RadioGroup>
                            {plansLoading ? (
                                <Skeleton
                                    variant="rounded"
                                    animation="wave"
                                    width={77}
                                    height={31}
                                />
                            ) : (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    onClick={disablePlan}
                                    size="small"
                                    color="error"
                                    disabled={plansData?.data?.length === 0}
                                >
                                    Disable
                                </Button>
                            )}
                        </Box>
                        <MarkEligible
                            open={markEligibleDialog.open}
                            sequence={markEligibleDialog.sequence}
                            handleClose={() =>
                                setMarkEligibleDialog((prev) => ({
                                    ...prev,
                                    open: false,
                                }))
                            }
                            vehicle={vehicle}
                            refetch={refetchPlans}
                        />
                    </Box>
                )
            )}

            <table className="table">
                <tbody>
                    {Object.keys(infoDetails).map(
                        (category: any, i: number) => (
                            <Fragment key={i}>
                                <tr className="header">
                                    <td colSpan={2}>
                                        <span className="label">
                                            {category}
                                        </span>
                                    </td>
                                </tr>
                                {Object.keys(infoDetails[category]).map(
                                    (label: any, i: number) => {
                                        let value =
                                            infoDetails[category][label];

                                        return (
                                            <tr
                                                key={i}
                                                className={
                                                    i === 0
                                                        ? "first"
                                                        : i ===
                                                          Object.keys(
                                                              infoDetails[
                                                                  category
                                                              ]
                                                          ).length -
                                                              1
                                                        ? "last"
                                                        : ""
                                                }
                                            >
                                                <td>
                                                    {category ===
                                                    "Vehicle Configuration"
                                                        ? getModelConfigLabel(
                                                              label
                                                          )
                                                        : label}
                                                </td>
                                                <td className="bold">
                                                    {/* {typeof value === "object"
                          ? JSON.stringify(value)
                          : value} */}

                                                    {label === "Health" ? (
                                                        <Avatar
                                                            variant="status"
                                                            className={
                                                                vehicle?.health ===
                                                                "active"
                                                                    ? ""
                                                                    : vehicle?.health ===
                                                                      "moderate"
                                                                    ? "yellow"
                                                                    : vehicle?.health ===
                                                                      "critical"
                                                                    ? "red"
                                                                    : "grey"
                                                            }
                                                        >
                                                            {value}
                                                        </Avatar>
                                                    ) : value === true ? (
                                                        "Enabled"
                                                    ) : value === false ? (
                                                        "Disabled"
                                                    ) : value === "" ? (
                                                        "-"
                                                    ) : typeof value ===
                                                      "string" ? (
                                                        value
                                                    ) : (
                                                        JSON.stringify(value)
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </Fragment>
                        )
                    )}
                </tbody>
            </table>
        </Box>
    );
};

export default Info;
