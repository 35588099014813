// import { client } from "index";
import { firebaseAuth } from "./firebase";
import { AUTH_URL } from "./constants";
import storageManager from "./storageManager";
import store from "store";
import { logout } from "actions";
// import { gql } from "@apollo/client";
import { snackbar } from "utils";
import { sendPasswordResetEmail } from "firebase/auth";

// const companyQuery = {
//   query: gql`
//     query CompanyDetails {
//       account {
//         me {
//           company {
//             id
//             name
//             token
//           }
//         }
//       }
//     }
//   `,
// };

const twentyNineDaysInMilliseconds = 1000 * 60 * 60 * 24 * 29;

export const revosLogin = async (user: any) => {
  return await fetch(`${AUTH_URL}/company/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  })
    .then((res) => res.json())
    .then(async (response) => {
      if (response?.status !== 200) return response;
      let boltToken = response.data?.token;
      let companyDetails = response.data?.user?.company;
      let companyToken = companyDetails?.token;

      storageManager.set(
        "boltTokenExpirationTime",
        new Date().getTime() + twentyNineDaysInMilliseconds,
      );
      storageManager.set("boltToken", boltToken);
      storageManager.set("companyToken", companyToken);

      return response;
    });
};

// export const revosLogin = async (accessToken: string, code: any) => {
//   return fetch(`${AUTH_URL}//user/login/firebase`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       ...(code ? { code } : {}),
//     },
//     body: JSON.stringify({
//       accessToken,
//     }),
//   })
//     .then((res) => res.json())
//     .then(async (response) => {
//       console.log(response);
//       if (response?.status >= 400) return response;
//       let boltToken = response.data?.token;
//       let companyDetails = response.data?.user?.company;
//       let companyToken = companyDetails?.token;

//       storageManager.set(
//         "boltTokenExpirationTime",
//         new Date().getTime() + twentyNineDaysInMilliseconds,
//       );
//       storageManager.set("boltToken", boltToken);

//       if (!companyDetails || !companyDetails.token) {
//         // let companyDataGql = await client.query(companyQuery);
//         // companyToken = companyDataGql.data.account?.me?.company?.token;

//         // if (companyToken) storageManager.set("companyToken", companyToken);
//         return { status: 400 };
//       } else {
//         storageManager.set("companyToken", companyToken);
//       }

//       return response;
//     });
// };

export const revosUserSignUp = async (body: any, code: any) => {
  return fetch(`${AUTH_URL}/company/register/user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(code ? { code } : {}),
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then(async (response) => {
      if (response?.status >= 400) return response;
      let boltToken = response.data?.token;
      // let companyDetails = response.data?.user?.company;
      // let companyToken = companyDetails?.token;

      storageManager.set(
        "boltTokenExpirationTime",
        new Date().getTime() + twentyNineDaysInMilliseconds,
      );
      storageManager.set("boltToken", boltToken);

      // if (!companyDetails || !companyDetails.token) {
      //   // let companyDataGql = await client.query(companyQuery);
      //   // companyToken = companyDataGql.data.account?.me?.company?.token;

      //   // if (companyToken) storageManager.set("companyToken", companyToken);
      //   return { status: 400 };
      // } else {
      //   storageManager.set("companyToken", companyToken);
      // }
      return response;
    });
};

export const getBoltToken = async (token?: string) => {
  // const { user } = store.getState().global.firebase;
  // let apiKey = user?.stsTokenManager?.apiKey || user?.apiKey || "";
  // let refreshToken = user?.stsTokenManager?.refreshToken || "";

  if (
    new Date().getTime() <
    parseInt(storageManager.get("boltTokenExpirationTime") || "")
  ) {
    return storageManager.get("boltToken");
  } else if (token) {
    let newBoltToken = await getNewBoltToken(token);
    return newBoltToken;
  }
  // else {
  //   if (!apiKey || !refreshToken) {
  //     console.log("getBoltToken");
  //     onTokenFail();
  //     return "";
  //   } else {
  //     let data = await getNewAccessToken(apiKey, refreshToken);
  //     let newAccessToken = data["access_token"];
  //     let newBoltToken = await getNewBoltToken(newAccessToken);
  //     return newBoltToken;
  //   }
  // }
};

async function getNewBoltToken(accessToken: string) {
  return fetch(`${AUTH_URL}/company/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      accessToken,
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      storageManager.set(
        "boltTokenExpirationTime",
        new Date().getTime() + twentyNineDaysInMilliseconds,
      );
      let boltToken = data.data.token;
      storageManager.set("boltToken", boltToken);
      return boltToken;
    })
    .catch((err) => {
      console.error(err);
      console.log("getNewBoltToken");

      onTokenFail();
    });
}

export async function refreshToken() {
  const token = storageManager.get("boltToken");
  return fetch(`${AUTH_URL}/refreshToken`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      refreshToken: token,
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.authToken) {
        storageManager.set(
          "boltTokenExpirationTime",
          new Date().getTime() + twentyNineDaysInMilliseconds,
        );
        let boltToken = data.authToken;
        storageManager.set("boltToken", boltToken);
        return boltToken;
      } else {
        onTokenFail();
      }
    })
    .catch((err) => {
      console.error(err);
      onTokenFail();
    });
}

export function onTokenFail(msg?: string) {
  snackbar.error(msg || "An error occurred. Please sign in again.");
  console.log("onTokenFail");
  store.dispatch(logout());
}

// export function firebaseLoginRequest({ email, password }: any) {
//   return new Promise((resolve, reject) => {
//     firebase.auth
//       .signInWithEmailAndPassword(email, password)
//       .then((resp) => {
//         resolve(resp);
//       })
//       .catch((error) => {
//         resolve(error);
//       });
//   });
// }

export function resetPasswordRequest(email: any) {
  return new Promise((resolve, reject) => {
    sendPasswordResetEmail(firebaseAuth, email)
      .then(() => {
        let resp = "Password reset link sent on your email.";
        resolve(resp);
      })
      .catch(() => {
        let error = {
          code: "500",
          message: "Error occurred. Please try again later.",
        };
        resolve(error);
      });
  });
}

// export function firebaseSignUpRequest(email: any, password: any) {
//   return new Promise((resolve, reject) => {
//     firebase.auth
//       .createUserWithEmailAndPassword(email, password)
//       .then((resp) => {
//         resolve(resp);
//       })
//       .catch((error) => {
//         resolve(error);
//       });
//   });
// }

// export function getTokenId(forceRefresh: any) {
//   return new Promise((resolve, reject) => {
//     // console.log(firebase, firebase.auth)
//     //if(!firebase.currentUser) return
//     firebase?.auth?.currentUser
//       ?.getIdToken(forceRefresh)
//       .then((idToken: any) => {
//         //console.log('token is', idToken)
//         resolve(idToken);
//       })
//       .catch(function (error: any) {
//         // Handle error
//       });
//   });
// }
