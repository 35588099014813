import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  // Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // Divider,
  IconButton,
  MenuItem,
  Select,
  // Slider,
  TextField,
  Typography,
} from "@mui/material";
// import { keys } from "@mui/system";
import CircularLoader from "components/CircularLoader";
// import Slide from "@mui/material/Slide";
// import { TransitionProps } from "@mui/material/transitions";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  authorizedFetch,
  // getEnabledSections,
  GlobalState,
  // makeCamelCase,
  setLoader,
  snackbar,
} from "utils";
import { AUTH_URL } from "utils/constants";
import validator from "validator";

const InviteDialog = ({ open, handleClose, user, refresh }: any) => {
  console.log(user?.permissionMatrix);
  const { company } = useSelector((state: GlobalState) => state.global);

  const [email, setEmail] = useState("");
  const [companyRoles, setCompanyRoles] = useState<any>([]);
  const [roles, setRoles] = useState<any>({
    vms: null,
    fms: null,
    cms: null,
    housekeeping: null,
  });

  useEffect(() => {
    if (open && company) {
      const roles = {
        ...(company?.permissionMatrix?.cms?.plan !== "NONE" && {
          cms: null,
        }),
        ...(company?.permissionMatrix?.fms?.plan !== "NONE" && {
          fms: null,
        }),
        ...(company?.permissionMatrix?.vms?.plan !== "NONE" && {
          vms: null,
        }),
        ...(company?.permissionMatrix?.housekeeping?.plan !== "NONE" && {
          housekeeping: null,
        }),
      };
      setRoles(roles);
    }
  }, [open, company]);

  const handleRole = (section: any, value: any) => {
    setRoles((prev: any) => {
      return {
        ...prev,
        [section]: value,
      };
    });
  };

  const getRolesURL = `${AUTH_URL}/company/roles`;
  const { isLoading: rolesLoading, data: rolesData } = useQuery(
    ["getCompanyRoles", open],
    () => authorizedFetch(getRolesURL)
  );

  useEffect(() => {
    if (open && rolesData && rolesData?.data?.length > 0) {
      const cmsRoles = rolesData?.data
        ?.filter((el: any) => el.product === "cms")
        .map((el: any) => {
          return { role: el.role, id: el._id };
        });
      const vmsRoles = rolesData?.data
        ?.filter((el: any) => el.product === "vms")
        .map((el: any) => {
          return { role: el.role, id: el._id };
        });
      const fmsRoles = rolesData?.data
        ?.filter((el: any) => el.product === "fms")
        .map((el: any) => {
          return { role: el.role, id: el._id };
        });
      const housekeepingRoles = rolesData?.data
        ?.filter((el: any) => el.product === "housekeeping")
        .map((el: any) => {
          return { role: el.role, id: el._id };
        });
      const companyRoles = {
        ...(cmsRoles.length > 0 && { cms: cmsRoles }),
        ...(vmsRoles.length > 0 && { vms: vmsRoles }),
        ...(fmsRoles.length > 0 && { fms: fmsRoles }),
        ...(housekeepingRoles.length > 0 && {
          housekeeping: housekeepingRoles,
        }),
      };

      setCompanyRoles(companyRoles);
    }
  }, [open, rolesData]);

  useEffect(() => {
    if (open && user) {
      setEmail(user?.email);
    }
    if (rolesData && rolesData?.data?.length > 0) {
      if (user?.permissionMatrix?.cms !== null && roles.hasOwnProperty("cms")) {
        handleRole("cms", user?.permissionMatrix?.cms?._id);
      }
      if (user?.permissionMatrix?.fms !== null && roles.hasOwnProperty("fms")) {
        handleRole("fms", user?.permissionMatrix?.fms?._id);
      }
      if (user?.permissionMatrix?.vms !== null && roles.hasOwnProperty("vms")) {
        handleRole("vms", user?.permissionMatrix?.vms?._id);
      }
      if (
        user?.permissionMatrix?.housekeeping !== null &&
        roles.hasOwnProperty("housekeeping")
      ) {
        handleRole("housekeeping", user?.permissionMatrix?.housekeeping?._id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, user, rolesData]);

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();

    setLoader(true);
    authorizedFetch(
      `${AUTH_URL}/company/users/${user?._id}/permissions/update`,
      {
        method: "POST",
        body: {
          permissionMatrix: roles,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((response) => {
      setLoader(false);
      if (response?.status === 200) {
        snackbar.success("Permissions updated successfully");
      } else {
        snackbar.error("Permissions could not be updated");
      }
      handleClose();
      refresh();
    });
  }

  useEffect(() => {
    if (!open) {
      setEmail("");
      setRoles({
        vms: null,
        fms: null,
        cms: null,
        housekeeping: null,
      });
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Invite New Member
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Box py={1}>
            <Box
              display="grid"
              gridTemplateColumns="auto 1fr"
              alignItems="center"
              gap={2}
            >
              <Typography sx={{ fontWeight: 600 }}>Email</Typography>
              <TextField
                autoComplete="off"
                disabled
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                fullWidth
                size="small"
                type="email"
                required
                error={email !== "" && !validator.isEmail(email)}
                helperText={
                  email !== "" && !validator.isEmail(email)
                    ? "Invalid email address"
                    : ""
                }
              />
              <Typography
                sx={{
                  fontWeight: 600,
                  alignSelf: "start",
                  pt: 1,
                }}
              >
                Role
              </Typography>

              <Box mt={1}>
                {rolesLoading ? (
                  <CircularLoader />
                ) : (
                  Object.keys(roles)?.map((el: any, i: any) => (
                    <Box key={i}>
                      <Typography
                        sx={{
                          mb: 1,
                          fontSize: 14,
                          fontWeight: 500,
                        }}
                      >
                        {el === "vms"
                          ? "Vehicle Management"
                          : el === "fms"
                          ? "Vehicle Lease Management"
                          : el === "cms"
                          ? "Charger Management"
                          : "Supply Chain Management"}
                      </Typography>
                      <Select
                        size="small"
                        value={roles[el] === null ? "" : roles[el]}
                        onChange={(e) => handleRole(el, e.target.value)}
                        displayEmpty
                        sx={{ width: 1, mb: 3 }}
                      >
                        <MenuItem disabled value="">
                          <em>Select</em>
                        </MenuItem>
                        {companyRoles[el]?.map((option: any, i: any) => (
                          <MenuItem key={i} value={option?.id}>
                            {option?.role}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  ))
                )}
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit">
            Update Permissions
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default InviteDialog;
