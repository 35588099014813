import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CircularLoader from "components/CircularLoader";
import RedAsterisk from "components/RedAsterisk";

const Config = ({
  form,
  config,
  setConfig,
  configLoading,
  configData,
  isRental,
}: any) => {
  function handleChange(key: string, value: any, type = "number") {
    // if (type === "number" && !/^[0-9]{0,3}$/.test(value)) return;
    setConfig((prev: any) => ({
      ...prev,
      [key]:
        type === "number"
          ? isNaN(parseFloat(value))
            ? null
            : parseFloat(value)
          : value,
    }));
  }

  console.log(config?.batteryMinVoltage);

  return (
    <>
      <Typography sx={{ mb: 2 }} variant="body2" color="text.secondary">
        All fields marked with ( <RedAsterisk /> ) are required
      </Typography>
      {configLoading ? (
        <Box p={3}>
          <CircularLoader />
        </Box>
      ) : (
        <Box
          sx={{
            py: form.protocol === "PNP" ? 3 : 2,
            display: "grid",
            gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
            gap: 4,
          }}
        >
          {configData?.data
            ?.filter(
              (field: any) =>
                !(
                  isRental &&
                  form.protocol === "PNP" &&
                  !["batteryMaxVoltage", "batteryMinVoltage"].includes(
                    field.key,
                  )
                ),
            )
            ?.map((field: any, i: number) => {
              const key = field.key;
              const label = field.name;
              const value =
                config[field.key] ?? (field.type === "boolean" ? false : "");

              console.log(label);
              console.log(key);

              return (
                <Box key={i} width={1}>
                  {field.type === "dropdown-single" ? (
                    <FormControl key={i} size="small" required fullWidth>
                      <InputLabel id={key}>{label}</InputLabel>
                      <Select
                        size="small"
                        labelId={key}
                        value={value}
                        onChange={(e) =>
                          handleChange(key, e.target.value, "text")
                        }
                        label={label}
                      >
                        {field.options?.map((option: any, i: number) => (
                          <MenuItem key={i} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : field.type === "boolean" ? (
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          size="small"
                          checked={value}
                          onChange={(e) =>
                            handleChange(key, e.target.checked, "boolean")
                          }
                        />
                      }
                      label={label}
                    />
                  ) : field.type === "number" ? (
                    <TextField
                      key={i}
                      type="number"
                      label={label}
                      fullWidth
                      size="small"
                      value={value}
                      disabled={
                        label === "Battery Maximum Voltage"
                          ? !config?.batteryMinVoltage
                          : false
                      }
                      onChange={
                        label === "Max Speed"
                          ? (e: any) => {
                              handleChange(
                                key,
                                Number(e.target.value) < 0
                                  ? 0
                                  : Number(e.target.value) > 999
                                  ? 999
                                  : e.target.value,
                              );
                            }
                          : label === "Current Limit"
                          ? (e: any) => {
                              handleChange(
                                key,
                                Number(e.target.value) < 0
                                  ? 0
                                  : Number(e.target.value) > 99
                                  ? 99
                                  : e.target.value,
                              );
                            }
                          : label === "Pickup Control Limit" ||
                            label === "Brake Regeneration Limit" ||
                            label === "Zero Throttle Regeneration Limit"
                          ? (e) => {
                              handleChange(
                                key,
                                Number(e.target.value) < 0
                                  ? 0
                                  : Number(e.target.value) > 100
                                  ? 100
                                  : e.target.value,
                              );
                            }
                          : label === "Battery Minimum Voltage" ||
                            label === "Battery Maximum Voltage" ||
                            label === "Over Voltage Limit" ||
                            label === "Under Voltage Limit"
                          ? (e) => {
                              handleChange(
                                key,
                                Number(e.target.value) < 0
                                  ? 0
                                  : Number(e.target.value) > 255
                                  ? 255
                                  : e.target.value,
                              );
                            }
                          : label === "Wheel Diameter"
                          ? (e: any) => {
                              handleChange(
                                key,
                                Number(e.target.value) < 0 ? 0 : e.target.value,
                              );
                            }
                          : (e: any) => {
                              handleChange(
                                key,
                                Number(e.target.value) < 0
                                  ? 0
                                  : Number(e.target.value) > 150
                                  ? 150
                                  : e.target.value,
                              );
                            }
                      }
                      required={field.required}
                      helperText={
                        label === "Max Speed"
                          ? "Value can be 0 to 999"
                          : label === "Current Limit"
                          ? "Value can be 0 to 99"
                          : label === "Wheel Diameter"
                          ? ""
                          : label === "Battery Minimum Voltage" ||
                            label === "Over Voltage Limit" ||
                            label === "Under Voltage Limit"
                          ? "Value can be 0 to 255"
                          : label === "Pickup Control Limit" ||
                            label === "Brake Regeneration Limit" ||
                            label === "Zero Throttle Regeneration Limit"
                          ? "Value can be 0 to 100"
                          : label === "Battery Maximum Voltage"
                          ? !config?.batteryMinVoltage
                            ? ""
                            : `Value can be ${config?.batteryMinVoltage} to 255`
                          : "Value can be 0 to 150"
                      }
                      error={
                        label === "Battery Maximum Voltage" &&
                        config?.batteryMaxVoltage
                          ? config?.batteryMinVoltage >
                            config?.batteryMaxVoltage
                          : false
                      }
                    />
                  ) : (
                    <TextField
                      key={i}
                      label={label}
                      fullWidth
                      size="small"
                      value={value}
                      onChange={(e) =>
                        handleChange(key, e.target.value, "text")
                      }
                      required={field.required}
                    />
                  )}
                </Box>
              );
            })}
        </Box>
      )}
    </>
  );
};

export default Config;
