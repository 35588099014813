import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  CheckCircleOutline,
  Close,
  EditOutlined,
  HighlightOff,
  HighlightOffOutlined,
  InsertDriveFileOutlined,
  // MoreVert,
  PendingOutlined,
  Warning,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Avatar,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActionArea,
  Dialog,
  // Menu,
  // MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stepper,
  Step,
  StepButton,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import {
  authorizedFetch,
  // authorizedFetch,
  drawer,
  getDarkModePreference,
  GlobalState,
  setLoader,
  snackbar,
  // setLoader,
  // snackbar,
} from "utils";
import { LEASE_URL } from "utils/constants";
import { useMutation, useQuery } from "react-query";

// import { LEASE_URL } from "utils/constants";
// import { useMutation } from "react-query";

const DrawerContent = ({ user, refetchKYC, refetchCount }: any) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  // const { company } = useSelector((state: GlobalState) => state.global);
  // const { canWrite } = getPermissions("rental:kyc");

  let table = [
    { header: "User Info" },
    {
      label: "Name",
      // value: `${user?.firstName || ""} ${user?.lastName || ""}`,
      value: `${
        user?.firstName === null
          ? ""
          : user?.firstName.charAt(0).toUpperCase() + user?.firstName.slice(1)
      } ${
        user?.lastName === null
          ? ""
          : user?.lastName.charAt(0).toUpperCase() + user?.lastName.slice(1)
      }`,
    },
    {
      label: "Email",
      // value: user?.email
      value: `${
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(user?.email)
          ? user?.email.toLowerCase()
          : ""
      }`,
    },
    {
      label: "Phone",
      // value: user?.phone || "-"
      value: user?.phone || "",
    },
  ];

  const url = `${LEASE_URL}/company/kyc/${user?._id}/get`;
  const { isLoading: kycLoading, data: kycData } = useQuery(
    ["getSingleKYC", user],
    () => authorizedFetch(url)
  );
  console.log(kycData);

  const docs = kycData?.data?.document || [];
  const status = user?.status || "";

  const [viewDialog, setViewDialog] = useState({ open: false, data: null });

  const [editDialog, setEditDialog] = useState({
    open: false,
    docs: {},
    user: {},
  });

  return (
    <>
      <EditDialog
        open={editDialog.open}
        handleClose={() => {
          setEditDialog({ ...editDialog, open: false });
        }}
        docs={editDialog.docs}
        user={editDialog.user}
        refetchKYC={refetchKYC}
        refetchCount={refetchCount}
        drawerClose={() => drawer.close()}
      />
      <ViewDialog
        open={viewDialog.open}
        data={viewDialog.data}
        handleClose={() => setViewDialog((prev) => ({ ...prev, open: false }))}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          <Box display="flex" alignItems="center">
            {user?.firstName || ""} {user?.lastName || ""}
            <Avatar
              variant="status"
              className={
                status === "REJECTED"
                  ? "red"
                  : status !== "APPROVED"
                  ? "yellow"
                  : ""
              }
              sx={{ ml: 2 }}
            >
              {status || "N/A"}
            </Avatar>
          </Box>
          <Box>
            <IconButton
              children={<HighlightOff />}
              color="inherit"
              size="small"
              onClick={() => drawer.close()}
            />
          </Box>
        </Box>
        <Box flexGrow={1} overflow="auto">
          <Box
            sx={{
              px: 3,
              py: 2.5,
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  position: "relative",
                  "& td": {
                    py: 2,
                    position: "absolute",
                    verticalAlign: "middle",
                    backgroundColor: (theme) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {table.map(({ header, label, value }, i) => {
                  const isFirst = table[i - 1]?.header;
                  const isLast = !table[i + 1] || table[i + 1].header;

                  return (
                    <tr
                      key={i}
                      className={
                        header
                          ? "header"
                          : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                      }
                    >
                      {header ? (
                        <td colSpan={2}>
                          <span>{header}</span>
                        </td>
                      ) : (
                        <>
                          {label && <td className="bold">{label}</td>}
                          {value && <td>{value}</td>}
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Box
              sx={{
                mb: 3,
                px: 2,
                py: 1.5,
                width: 1,
                backgroundColor: (theme) => theme.customColors.header,
                borderRadius: "4px",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              KYC Documents
              <IconButton
                sx={{ ml: 1.5 }}
                children={<EditOutlined />}
                color="primary"
                size="small"
                onClick={() => {
                  setEditDialog({
                    open: true,
                    docs: docs,
                    user: user,
                  });
                }}
              />
            </Box>
            {kycLoading ? (
              <Box
                sx={{
                  gridColumn: { sm: "span 2" },
                  display: "flex",
                  justifyContent: "center",
                  mt: 1,
                }}
              >
                <CircularProgress />
              </Box>
            ) : docs.length === 0 ? (
              <Typography textAlign="center" color="text.secondary">
                No documents
              </Typography>
            ) : (
              docs.map((doc: any, i: number) => (
                <Box
                  key={i}
                  sx={{
                    mb: 1.5,
                    width: 1,
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "space-between"
                  }}
                >
                  <Card
                    onClick={() => setViewDialog({ open: true, data: doc })}
                    variant="outlined"
                  >
                    <CardActionArea sx={{ display: "flex" }}>
                      <CardMedia
                        component="img"
                        sx={{ width: 120, height: 120 }}
                        image={doc.url || ""}
                      />
                      <CardContent sx={{ width: 240 }}>
                        <Typography mb={0.5} fontWeight="bold">
                          {doc.name || "-"}
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color:
                              doc.status === "APPROVED" || status === "APPROVED"
                                ? "success.main"
                                : doc.status === "REJECTED"
                                ? "error.main"
                                : "warning.main",
                          }}
                        >
                          {doc.status === "APPROVED" ||
                          status === "APPROVED" ? (
                            <CheckCircleOutline
                              fontSize="small"
                              sx={{ mr: 0.5 }}
                            />
                          ) : doc.status === "REJECTED" ? (
                            <HighlightOffOutlined
                              fontSize="small"
                              sx={{ mr: 0.5 }}
                            />
                          ) : (
                            <PendingOutlined
                              fontSize="small"
                              sx={{ mr: 0.5 }}
                            />
                          )}
                          {status === "APPROVED"
                            ? "Approved"
                            : doc.status[0] + doc.status.toLowerCase().slice(1)}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  {/* {status !== "APPROVED" && (
                    <KYCActions user={user} docs={docs} doc={doc} />
                  )} */}
                  {/* <ToggleButtonGroup
                    size="small"
                    orientation="vertical"
                    exclusive
                    defaultValue={doc.status}
                  >
                    <ToggleButton value="APPROVED">Approve</ToggleButton>
                    <ToggleButton value="REJECTED">Reject</ToggleButton>
                  </ToggleButtonGroup> */}
                </Box>
              ))
            )}
            {status !== "APPROVED" && docs.length !== 3 && (
              <Box mt={4} px={2}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: 500,
                  }}
                >
                  <Warning color="warning" sx={{ mr: 1 }} />
                  Required Documents:
                </Box>
                <List dense>
                  {["DL_FRONT", "DL_BACK", "PROFILE"].map((el, i) =>
                    docs.findIndex((doc: any) => doc.type === el) === -1 ? (
                      <ListItem key={i} sx={{ px: 0.5 }}>
                        <ListItemIcon sx={{ minWidth: 32 }}>
                          <InsertDriveFileOutlined color="disabled" />
                        </ListItemIcon>
                        <ListItemText primary={el} />
                      </ListItem>
                    ) : null
                  )}
                </List>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

// const KYCActions = ({ user, docs, doc }: any) => {
//   const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

//   const [allDocs, setAllDocs] = useState<any>({
//     DL_FRONT: "",
//     DL_BACK: "",
//     PROFILE: "",
//   });

//   const { DL_FRONT, DL_BACK, PROFILE } = allDocs;

//   useEffect(() => {
//     if (docs) {
//       let DL_FRONT = docs.find((el: any) => el.name === "DL_FRONT");
//       let DL_BACK = docs.find((el: any) => el.name === "DL_BACK");
//       let PROFILE = docs.find((el: any) => el.name === "PROFILE");

//       setAllDocs({
//         DL_FRONT: DL_FRONT?.status,
//         DL_BACK: DL_BACK?.status,
//         PROFILE: PROFILE?.status,
//       });
//     }
//   }, [docs]);

//   const updateStatusURL = `${LEASE_URL}/company/kyc/${user?._id}/markStatus`;

//   const updateStatusMutation = useMutation(
//     "addFences",
//     () =>
//       authorizedFetch(updateStatusURL, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           token: "1234",
//         },
//         body: {
//           status: allDocs,
//         },
//       }),
//     {
//       onSuccess: () => {
//         setLoader(false);
//         snackbar.success(`Status Updated`);
//       },
//       onError: () => {
//         setLoader(false);
//         snackbar.error(`Error updating status`);
//       },
//     }
//   );

//   function statusChanges(action: string) {
//     setAnchorEl(null);

//     setAllDocs({
//       DL_FRONT: doc?.name === "DL_FRONT" ? action : DL_FRONT,
//       DL_BACK: doc?.name === "DL_BACK" ? action : DL_BACK,
//       PROFILE: doc?.name === "PROFILE" ? action : PROFILE,
//     });
//   }

//   // useEffect(() => {
//   //   setLoader(true);
//   //   updateStatusMutation.mutate();
//   // }, [allDocs]);

//   return (
//     <>
//       <IconButton sx={{ ml: 1 }} onClick={(e) => setAnchorEl(e.currentTarget)}>
//         <MoreVert />
//       </IconButton>
//       <Menu
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={() => setAnchorEl(null)}
//       >
//         <MenuItem
//           disabled={doc.status === "APPROVED"}
//           onClick={() => statusChanges("APPROVED")}
//         >
//           Approve
//         </MenuItem>
//         <MenuItem
//           disabled={doc.status === "REJECTED"}
//           onClick={() => statusChanges("REJECTED")}
//         >
//           Reject
//         </MenuItem>
//       </Menu>
//     </>
//   );
// };

const EditDialog = ({
  open,
  docs,
  user,
  handleClose,
  refetchKYC,
  refetchCount,
  drawerClose,
}: any) => {
  const [step, setStep] = useState(0);
  const steps = ["DL Front", "DL Back", "ID Front", "ID Back"];
  const [allStatus, setAllStatus] = useState<any>({
    DL_FRONT: "",
    DL_BACK: "",
    ID_FRONT: "",
    ID_BACK: "",
  });
  const [allImg, setAllImg] = useState<any>({
    DL_FRONT: "",
    DL_BACK: "",
    ID_FRONT: "",
    ID_BACK: "",
  });
  const [viewDialog, setViewDialog] = useState<any>({
    open: false,
    data: null,
  });

  useEffect(() => {
    if (open && docs) {
      let DL_FRONT = docs?.find((el: any) => el.name === "DL_FRONT");
      let DL_BACK = docs?.find((el: any) => el.name === "DL_BACK");
      let ID_FRONT = docs?.find((el: any) => el.name === "ID_FRONT");
      let ID_BACK = docs?.find((el: any) => el.name === "ID_BACK");

      setAllStatus({
        DL_FRONT: DL_FRONT?.status,
        DL_BACK: DL_BACK?.status,
        ID_FRONT: ID_FRONT?.status,
        ID_BACK: ID_BACK?.status,
      });
      setAllImg({
        DL_FRONT: DL_FRONT?.url,
        DL_BACK: DL_BACK?.url,
        ID_FRONT: ID_FRONT?.url,
        ID_BACK: ID_BACK?.url,
      });
    }
  }, [docs, open]);

  const { DL_FRONT, DL_BACK, ID_FRONT, ID_BACK } = allStatus;

  const updateStatusURL = `${LEASE_URL}/company/kyc/${user?._id}/markStatus`;

  const updateStatusMutation = useMutation(
    "addFences",
    () =>
      authorizedFetch(updateStatusURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: "1234",
        },
        body: {
          status: allStatus,
        },
      }),
    {
      onSuccess: () => {
        setLoader(false);
        refetchKYC();
        refetchCount();
        drawerClose();
        snackbar.success(`Status Updated`);
      },
      onError: () => {
        setLoader(false);
        snackbar.error(`Error updating status`);
      },
    }
  );

  function handleBack() {
    setStep(step - 1);
  }

  const handleSave = () => {
    setLoader(true);
    updateStatusMutation.mutate();
    handleClose();
  };

  function handleNext() {
    if (step === steps.length - 1) {
      handleSave();
    } else setStep(step + 1);
  }

  function isComplete(step: number) {
    switch (step) {
      case 0:
        return DL_FRONT !== "PENDING";
      case 1:
        return DL_BACK !== "PENDING";
      case 2:
        return ID_FRONT !== "PENDING";
      case 3:
        return ID_BACK !== "PENDING";
      default:
        break;
    }
  }

  function handleChange(key: string, value: string) {
    setAllStatus((prevInput: any) => ({ ...prevInput, [key]: value }));
  }

  const disabled =
    DL_FRONT === "PENDING" ||
    DL_BACK === "PENDING" ||
    ID_FRONT === "PENDING" ||
    ID_BACK === "PENDING";

  useEffect(() => {
    if (!open) {
      setStep(0);
      setAllStatus({
        DL_FRONT: "",
        DL_BACK: "",
        PROFILE: "",
      });
      setAllImg({
        DL_FRONT: "",
        DL_BACK: "",
        PROFILE: "",
      });
    }
  }, [open]);

  return (
    <>
      <ViewDialog
        open={viewDialog.open}
        data={viewDialog.data}
        handleClose={() =>
          setViewDialog((prev: any) => ({ ...prev, open: false }))
        }
      />
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            maxWidth: 800,
            width: 1,
            "& .MuiInputBase-root": {
              fontSize: 14,
              borderRadius: 1,
              p: "3.5px 5px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          Update KYC
          <IconButton
            children={<HighlightOff />}
            color="inherit"
            onClick={handleClose}
            sx={{ transform: "translate(8px, -8px)" }}
          />
        </DialogTitle>
        <DialogContent sx={{ pb: "16px !important" }}>
          <Stepper
            sx={{ my: 4, mx: "auto", maxWidth: 534 }}
            activeStep={step}
            nonLinear
            alternativeLabel
          >
            {steps.map((label, i) => (
              <Step key={i}>
                <StepButton onClick={() => setStep(i)}>{label}</StepButton>
              </Step>
            ))}
          </Stepper>
          {step === 0 && (
            <Box
              sx={{
                maxWidth: 500,
                mx: "auto",
                py: 2,
              }}
            >
              <Box>
                <Typography className="label">Image</Typography>
                <Box
                  width={500}
                  height={"auto"}
                  display="flex"
                  justifyContent={"center"}
                >
                  <img
                    width={"auto"}
                    style={{ maxWidth: 500, cursor: "pointer" }}
                    height={300}
                    src={allImg.DL_FRONT}
                    alt={"DL_FRONT"}
                    onClick={() =>
                      setViewDialog({
                        open: true,
                        data: { url: allImg.DL_FRONT },
                      })
                    }
                  ></img>
                </Box>
              </Box>
              <Box mt={2}>
                <Typography className="label">Status</Typography>
                <Select
                  fullWidth
                  value={DL_FRONT}
                  onChange={(e: any) => {
                    handleChange("DL_FRONT", e.target.value);
                  }}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>Select</em>
                  </MenuItem>
                  {["APPROVED", "REJECTED", "PENDING"]?.map(
                    (el: any, id: any) => (
                      <MenuItem key={el} value={el}>
                        {el}
                      </MenuItem>
                    )
                  )}
                </Select>
              </Box>
            </Box>
          )}
          {step === 1 && (
            <Box
              sx={{
                maxWidth: 500,
                mx: "auto",
                py: 2,
              }}
            >
              <Box>
                <Typography className="label">Image</Typography>
                <Box
                  width={500}
                  height={"auto"}
                  display="flex"
                  justifyContent={"center"}
                >
                  <img
                    width={"auto"}
                    style={{ maxWidth: 500, cursor: "pointer" }}
                    height={300}
                    src={allImg.DL_BACK}
                    alt={"DL_BACK"}
                    onClick={() =>
                      setViewDialog({
                        open: true,
                        data: { url: allImg.DL_BACK },
                      })
                    }
                  ></img>
                </Box>
              </Box>
              <Box mt={2}>
                <Typography className="label">Status</Typography>
                <Select
                  fullWidth
                  value={DL_BACK}
                  onChange={(e: any) => {
                    handleChange("DL_BACK", e.target.value);
                  }}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>Select</em>
                  </MenuItem>
                  {["APPROVED", "REJECTED", "PENDING"]?.map(
                    (el: any, id: any) => (
                      <MenuItem key={el} value={el}>
                        {el}
                      </MenuItem>
                    )
                  )}
                </Select>
              </Box>
            </Box>
          )}
          {step === 2 && (
            <Box
              sx={{
                maxWidth: 500,
                mx: "auto",
                py: 2,
              }}
            >
              <Box>
                <Typography className="label">Image</Typography>
                <Box
                  width={500}
                  height={"auto"}
                  display="flex"
                  justifyContent={"center"}
                >
                  <img
                    width={"auto"}
                    style={{ maxWidth: 500, cursor: "pointer" }}
                    height={300}
                    src={allImg.ID_FRONT}
                    alt={"ID FRONT"}
                    onClick={() =>
                      setViewDialog({
                        open: true,
                        data: { url: allImg.ID_FRONT },
                      })
                    }
                  ></img>
                </Box>
              </Box>
              <Box mt={2}>
                <Typography className="label">Status</Typography>
                <Select
                  fullWidth
                  value={ID_FRONT}
                  onChange={(e: any) => {
                    handleChange("ID_FRONT", e.target.value);
                  }}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>Select</em>
                  </MenuItem>
                  {["APPROVED", "REJECTED", "PENDING"]?.map(
                    (el: any, id: any) => (
                      <MenuItem key={el} value={el}>
                        {el}
                      </MenuItem>
                    )
                  )}
                </Select>
              </Box>
            </Box>
          )}
          {step === 3 && (
            <Box
              sx={{
                maxWidth: 500,
                mx: "auto",
                py: 2,
              }}
            >
              <Box>
                <Typography className="label">Image</Typography>
                <Box
                  width={500}
                  height={"auto"}
                  display="flex"
                  justifyContent={"center"}
                >
                  <img
                    width={"auto"}
                    style={{ maxWidth: 500, cursor: "pointer" }}
                    height={300}
                    src={allImg.ID_BACK}
                    alt={"ID BACK"}
                    onClick={() =>
                      setViewDialog({
                        open: true,
                        data: { url: allImg.ID_BACK },
                      })
                    }
                  ></img>
                </Box>
              </Box>
              <Box mt={2}>
                <Typography className="label">Status</Typography>
                <Select
                  fullWidth
                  value={ID_BACK}
                  onChange={(e: any) => {
                    handleChange("ID_BACK", e.target.value);
                  }}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>Select</em>
                  </MenuItem>
                  {["APPROVED", "REJECTED", "PENDING"]?.map(
                    (el: any, id: any) => (
                      <MenuItem key={el} value={el}>
                        {el}
                      </MenuItem>
                    )
                  )}
                </Select>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {step !== 0 && (
            <Button variant="outlined" onClick={handleBack}>
              Back
            </Button>
          )}
          {step === 0 && (
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          )}
          <Button
            onClick={handleNext}
            variant={
              isComplete(step) || step === steps.length - 1
                ? "contained"
                : "outlined"
            }
            disableElevation
            disabled={step === steps.length - 1 && disabled}
          >
            {step === steps.length - 1
              ? "Save"
              : isComplete(step)
              ? "Next"
              : "Skip"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ViewDialog = ({ open, data, handleClose }: any) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <img src={data?.url || ""} alt="KYC Document" />
      <IconButton
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          m: 1,
        }}
        onClick={handleClose}
        color="inherit"
      >
        <Close />
      </IconButton>
    </Dialog>
  );
};

export default DrawerContent;
