import { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Checkbox,
  Divider,
  MenuItem,
  Pagination,
  Select,
  LinearProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getDarkModePreference, GlobalState, resolvePath } from "../utils";

type Row = {
  id: string | number;
  [key: string]: any;
};
type SelectedRows = readonly (string | number)[];
export type Column = {
  key: string;
  label: string;
  isNumeric?: boolean;
  Render?: (row: any) => JSX.Element | string;
  valueGetter?: (row: any) => any;
  format?: (value: any) => string;
};

interface Props {
  idKey?: string;
  loading?: boolean;
  small?: boolean;
  smallPagination?: boolean;
  disablePagination?: boolean;
  height?: number | string;
  px?: number;
  serverSidePagination?: boolean;
  activePage?: number;
  activePageSize?: number;
  onPageChange?: (arg: number) => void;
  onPageSizeChange?: (arg: number) => void;
  serverSideSorting?: boolean;
  sortModel?: any;
  sortBy?: string;
  setSortBy?: (arg: string) => void;
  rowCount?: number;
  rowsPerPage?: number;
  selectOnClick?: boolean;
  selectable?: boolean;
  selectedRows?: any;
  setSelectedRows?: (rows: any) => void;
  toolbar?: () => JSX.Element;
  hideDivider?: boolean;
  columns: Column[];
  rows: Row[];
  disableSorting?: boolean;
  fontSize?: number;
  singleSelect?: boolean;
  onRowClick?: (row: any) => void;
}

function descendingComparator<T>(
  a: T,
  b: T,
  orderBy: keyof T,
  columns: Column[],
) {
  const valueGetter = columns.find((el) => el.key === orderBy)?.valueGetter;
  let aValue =
    (valueGetter ? valueGetter(a) : resolvePath(a, String(orderBy))) || "";
  let bValue =
    (valueGetter ? valueGetter(b) : resolvePath(b, String(orderBy))) || "";
  // if (b[orderBy] < a[orderBy]) {
  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
  columns: Column[],
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, columns)
    : (a, b) => -descendingComparator(a, b, orderBy, columns);
}

const TableComponent: React.FC<Props> = ({
  idKey = "id",
  loading,
  small,
  smallPagination,
  disablePagination,
  height,
  px,
  serverSidePagination,
  activePage,
  activePageSize,
  onPageChange,
  onPageSizeChange,
  serverSideSorting,
  sortModel,
  sortBy,
  setSortBy,
  rowCount,
  rowsPerPage,
  selectOnClick,
  selectable,
  selectedRows,
  setSelectedRows,
  columns,
  rows,
  toolbar,
  hideDivider,
  singleSelect,
  disableSorting = false,
  onRowClick,
}) => {
  const theme = useTheme();
  const isXsUp = useMediaQuery(theme.breakpoints.up("xs"));
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state),
  );

  type Order = "asc" | "desc";
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("");

  const [selected, setSelected] = useState<SelectedRows>([]);

  const [pageSize, setPageSize] = useState(
    disablePagination ? rows.length : rowsPerPage || 10,
  );
  const [page, setPage] = useState(1);

  function handleSelect(id: string | number) {
    if (selectable) {
      const selectedIndex = selected.indexOf(id);
      let newSelected: SelectedRows = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      setSelected(newSelected);
      setSelectedRows && setSelectedRows(newSelected);
    }
  }

  useEffect(() => {
    // Set order state in case of server side sorting
    if (serverSideSorting) {
      setOrderBy("");
      if (sortBy && sortModel) {
        Object.keys(sortModel).forEach((key: string) => {
          if (sortModel[key].asc === sortBy || sortModel[key].desc === sortBy) {
            setOrderBy(key);
            setOrder(sortModel[key].asc === sortBy ? "asc" : "desc");
          }
        });
      }
    }
  }, [serverSideSorting, sortBy, sortModel]);

  useEffect(() => {
    if (selectedRows) {
      setSelected(selectedRows);
    } else {
      setSelected([]);
      setSelectedRows && setSelectedRows([]);
      setOrderBy("");
    }
  }, [rows, selectedRows, setSelectedRows]);

  return (
    <Box
      sx={{
        width: 1,
        position: "relative",
        pb: disablePagination ? 0 : { xs: "114px", sm: "74px" },
      }}
    >
      <TableContainer
        sx={{
          height: height || (small ? 600 : 619),
          maxHeight: "calc(100vh - 300px)",
          px: px !== undefined ? px : 3,
          "& .MuiTableRow-root > .checkbox-cell": {
            p: "0 12px 0 18px !important",
            minWidth: 0,
            width: 38,
          },
          ...(selectable
            ? {
                "& th:nth-of-type(2), td:nth-of-type(2)": {
                  pl: "0 !important",
                },
              }
            : {}),
          "& .MuiCheckbox-root": {
            color: "text.primary",
            "&.Mui-checked": {
              color: (theme) => theme.palette.primary.main,
            },
            "&.MuiCheckbox-indeterminate": {
              color: (theme) => theme.palette.primary.main,
            },
          },
          "& .MuiTableBody-root > .MuiTableRow-root": {
            ...(!small
              ? {
                  borderBottom: "2px solid",
                  borderColor: (theme) =>
                    isDarkMode ? "#1E1E1E" : theme.palette.background.paper,
                }
              : {}),
            transition: "100ms",
            "&.Mui-selected": {
              backgroundColor: isDarkMode ? "#424242" : "#E8F1F8",
            },
          },
        }}
      >
        {selected.length > 0 && (
          <Box
            sx={{
              width: 1,
              height: 60,
              pl: 3,
              pr: pageSize !== 10 ? 4.5 : 3,
              position: "absolute",
              top: 0,
              right: 0,
              display: "grid",
              gridTemplateColumns: "56px minmax(max-content, 1fr)",
            }}
          >
            <Box sx={{ width: 1, height: 1 }} />
            <Box
              sx={{
                zIndex: 3,
                width: 1,
                height: 58,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                pl: 2,
                pr: 3,
                borderRadius: "3px",
              }}
            >
              {selected.length} selected
              <Box
                sx={{
                  ml: 3,
                  ".MuiButton-root": {
                    textTransform: "none",
                    color: "text.primary",
                  },
                }}
              >
                {toolbar && toolbar()}
              </Box>
            </Box>
          </Box>
        )}
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {selectable && (
                <TableCell
                  className="checkbox-cell"
                  sx={{ visibility: singleSelect ? "hidden" : "visible" }}
                >
                  <Checkbox
                    size="small"
                    indeterminate={
                      selected.length > 0 && selected.length < rows.length
                    }
                    checked={rows.length > 0 && selected.length === rows.length}
                    onChange={(e) => {
                      let newSelecteds: SelectedRows = [];
                      if (
                        selected.length > 0 &&
                        selected.length < rows.length
                      ) {
                        newSelecteds = [];
                      } else if (e.target.checked) {
                        newSelecteds = rows.map((row) => row[idKey]);
                      }
                      setSelected(newSelecteds);
                      setSelectedRows && setSelectedRows(newSelecteds);
                    }}
                  />
                </TableCell>
              )}
              {columns.map(({ key, label, isNumeric }) => {
                const isSortingDisabled =
                  key === "actions" ||
                  label === "" ||
                  (serverSideSorting &&
                    !(sortModel || {}).hasOwnProperty(key)) ||
                  disableSorting;

                return (
                  <TableCell
                    key={key}
                    align={isNumeric ? "right" : "left"}
                    sortDirection={orderBy === key ? order : false}
                  >
                    <TableSortLabel
                      disabled={isSortingDisabled}
                      sx={{
                        visibility: selected.length > 0 ? "hidden" : "visible",
                        "& .custom-label": {
                          transform: "translateY(1px)",
                          lineHeight: "1em",
                        },
                        ...(!isSortingDisabled
                          ? {
                              "& .MuiTableSortLabel-icon": {
                                opacity: 0.35,
                              },
                            }
                          : {}),
                      }}
                      active={orderBy === key}
                      direction={orderBy === key ? order : "asc"}
                      onClick={(e) => {
                        // 3 possible states:
                        // 1. inactive
                        // 2. desc
                        // 3. asc

                        const isInactive = orderBy !== key;
                        const isAsc = order === "asc";
                        const isDesc = order === "desc";

                        const ascKey =
                          serverSideSorting && sortModel
                            ? sortModel[key].asc
                            : "";
                        const descKey =
                          serverSideSorting && sortModel
                            ? sortModel[key].desc
                            : "";

                        if (isInactive) {
                          setOrderBy(key);
                          if (serverSideSorting) {
                            // if desc possible, sort by desc, otherwise asc
                            setSortBy && setSortBy(descKey || ascKey);
                            setOrder(descKey ? "desc" : "asc");
                          } else {
                            setOrder("desc");
                          }
                        } else {
                          if (isAsc) {
                            setOrderBy("");
                            setSortBy && setSortBy("");
                          } else if (isDesc) {
                            if (serverSideSorting) {
                              // if asc possible, sort by asc, otherwise make inactive
                              setOrderBy(ascKey ? key : "");
                              setSortBy && setSortBy(ascKey || "");
                            } else {
                              setOrderBy(key);
                            }
                            setOrder("asc");
                          }
                        }
                      }}
                    >
                      <span className="custom-label">{label}</span>
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* <TableRow sx={{ border: '0 !important' }}>
              <TableCell sx={{ p: 0 }} colSpan={columns.length}>
                <LinearProgress sx={{
                  borderRadius: 1
                }} />
              </TableCell>
            </TableRow> */}
            {rows
              ?.slice()
              .sort(
                getComparator(order, serverSideSorting ? "" : orderBy, columns),
              )
              .slice(
                serverSidePagination ? undefined : (page - 1) * pageSize,
                serverSidePagination ? undefined : page * pageSize,
              )
              .map((row, i) => (
                <TableRow
                  key={i}
                  hover
                  sx={
                    !!onRowClick
                      ? {
                          cursor: "pointer",
                        }
                      : undefined
                  }
                  selected={selected.includes(row[idKey])}
                >
                  {selectable && (
                    <TableCell className="checkbox-cell">
                      <Checkbox
                        size="small"
                        checked={selected.includes(row[idKey])}
                        onChange={() => handleSelect(row[idKey])}
                      />
                    </TableCell>
                  )}
                  {columns.map(({ key, Render, valueGetter, format }, i) => {
                    let value = valueGetter
                      ? valueGetter(row)
                      : resolvePath(row, key);

                    return (
                      <TableCell
                        key={i}
                        sx={{ ...(Render ? { py: 0 } : {}) }}
                        onClick={() => {
                          selectOnClick && handleSelect(row[idKey]);
                          onRowClick && onRowClick(row);
                        }}
                      >
                        {Render ? (
                          Render(row)
                        ) : (
                          <Typography variant="body2">
                            {value !== null && typeof value === "object"
                              ? JSON.stringify(value)
                              : format
                                ? format(value)
                                : value || "-"}
                          </Typography>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {loading && (
          <LinearProgress
            sx={{
              borderRadius: 1,
              transform: "translateY(-7px)",
            }}
          />
        )}
        {!loading && rows.length === 0 && (
          <Box
            sx={{
              mt: 4,
              textAlign: "center",
              color: "text.disabled",
              userSelect: "none",
            }}
          >
            No rows
          </Box>
        )}
      </TableContainer>
      {!disablePagination && !hideDivider && <Divider sx={{ width: 1 }} />}
      {!disablePagination && (
        <Box
          sx={{
            userSelect: "none",
            width: 1,
            px: { xs: 0, sm: 3 },
            pt: small ? 1.75 : 0,
            pb: {
              xs: small ? 6.5 : 2,
              sm: small ? (hideDivider ? 4 : 3) : 2.5,
            },
            position: "absolute",
            bottom: 0,
            display: "flex",
            flexDirection: { xs: "column-reverse", sm: "row" },
            justifyContent: { xs: "start", sm: "space-between" },
            alignItems: "center",
            "& .MuiBox-root > .MuiSelect-root": {
              mx: 1.25,
              width: 55,
              height: 28,
              fontSize: 14,
              backgroundColor: "transparent",
              "& > div": {
                p: 0.75,
                pl: 1.25,
              },
              "& svg": {
                transform: "translateX(3px) translateY(3px)",
                height: 16,
                width: 16,
              },
            },
          }}
        >
          {rowsPerPage ? (
            <div />
          ) : (
            <Box mt={{ xs: 1.5, sm: 0 }} fontSize={14}>
              Show
              <Select
                size="small"
                sx={{ mx: 1 }}
                value={activePageSize !== undefined ? activePageSize : pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                  onPageSizeChange && onPageSizeChange(Number(e.target.value));
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
              per page
            </Box>
          )}
          <Pagination
            boundaryCount={!isXsUp || smallPagination ? 1 : 2}
            count={Math.ceil((rowCount ? rowCount : rows?.length) / pageSize)}
            page={activePage !== undefined ? activePage : page}
            onChange={(e, value) => {
              if (typeof value === "number") {
                setPage(value);
                onPageChange && onPageChange(value);
              }
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default TableComponent;
