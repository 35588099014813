import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { queryClient } from "index";
import { authorizedFetch, drawer, setLoader, snackbar } from "utils";
import { LEASE_URL } from "utils/constants";
// import { LEASE_URL } from "utils/constants";

const ToggleDialog = ({ open, handleClose, hub }: any) => {
  function handleToggle() {
    let status = hub?.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";

    setLoader(true);
    let url = `${LEASE_URL}/company/hubs/${hub?._id}/updateStatus?status=${status}`;
    authorizedFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.message === "Hub status updated successfully") {
          snackbar.success(res.message);
          queryClient.resetQueries("getAllHubs");
          drawer.close();
          handleClose();
        } else {
          snackbar.error(
            hub?.status === "ACTIVE"
              ? `Error deactivating hub`
              : `Error activating hub`
          );
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error(
          hub?.status === "ACTIVE"
            ? `Error deactivating vendor`
            : `Error activating vendor`
        );
      });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {hub?.status === "ACTIVE" ? "Deactivate Hub?" : "Activate Hub?"}
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ my: 1, mr: 5 }}>
          Are you sure you want to{" "}
          {hub?.status === "ACTIVE" ? "deactivate" : "activate"}{" "}
          <b>{hub?.name}</b>?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}> Cancel</Button>
        <Button variant="contained" onClick={handleToggle}>
          {hub?.status === "ACTIVE" ? "Deactivate" : "Activate"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ToggleDialog;
