import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { login, toggleDarkMode, toggleSnackbar } from "./actions";
import { getDarkModePreference, GlobalState, setLoader } from "./utils";
import jwt from "jwt-simple";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  createTheme,
  CssBaseline,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import { alpha, ThemeProvider, useTheme } from "@mui/system";
import { StyledEngineProvider } from "@mui/material/styles";

import DashboardLayout from "./pages/DashboardLayout";
import Auth from "./pages/Auth";
import { ExpandMore, KeyboardArrowDownRounded } from "@mui/icons-material";

import { defaults } from "react-chartjs-2";
import storageManager from "utils/storageManager";
defaults.font.family = "Nunito Sans";

interface CustomTheme {
  customColors: {
    header: string;
    border: string;
    green: string;
    black: string;
    greenSecondary: string;
    blue: string;
    blueSecondary: string;
    yellowSecondary: string;
    orangeSecondary: string;
    redSecondary: string;
    violetSecondary: string;
    lightGrey: string;
    grey: string;
    greySecondary: string;
    greyTertiary: string;
    action: string;
    text: {
      plain: string;
      plain2: string;
      green: string;
      blue: string;
      greenSecondary: string;
      blueSecondary: string;
      yellowSecondary: string;
      orangeSecondary: string;
      redSecondary: string;
      grey: string;
    };
    background: {
      grey: string;
      lightGrey: string;
    };
  };
  customShadows: {
    small: string;
    medium: string;
    inputField: string;
    card: string;
  };
}

declare module "@mui/material/styles" {
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
  interface BreakpointOverrides {
    lg2: true;
  }
}
declare module "@mui/material/Avatar" {
  interface AvatarPropsVariantOverrides {
    icon: true;
    label: true;
    status: true;
  }
}
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    action: true;
  }
}

const App = () => {
  let isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state),
  );
  let loaderState = useSelector((state: GlobalState) => state.global.loader);
  let snackbarState = useSelector(
    (state: GlobalState) => state.global.snackbar,
  );
  const { snackPack, open, messageInfo, actionInfo } = snackbarState;
  const dispatch = useDispatch();

  const setSnackbar = (val: object) => {
    dispatch(toggleSnackbar({ ...snackbarState, ...val }));
  };

  const handleClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };

  const handleExited = () => {
    setSnackbar({ messageInfo: undefined });
  };

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      dispatch(
        toggleSnackbar({
          ...snackbarState,
          messageInfo: { ...snackPack[0] },
          snackPack: snackPack.slice(1),
          open: true,
        }),
      );
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      dispatch(toggleSnackbar({ ...snackbarState, open: false }));
    }
  }, [snackPack, messageInfo, open, dispatch, snackbarState]);

  useEffect(() => {
    const mq = window.matchMedia("(prefers-color-scheme: dark)");
    function setTheme(e: MediaQueryListEvent) {
      dispatch(toggleDarkMode(e.matches));
    }
    mq.addEventListener("change", setTheme);
    return function cleanUp() {
      mq.removeEventListener("change", setTheme);
    };
  }, [dispatch]);

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const newTheme = useMemo(
    () =>
      createTheme({
        breakpoints: {
          values: {
            // ...theme.breakpoints.values,
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            lg2: 1366,
            xl: 1536,
          },
        },
        palette: {
          mode: isDarkMode ? "dark" : "light",
          primary: {
            // main: "#62d16a",
            main: "#26C72D",
          },
          secondary: {
            main: "#57B8FF",
          },
          success: {
            // main: "#62d16a",
            main: "#26C72D",
          },
          error: {
            main: "#F40D2C",
          },
          // info: {
          //   main: "#6EBDE7",
          // },
          // warning: {
          //   main: "#F4845D",
          // },
          common: {
            black: "#3D3D3D",
            white: "#F6F8FB",
          },
          text: {
            // primary: isDarkMode ? "#fff" : "#232D42",
            // primary: isDarkMode ? "#fff" : "#3D3D3D",
            primary: isDarkMode ? "#fff" : "#4B4B4B",
            secondary: isDarkMode ? "rgba(255, 255, 255, 0.7)" : "#495057",
            // secondary: isDarkMode ? "rgba(255, 255, 255, 0.7)" : "#808080",
          },
          background: {
            // default: isDarkMode ? "#1a1a1a" : "#F6F8FB",
            default: isDarkMode ? "#1a1a1a" : "#fff",
            paper: isDarkMode ? "#121212" : "#fff",
          },
          divider: isDarkMode ? "rgba(255, 255, 255, 0.12)" : "#EBEFF2",
        },
        customColors: {
          header: isDarkMode ? "#1a1a1a" : "#EAF6FF",
          border: isDarkMode ? "rgba(255, 255, 255, 0.12)" : "#E6E9F4",
          green: "#62D16A",
          black: "#000000",
          greenSecondary: "#29CB97",
          blue: "#57B8FF",
          blueSecondary: "#579BFC", // blueSecondary: "#1162FB",
          yellowSecondary: "#f5d63d",
          orangeSecondary: "#f7782f",
          redSecondary: "#ed344a",
          violetSecondary: "#CC52E2",
          lightGrey: isDarkMode ? "rgba(255, 255, 255, 0.5)" : "#A5A5A5",
          grey: isDarkMode ? "rgba(255, 255, 255, 0.5)" : "#8A92A6",
          greySecondary: isDarkMode ? "rgba(255, 255, 255, 0.5)" : "#5A607F",
          greyTertiary: "#808080",
          action: "#ADB5BD",
          text: {
            plain: isDarkMode ? "#fff" : "#000",
            plain2: isDarkMode ? "#fff" : "#202020",
            green: "#62D16A", // green: "#28CA96",
            blue: "#579BFC", // blue: "#57B8FF",
            greenSecondary: "#62D16A", // greenSecondary: "#68D6A5",
            blueSecondary: "#579BFC", // blueSecondary: "#477BED",
            yellowSecondary: "#f5d63d",
            orangeSecondary: "#f7782f",
            redSecondary: "#E2445C", // redSecondary: "#ed344a",
            grey: isDarkMode ? "rgba(255, 255, 255, 0.7)" : "#808080",
          },
          background: {
            lightGrey: isDarkMode ? "#1a1a1a" : "#f7f7f7",
            grey: isDarkMode ? "#212121" : "#ECECEC",
          },
        },
        customShadows: {
          small: "0 0 4px #1C295A14",
          medium: "0 0 10px #1C295A14",
          inputField: "0px 4px 10px 0px #0000000D",
          card: isMdDown
            ? "0px 4px 8px 0px #00000012"
            : "0px 0px 21px 0px #00000012",
        },
      }),
    [isDarkMode, isMdDown],
  );

  const customTheme = useMemo(
    () =>
      createTheme({
        ...newTheme,
        [newTheme.breakpoints.down("md")]: {
          customShadows: {
            card: "0px 4px 8px 0px #00000012",
          },
        },
        shape: {
          borderRadius: 6,
        },
        typography: {
          h2: {
            fontSize: 28,
            fontWeight: 600,
            [newTheme.breakpoints.down("md")]: {
              fontSize: 24,
            },
          },
          h3: {
            fontSize: 20,
            fontWeight: 600,
            [newTheme.breakpoints.down("md")]: {
              fontSize: 16,
            },
          },
          h6: {
            fontSize: 17,
            fontWeight: 700,
          },
        },
        components: {
          // MuiButtonBase: {
          //   defaultProps: {
          //     disableRipple: true,
          //   },
          // },
          MuiTypography: {
            styleOverrides: {
              root: {
                "&.label": {
                  fontWeight: 500,
                  color: isDarkMode ? "#fff" : "#000",
                  // marginBottom: 12,
                  lineHeight: "18px",
                },
              },
            },
          },
          MuiCssBaseline: {
            styleOverrides: {
              body: {
                "&::-webkit-scrollbar, & *::-webkit-scrollbar, &::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner":
                  {
                    backgroundColor: newTheme.palette.background.default,
                    width: 12,
                    height: 12,
                  },
                "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                  backgroundColor:
                    newTheme.palette.grey[isDarkMode ? 600 : 500],
                  borderRadius: 8,
                  minHeight: 24,
                  border: "3px solid",
                  borderColor: newTheme.palette.background.default,
                },
              },
            },
          },
          MuiDialog: {
            styleOverrides: {
              paper: {
                borderRadius: 8,
              },
              root: {
                "& .MuiDialogTitle-root": {
                  padding: 32,
                  paddingBottom: 8,
                  fontSize: 18,
                  fontWeight: 500,
                },
                "& .MuiDialogContent-root": {
                  padding: "0 32px",
                  "& .label": {
                    fontWeight: 500,
                    color: isDarkMode ? "#fff" : "#000",
                    marginBottom: 12,
                    lineHeight: "18px",
                  },
                  "& .header": {
                    fontWeight: 500,
                    lineHeight: "18px",
                    gridColumn: "span 2",
                  },
                  "&.py-1": {
                    padding: "8px 32px",
                  },
                },
                "& .MuiDialogActions-root": {
                  padding: 32,
                  "&.dense": {
                    padding: "0 32px 24px",
                  },
                  "&>:not(:first-of-type)": {
                    marginLeft: 16,
                  },
                  "& .MuiButton-root": {
                    height: 40,
                    minWidth: 90,
                    [newTheme.breakpoints.up("sm")]: {
                      minWidth: 145,
                    },
                  },
                },
              },
            },
          },
          MuiStep: {
            styleOverrides: {
              root: {
                "& .MuiStepIcon-root": {
                  color: alpha(newTheme.palette.primary.main, 0.1),
                },
                "& .Mui-active.MuiStepIcon-root": {
                  color: newTheme.palette.primary.main,
                },
                "& .MuiStepIcon-text": {
                  fill: alpha(newTheme.palette.primary.main, 0.4),
                },
                "& .Mui-active > .MuiStepIcon-text": {
                  fill: "#fff",
                },
                "& .MuiStepLabel-labelContainer": {
                  color: newTheme.customColors.grey,
                },
              },
            },
          },
          MuiStepButton: {
            defaultProps: { disableRipple: true },
          },
          MuiStepConnector: {
            styleOverrides: {
              root: {
                "& > span": {
                  borderTop: "2px solid",
                  borderColor: alpha(newTheme.palette.primary.main, 0.2),
                },
                "&.Mui-active > span, &.Mui-completed > span": {
                  borderColor: newTheme.palette.primary.main,
                },
              },
            },
          },
          MuiAvatar: {
            variants: [
              {
                props: { variant: "icon" },
                style: {
                  border: "1px solid",
                  borderColor: alpha(newTheme.palette.primary.main, 0.2),
                  backgroundColor: alpha(newTheme.palette.primary.main, 0.14),
                  color: newTheme.customColors.green,
                  [newTheme.breakpoints.down("sm")]: {
                    width: 32,
                    height: 32,
                    "& .MuiSvgIcon-root": {
                      fontSize: 22,
                    },
                  },
                },
              },
              {
                props: { variant: "label" },
                style: {
                  border: "1px solid",
                  borderColor: alpha(newTheme.palette.primary.main, 0.2),
                  backgroundColor: alpha(newTheme.palette.primary.main, 0.14),
                  color: newTheme.palette.primary.main,
                  fontSize: 12,
                  fontWeight: 600,
                  fontFamily: "Poppins !important",
                  borderRadius: 3,
                  width: "auto",
                  height: "auto",
                  padding: "4px 8px",
                },
              },
              {
                props: { variant: "status" },
                style: {
                  // minWidth: 100,
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: "16px",
                  borderRadius: 50,
                  width: "fit-content",
                  height: "auto",
                  padding: "4px 8px",
                  // border: "1px solid",
                  // borderColor: alpha(theme.palette.primary.main, 0.2),
                  // backgroundColor: alpha(theme.palette.primary.main, 0.14),
                  // color: theme.palette.primary.main,
                  color: "#01A229",
                  borderColor: "#01A229",
                  background: isDarkMode ? alpha("#01A229", 0.1) : "#E0F4E4",
                  "&.red": {
                    // borderColor: alpha(theme.palette.error.main, 0.2),
                    // backgroundColor: alpha(theme.palette.error.main, 0.14),
                    // color: theme.palette.error.main,
                    color: "#EB5C5C",
                    borderColor: "#EB5C5C",
                    background: isDarkMode ? alpha("#EB5C5C", 0.1) : "#FFEAEA",
                  },
                  "&.yellow": {
                    // borderColor: alpha(theme.palette.warning.main, 0.2),
                    // backgroundColor: alpha(theme.palette.warning.main, 0.14),
                    // color: theme.palette.warning.main,
                    color: "#FFAB01",
                    borderColor: "#FFAB01",
                    background: isDarkMode ? alpha("#FFAB01", 0.1) : "#FFF7DE",
                  },
                  "&.blue": {
                    // borderColor: alpha(theme.palette.info.main, 0.2),
                    // backgroundColor: alpha(theme.palette.info.main, 0.14),
                    // color: theme.palette.info.main,
                    color: "#366AB4",
                    borderColor: "#366AB4",
                    background: isDarkMode ? alpha("#366AB4", 0.1) : "#EAF6FF",
                  },
                  "&.grey": {
                    // borderColor: theme.palette.action.selected,
                    // backgroundColor: theme.palette.action.hover,
                    // color: alpha(theme.palette.action.active, 0.5),
                    color: isDarkMode ? "#808080" : "#4B4B4B",
                    borderColor: isDarkMode ? "#808080" : "#4B4B4B",
                    background: isDarkMode ? alpha("#4B4B4B", 0.1) : "#ECECEC",
                  },
                  "&.outlined": {
                    border: "1px solid",
                  },
                },
              },
            ],
          },
          MuiAutocomplete: {
            styleOverrides: {
              root: {
                "&.rounded .MuiInputBase-root": {
                  borderRadius: 50,
                },
              },
            },
          },
          MuiSelect: {
            variants: [
              {
                props: { variant: "outlined" },
                style: {
                  borderWidth: "1px !important",
                  "&.shadow": {
                    boxShadow: newTheme.customShadows.inputField,
                  },
                  "&.rounded": {
                    borderRadius: 50,
                  },
                },
              },
            ],
            defaultProps: {
              IconComponent: KeyboardArrowDownRounded,
            },
            styleOverrides: {
              icon: {
                color: newTheme.customColors.text.grey,
              },
              iconOpen: {
                color: newTheme.palette.primary.main,
              },
              outlined: {},
            },
          },
          MuiInputLabel: {
            styleOverrides: {
              formControl: {
                color: newTheme.customColors.text.grey,
              },
            },
          },
          MuiMenuItem: {
            defaultProps: {
              dense: true,
            },
          },
          MuiInputBase: {
            styleOverrides: {
              root: {
                backgroundColor: isDarkMode ? "#212121" : "white",
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                },
                "&:focus": {
                  boxShadow: "none",
                },
                "&.rounded": {
                  borderRadius: 50,
                },
              },
              containedPrimary: {
                color: "white",
              },
              outlined: {
                backgroundColor: newTheme.palette.background.paper,
                "&:hover": {
                  backgroundColor: newTheme.palette.background.paper,
                },
              },
            },
            variants: [
              {
                props: { variant: "action" },
                style: {
                  color: isDarkMode
                    ? newTheme.palette.secondary.main
                    : "#0058FF",
                  textTransform: "none",
                  fontSize: 14,
                  padding: "4px 8px",
                  minWidth: 0,
                },
              },
            ],
          },
          MuiSnackbar: {
            styleOverrides: {
              root: {
                "& .MuiAlert-root": {
                  boxShadow: newTheme.shadows[3],
                },
              },
            },
            defaultProps: {
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              autoHideDuration: 4000,
            },
          },
          MuiTable: {
            styleOverrides: {
              root: {
                borderCollapse: "collapse",
              },
            },
          },
          MuiTableHead: {
            styleOverrides: {
              root: {
                borderBottom: "7px solid",
                borderColor: isDarkMode
                  ? "#1E1E1E"
                  : newTheme.palette.background.paper,
                "& th:first-of-type": {
                  borderRadius: "6px 0 0 6px",
                },
                "& th:last-of-type": {
                  borderRadius: "0 6px 6px 0",
                },
              },
            },
          },
          MuiTableBody: {
            styleOverrides: {
              root: {
                "& td:first-of-type": {
                  borderRadius: "6px 0 0 6px",
                },
                "& td:last-of-type": {
                  borderRadius: "0 6px 6px 0",
                },
              },
            },
          },
          MuiTableCell: {
            styleOverrides: {
              root: {
                border: "none",
              },
              body: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
              head: {
                "&.MuiTableCell-sizeSmall": {
                  fontFamily: "Poppins, sans-serif !important",
                  fontSize: 12,
                },
                fontSize: 13,
                fontWeight: 500,
                textTransform: "uppercase",
                padding: "16px 15px 15px !important",
                backgroundColor: newTheme.customColors.header,
              },
              sizeSmall: {
                padding: "8px 14px",
              },
              sizeMedium: {
                padding: "16px 12px",
                "&.MuiTableCell-head": {
                  padding: "18px 12px 16px !important",
                },
              },
            },
          },
          MuiTabs: {
            styleOverrides: {
              root: {
                flexGrow: 1,
                minHeight: 35,
                position: "relative",
                "&:after": {
                  content: '""',
                  width: "100%",
                  height: 4,
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  borderRadius: 6,
                  backgroundColor: newTheme.palette.divider,
                  zIndex: 1,
                },
                "&.dense .MuiTab-root": {
                  padding: "4px 16px",
                },
                "&.less-dense .MuiTab-root": {
                  padding: "4px 24px",
                },
              },
              indicator: {
                height: 4,
                borderRadius: 6,
                zIndex: 2,
              },
            },
          },
          MuiTab: {
            defaultProps: {
              disableRipple: true,
            },
            styleOverrides: {
              root: {
                minWidth: 0,
                minHeight: 0,
                padding: "4px 32px",
                textTransform: "none",
                fontSize: 14,
                fontWeight: 400,
                color: newTheme.customColors.grey,
                "&.Mui-selected": {
                  fontWeight: 600,
                },
                "&.hasCount": {
                  px: 3,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  lineHeight: "1em",
                  "&:after": {
                    marginLeft: 8,
                    padding: "4px 12px 3px",
                    border: "0.5px solid",
                    borderRadius: "3px",
                    borderColor: alpha(newTheme.palette.primary.main, 0.2),
                    backgroundColor: alpha(newTheme.palette.primary.main, 0.14),
                    color: newTheme.customColors.green,
                    fontSize: 10,
                    lineHeight: "11px",
                    fontWeight: 600,
                  },
                },
              },
            },
          },
          MuiFormControlLabel: {
            styleOverrides: {
              label: {
                // fontSize: "0.9rem",
              },
            },
          },
          MuiPagination: {
            defaultProps: {
              color: "primary",
              shape: "rounded",
              size: "small",
              boundaryCount: 2,
              siblingCount: 0,
            },
          },
          MuiPaginationItem: {
            styleOverrides: {
              sizeSmall: {
                height: 28,
                minWidth: 28,
                color: newTheme.customColors.text.grey,
                "&.Mui-selected": {
                  color: "#fff",
                },
              },
            },
          },
          MuiAccordion: {
            styleOverrides: {
              root: {
                boxShadow: "none",
                borderRadius: newTheme.shape.borderRadius,
                overflow: "hidden",
                border: `1px solid ${newTheme.customColors.border}`,
              },
            },
          },
          MuiAccordionSummary: {
            defaultProps: {
              expandIcon: <ExpandMore />,
            },
            styleOverrides: {
              root: {
                backgroundColor: newTheme.customColors.header,
                // borderBottom: `1px solid ${theme.customColors.border}`,
              },
            },
          },
        },
      }),
    [isDarkMode, newTheme],
  );

  const isLoggedIn = useSelector((state: GlobalState) =>
    Boolean(state.global.token),
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={customTheme}>
        <CssBaseline enableColorScheme />
        <Switch>
          <Route path="/redirect" component={RedirectPage} />
          {isLoggedIn ? (
            <Route path="/" component={DashboardLayout} />
          ) : (
            <Route path="/" component={Auth} />
          )}
          <Redirect to="/" />
        </Switch>
        <Backdrop
          open={loaderState}
          sx={{
            color: "primary.light",
            zIndex: (theme: any) => theme.zIndex.snackbar - 1,
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          key={messageInfo ? messageInfo.key : undefined}
          open={open}
          onClose={handleClose}
          TransitionProps={{ onExited: handleExited }}
          message={messageInfo ? messageInfo.message : undefined}
          {...(actionInfo ? {} : { onClose: handleClose })}
        >
          <Alert
            // onClose={handleClose}
            severity={messageInfo?.type}
            variant={actionInfo ? "outlined" : "standard"}
            sx={
              actionInfo
                ? { bgcolor: (theme: any) => theme.palette.background.paper }
                : {}
            }
            {...(actionInfo
              ? {
                  action: (
                    <Button
                      color="info"
                      sx={{
                        mx: 0.5,
                        transform: "translateY(-1px)",
                        // bgcolor: theme => theme.palette.info.dark,
                        pt: 0.75,
                        px: 1,
                        lineHeight: "1.5em",
                        textTransform: "none",
                      }}
                      size="small"
                      variant="contained"
                      onClick={actionInfo.buttonAction}
                    >
                      {actionInfo.buttonText}
                    </Button>
                  ),
                }
              : { onClose: handleClose })}
          >
            {messageInfo?.message}
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const RedirectPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    setLoader(true);
    let params = new URLSearchParams(window.location.search);
    let loginJwt = params.get("token") || "";
    if (!loginJwt) {
      setLoader(false);
    } else {
      storageManager.set("loginJwt", loginJwt);
      const userCredentials = jwt.decode(loginJwt, "bolt-secret");
      dispatch(login(userCredentials));
    }
    window.location.assign("/");
    // eslint-disable-next-line
  }, []);

  return <div />;
};

export default withRouter(App);
