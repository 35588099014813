import {
  EditOutlined,
  ErrorOutline,
  HighlightOff,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { authorizedFetch, drawer, setLoader, snackbar } from "utils";
import { RETAIL_URL } from "utils/constants";
import PhoneTextField from "components/PhoneTextField";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import validator from "validator";
import countryNames from "react-phone-number-input/locale/en.json";

type CountryCode = keyof typeof countryNames;

interface Props {
  open: boolean;
  handleClose: () => void;
  refetchVehicles: any;
  refetchVehicleData: any;
  vehicle: any;
  vehicleInfo: any;
}

const InputData: any = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  local: "",
  district: null,
  state: null,
  pincode: "",
};

const EditUserDialog: React.FC<Props> = ({
  open,
  handleClose,
  refetchVehicles,
  refetchVehicleData,
  vehicle,
  vehicleInfo,
}) => {
  const [step, setStep] = useState(0);
  const steps = ["User Info", "Finish"];
  const [checkDisclaimer, setCheckDisclaimer] = useState(false);

  const [input, setInput] = useState({ ...InputData });

  const { firstName, lastName, phone, email, local, pincode, district, state } =
    input;

  const [selectedCountry, setSelectedCountry] = useState<CountryCode>("IN");

  const statesUrl = `https://geocode.revos.in/place/states?country=${countryNames[selectedCountry]}&input=`;
  const { isLoading: statesLoading, data: statesData } = useQuery(
    ["getStates", selectedCountry],
    () => authorizedFetch(statesUrl),
  );

  const citiesUrl = `https://geocode.revos.in/place/cities?country=${countryNames[selectedCountry]}&state=${state}&input=`;
  const { isLoading: citiesLoading, data: citiesData } = useQuery(
    ["getCities", state],
    () => authorizedFetch(citiesUrl),
    {
      enabled: Boolean(state),
    },
  );

  const editUserDetailsURL = `${RETAIL_URL}/distribution/${vehicle?.vin}/change-owner`;

  const mutation = useMutation(
    `addUserDetails`,
    () =>
      authorizedFetch(editUserDetailsURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          firstName,
          lastName,
          email,
          phone,
          address: {
            country: countryNames[selectedCountry],
            state,
            district,
            pincode,
            local,
          },
        },
      }),
    {
      onSuccess: (res) => {
        if (res.msg === "Owner changed successfully") {
          snackbar.success(`User Details Updated`);
          refetchVehicles();
          refetchVehicleData();
          drawer.close();
          handleClose();
        } else {
          snackbar.error(`Error updating user details`);
        }
        setLoader(false);
      },
      onError: () => {
        snackbar.error(`Error updating user details`);
      },
    },
  );

  function handleChange(key: string, value: string) {
    setInput((prevInput: any) => ({ ...prevInput, [key]: value }));
  }

  const handleSave = () => {
    setLoader(true);
    mutation.mutate();
    handleClose();
  };

  function handleNext() {
    if (step === steps.length - 1) {
      handleSave();
    } else setStep(step + 1);
  }
  function handleBack() {
    setStep(step - 1);
  }

  function isComplete(step: number) {
    switch (step) {
      case 0:
        return (
          ![firstName, phone, district, state].includes("") && checkDisclaimer
        );

      default:
        break;
    }
  }

  const disabled =
    [firstName, phone, district, state].includes("") || !checkDisclaimer;

  useEffect(() => {
    if (open && vehicle) {
      setInput({
        firstName: vehicle?.currentOwner?.firstName
          ? vehicle?.currentOwner?.firstName
          : "",
        lastName: vehicle?.currentOwner?.lastName
          ? vehicle?.currentOwner?.lastName
          : "",
        phone:
          vehicle?.currentOwner?.phone && vehicle?.currentOwner?.phone !== "N/A"
            ? vehicle?.currentOwner?.phone
            : "",
        email:
          vehicle?.currentOwner?.email && vehicle?.currentOwner?.email !== "N/A"
            ? vehicle?.currentOwner?.email
            : "",
        local: vehicle?.currentOwner?.address?.local || "",
        district: vehicle?.currentOwner?.address?.district || "",
        pincode: vehicle?.currentOwner?.address?.pincode || "",
        state: vehicle?.currentOwner?.address?.state || "",
        country: vehicle?.currentOwner?.address?.country || "",
      });
    }
  }, [open, vehicle]);

  useEffect(() => {
    if (!open) {
      setInput({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        local: "",
        pincode: "",
        district: "",
        state: "",
        country: "",
      });
      setStep(0);
      setCheckDisclaimer(false);
    }
  }, [open]);

  const validateEmail = (email: any) => {
    return validator.isEmail(email);
  };

  console.log(input);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 1200,
          width: 1,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 10,
            p: "3.5px 5px",
          },
        },
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          Edit User Details (
          {vehicleInfo?.ownersChanged ? vehicleInfo?.ownersChanged : 0}/2)
          <IconButton
            children={<HighlightOff />}
            color="inherit"
            onClick={handleClose}
            sx={{ transform: "translate(8px, -8px)" }}
          />
        </Box>
        <Typography variant="body2" color="text.secondary" mt={-1}>
          Edit opportunities available : (
          {vehicleInfo?.ownersChanged === 0
            ? 2
            : vehicleInfo?.ownersChanged === 2
              ? 0
              : 1}
          /2)
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ pb: "16px !important" }}>
        <Stepper
          sx={{ my: 4, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton onClick={() => setStep(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {step === 0 && (
          <>
            <Box sx={{ maxWidth: { xs: 280, sm: 750 }, mx: "auto" }}>
              <Typography sx={{ fontSize: "0.875rem" }}>
                All fields with ( * ) are required
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: { xs: 280, sm: 750 },
                mx: "auto",
                py: 2,
                display: "grid",
                gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                gap: 3,
                "& .required": {
                  color: "red",
                },
                "& .MuiInputBase-root": { borderRadius: 10 },
              }}
            >
              <Box>
                <Typography className="label">
                  First Name <span className="required">&#x2a;</span>
                </Typography>

                <TextField
                  fullWidth
                  autoComplete="off"
                  size="medium"
                  value={firstName}
                  placeholder="First Name"
                  onChange={(e) => {
                    handleChange(
                      "firstName",
                      e.target.value?.length > 0
                        ? e.target.value.replace(/[^a-zA-Z0-9-_ ]/, "")
                        : e.target.value,
                    );
                  }}
                  inputProps={{ maxLength: 17 }}
                />
              </Box>
              <Box>
                <Typography className="label">Last Name</Typography>
                <TextField
                  fullWidth
                  autoComplete="off"
                  size="medium"
                  value={lastName}
                  placeholder="Last Name"
                  onChange={(e) => {
                    handleChange(
                      "lastName",
                      e.target.value?.length > 0
                        ? e.target.value.replace(/[^a-zA-Z0-9-_ ]/, "")
                        : e.target.value,
                    );
                  }}
                  inputProps={{ maxLength: 17 }}
                />
              </Box>
              <Box>
                <Typography className="label">
                  Phone <span className="required">&#x2a;</span>
                </Typography>
                <PhoneInput
                  size="medium"
                  placeholder="Enter phone number"
                  value={phone}
                  onChange={(value) => handleChange("phone", value || "")}
                  onCountryChange={(value: any) => setSelectedCountry(value)}
                  defaultCountry="IN"
                  international
                  limitMaxLength
                  inputComponent={PhoneTextField}
                  error={!!phone && !isValidPhoneNumber(phone)}
                />
              </Box>
              <Box>
                <Typography className="label">Email</Typography>
                <TextField
                  fullWidth
                  size="medium"
                  placeholder="Email"
                  value={email}
                  error={!!email && !validateEmail(email)}
                  onChange={(e: any) => {
                    handleChange("email", e.target.value);
                  }}
                  autoComplete="off"
                />
              </Box>
              <Box sx={{ gridColumn: { sm: "span 2" } }}>
                <Typography className="label" mt={2}>
                  Buyers Address
                </Typography>
                <Box
                  sx={{
                    maxWidth: 750,
                    mx: "auto",
                    display: "grid",
                    gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                    gap: 3,
                    mt: 2,
                  }}
                >
                  <Box sx={{ gridColumn: { sm: "span 2" } }}>
                    <Typography fontSize={14} mb={1.5}>
                      Plot No., Street Name, Landmark
                    </Typography>
                    <TextField
                      fullWidth
                      size="medium"
                      placeholder="Plot No., Street Name, Landmark"
                      value={local}
                      onChange={(e: any) => {
                        setInput({
                          ...input,
                          local: e.target.value,
                        });
                      }}
                    />
                  </Box>

                  <Box>
                    <Typography className="label">Country</Typography>
                    <TextField
                      fullWidth
                      disabled
                      size="medium"
                      value={countryNames[selectedCountry]}
                    />
                  </Box>
                  <Box>
                    <Typography className="label">
                      State <span className="required">&#x2a;</span>
                    </Typography>
                    <Autocomplete
                      loading={statesLoading}
                      size="medium"
                      popupIcon={<KeyboardArrowDown />}
                      value={state}
                      onChange={(e, newValue) => {
                        setInput({
                          ...input,
                          state: newValue,
                        });
                      }}
                      options={(statesData?.data?.constructor === Array
                        ? statesData.data
                        : []
                      ).map((el: any) => el.name)}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Select..." />
                      )}
                    />
                  </Box>

                  <Box>
                    <Typography fontSize={14} mb={1}>
                      Pincode
                    </Typography>
                    <TextField
                      fullWidth
                      size="medium"
                      placeholder="Pincode"
                      value={pincode}
                      onChange={(e: any) => {
                        if (/^[0-9]{0,6}$/.test(e.target.value))
                          setInput({
                            ...input,
                            pincode: e.target.value,
                          });
                      }}
                    />
                  </Box>

                  <Box>
                    <Typography className="label">
                      City/District <span className="required">&#x2a;</span>
                    </Typography>
                    {!citiesLoading &&
                    citiesData?.data?.constructor === Array &&
                    citiesData?.data?.length === 0 ? (
                      <TextField
                        fullWidth
                        size="medium"
                        placeholder="City/District"
                        value={district}
                        onChange={(e: any) => {
                          setInput({
                            ...input,
                            district: e.target.value,
                          });
                        }}
                      />
                    ) : (
                      <Autocomplete
                        disabled={!state}
                        loading={citiesLoading}
                        size="medium"
                        popupIcon={<KeyboardArrowDown />}
                        value={district}
                        onChange={(e, newValue) => {
                          setInput({
                            ...input,
                            district: newValue,
                          });
                        }}
                        options={(citiesData?.data?.constructor === Array
                          ? citiesData.data
                          : []
                        ).map((el: any) => el.name)}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Select..." />
                        )}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  gridColumn: {
                    sm: "span 2",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "flex-start",
                    mt: 2,
                  },
                }}
              >
                <Checkbox
                  size="small"
                  sx={{ mr: 1 }}
                  onChange={() => setCheckDisclaimer(!checkDisclaimer)}
                />
                <Typography fontSize={14}>
                  Once you save these changes, you will use up{" "}
                  {vehicleInfo?.ownersChanged === 1 ? "two" : "one"} of the two
                  edit opportunities you have. Please check all the details once
                  before saving.
                </Typography>
              </Box>
            </Box>
          </>
        )}
        {step === 1 && (
          <Box
            sx={{
              maxWidth: 750,
              mx: "auto",
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    bgcolor: (theme) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {[
                  { header: "Pricing Info", onEdit: () => setStep(0) },
                  { label: "First Name", value: firstName, required: true },
                  { label: "Last Name", value: lastName },
                  { label: "Phone Number", value: phone, required: true },
                  { label: "Email", value: email },
                  {
                    label: "Plot No., Street Name, Landmark",
                    value: local,
                  },
                  {
                    label: "Country",
                    value: countryNames[selectedCountry],
                  },
                  { label: "State", value: state, required: true },
                  { label: "Pincode", value: pincode },
                  { label: "City/District", value: district, required: true },
                ].map(({ header, onEdit, label, value, required }, i, arr) => {
                  const isFirst = arr[i - 1]?.header;
                  const isLast = !arr[i + 1] || arr[i + 1].header;

                  return (
                    <tr
                      key={i}
                      className={
                        header
                          ? "header"
                          : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                      }
                    >
                      {header ? (
                        <td colSpan={2}>
                          <span>{header}</span>
                          <IconButton
                            sx={{ ml: 1.5 }}
                            children={<EditOutlined />}
                            color="primary"
                            size="small"
                            onClick={onEdit}
                          />
                        </td>
                      ) : (
                        <>
                          <td>{label}</td>
                          <td className="bold">
                            {value ||
                              (required ? (
                                <Box display="flex" alignItems="center">
                                  <ErrorOutline
                                    fontSize="small"
                                    color="error"
                                    style={{ marginRight: 8 }}
                                  />
                                  Required
                                </Box>
                              ) : (
                                "-"
                              ))}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {step !== 0 && (
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        )}
        {step === 0 && (
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        )}
        <Button
          onClick={handleNext}
          variant={
            isComplete(step) || step === steps.length - 1
              ? "contained"
              : "outlined"
          }
          disableElevation
          disabled={disabled}
        >
          {step === steps.length - 1 ? "Save" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserDialog;
