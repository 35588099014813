import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Drawer,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import RangeSelector from "components/RangeSelector";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { bookingsFilterObj } from "../..";

const FiltersDrawer = ({
  open,
  onClose,
  filters,
  setFilters,
  filterLabels,
}: any) => {
  const [tab, setTab] = useState(0);

  const [citySearch, setCitySearch] = useState("");

  const url = `https://geocode.revos.in/place/cities?country=India&state=&input=`;
  const { isLoading, data } = useQuery(["getCities"], () =>
    fetch(url, {
      headers: {
        cache: "true",
      },
    }).then((res) => res.json())
  );

  const list = useMemo(() => {
    return (data?.data?.constructor === Array ? data.data : []).filter(
      (el: any) =>
        !["Bangalore Rural", "Bangalore Urban"].includes(el.name) && // Hide these two in favor of "Bengaluru"
        el.name.toLowerCase().includes(citySearch.toLowerCase())
    );
  }, [data, citySearch]);

  const maxListLength = 10;

  function handleChange(
    key: string,
    value: string,
    shouldAdd: boolean,
    separatedBy = " "
  ) {
    setFilters((prev: any) => ({
      ...prev,
      [key]: shouldAdd
        ? prev[key] === ""
          ? value
          : prev[key].split(separatedBy).concat(value).join(separatedBy)
        : prev[key]
            .split(separatedBy)
            .filter((el: string) => el !== value)
            .join(separatedBy),
    }));

    if (key === "city" && shouldAdd) setCitySearch("");
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        sx: {
          height: 1,
          width: 1,
          maxWidth: 450,
          display: "flex",
          flexDirection: "column",
        },
      }}
      keepMounted // Important for RangeSelector to work (It resets the state on every mount)
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
        }}
      >
        <Typography variant="h3">Filters</Typography>
        <Button
          sx={{ textTransform: "none" }}
          color="inherit"
          size="small"
          startIcon={<Close />}
          onClick={onClose}
        >
          Close
        </Button>
      </Box>
      <Divider />
      <Box
        sx={{
          flexGrow: 1,
          display: "grid",
          gridTemplateColumns: "135px 1fr",
          fontSize: 14,
        }}
      >
        <Box
          sx={{
            background: (theme) => theme.customColors.background.lightGrey,
            borderRight: 1,
            borderColor: (theme) => theme.customColors.border,
            "& > .MuiBox-root": {
              py: 3,
              px: 1.5,
              borderLeft: 4,
              borderColor: "transparent",
              cursor: "pointer",
              "&.active": {
                background: (theme) => theme.palette.background.paper,
                borderColor: "#62D16A",
              },
            },
          }}
        >
          {[
            {
              label: "Date Range",
              isActive: filters.dateFrom !== "" || filters.dateTo !== "",
            },
            {
              label: "Status",
              isActive: filters.status !== "",
            },
            {
              label: "Refund Status",
              isActive: filters.refundStatus !== "",
            },
            {
              label: "Location",
              isActive: filters.city !== "",
            },
          ].map((el, i) => (
            <Box
              key={i}
              className={tab === i ? "active" : ""}
              onClick={() => setTab(i)}
              display="flex"
              alignItems="center"
            >
              {el.label}
              <Box
                sx={{
                  height: "6px !important",
                  width: "6px !important",
                  borderRadius: "50%",
                  ml: 1,
                  background: (theme) =>
                    el.isActive ? theme.palette.primary.main : "transparent",
                }}
              />
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            height: "calc(100vh - 150px)",
          }}
        >
          {/* Keep RangeSelector mounted */}
          <Box sx={{ display: tab === 0 ? "block" : "none" }}>
            <RangeSelector
              initialRange="Last 30 days"
              setRange={(range: any) =>
                setFilters({
                  ...filters,
                  dateFrom: range[0].format("YYYY-MM-DD"),
                  dateTo: range[1].format("YYYY-MM-DD"),
                })
              }
            />
          </Box>
          {tab === 1 &&
            Object.entries(filterLabels.status).map(
              ([value, label]: any, i) => (
                <FormControlLabel
                  key={i}
                  sx={{
                    mb: 1,
                    "& .MuiFormControlLabel-label": {
                      fontSize: 14,
                    },
                  }}
                  control={
                    <Checkbox
                      size="small"
                      checked={filters.status.split(" ").includes(value)}
                      onChange={(e) =>
                        handleChange("status", value, e.target.checked)
                      }
                    />
                  }
                  label={label}
                />
              )
            )}
          {tab === 2 &&
            Object.entries(filterLabels.refundStatus).map(
              ([value, label]: any, i) => (
                <FormControlLabel
                  key={i}
                  sx={{
                    mb: 1,
                    "& .MuiFormControlLabel-label": {
                      fontSize: 14,
                    },
                  }}
                  control={
                    <Checkbox
                      size="small"
                      checked={filters.refundStatus.split(" ").includes(value)}
                      onChange={(e) =>
                        handleChange("refundStatus", value, e.target.checked)
                      }
                    />
                  }
                  label={label}
                />
              )
            )}
          {tab === 3 && (
            <>
              <TextField
                sx={{ mb: 1.5 }}
                size="small"
                placeholder="Search..."
                value={citySearch}
                onChange={(e) => setCitySearch(e.target.value)}
              />
              {!!filters.city && filters.city.split("_").length > 0 && (
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {filters.city.split("_").map((el: string, i: number) => (
                    <Chip
                      key={i}
                      size="small"
                      sx={{
                        mr: 1,
                        mb: 1,
                        lineHeight: "1em",
                        color: (theme) => theme.customColors.text.grey,
                        bgcolor: (theme) =>
                          theme.customColors.background.grey,
                      }}
                      label={el}
                      onDelete={() => handleChange("city", el, false, "_")}
                    />
                  ))}
                  {filters.city.split("_").length > 1 && (
                    <Chip
                      size="small"
                      color="secondary"
                      variant="outlined"
                      onClick={() => setFilters({ ...filters, city: "" })}
                      label="Clear All"
                    />
                  )}
                </Box>
              )}
              {isLoading ? (
                "Loading..."
              ) : (
                <>
                  {list.slice(0, maxListLength).map((el: any, i: number) => (
                    <FormControlLabel
                      key={i}
                      sx={{
                        mb: 1,
                        "& .MuiFormControlLabel-label": {
                          fontSize: 14,
                        },
                      }}
                      control={
                        <Checkbox
                          size="small"
                          checked={filters.city.split("_").includes(el.name)}
                          onChange={(e) =>
                            handleChange("city", el.name, e.target.checked, "_")
                          }
                        />
                      }
                      label={el.name}
                    />
                  ))}
                  <Box mt={1}>
                    {list.length === 0
                      ? `No locations found${
                          citySearch ? ` for "${citySearch}"` : ""
                        }`
                      : ""}
                    <Typography
                      sx={{
                        fontSize: 12,
                        color: "text.secondary",
                        opacity: 0.8,
                        cursor: "default",
                        userSelect: "none",
                      }}
                    >
                      {list.length > maxListLength
                        ? `+${list.length - maxListLength} more...`
                        : ""}
                    </Typography>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          py: 3,
          px: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0px -3px 12px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Button
          sx={{
            textTransform: "none",
            borderRadius: 3,
          }}
          color="inherit"
          onClick={() => setFilters({ ...bookingsFilterObj })}
        >
          Clear all
        </Button>
        <Button
          sx={{
            textTransform: "none",
            borderRadius: 3,
            px: 3.5,
          }}
          variant="contained"
          onClick={onClose}
        >
          Apply Filters
        </Button>
      </Box>
    </Drawer>
  );
};

export default FiltersDrawer;
