import moment from "moment";
import { useEffect, useState } from "react";
import { titleCase } from "utils";

const RelativeTime = ({ time }: { time: string }) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => setKey((prev) => prev + 1), 10 * 1000);
    return () => clearInterval(interval);
  }, []);

  return <span key={key}>{titleCase(moment(time).fromNow())}</span>;
};

export default RelativeTime;
