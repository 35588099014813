import { Box, Paper, Skeleton, Typography } from "@mui/material";
import InfoLabel from "components/InfoLabel";
import { Doughnut } from "react-chartjs-2";

const colors: any = {
  Private: "#6EBDE7",
  Public: "#366AB4",
  Restricted: "#6F7BF7",
};

const ChargerAccess = ({
  chargerCountLoading: isLoading,
  ChargerCountData: data,
  disableTotal,
}: any) => {
  const totalPrivateChargers = Object.entries(
    data?.data?.by_usage || {},
  ).reduce(
    (acc, [key, value]) =>
      key.includes("PRIVATE") ? acc + (value as number) : acc,
    0,
  );
  const totalPublicChargers = Object.entries(data?.data?.by_usage || {}).reduce(
    (acc, [key, value]) =>
      key.includes("PUBLIC") ? acc + (value as number) : acc,
    0,
  );
  const totalRestrictedChargers = Object.entries(
    data?.data?.by_usage || {},
  ).reduce(
    (acc, [key, value]) =>
      key.includes("RESTRICTED") ? acc + (value as number) : acc,
    0,
  );
  const totalChargers =
    totalPrivateChargers + totalPublicChargers + totalRestrictedChargers;

  function format(n: number) {
    return n.toLocaleString();
  }

  return (
    <Paper
      sx={{
        p: { xs: 3, md: 3.5 },
      }}
    >
      <Box display="flex" flexDirection="column" height={1}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" mr={{ xs: 1, md: 2.5 }}>
            Charger Access
          </Typography>
          {!disableTotal ? (
            isLoading ? (
              <Skeleton
                variant="rectangular"
                width={95}
                height={28}
                sx={{ borderRadius: 10 }}
              />
            ) : (
              <InfoLabel dense label="Total" value={format(totalChargers)} />
            )
          ) : null}
        </Box>
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            sx={{ mt: 2, flexGrow: 1, borderRadius: 1 }}
          />
        ) : (
          <Box
            sx={{
              flexGrow: 1,
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              alignItems: "center",
              gap: 2,
            }}
          >
            {/* Legend */}
            <Box display="grid" gap={{ xs: 2, md: 4 }} ml={{ xs: 0, md: 4 }}>
              {[
                {
                  label: "Private",
                  value: format(totalPrivateChargers),
                },
                {
                  label: "Public",
                  value: format(totalPublicChargers),
                },
                {
                  label: "Restricted",
                  value: format(totalRestrictedChargers),
                },
              ].map((el, i) => (
                <Box key={i} display="flex" alignItems="center">
                  <Box
                    alignSelf="stretch"
                    width="5px"
                    borderRadius={50}
                    bgcolor={colors[el.label]}
                  />
                  <Box px={1.75} py={0.25}>
                    <Box fontSize={12}>{el.label}</Box>
                    <Box
                      color={(theme) => theme.customColors.text.plain}
                      fontSize={{ xs: 16, md: 20 }}
                      lineHeight="1.1em"
                      fontWeight={700}
                    >
                      {el.value}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
            {/* Chart */}
            <Box position="relative" width={1} height={1}>
              <Doughnut
                style={{
                  position: "absolute",
                  maxHeight: "100%",
                  maxWidth: 300,
                  top: "50%",
                  left: "50%",
                  transform: "translateX(-50%) translateY(-50%)",
                  zIndex: 2,
                }}
                data={(canvas) => {
                  return {
                    datasets: [
                      {
                        data: [
                          totalPrivateChargers,
                          totalPublicChargers,
                          totalRestrictedChargers,
                        ],
                        backgroundColor: Object.values(colors),
                        hoverBackgroundColor: Object.values(colors),
                        borderColor: Object.values(colors),
                        hoverBorderColor: Object.values(colors),
                        borderWidth: 0,
                        hoverBorderWidth: 10,
                        cutout: "60%",
                        hoverOffset: 0,
                        borderJoinStyle: "miter",
                        // spacing: 1,
                      },
                    ],
                    labels: ["Private", "Public", "Restricted"],
                  };
                }}
                options={{
                  layout: {
                    padding: 16,
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      displayColors: false,
                      callbacks: {
                        title: (context) => {
                          const total = context[0].dataset.data.reduce(
                            (a, b) => a + b,
                            0,
                          );
                          const percentage =
                            ((context[0].raw as number) / total) * 100;
                          return `${percentage.toLocaleString(undefined, { maximumFractionDigits: 2 })}%`;
                        },
                        label: (context) => {
                          return [
                            `${context.formattedValue} chargers are`,
                            `${context.label}`,
                          ];
                        },
                      },
                      cornerRadius: 0,
                      titleFont: {
                        size: 20,
                      },
                      bodyFont: {
                        size: 12,
                      },
                      padding: 12,
                    },
                  },
                }}
              />
              <Box
                sx={{
                  zIndex: 1,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translateX(-50%) translateY(-50%)",
                  pointerEvents: "none",
                  textAlign: "center",
                  color: (theme) => theme.customColors.text.plain,
                }}
              >
                <Typography fontSize={{ xs: 18, md: 32 }} fontWeight={700} lineHeight="1em">
                  {format(totalChargers)}
                </Typography>
                <Typography fontSize={14}>Total</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default ChargerAccess;
