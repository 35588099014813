import { Box, Skeleton, Typography } from "@mui/material";
import InfoLabel from "components/InfoLabel";
import { Doughnut } from "react-chartjs-2";

const colors: any = {
  HEALTHY: "#01A229",
  MODERATE: "#31D18E",
  CRITICAL: "#FFAB01",
  INACTIVE: "#EB5C5C",
};

const OperationalStatus = ({
  chargerCountLoading: isLoading,
  ChargerCountData: data,
  disableTotal,
}: any) => {
  const totalChargers = Object.values(data?.data?.by_health || {}).reduce(
    (acc, cur) => (acc as number) + (cur as number),
    0,
  ) as number;
  const healthyChargers = data?.data?.by_health?.HEALTHY || 0;
  const moderateChargers = data?.data?.by_health?.MODERATE || 0;
  const criticalChargers = data?.data?.by_health?.CRITICAL || 0;
  const inactiveChargers = data?.data?.by_health?.INACTIVE || 0;

  // const isLoading = false;
  // const totalChargers = 40;
  // const healthyChargers = 20;
  // const moderateChargers = 10;
  // const criticalChargers = 5;
  // const inactiveChargers = 5;

  function format(n: number) {
    return n.toLocaleString();
  }

  return (
    <Box display="flex" flexDirection="column" height={1}>
      <Box
        sx={{
          // mb: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" mr={{ xs: 1, md: 2.5 }}>
          Operational Status
        </Typography>
        {!disableTotal ? (
          isLoading ? (
            <Skeleton
              variant="rectangular"
              width={80}
              height={24}
              sx={{ borderRadius: 10 }}
            />
          ) : (
            <InfoLabel dense label="Total" value={format(totalChargers)} />
          )
        ) : null}
      </Box>
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          sx={{ mt: 2, flexGrow: 1, borderRadius: 1 }}
        />
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            display: "grid",
            gridTemplateColumns: "2fr 3fr",
            alignItems: "center",
            gap: 2,
          }}
        >
          {/* Legend */}
          <Box display="grid" gap={{ xs: 2, md: 3 }} ml={{ xs: 0, md: 2 }}>
            {[
              {
                label: "Used within last 15 days",
                value: format(healthyChargers),
              },
              {
                label: "Used between last 16-30  days",
                value: format(moderateChargers),
              },
              {
                label: "Used between last 31-45 days",
                value: format(criticalChargers),
              },
              {
                label: "Used before last 45 days",
                value: format(inactiveChargers),
              },
            ].map((el, i) => (
              <Box key={i} display="flex" alignItems="center">
                <Box
                  alignSelf="stretch"
                  width="5px"
                  borderRadius={50}
                  bgcolor={Object.values(colors)[i] as string}
                />
                <Box px={1.75} py={0.25}>
                  <Box fontSize={12}>{el.label}</Box>
                  <Box
                    color={(theme) => theme.customColors.text.plain}
                    fontSize={{ xs: 16, md: 20 }}
                    lineHeight="1.1em"
                    fontWeight={700}
                  >
                    {el.value}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
          {/* Chart */}
          <Box position="relative" width={1} height={1}>
            <Doughnut
              style={{
                position: "absolute",
                maxHeight: "100%",
                maxWidth: 300,
                top: "50%",
                left: "50%",
                transform: "translateX(-50%) translateY(-50%)",
                zIndex: 2,
                // border: `1px solid black`
              }}
              data={(canvas) => {
                return {
                  datasets: [
                    {
                      data: [
                        healthyChargers,
                        moderateChargers,
                        criticalChargers,
                        inactiveChargers,
                      ],
                      backgroundColor: Object.values(colors),
                      hoverBackgroundColor: Object.values(colors),
                      borderColor: Object.values(colors),
                      hoverBorderColor: Object.values(colors),
                      borderWidth: 0,
                      hoverBorderWidth: 10,
                      cutout: "60%",
                      hoverOffset: 0,
                      borderJoinStyle: "miter",
                      // spacing: 1,
                      circumference: 180,
                      rotation: -90,
                    },
                  ],
                  labels: ["HEALTHY", "MODERATE", "CRITICAL", "INACTIVE"],
                };
              }}
              options={{
                layout: {
                  padding: 16,
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    displayColors: false,
                    callbacks: {
                      title: (context) => {
                        const total = context[0].dataset.data.reduce(
                          (a, b) => a + b,
                          0,
                        );
                        const percentage =
                          ((context[0].raw as number) / total) * 100;
                        return `${percentage.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}%`;
                      },
                      label: (context) => {
                        return context.label === "HEALTHY"
                          ? [
                              `${context.formattedValue} used within`,
                              `last 15 days`,
                            ]
                          : context.label === "MODERATE"
                            ? [
                                `${context.formattedValue} used between`,
                                `last 16-30 days`,
                              ]
                            : context.label === "CRITICAL"
                              ? [
                                  `${context.formattedValue} used between`,
                                  `last 31-45 days`,
                                ]
                              : context.label === "INACTIVE"
                                ? [
                                    `${context.formattedValue} used before`,
                                    `last 45 days`,
                                  ]
                                : "";
                      },
                    },
                    cornerRadius: 0,
                    titleFont: {
                      size: 20,
                    },
                    bodyFont: {
                      size: 12,
                    },
                    padding: 12,
                  },
                },
              }}
            />
            <Box
              sx={{
                zIndex: 1,
                position: "absolute",
                top: "62%",
                left: "50%",
                transform: "translateX(-50%) translateY(-50%)",
                pointerEvents: "none",
                textAlign: "center",
                color: (theme) => theme.customColors.text.plain,
              }}
            >
              <Typography
                fontSize={{ xs: 18, md: 24 }}
                fontWeight={700}
                lineHeight="1em"
              >
                {format(totalChargers)}
              </Typography>
              <Typography fontSize={14}>Total</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default OperationalStatus;
