import { gql, useMutation } from "@apollo/client";
import { DeleteOutline, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  // Tab,
  // Tabs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
} from "@mui/material";
import Table from "components/Table";
import { queryClient } from "index";
import moment from "moment";
import { useState } from "react";
import { drawer, getPermissions, setLoader, snackbar } from "utils";
import Search from "../../../components/Search";
import DrawerContent from "./DrawerContent";

const List = ({
  vendorsLoading,
  vendorsData,
  setSearch,
  page,
  pageSize,
  setPage,
  setPageSize,
}: any) => {
  const { canDelete } = getPermissions("charger:vendors");
  // const [tab, setTab] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState<any>({
    open: false,
    selected: [],
  });

  return (
    <>
      <Paper
        sx={{
          width: 1,
          boxShadow: (theme) => theme.customShadows.card,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            width: 1,
            p: 3,
            pb: 2.75,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box />
          <Search
            handleSearch={(value) => {
              setSearch(value);
            }}
            persist
            enableClear
          />
        </Box>
        <Table
          idKey="_id"
          loading={vendorsLoading}
          selectable={canDelete}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          serverSidePagination
          rowCount={vendorsData?.meta?.totalCount || 0}
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          rows={
            vendorsData?.data?.constructor === Array ? vendorsData.data : []
          }
          columns={[
            {
              key: "vendorName",
              label: "Vendor Name",
              Render: (row) => <Box sx={{ py: 2 }}>{row.name}</Box>,
            },
            {
              key: "numberOfChargers",
              label: "Assigned Chargers",
              Render: (row) => (
                <Box display="flex" alignItems="center">
                  {row.numberOfChargers}
                  <IconButton
                    size="small"
                    sx={{
                      ml: 0.5,
                      color: (theme: any) => theme.customColors.action,
                    }}
                    children={<InfoOutlined fontSize="small" />}
                    onClick={() =>
                      drawer.open(
                        <DrawerContent
                          key={row._id}
                          vendor={row}
                          viewChargers={true}
                        />
                      )
                    }
                  />
                </Box>
              ),
            },
            // {
            //   key: "vendorPhone",
            //   label: "Phone",
            //   Render: (row) => <Box>{row.phone}</Box>,
            // },
            {
              key: "createdAt",
              label: "Created At",
              Render: (row) => (
                <Box>{moment(row.createdAt).format("ddd, MMM DD, YYYY")}</Box>
              ),
            },
            {
              key: "actions",
              label: "Actions",
              Render: (row) => (
                // <Button
                //   variant="action"
                //   onClick={() =>
                //     drawer.open(<DrawerContent key={row._id} vendor={row} />)
                //   }
                // >
                //   View
                // </Button>
                <Box display="flex" alignItems="center">
                  {/* {row.numberOfChargers} */}
                  <IconButton
                    size="small"
                    sx={{
                      ml: 0.5,
                      color: (theme: any) => theme.customColors.action,
                    }}
                    children={<InfoOutlined fontSize="small" />}
                    onClick={() =>
                      drawer.open(
                        <DrawerContent
                          key={row._id}
                          vendor={row}
                          //  viewChargers={true}
                        />
                      )
                    }
                  />
                </Box>
              ),
            },
          ]}
          toolbar={() => (
            <Button
              startIcon={<DeleteOutline />}
              onClick={() => {
                setDeleteDialog({ open: true, data: selectedRows });
              }}
            >
              Delete
            </Button>
          )}
        />
      </Paper>
      <DeleteDialog
        open={deleteDialog.open}
        handleClose={() => {
          setDeleteDialog({ ...deleteDialog, open: false });
        }}
        selected={deleteDialog.data}
      />
    </>
  );
};

interface DeleteVendorProps {
  open: boolean;
  handleClose: () => void;
  selected: string[];
}

const DeleteDialog: React.FC<DeleteVendorProps> = ({
  open,
  handleClose,
  selected,
}) => {
  const UPDATE_STATUS = gql`
    mutation DeleteSubCompany($where: CompanyDeleteInput!) {
      company {
        deleteSubCompany(where: $where) {
          id
          status
        }
      }
    }
  `;
  const [deleteVendor] = useMutation(UPDATE_STATUS, {
    onCompleted: () => {
      ["allStats", "getVendors"].forEach((query) => {
        queryClient.resetQueries(query, { exact: true });
      });
      setLoader(false);
      snackbar.success(`Vendor${selected?.length > 1 ? "s" : ""} deleted`);
      handleClose();
    },
    onError: () => {
      setLoader(false);
      snackbar.error(`Error deleting vendor${selected?.length > 1 ? "s" : ""}`);
    },
  });
  function confirm() {
    setLoader(true);
    deleteVendor({
      variables: {
        where: {
          id: selected,
        },
      },
    });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete Vendor{selected?.length > 1 ? "s" : ""}</DialogTitle>
      <DialogContent className="py-1">
        Delete {selected?.length > 1 || ""} selected vendor
        {selected?.length > 1 ? "s" : ""}?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={confirm}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default List;
