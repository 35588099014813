import {
  AccountBalanceOutlined,
  Add,
  ArrowBack,
  DeleteOutlined,
  EditOutlined,
  ErrorOutline,
  HighlightOff,
  MoreVert,
  SettingsOutlined,
  WarningAmber,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import CircularLoader from "components/CircularLoader";
import RedAsterisk from "components/RedAsterisk";
import { queryClient } from "index";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { PAYMENTS_URL } from "utils/constants";
import validator from "validator";

const inputObj = {
  account: "",
  confirmAccount: "",
  bankName: "",
  ifscCode: "",
  accountHolder: "",
  beneficiaryName: "",
  beneficiaryPhone: "",
  beneficiaryEmail: "",
  addressPrimary: "",
  addressSecondary: "",
  city: "",
  state: "",
  pinCode: "",
  panNumber: "",
  aadharNumber: "",
  gstNumber: "",
  gstAddressSameAsContactAddress: true,
  gstAddress: {
    address1: "",
    address2: "",
    city: "",
    state: "",
    pincode: "",
  },
};

const PaymentDialog = ({ open, handleClose, data: user }: any) => {
  const [activeView, setActiveView] = useState<"main" | "add" | "edit">("main");
  const [step, setStep] = useState<number>(0);
  const steps = [
    "Bank Account Info",
    "Contact Info",
    "Additional Info",
    "Finish",
  ];
  const [accountIndex, setAccountIndex] = useState<number>(0);

  const [input, setInput] = useState({ ...inputObj });

  const {
    account,
    confirmAccount,
    bankName,
    ifscCode,
    accountHolder,
    beneficiaryName,
    beneficiaryPhone,
    beneficiaryEmail,
    addressPrimary,
    addressSecondary,
    city,
    state,
    pinCode,
    panNumber,
    aadharNumber,
    gstNumber,
    gstAddressSameAsContactAddress,
    gstAddress,
  } = input;

  const getPaymentDetailsUrl = `${PAYMENTS_URL}/v1/payments/paymentmeta/default/getAll?userId=${user?._id}`;
  const { isLoading: paymentDetailsLoading, data: paymentDetails } = useQuery(
    ["getPaymentDetails", user?._id],
    () => authorizedFetch(getPaymentDetailsUrl),
    {
      enabled: Boolean(user?._id),
    },
  );

  const accountsList =
    paymentDetails?.data?.constructor === Array ? paymentDetails.data : [];

  useEffect(() => {
    if (open) {
      setActiveView("main");
    }
  }, [open]);

  useEffect(() => {
    const details =
      paymentDetails?.data?.constructor === Array &&
      paymentDetails.data.length > 0
        ? paymentDetails.data[accountIndex]
        : null;

    if (activeView === "main") {
      setTimeout(() => {
        setStep(0);
      }, 400);
    } else if (details && activeView === "edit") {
      const isGstAddressSameAsContactAddress =
        details?.contactInfo?.address1 ===
          details?.contactInfo?.gstAddress?.address1 &&
        details?.contactInfo?.address2 ===
          details?.contactInfo?.gstAddress?.address2 &&
        details?.contactInfo?.city === details?.contactInfo?.gstAddress?.city &&
        details?.contactInfo?.state ===
          details?.contactInfo?.gstAddress?.state &&
        details?.contactInfo?.pincode ===
          details?.contactInfo?.gstAddress?.pincode;

      setInput({
        account: "",
        confirmAccount: "",
        bankName: details?.bankDetails?.bankName || "",
        ifscCode: details?.bankDetails?.ifsc || "",
        accountHolder: details?.bankDetails?.accountHolder || "",
        beneficiaryName: details?.contactInfo?.name || "",
        beneficiaryPhone: details?.contactInfo?.phone.includes("+91")
          ? details?.contactInfo?.phone.slice(3)
          : details?.contactInfo?.phone || "",
        beneficiaryEmail: details?.contactInfo?.email || "",
        addressPrimary: details?.contactInfo?.address1 || "",
        addressSecondary: details?.contactInfo?.address2 || "",
        city: details?.contactInfo?.city || "",
        state: details?.contactInfo?.state || "",
        pinCode: details?.contactInfo?.pincode || "",
        panNumber: details?.contactInfo?.panNo || "",
        aadharNumber: details?.contactInfo?.aadharNo || "",
        gstNumber: details?.contactInfo?.gstin || "",
        gstAddressSameAsContactAddress: isGstAddressSameAsContactAddress,
        gstAddress: {
          address1: details?.contactInfo?.gstAddress?.address1 || "",
          address2: details?.contactInfo?.gstAddress?.address2 || "",
          city: details?.contactInfo?.gstAddress?.city || "",
          state: details?.contactInfo?.gstAddress?.state || "",
          pincode: details?.contactInfo?.gstAddress?.pincode || "",
        },
      });
    } else if (activeView === "add") {
      setInput({ ...inputObj });
    }
  }, [paymentDetails, accountIndex, activeView]);

  function handleChange(key: string, value: any) {
    if (key.includes(".")) {
      const [parentKey, childKey] = key.split(".");
      setInput((prevInput: any) => ({
        ...prevInput,
        [parentKey]: { ...prevInput[parentKey], [childKey]: value },
      }));
    } else {
      setInput((prevInput) => ({ ...prevInput, [key]: value }));
    }
  }

  const isValid = {
    // Step 1
    account: /^[0-9]{9,16}$/.test(account),
    confirmAccount: confirmAccount === account,
    bankName: true,
    ifscCode: /[A-Z]{4}[0][A-Z0-9]{6}$/.test(ifscCode),
    accountHolder: true,

    // Step 2
    beneficiaryName: true,
    beneficiaryPhone:
      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
        beneficiaryPhone,
      ),
    beneficiaryEmail: validator.isEmail(beneficiaryEmail),
    addressPrimary: true,
    addressSecondary: true,
    city: true,
    state: true,
    pinCode: /^[0-9]{6}$/.test(pinCode),

    // Step 3
    panNumber:
      panNumber === ""
        ? true
        : /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(panNumber),
    aadharNumber:
      aadharNumber === ""
        ? true
        : /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/.test(aadharNumber),
    gstNumber:
      gstNumber === ""
        ? true
        : /^[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
            gstNumber,
          ),
    gstAddressPincode:
      gstNumber === "" || gstAddressSameAsContactAddress
        ? true
        : /^[0-9]{6}$/.test(gstAddress.pincode),
  };

  function isComplete(step: number) {
    switch (step) {
      case 0:
        return (
          isValid.account &&
          isValid.confirmAccount &&
          isValid.ifscCode &&
          ![bankName, account].includes("")
        );
      case 1:
        return (
          isValid.beneficiaryPhone &&
          isValid.beneficiaryEmail &&
          isValid.pinCode &&
          ![
            beneficiaryName,
            addressPrimary,
            addressSecondary,
            city,
            state,
          ].includes("")
        );
      case 2:
        return isValid.panNumber && isValid.aadharNumber && isValid.gstNumber;
      default:
        break;
    }
  }

  const accountExists = accountsList
    .filter((el: any) =>
      // Filter out current account only when edit mode
      activeView !== "add" ? el._id !== accountsList[accountIndex]._id : true,
    )
    .some((el: any) => el?.bankDetails?.bankAccount === account);

  const disabled =
    [
      account,
      confirmAccount,
      bankName,
      ifscCode,
      accountHolder,

      beneficiaryName,
      beneficiaryPhone,
      beneficiaryEmail,
      addressPrimary,
      addressSecondary,
      city,
      state,
      pinCode,

      ...(!!gstNumber && !gstAddressSameAsContactAddress
        ? [
            gstAddress.address1,
            gstAddress.city,
            gstAddress.state,
            gstAddress.pincode,
          ]
        : []),
    ].includes("") || Object.values(isValid).includes(false);

  function handleBack() {
    setStep(step - 1);
  }

  function handleNext() {
    if (step === steps.length - 1) {
      handleSave();
    } else setStep(step + 1);
  }

  function handleSave() {
    setLoader(true);
    // if (activeView === "add") {
    addPaymentMethod();
    // } else if (activeView === "edit" && paymentDetails?.data?.[accountIndex]) {
    //   authorizedFetch(
    //     `${PAYMENTS_URL}/v1/payments/paymentmeta/default/delete`,
    //     {
    //       method: "DELETE",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: {
    //         paymentMetaId: paymentDetails.data[accountIndex]._id,
    //         userId: user?._id,
    //       },
    //     },
    //   ).then(() => {
    //     addPaymentMethod();
    //   });
    // }
  }

  function addPaymentMethod() {
    authorizedFetch(
      `${PAYMENTS_URL}/v1/payments/paymentmeta/default/${activeView === "add" ? "create" : `update/${paymentDetails.data[accountIndex]._id}`}`,
      {
        method: activeView === "add" ? "POST" : "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          ...(activeView === "add"
            ? {
                user: user?._id,
                stage: "PROD",
              }
            : {}),
          bankAccount: input.account,
          bankName: input.bankName,
          ifsc: input.ifscCode,
          accountHolder: input.accountHolder,
          name: input.beneficiaryName,
          phone: `+91${input.beneficiaryPhone}`,
          email: input.beneficiaryEmail,
          address1: input.addressPrimary,
          address2: input.addressSecondary,
          city: input.city,
          state: input.state,
          pincode: input.pinCode,
          panNo: input.panNumber,
          aadharNo: input.aadharNumber,
          gstin: input.gstNumber,
          ...(!!input.gstNumber
            ? {
                gstAddress: gstAddressSameAsContactAddress
                  ? {
                      address1: input.addressPrimary,
                      address2: input.addressSecondary,
                      city: input.city,
                      state: input.state,
                      pincode: input.pinCode,
                    }
                  : {
                      address1: gstAddress.address1,
                      address2: gstAddress.address2,
                      city: gstAddress.city,
                      state: gstAddress.state,
                      pincode: gstAddress.pincode,
                    },
              }
            : {}),
        },
      },
    )
      .then((res) => {
        setLoader(false);
        if (res.status === 200) {
          snackbar.success("Payment details updated");
          queryClient.resetQueries("getPaymentDetails");
          setActiveView("main");
        } else {
          snackbar.error(
            `Error${res.message ? `: ${res.message}` : ` ${activeView === "add" ? "adding" : "editing"} payment details`}`,
          );
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
      });
  }

  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      maxWidth={activeView === "main" ? "sm" : "md"}
      fullWidth
      PaperProps={{
        sx: {
          transition: "all 300ms",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {activeView !== "main" && (
            <IconButton
              size="small"
              children={<ArrowBack />}
              color="inherit"
              onClick={() => setActiveView("main")}
              sx={{ mr: 1.5 }}
            />
          )}
          {activeView === "main" ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              Payment Details
              {(user?.firstName || user?.lastName || user?.phone) && (
                <>
                  <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
                  <Typography color="text.secondary">
                    {user?.firstName || user?.lastName
                      ? `${user?.firstName || ""} ${user?.lastName || ""}`
                      : `${user?.phone?.slice(0, 3)} ${user?.phone?.slice(3)}`}
                  </Typography>
                </>
              )}
            </Box>
          ) : activeView === "add" ? (
            "Add Account"
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              Edit Account
              <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
              <Typography color="text.secondary">
                ••••{" "}
                {paymentDetails.data[
                  accountIndex
                ].bankDetails.bankAccount.slice(-4)}
              </Typography>
            </Box>
          )}
        </Box>
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent>
        <Collapse in={activeView === "main"}>
          <Collapse in={paymentDetailsLoading}>
            <Box my={8}>
              <CircularLoader />
            </Box>
          </Collapse>
          <Collapse in={!paymentDetailsLoading}>
            <Box
              sx={{
                mt: 2,
                gridColumn: "1/-1",
                display: "grid",
                // gridTemplateColumns: { sm: "1fr 1fr" },
                gap: 3,
                maxWidth: 380,
                mx: "auto",
              }}
            >
              {accountsList.length === 0 ? (
                <Box
                  sx={{
                    gridColumn: "1/-1",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 5,
                    border: "2px dashed",
                    borderRadius: 1,
                    borderColor: (theme) => theme.customColors.border,
                    color: (theme) => theme.customColors.text.grey,
                  }}
                >
                  <Typography mb={3}>No Accounts Found</Typography>
                  <Button
                    sx={{ textTransform: "none" }}
                    startIcon={<Add />}
                    variant="outlined"
                    onClick={() => setActiveView("add")}
                  >
                    Add Account
                  </Button>
                </Box>
              ) : (
                accountsList.map((el: any, i: number) => (
                  <AccountCard
                    key={i}
                    {...{
                      el,
                      i,
                      user,
                      setActiveView,
                      setAccountIndex,
                      accountsList,
                    }}
                  />
                ))
              )}
              {accountsList.length > 0 && (
                <Card
                  variant="outlined"
                  sx={{
                    border: (theme) =>
                      `1px dashed ${theme.palette.primary.main}`,
                    color: "primary.main",
                    height: 1,
                    minHeight: 100,
                  }}
                >
                  <CardActionArea
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 1,
                    }}
                    onClick={() => setActiveView("add")}
                  >
                    <Add color="inherit" sx={{ mr: 0.5 }} />
                    <Typography>Add Account</Typography>
                  </CardActionArea>
                </Card>
              )}
            </Box>
          </Collapse>
        </Collapse>
        <Collapse in={["add", "edit"].includes(activeView)}>
          <>
            <Stepper
              sx={{ my: 4, mx: "auto", maxWidth: 550 }}
              activeStep={step}
              nonLinear
              alternativeLabel
            >
              {steps.map((label, i) => (
                <Step key={i}>
                  <StepButton onClick={() => setStep(i)}>{label}</StepButton>
                </Step>
              ))}
            </Stepper>
            <Collapse in={step === 0}>
              <>
                <Box
                  sx={{
                    maxWidth: 560,
                    mx: "auto",
                    my: 2,
                  }}
                >
                  <Typography variant="body2" color="text.secondary">
                    <RedAsterisk /> Required fields
                  </Typography>
                </Box>
                <Box
                  sx={{
                    maxWidth: 560,
                    mx: "auto",
                    display: "grid",
                    gridTemplateColumns: { sm: "1fr 1fr" },
                    gap: 3,
                  }}
                >
                  <Box>
                    <Typography className="label">
                      Account Number <RedAsterisk />{" "}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Account Number"
                      value={account}
                      onChange={(e: any) => {
                        // if (/^[0-9]{0,16}$/.test(e.target.value)) {
                        handleChange("account", e.target.value);
                        // }
                      }}
                      error={
                        account !== "" && (!isValid.account || accountExists)
                      }
                      helperText={
                        accountExists ? "Account already exists" : undefined
                      }
                      autoComplete="off"
                    />
                  </Box>
                  <Box>
                    <Typography className="label">
                      Confirm Account Number <RedAsterisk />
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Confirm Account Number"
                      value={confirmAccount}
                      onChange={(e: any) => {
                        // if (/^[0-9]{0,16}$/.test(e.target.value)) {
                        handleChange("confirmAccount", e.target.value);
                        // }
                      }}
                      error={confirmAccount !== "" && !isValid.confirmAccount}
                      autoComplete="off"
                    />
                  </Box>
                  <Box>
                    <Typography className="label">
                      Bank Name <RedAsterisk />
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Bank Name"
                      value={bankName}
                      onChange={(e: any) => {
                        // if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                        handleChange("bankName", e.target.value);
                        // }
                      }}
                      autoComplete="off"
                    />
                  </Box>
                  <Box>
                    <Typography className="label">
                      IFSC Code <RedAsterisk />
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="IFSC Code"
                      value={ifscCode}
                      onChange={(e: any) => {
                        let value = String(e.target.value).toUpperCase();
                        // if (/^[A-Z0-9]{0,11}$/.test(value)) {
                        handleChange("ifscCode", value);
                        // }
                      }}
                      error={ifscCode !== "" && !isValid.ifscCode}
                      autoComplete="off"
                    />
                  </Box>
                  <Box>
                    <Typography className="label">
                      Account Holder <RedAsterisk />
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Account Holder"
                      value={accountHolder}
                      onChange={(e: any) => {
                        // if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                        handleChange("accountHolder", e.target.value);
                        // }
                      }}
                      autoComplete="off"
                    />
                  </Box>
                </Box>
              </>
            </Collapse>
            <Collapse in={step === 1}>
              <>
                <Box
                  sx={{
                    maxWidth: 560,
                    mx: "auto",
                    my: 2,
                  }}
                >
                  <Typography variant="body2" color="text.secondary">
                    <RedAsterisk /> Required fields
                  </Typography>
                </Box>
                <Box
                  sx={{
                    maxWidth: 560,
                    mx: "auto",
                    display: "grid",
                    gridTemplateColumns: { sm: "1fr 1fr" },
                    gap: 3,
                  }}
                >
                  <Box>
                    <Typography className="label">
                      Beneficiary Name <RedAsterisk />
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Beneficiary Name"
                      value={beneficiaryName}
                      onChange={(e: any) => {
                        // if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                        handleChange("beneficiaryName", e.target.value);
                        // }
                      }}
                      autoComplete="off"
                    />
                  </Box>
                  <Box>
                    <Typography className="label">
                      Beneficiary Phone <RedAsterisk />
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Beneficiary Phone"
                      value={beneficiaryPhone}
                      onChange={(e: any) => {
                        // if (/^[0-9]{0,10}$/.test(e.target.value)) {
                        handleChange("beneficiaryPhone", e.target.value);
                        // }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{ ml: 1 }}>
                            +91
                          </InputAdornment>
                        ),
                      }}
                      error={
                        beneficiaryPhone !== "" && !isValid.beneficiaryPhone
                      }
                      autoComplete="off"
                    />
                  </Box>
                  <Box>
                    <Typography className="label">
                      Beneficiary Email <RedAsterisk />
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Beneficiary Email"
                      value={beneficiaryEmail}
                      onChange={(e: any) =>
                        handleChange("beneficiaryEmail", e.target.value)
                      }
                      error={
                        beneficiaryEmail !== "" && !isValid.beneficiaryEmail
                      }
                      autoComplete="off"
                    />
                  </Box>
                  <Box gridColumn="1/-1">
                    <Typography className="label">
                      Address Line 1 <RedAsterisk />
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Address Line 1"
                      value={addressPrimary}
                      onChange={(e: any) =>
                        handleChange("addressPrimary", e.target.value)
                      }
                      autoComplete="off"
                    />
                  </Box>
                  <Box gridColumn="1/-1">
                    <Typography className="label">
                      Address Line 2 <RedAsterisk />
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Address Line 2"
                      value={addressSecondary}
                      onChange={(e: any) =>
                        handleChange("addressSecondary", e.target.value)
                      }
                      autoComplete="off"
                    />
                  </Box>
                  <Box>
                    <Typography className="label">
                      City <RedAsterisk />
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="City"
                      value={city}
                      onChange={(e: any) => {
                        // if (/^[a-zA-Z]{0,20}$/.test(e.target.value)) {
                        handleChange("city", e.target.value);
                        // }
                      }}
                      autoComplete="off"
                    />
                  </Box>
                  <Box>
                    <Typography className="label">
                      State <RedAsterisk />
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="State"
                      value={state}
                      onChange={(e: any) => {
                        // if (/^[A-Za-z\s]*$/.test(e.target.value)) {
                        handleChange("state", e.target.value);
                        // }
                      }}
                      autoComplete="off"
                    />
                  </Box>
                  <Box>
                    <Typography className="label">
                      PIN Code <RedAsterisk />
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="PIN Code"
                      value={pinCode}
                      onChange={(e: any) => {
                        // if (/^[0-9]{0,6}$/.test(e.target.value)) {
                        handleChange("pinCode", e.target.value);
                        // }
                      }}
                      error={pinCode !== "" && !isValid.pinCode}
                      autoComplete="off"
                      required
                    />
                  </Box>
                </Box>
              </>
            </Collapse>
            <Collapse in={step === 2}>
              <Box
                sx={{
                  maxWidth: 560,
                  mx: "auto",
                  my: 2,
                  display: "grid",
                  gridTemplateColumns: { sm: "1fr 1fr" },
                  gap: 3,
                }}
              >
                <Box>
                  <Typography className="label">PAN Number</Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="PAN Number (Optional)"
                    value={panNumber}
                    onChange={(e: any) => {
                      let value = String(e.target.value).toUpperCase();
                      // if (/^[A-Z0-9]{0,10}$/.test(value)) {
                      handleChange("panNumber", value);
                      // }
                    }}
                    error={panNumber !== "" && !isValid.panNumber}
                    autoComplete="off"
                  />
                </Box>
                <Box>
                  <Typography className="label">Aadhaar Number</Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Aadhaar Number (Optional)"
                    value={aadharNumber}
                    onChange={(e: any) => {
                      // if (/^[0-9]{0,12}$/.test(e.target.value)) {
                      handleChange("aadharNumber", e.target.value);
                      // }
                    }}
                    error={aadharNumber !== "" && !isValid.aadharNumber}
                    autoComplete="off"
                  />
                </Box>
                <Box>
                  <Typography className="label">GST Number</Typography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="GST Number (Optional)"
                    value={gstNumber}
                    onChange={(e) => {
                      let value = String(e.target.value).toUpperCase();
                      // if (/^[A-Z0-9]{0,15}$/.test(value)) {
                      handleChange("gstNumber", value);
                      // }
                    }}
                    error={gstNumber !== "" && !isValid.gstNumber}
                    autoComplete="off"
                  />
                </Box>
                <Collapse in={!!gstNumber} sx={{ gridColumn: "1/-1" }}>
                  <Box>
                    <Typography className="label">
                      GST Address <RedAsterisk />
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={gstAddressSameAsContactAddress}
                          onChange={(e) =>
                            handleChange(
                              "gstAddressSameAsContactAddress",
                              e.target.checked,
                            )
                          }
                        />
                      }
                      label="Same as Contact Address"
                    />
                  </Box>
                  <Collapse in={!gstAddressSameAsContactAddress}>
                    <Box
                      sx={{
                        mt: 2,
                        display: "grid",
                        gridTemplateColumns: { sm: "1fr 1fr" },
                        gap: 3,
                      }}
                    >
                      <Box gridColumn="1/-1">
                        <Typography className="label">
                          Address Line 1 <RedAsterisk />
                        </Typography>
                        <TextField
                          fullWidth
                          size="small"
                          placeholder="Address Line 1"
                          value={input.gstAddress.address1}
                          onChange={(e) =>
                            handleChange("gstAddress.address1", e.target.value)
                          }
                          autoComplete="off"
                          required
                        />
                      </Box>
                      <Box gridColumn="1/-1">
                        <Typography className="label">
                          Address Line 2
                        </Typography>
                        <TextField
                          fullWidth
                          size="small"
                          placeholder="Address Line 2"
                          value={input.gstAddress.address2}
                          onChange={(e) =>
                            handleChange("gstAddress.address2", e.target.value)
                          }
                          autoComplete="off"
                        />
                      </Box>
                      <Box>
                        <Typography className="label">
                          City <RedAsterisk />
                        </Typography>
                        <TextField
                          fullWidth
                          size="small"
                          placeholder="City"
                          value={input.gstAddress.city}
                          onChange={(e) =>
                            handleChange("gstAddress.city", e.target.value)
                          }
                          autoComplete="off"
                          required
                        />
                      </Box>
                      <Box>
                        <Typography className="label">
                          State <RedAsterisk />
                        </Typography>
                        <TextField
                          fullWidth
                          size="small"
                          placeholder="State"
                          value={input.gstAddress.state}
                          onChange={(e) =>
                            handleChange("gstAddress.state", e.target.value)
                          }
                          autoComplete="off"
                          required
                        />
                      </Box>
                      <Box>
                        <Typography className="label">
                          PIN Code <RedAsterisk />
                        </Typography>
                        <TextField
                          fullWidth
                          size="small"
                          placeholder="PIN Code"
                          value={input.gstAddress.pincode}
                          onChange={(e) =>
                            handleChange("gstAddress.pincode", e.target.value)
                          }
                          error={pinCode !== "" && !isValid.pinCode}
                          autoComplete="off"
                          required
                        />
                      </Box>
                    </Box>
                  </Collapse>
                </Collapse>
              </Box>
            </Collapse>
            <Collapse in={step === 3}>
              <Box
                sx={{
                  maxWidth: 560,
                  mx: "auto",
                  "& .table": {
                    borderCollapse: "collapse",
                    width: 1,
                    fontSize: 14,
                    lineHeight: "16px",
                    "& td": {
                      py: 1.25,
                      px: 2,
                    },
                    "& .bold": {
                      fontWeight: 500,
                    },
                    "& .header": {
                      px: 2,
                      py: 1,
                      position: "relative",
                      "& td": {
                        position: "absolute",
                        verticalAlign: "middle",
                        bgcolor: (theme: any) => theme.customColors.header,
                        width: 1,
                        borderRadius: "4px",
                        fontSize: 16,
                        fontWeight: 600,
                        "& span": {
                          display: "inline-block",
                          transform: "translateY(1px)",
                        },
                      },
                    },
                    "& .first > td": {
                      pt: 9,
                    },
                    "& .last > td": {
                      pb: 3,
                    },
                  },
                }}
              >
                <table className="table">
                  <tbody>
                    {[
                      {
                        header: "Bank Account Info",
                        onEdit: () => setStep(0),
                      },
                      {
                        label: "Account Number",
                        value: account,
                        required: true,
                        isValid:
                          isValid.account &&
                          isValid.confirmAccount &&
                          !accountExists,
                      },
                      { label: "Bank Name", value: bankName, required: true },
                      {
                        label: "IFSC Code",
                        value: ifscCode,
                        required: true,
                        isValid: isValid.ifscCode,
                      },
                      {
                        label: "Account Holder",
                        value: accountHolder,
                        required: true,
                      },

                      { header: "Contact Info", onEdit: () => setStep(1) },
                      {
                        label: "Beneficiary Name",
                        value: beneficiaryName,
                        required: true,
                      },
                      {
                        label: "Beneficiary Phone",
                        value:
                          beneficiaryPhone !== ""
                            ? "+91 " + beneficiaryPhone
                            : "",
                        required: true,
                        isValid: isValid.beneficiaryPhone,
                      },
                      {
                        label: "Beneficiary Email",
                        value: beneficiaryEmail,
                        required: true,
                        isValid: isValid.beneficiaryEmail,
                      },
                      {
                        label: "Address Line 1",
                        value: addressPrimary,
                        required: true,
                      },
                      {
                        label: "Address Line 2",
                        value: addressSecondary,
                        required: true,
                      },
                      { label: "City", value: city, required: true },
                      { label: "State", value: state, required: true },
                      {
                        label: "PIN Code",
                        value: pinCode,
                        required: true,
                        isValid: isValid.pinCode,
                      },

                      { header: "Additional Info", onEdit: () => setStep(2) },
                      {
                        label: "PAN Number",
                        value: panNumber,
                        isValid: isValid.panNumber,
                      },
                      {
                        label: "Aadhaar Number",
                        value: aadharNumber,
                        isValid: isValid.aadharNumber,
                      },
                      {
                        label: "GST Number",
                        value: gstNumber,
                        isValid: isValid.gstNumber,
                      },
                      ...(!!gstNumber
                        ? [
                            {
                              label: "GST Address",
                              value: gstAddressSameAsContactAddress
                                ? "Same as Contact Address"
                                : "",
                              bold: !gstAddressSameAsContactAddress,
                            },
                            ...(!gstAddressSameAsContactAddress
                              ? [
                                  {
                                    label: "Address Line 1",
                                    value: gstAddress.address1,
                                    required: true,
                                  },
                                  {
                                    label: "Address Line 2",
                                    value: gstAddress.address2,
                                  },
                                  {
                                    label: "City",
                                    value: gstAddress.city,
                                    required: true,
                                  },
                                  {
                                    label: "State",
                                    value: gstAddress.state,
                                    required: true,
                                  },
                                  {
                                    label: "PIN Code",
                                    value: gstAddress.pincode,
                                    required: true,
                                    isValid: isValid.gstAddressPincode,
                                  },
                                ]
                              : []),
                          ]
                        : []),
                    ].map(
                      (
                        {
                          header,
                          onEdit,
                          label,
                          value,
                          required,
                          isValid,
                          bold,
                        },
                        i,
                        arr,
                      ) => {
                        const isFirst = arr[i - 1]?.header;
                        const isLast = !arr[i + 1] || arr[i + 1].header;

                        return (
                          <tr
                            key={i}
                            className={
                              header
                                ? "header"
                                : `${isFirst ? "first" : ""} ${
                                    isLast ? "last" : ""
                                  }`
                            }
                          >
                            {header ? (
                              <td colSpan={2}>
                                <span>{header}</span>
                                <IconButton
                                  sx={{ ml: 1.5 }}
                                  children={<EditOutlined />}
                                  color="primary"
                                  size="small"
                                  onClick={onEdit}
                                />
                              </td>
                            ) : (
                              <>
                                <td style={{ fontWeight: bold ? 700 : 400 }}>
                                  {label}
                                </td>
                                <td className="bold">
                                  {value !== "" ? (
                                    <Box display="flex" alignItems="center">
                                      {value}
                                      {isValid === false && (
                                        <>
                                          <WarningAmber
                                            fontSize="small"
                                            color="warning"
                                            sx={{ ml: 2, mr: 1 }}
                                          />
                                          Invalid
                                        </>
                                      )}
                                    </Box>
                                  ) : (
                                    required && (
                                      <Box display="flex" alignItems="center">
                                        <ErrorOutline
                                          fontSize="small"
                                          color="error"
                                          sx={{ mr: 1 }}
                                        />
                                        Required
                                      </Box>
                                    )
                                  )}
                                </td>
                              </>
                            )}
                          </tr>
                        );
                      },
                    )}
                  </tbody>
                </table>
              </Box>
            </Collapse>
          </>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Collapse in={["add", "edit"].includes(activeView)}>
          {step !== 0 && (
            <Button variant="outlined" onClick={handleBack}>
              Back
            </Button>
          )}
          <Button
            sx={{ ml: 2 }}
            onClick={handleNext}
            variant="contained"
            color={
              isComplete(step) || step === steps.length - 1
                ? "primary"
                : "inherit"
            }
            disableElevation
            disabled={step === steps.length - 1 && disabled}
          >
            {step === steps.length - 1
              ? "Save"
              : isComplete(step)
                ? "Next"
                : "Skip"}
          </Button>
        </Collapse>
      </DialogActions>
    </Dialog>
  );
};

const AccountCard = ({
  el,
  i,
  user,
  setActiveView,
  setAccountIndex,
  accountsList,
}: any) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [primaryBankDialog, setPrimaryBankDialog] = useState(false);

  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
      }}
    >
      <Box
        sx={{
          bgcolor: (theme) => theme.palette.divider,
          color: (theme) => theme.customColors.action,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 1,
          width: 1,
          maxWidth: 100,
          position: "relative",
        }}
      >
        {el?.isDefault && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              p: "2px 6px",
              borderBottomRightRadius: "6px",
              bgcolor: "primary.main",
              color: "white",
              fontSize: 12,
              fontWeight: 600,
            }}
          >
            Primary
          </Box>
        )}
        <AccountBalanceOutlined color="inherit" fontSize="large" />
      </Box>
      <Box
        sx={{
          py: 1,
          px: 2,
          flexGrow: 1,
        }}
      >
        <Typography variant="overline">{el.bankDetails.bankName}</Typography>
        <Typography variant="h6" mb={1}>
          •••• {el.bankDetails.bankAccount.slice(-4)}
        </Typography>
        <Typography variant="caption">{el.bankDetails.ifsc}</Typography>
      </Box>
      <Box height={1}>
        <IconButton
          size="small"
          children={<MoreVert />}
          sx={{ m: 0.5 }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setActiveView("edit");
              setAccountIndex(i);
            }}
          >
            <ListItemIcon>
              <EditOutlined />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setDeleteDialogOpen(true);
            }}
          >
            <ListItemIcon>
              <DeleteOutlined />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
          {!el?.isDefault && (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setPrimaryBankDialog(true);
              }}
            >
              <ListItemIcon>
                <SettingsOutlined />
              </ListItemIcon>
              <ListItemText>Set as primary</ListItemText>
            </MenuItem>
          )}
        </Menu>
        <DeleteDialog
          open={deleteDialogOpen}
          handleClose={() => setDeleteDialogOpen(false)}
          account={el}
          user={user}
        />
        <PrimaryBankDialog
          open={primaryBankDialog}
          handleClose={() => setPrimaryBankDialog(false)}
          account={el}
          user={user}
          accountsList={accountsList}
        />
      </Box>
    </Card>
  );
};

const PrimaryBankDialog = ({
  open,
  handleClose,
  account,
  user,
  accountsList,
}: any) => {
  function setPrimaryAccount(userId: any, data: any) {
    setLoader(true);
    authorizedFetch(`${PAYMENTS_URL}/v1/payments/paymentmeta/default/set`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        userId: userId._id,
        paymentMetaId: data._id,
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.status === 200) {
          snackbar.success(
            `${data.bankDetails.bankName} set as primary account`,
          );
          queryClient.resetQueries("getPaymentDetails");
        } else {
          snackbar.error(`Error${res.message ? ": " + res.message : ""}`);
        }
      })
      .catch((err) => {
        setLoader(false);
        snackbar.error("An error occurred");
        console.error(err);
      });
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Set Primary Account</DialogTitle>
      <DialogContent>
        <Typography my={1}>
          Are you sure you want to
          {accountsList === 1
            ? " set "
            : " change this user's default bank account to "}
          <b>
            {`${
              account.bankDetails.bankName
            } (•••• ${account.bankDetails.bankAccount.slice(-4)})`}
          </b>
          {accountsList.length === 1
            ? " as this user's default bank account"
            : ""}
          ?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => setPrimaryAccount(user, account)}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeleteDialog = ({ open, handleClose, account, user }: any) => {
  function handleDelete() {
    setLoader(true);
    authorizedFetch(`${PAYMENTS_URL}/v1/payments/paymentmeta/default/delete`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        paymentMetaId: account._id,
        userId: user?._id,
      },
    }).then((res) => {
      setLoader(false);
      if (res.status === 200) {
        snackbar.success("Account deleted");
        queryClient.resetQueries("getPaymentDetails");
        handleClose();
      } else {
        snackbar.error("Error deleting account");
      }
    });
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Delete Account</DialogTitle>
      <DialogContent>
        <Typography mb={1}>
          Are you sure you want to delete this account?
        </Typography>
        <Typography
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            rowGap: 0.5,
            columnGap: 2,
          }}
        >
          <span>Account No.</span>
          <b>{account?.bankDetails?.bankAccount}</b>
          <span>Bank Name</span>
          <b>{account?.bankDetails?.bankName}</b>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentDialog;
