import { Box, Paper, Typography, useTheme } from "@mui/material";
import InfoLabel from "components/InfoLabel";
import moment from "moment";
import { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";

function formatValue(value: any) {
  return parseFloat(value || 0).toLocaleString(undefined, {
    maximumFractionDigits: 2,
  });
}

const Gauge = ({
  vehicleSnapshot,
  vehicle,
  isTripsPage,
  vehicleLogs,
  activeTime,
  tripData,
}: any) => {
  const theme = useTheme();

  const [tripsPageSpeed, setTripsPageSpeed] = useState(0);

  let speed = 0,
    maxSpeed = 80;

  const config =
    vehicle?.config?.vehicleConfigValues ||
    vehicle?.model?.config?.configs ||
    {};

  let { wheelDiameter } =
    vehicle?.config?.vehicleConfigValues ||
    vehicle?.model?.config?.configs ||
    {};

  const hasWheelRpm = ![null, undefined].includes(
    vehicleLogs?.can
      ? vehicleLogs?.can[0]?.wheelRpm
      : vehicleLogs?.uart?.wheelRpm,
  );
  const hasWheelDiameter = ![null, undefined].includes(wheelDiameter);

  if (isTripsPage) {
    speed = tripsPageSpeed;
  } else {
    speed =
      vehicleLogs?.uart && vehicleLogs?.uart?.length !== 0
        ? vehicleLogs?.uart[0]?.vehicleSpeed
        : vehicleLogs?.can && vehicleLogs?.can?.length !== 0
          ? vehicleLogs?.can[0]?.vehicleSpeed
          : hasWheelDiameter && hasWheelRpm
            ? vehicleLogs.uart[0].wheelRpm *
              3.1514 *
              ((wheelDiameter * 0.0254 * 3.6) / 60)
            : vehicleLogs?.location[0]?.vehicleSpeed
              ? vehicleLogs?.location[0]?.vehicleSpeed
              : vehicleLogs?.location[0]?.gpsSpeed || 0;
  }

  if (speed < 0) speed = 0;

  maxSpeed = config?.maxSpeed;
  if (maxSpeed && speed >= maxSpeed) {
    speed = maxSpeed;
  }

  speed = Number(speed?.toFixed(0));

  useEffect(() => {
    if (!tripData) return;

    function findClosest(array: any[]) {
      return array?.length === 0
        ? null
        : array?.reduce((a: any, b: any) => {
            return Math.abs(moment(b.timestamp).valueOf() - activeTime) <
              Math.abs(moment(a.timestamp).valueOf() - activeTime)
              ? b
              : a;
          });
    }

    const gpsSpeed =
      tripData?.uart?.constructor === Array && tripData?.uart?.length !== 0
        ? findClosest(tripData?.uart)?.vehicleSpeed
        : tripData?.can && tripData?.can?.length !== 0
          ? findClosest(tripData?.can)?.vehicleSpeed
          : hasWheelDiameter && hasWheelRpm
            ? findClosest(tripData.uart)?.wheelRpm *
              3.1514 *
              ((wheelDiameter * 0.0254 * 3.6) / 60)
            : tripData?.location?.vehicleSpeed
              ? findClosest(tripData?.location)?.vehicleSpeed
              : findClosest(tripData?.location)?.gpsSpeed;

    setTripsPageSpeed(gpsSpeed);

    // eslint-disable-next-line
  }, [tripData, activeTime]);

  const stats = {
    odometer: vehicleSnapshot?.uart?.odometer
      ? (parseFloat(vehicleSnapshot.uart.odometer) / 1000).toFixed(2)
      : vehicle.metrics?.odometer
        ? formatValue(parseFloat(vehicle.metrics.odometer) / 1000)
        : "N/A",
  };

  return (
    <Paper sx={{ height: 400 }}>
      <Box
        sx={{
          my: 2,
          ml: 2,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
        }}
      >
        <Typography variant="h6" mr={2} mb={{ xs: 2.5, md: 0 }}>
          Speedometer
        </Typography>
        <InfoLabel value="km/h" />
      </Box>
      <Box maxWidth={300} mx="auto">
        <Box height={150} mt={1}>
          <GaugeChart
            nrOfLevels={15}
            percent={speed / (maxSpeed || 80)}
            animate={false}
            hideText
            arcPadding={0}
            cornerRadius={0}
            arcWidth={0.15}
            needleBaseColor={theme.palette.primary.main}
            needleColor={theme.palette.primary.main}
            arcsLength={[0.1, 0.2, 0.4, 0.1, 0.2]}
            colors={[
              "#01A229",
              theme.palette.primary.main,
              "#FFAB01",
              "#F4845D",
              "#EB5C5C",
            ]}
            style={{
              height: 150,
              width: "100%",
              margin: "28px auto 0",
            }}
          />
        </Box>
        <Box
          sx={{
            margin: "0 auto",
            width: 1,
            maxWidth: "65%",
            display: "flex",
            justifyContent: "space-between",
            transform: "translateY(-16px) translateX(4px)",
          }}
        >
          <Typography>0</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={700}>{speed || 0}</Typography>
            {/* <Typography fontSize={14}>km/h</Typography> */}
          </Box>
          <Typography>{maxSpeed || 80}</Typography>
        </Box>
        {/* <Box sx={{ mt: -1.5, textAlign: "center" }}>
          <Typography
            sx={{
              color: "text.secondary",
              lineHeight: "0.9em",
            }}
            variant="overline"
          >
            Speedometer
          </Typography>
        </Box> */}
        <Box
          sx={{
            width: 1,
            mt: 1.5,
            // bgcolor: (theme) => theme.customColors.header,
            // borderRadius: 1,
            p: 2.5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            "& .value": {
              fontSize: 24,
              fontWeight: 700,
              lineHeight: "1em",
              color: "text.primary",
              textAlign: "center",
              "& span": {
                // fontSize: "0.7em",
                // fontWeight: 400,
                // color: "text.primary",
              },
            },
            "& .greyed": {
              color: (theme) => theme.palette.text.disabled,
              userSelect: "none",
              textAlign: "center",
            },
          }}
        >
          <Typography
            mb={1}
            className={`value${stats.odometer === "N/A" ? " greyed" : ""}`}
          >
            {stats.odometer}
            {stats.odometer !== "N/A" && " km"}
            {/* {stats.odometer !== "N/A" && <span> km</span>} */}
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              lineHeight: "0.9em",
              textAlign: "center",
            }}
            variant="overline"
          >
            Odometer
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default Gauge;
