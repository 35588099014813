import MapComponent from "components/Map";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { authorizedFetch, validateLatLng } from "utils";
import { CMS_STATS_URL } from "utils/constants";

const OverviewMap = ({
  params,
  // isLoading,
  // data,
}: any) => {
  const { vehicleType, chargerType, accessType, city, masterView } = params;
  // const { masterView } = params;

  const { isLoading, data } = useQuery(
    [
      "GET cms-stats /geo/chargers",
      vehicleType,
      chargerType,
      accessType,
      city,
      masterView,
    ],
    () =>
      authorizedFetch(
        `${CMS_STATS_URL}/geo/chargers?${vehicleType}${chargerType}${accessType}${city}&master=${masterView}`,
      ),
  );

  const chargers = useMemo(() => {
    if (data?.data?.constructor === Array) {
      return data.data
        .filter((el: any) => {
          // let { latitude, longitude } = el?.station?.location || {};
          let [latitude, longitude] = el?.coordinates || [];
          return validateLatLng(latitude, longitude);
        })
        .map((el: any, i: number) => ({
          key: `charger-${i}`,
          charger: {
            // chargerId: el.id,
            chargerId: el.properties.id,
          },
          station: {
            location: {
              // latitude: el.station.location.latitude,
              // longitude: el.station.location.longitude,
              latitude: el.coordinates[0],
              longitude: el.coordinates[1],
            },
          },
        }));
    } else {
      return null;
    }
  }, [data]);

  return (
    <MapComponent
      key={JSON.stringify({ masterView, chargers })}
      loading={isLoading}
      type="points"
      dataArray={chargers || []}
    />
  );
};

export default OverviewMap;
