import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { authorizedFetch, drawer, GlobalState } from "utils";
import { BOLT_URL } from "utils/constants";
// import Cards from "./Cards";
import List from "./List";
import Highlights from "./Highlights";

const Users = () => {
  const { company, masterView } = useSelector(
    (state: GlobalState) => state.global,
  );
  const isCUGMonitorAccount = company?.type === "CUG_VENDOR";
  const [tab, setTab] = useState<"users" | "hosts" | "CUGs">("users");

  const [type, setType] = useState<"APP_USER" | "COMPANY_USER">("APP_USER");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("name");

  const first = pageSize;
  const skip = pageSize * (page - 1);

  const usersUrl = `${BOLT_URL}/company/users?type=APP_USER&first=${first}&skip=${skip}${
    search ? `&${searchBy}=` + search : ""
  }`;
  const { isLoading: usersLoading, data: usersData } = useQuery(
    ["getAppUsers", first, skip, search ? searchBy : null, search, masterView],
    () =>
      authorizedFetch(usersUrl, {
        headers: {
          master: masterView,
        },
      }),
    { enabled: !isCUGMonitorAccount },
  );
  const employeesUrl = `${BOLT_URL}/company/users?type=COMPANY_USER&first=${first}&skip=${skip}${
    search ? `&${searchBy}=` + search : ""
  }`;
  const { isLoading: employeesLoading, data: employeesData } = useQuery(
    ["getEmployees", first, skip, search ? searchBy : null, search, masterView],
    () =>
      authorizedFetch(employeesUrl, {
        headers: {
          master: masterView,
        },
      }),
    { enabled: !isCUGMonitorAccount },
  );

  useEffect(() => {
    // appBar.open(<AppBar />);

    return () => {
      // appBar.close();
      drawer.close();
    };
  }, []);

  useEffect(() => {
    setPage(1);
  }, [type, tab, search]);

  return (
    <Box
      sx={{
        px: { xs: 2, md: 4 },
        pt: { xs: 2, md: 4 },
        pb: { xs: 3, md: 6 },
      }}
    >
      <Highlights {...{ masterView }} />
      {/* <Cards
        {...{
          tab,
          appUsersLoading: usersLoading,
          appUsersData: usersData,
          employeesLoading,
          employeesData,
        }}
      /> */}
      <List
        {...{
          tab,
          setTab,
          usersLoading,
          usersData,
          employeesLoading,
          employeesData,
          type,
          setType,
          page,
          setPage,
          pageSize,
          setPageSize,
          search,
          setSearch,
          searchBy,
          setSearchBy,
          first,
          skip,
        }}
      />
    </Box>
  );
};

export default Users;
