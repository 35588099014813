import {
  EditOutlined,
  ErrorOutline,
  HighlightOff,
  QrCode2Outlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Step,
  StepButton,
  Stepper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { authorizedFetch, drawer, setLoader, snackbar, titleCase } from "utils";
import { BOLT_URL, HEALTH_URL, TICKETS_URL } from "utils/constants";
import QRDialog from "./QRDialog";

interface Props {
  open: boolean;
  handleClose: () => void;
  refetch: any;
  data: any;
}

const AddPricingDialog: React.FC<Props> = ({
  open,
  handleClose,
  refetch,
  data,
}) => {
  const [step, setStep] = useState(0);
  const steps = ["Status Info", "Finish"];
  const [note, setNote] = useState("");

  const [nextAction, setNextAction] = useState<string>("");
  const [nextActionNote, setNextActionNote] = useState<string>("");

  const [hideCharger, setHideCharger] = useState(true);
  const [unhideCharger, setUnhideCharger] = useState(true);

  const [status, setStatus] = useState("");

  const [remapData, setRemapData] = useState({ UID: "", phone: "" });

  const [tab, setTab] = useState(0);
  const [qrData, setQRData] = useState("");
  const [openQRDialog, setOpenQRDialog] = useState(false);

  const updateTicketURL = `${TICKETS_URL}/ticket/chargers/${data._id}/update`;

  const chargersAllUrl = `${BOLT_URL}/company/stats/all?`;

  const { data: allData } = useQuery(["getAllChargers"], () =>
    authorizedFetch(chargersAllUrl)
  );

  const nextMutation = useMutation(
    `nextActionTicket`,
    () =>
      authorizedFetch(updateTicketURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          status: nextAction,
          currentNote: nextActionNote,
        },
      }),
    {
      onSuccess: (data) => {
        if (data.msg === "Charger Ticket updated") {
          snackbar.success(`Ticket Updated to ${nextAction}`);
          refetch();
          drawer.close();

          setLoader(false);
        } else {
          snackbar.error(`Error updating ticket`);
        }
      },
    }
  );

  const chargerUrl = `${BOLT_URL}/company/charger/${data.UID}`;
  const {
    // isLoading: chargerLoading,
    data: chargerData,
    // refetch: refetchCharger,
  } = useQuery(["getChargerForRemap", data.UID], () =>
    authorizedFetch(chargerUrl)
  );

  const mutation = useMutation(
    `updatedChargerTicket`,
    () =>
      authorizedFetch(updateTicketURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          status: status,
          currentNote: note,
        },
      }),
    {
      onSuccess: (data) => {
        if (data.msg === "Charger Ticket updated") {
          snackbar.success(`Ticket Updated to ${status}`);
          refetch();
          drawer.close();
          setLoader(false);
        } else {
          snackbar.error(`Error updating ticket`);
        }
      },
    }
  );

  const chargerRemapURL = `${BOLT_URL}/restricted/changeowner`;

  const chargerRemapMutation = useMutation(
    `nextActionTicket`,
    () =>
      authorizedFetch(chargerRemapURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          chargerIds: [remapData?.UID],
          phone: remapData?.phone,
        },
      }),
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          snackbar.success(`Charger Remapped`);
          drawer.close();
          setLoader(false);
        } else {
          snackbar.error(`Error updating ticket`);
        }
      },
    }
  );

  const chargerVisbilityMutation = useMutation(
    "hideChargerFromMap",
    () =>
      authorizedFetch(`${HEALTH_URL}/product/toggle`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          productId: data?.UID,
          productType: "CHARGING_SOCKET",
          note:
            hideCharger && !unhideCharger
              ? `Charger hidden as it is sent to SCM for fault analysis. Ticket Id : ${data._id}}`
              : "Charger unhidden as it is fixed",
        },
      }),
    {
      onSuccess: (data) => {
        if (data.status === 200) {
          snackbar.success(
            `Charger ${
              hideCharger && !unhideCharger ? "hidden" : "unhidden"
            } from map`
          );
          drawer.close();

          setLoader(false);
        } else {
          snackbar.error(
            `Charger ${
              hideCharger && !unhideCharger ? "hiding" : "unhiding"
            } from map`
          );
        }
      },
    }
  );

  const handleSave = () => {
    setLoader(true);
    mutation.mutate();

    if (status === "CHARGER_REPLACED") {
      setTimeout(() => {
        chargerRemapMutation.mutate();
        setLoader(false);
        handleClose();
        drawer.close();
      }, 1500);
    }

    if (
      ["FIRMWARE_UPDATED", "CHARGER_FIXED", "CHARGER_REPLACED"].includes(status)
    ) {
      setTimeout(() => {
        nextMutation.mutate();
        setLoader(false);
        handleClose();
        drawer.close();
      }, 1500);
    } else {
      handleClose();
    }

    if (
      (status === "RCA_INITIATED" || nextAction === "RCA_INITIATED") &&
      hideCharger
    ) {
      setTimeout(() => {
        chargerVisbilityMutation.mutate();
        setLoader(false);
        handleClose();
        drawer.close();
      }, 1500);
    }

    if (
      data?.status === "RCA_INITIATED" &&
      status === "CHARGER_FIXED" &&
      unhideCharger
    ) {
      setTimeout(() => {
        chargerVisbilityMutation.mutate();
        drawer.close();

        setLoader(false);
        handleClose();
      }, 1500);
    }

    if (status === "CLOSED") {
      drawer.close();
      setLoader(false);
      handleClose();
    }
  };

  function handleNext() {
    if (step === steps.length - 1) {
      handleSave();
    } else setStep(step + 1);
  }
  function handleBack() {
    setStep(step - 1);
  }

  function isComplete(step: number) {
    switch (step) {
      case 0:
        return ![
          status,
          note,
          ...[status === "FIRMWARE_UPDATED" ? nextAction : []],
          ...[status === "CHARGER_REPLACED" ? remapData?.UID : []],
        ].includes("");

      default:
        break;
    }
  }

  const disabled = [
    status,
    note,
    ...[status === "FIRMWARE_UPDATED" ? nextAction : []],
    ...[status === "CHARGER_REPLACED" ? remapData?.UID : []],
  ].includes("");

  useEffect(() => {
    if (!open) {
      setStatus("");
      setStep(0);
      setNextAction("");
      setNote("");
      setNextActionNote("");
    }
  }, [open]);

  useEffect(() => {
    if (["CHARGER_REPLACED", "CHARGER_FIXED"].includes(status)) {
      setNextAction("CLOSED");
    }

    if (status === "RCA_INITIATED") {
      setHideCharger(true);
      setUnhideCharger(false);
    }
    if (status === "CHARGER_FIXED") {
      setHideCharger(false);
      setUnhideCharger(true);
    }
  }, [status, open]);

  useEffect(() => {
    if (nextAction === "CLOSED") {
      setNextActionNote("Ticket Closed");
    } else {
      setNextActionNote("RCA initiated on the charger");
    }
  }, [nextAction]);

  // const validatePhone = (phone: any) => {
  //   return /^[0-9]{10}$/.test(phone);
  // };

  useEffect(() => {
    if (chargerData?.data?.owner?.phone) {
      let number: string;
      if (chargerData?.data?.owner?.phone[0] === "+") {
        number = chargerData?.data?.owner?.phone.slice(3);
      } else {
        number = chargerData?.data?.owner?.phone;
      }
      setRemapData((prev) => ({
        ...prev,
        phone: number,
      }));
    }
  }, [chargerData]);

  useEffect(() => {
    if (tab === 0 && qrData !== "Charger UID not found") {
      setRemapData((prev) => ({
        ...prev,
        UID: qrData,
      }));
    }
  }, [tab, qrData]);

  const selectionMessage = (
    <Box
      sx={{
        width: "100%",
        height: 50,
        backgroundColor:
          status === "FIRMWARE_UPDATED" || status === "RCA_INITIATED"
            ? "#FFFF0030"
            : "#00800120",
        borderRadius: 1,
        mb: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography fontSize={12} fontWeight="medium" p={1}>
        {status === "FIRMWARE_UPDATED"
          ? "Please confirm the next action to be taken."
          : status === "RCA_INITIATED"
          ? "Charger will be sent to SCM for further testing."
          : status === "CHARGER_FIXED"
          ? "This Ticket will be Closed."
          : "Please Remap the Charger to a new UID. When done, this Ticket will be Closed."}
      </Typography>
    </Box>
  );

  return <>
    <QRDialog
      open={openQRDialog}
      handleClose={() => setOpenQRDialog(false)}
      setData={setQRData}
      data={qrData}
      chargers={allData?.data?.chargers || []}
    />
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiPaper-root": {
          margin: 2,
        },
      }}
      PaperProps={{
        sx: {
          maxWidth: 500,
          width: 1,
          p: 0,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 1,
            p: "3.5px 5px",
            m: 0,
          },
          "& .MuiPaper-root": {
            margin: 0,
          },
        },
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Box alignItems="center"> Update Ticket</Box>

          <IconButton
            children={<HighlightOff />}
            color="inherit"
            onClick={handleClose}
            sx={{ transform: "translate(8px, -8px)" }}
          />
        </Box>

        <Box
          display="flex"
          alignItems={"center"}
          justifyContent="center"
          mt={1}
          mb={0.5}
          width={1}
          gap={2}
        >
          <Typography
            fontSize={14}
            sx={{ opacity: 0.7 }}
            fontWeight="bold"
            mr={1}
          >
            {data?.UID}
          </Typography>
          <Avatar
            variant="status"
            className={
              ["CHARGER_FIXED", "CLOSED", "CHARGER_REPLACED"].includes(
                data?.status
              )
                ? "green"
                : data?.status === "SERVICE_INITIATED"
                ? "red"
                : data?.status === "FIRMWARE_UPDATED"
                ? "yellow"
                : data?.status === "RCA_INITIATED"
                ? "orange"
                : "grey"
            }
          >
            {data?.status
              ? data?.status.split("_").map(titleCase).join(" ")
              : "N/A"}
          </Avatar>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pb: "16px !important" }}>
        <Stepper
          sx={{ my: 2, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          nonLinear
          alternativeLabel
        >
          {steps.map((label: any, i: any) => (
            <Step key={i}>
              <StepButton>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {step === 0 && (
          <>
            {[
              "FIRMWARE_UPDATED",
              "CHARGER_FIXED",
              "CHARGER_REPLACED",
              "RCA_INITIATED",
            ].includes(status) && selectionMessage}
            <Box
              sx={{
                mt: 1,
                maxWidth: { xs: 280, sm: 560 },
                mx: "auto",
              }}
            >
              <Typography sx={{ fontSize: "0.875rem" }}>
                All fields with ( * ) are required
              </Typography>
            </Box>

            <Box
              sx={{
                maxWidth: { xs: 280, sm: 560 },
                mx: "auto",
                py: 2,
                display: "grid",
                gridTemplateColumns: {
                  xs: "1fr",
                  sm: "1fr 1fr",
                },
                gap: 2,
                "& .required": {
                  color: "red",
                },
              }}
            >
              <Box gridColumn={{ sm: "span 2" }}>
                <Typography className="label">
                  Select Status <span className="required">&#x2a;</span>
                </Typography>
                <Select
                  size="medium"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  fullWidth
                  placeholder="Select"
                  displayEmpty
                  renderValue={
                    status !== "" ? undefined : () => <em>Select</em>
                  }
                >
                  {[
                    ...(["SERVICE_INITIATED"].includes(data?.status)
                      ? ["FIRMWARE_UPDATED"]
                      : []),
                    ...(["FIRMWARE_UPDATED"].includes(data?.status)
                      ? ["RCA_INITIATED"]
                      : []),
                    ...(["SERVICE_INITIATED", "FIRMWARE_UPDATED"].includes(
                      data?.status
                    )
                      ? []
                      : ["CHARGER_FIXED"]),
                    ...(["SERVICE_INITIATED", "FIRMWARE_UPDATED"].includes(
                      data?.status
                    )
                      ? []
                      : ["CHARGER_REPLACED"]),
                    "CLOSED",
                  ].map((el: any, i: number) => (
                    <MenuItem key={i} value={el}>
                      {el === "CLOSED"
                        ? "Close Ticket"
                        : el.split("_").map(titleCase).join(" ")}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              {status === "FIRMWARE_UPDATED" && (
                <Box gridColumn={{ sm: "span 2" }}>
                  <Typography className="label">
                    Next Action
                    <span className="required">&#x2a;</span>
                  </Typography>
                  <Select
                    size="medium"
                    value={nextAction}
                    onChange={(e) => setNextAction(e.target.value)}
                    fullWidth
                    placeholder="Select"
                    displayEmpty
                    renderValue={
                      nextAction !== "" ? undefined : () => <em>Select</em>
                    }
                  >
                    {["RCA_INITIATED", "CLOSED"].map((el: any, i: number) => (
                      <MenuItem key={i} value={el}>
                        {el === "CLOSED"
                          ? "Close Ticket"
                          : el.split("_").map(titleCase).join(" ")}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              )}

              {(status === "RCA_INITIATED" ||
                nextAction === "RCA_INITIATED") && (
                <Box gridColumn={{ sm: "span 2" }}>
                  <FormControlLabel
                    label="Hide Charger from Map"
                    onClick={() => {
                      setHideCharger(!hideCharger);
                      setUnhideCharger(false);
                    }}
                    control={<Checkbox defaultChecked />}
                  />
                </Box>
              )}

              {data?.status === "RCA_INITIATED" &&
                status === "CHARGER_FIXED" && (
                  <Box gridColumn={{ sm: "span 2" }}>
                    <FormControlLabel
                      label="Unhide Charger from Map"
                      onClick={() => {
                        setUnhideCharger(!unhideCharger);
                        setHideCharger(false);
                      }}
                      control={<Checkbox defaultChecked />}
                    />
                  </Box>
                )}

              {status === "CHARGER_REPLACED" && (
                <Box gridColumn={{ sm: "span 2" }} mt={2}>
                  <Typography className="label">
                    Enter Replacement Charger UID{" "}
                    <span className="required">&#x2a;</span>
                  </Typography>
                  <Box>
                    <Tabs
                      className="less-dense"
                      value={tab}
                      onChange={(e, tab) => setTab(tab)}
                    >
                      <Tab
                        label="Scan"
                        sx={{ width: "50%" }}
                        // disabled={
                        //   selectedCharger !== "" || selectedCharger !== null
                        // }
                      />
                      <Tab
                        label="Type"
                        sx={{ width: "50%" }}
                        // disabled={qrData !== ""}
                      />
                    </Tabs>
                  </Box>
                  {tab === 0 && (
                    <Box gridColumn={{ sm: "span 2" }} my={4}>
                      {/* <Typography className="label">
                    Scan a Charger <span className="required">&#x2a;</span>
                  </Typography> */}
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {qrData === "" ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              sx={{
                                // ml: 2,
                                px: 0.5,
                                minWidth: 0,
                                width: 100,
                                height: 100,
                              }}
                              variant="outlined"
                              onClick={() => {
                                setQRData("");
                                setOpenQRDialog(true);
                              }}
                            >
                              <QrCode2Outlined
                                sx={{
                                  fontSize: 50,
                                }}
                              />
                            </Button>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                fontSize: 13,
                                mt: 1,
                                opacity: 0.7,
                              }}
                            >
                              Click to Scan
                            </Typography>
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              width: "100%",
                              height: 50,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              border: "1px solid #00000020",
                              borderRadius: 2,
                            }}
                          >
                            <Typography fontWeight="bold" ml={4}>
                              {qrData}
                            </Typography>
                            <IconButton
                              children={<HighlightOff />}
                              color="inherit"
                              onClick={() => setQRData("")}
                              sx={{
                                mr: 4,
                                opacity: 0.5,
                              }}
                            />
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}

                  {tab === 1 && (
                    <Box gridColumn={{ sm: "span 2" }} my={6}>
                      {/* <Typography className="label">
                        Enter Replacement Charger UID{" "}
                        <span className="required">&#x2a;</span>
                      </Typography> */}
                      <TextField
                        fullWidth
                        size="small"
                        placeholder="ABC000"
                        value={remapData.UID}
                        onChange={(e: any) => {
                          let value = e.target.value.toUpperCase();
                          if (/^([A-Z0-9]){0,8}$/.test(value))
                            setRemapData({
                              ...remapData,
                              UID: value,
                            });
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              sx={{
                                ml: 1,
                                mr: 0.25,
                                "& > .MuiTypography-root": {
                                  fontSize: 14,
                                },
                              }}
                            >
                              BOLT_
                            </InputAdornment>
                          ),
                        }}
                        error={
                          !!remapData.UID &&
                          !/^([A-Z0-9]){5,8}$/.test(remapData.UID)
                        }
                      />
                    </Box>
                  )}

                  <Box gridColumn={{ sm: "span 2" }}>
                    <Typography className="label">Owner Phone</Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Owner Phone"
                      value={remapData.phone}
                      onChange={(e: any) => {
                        if (/^[0-9]{0,10}$/.test(e.target.value))
                          setRemapData({
                            ...remapData,
                            phone: e.target.value,
                          });
                      }}
                      // error={!!remapData && !validatePhone(remapData)}
                      // helperText={
                      //   !!remapData && !validatePhone(remapData)
                      //     ? "Incomplete phone number"
                      //     : undefined
                      // }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{
                              "& > .MuiTypography-root": {
                                ml: 0.5,
                                fontSize: "14px !important",
                              },
                            }}
                          >
                            +91
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              )}

              <Box mt={3} gridColumn={{ sm: "span 2" }}>
                <Typography className="label">
                  Add Note <span className="required">&#x2a;</span>
                </Typography>
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  multiline
                  rows={8}
                  placeholder="Write a note on the update..."
                  value={note}
                  onChange={(e: any) => {
                    if (e.target.value.length <= 250) setNote(e.target.value);
                  }}
                  sx={{
                    "& .MuiInputBase-root": { p: 1.5 },
                  }}
                />
              </Box>
            </Box>
          </>
        )}

        {step === 1 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    bgcolor: (theme) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {[
                  {
                    header: "Ticket Info",
                    onEdit: () => setStep(0),
                  },
                  {
                    label: "Status",
                    value: ["CHARGER_FIXED", "CHARGER_REPLACED"].includes(
                      status
                    ) ? (
                      <>
                        <p>
                          {status.split("_").map(titleCase).join(" ")}
                          <br />
                          (Ticket will be Closed)
                        </p>
                      </>
                    ) : status === "CLOSED" ? (
                      "Close Ticket"
                    ) : (
                      status.split("_").map(titleCase).join(" ")
                    ),
                    required: true,
                  },
                  ...[
                    status === "FIRMWARE_UPDATED"
                      ? {
                          label: "Next Action",
                          value: nextAction
                            .split("_")
                            .map(titleCase)
                            .join(" "),
                          required: true,
                        }
                      : {},
                  ],
                  ...[
                    status === "CHARGER_REPLACED"
                      ? {
                          label: "Remaped Charger UID",
                          value: remapData.UID,
                          required: true,
                        }
                      : {},
                  ],
                  ...[
                    status === "CHARGER_REPLACED"
                      ? {
                          label: "Owner Phone Number",
                          value: remapData.phone,
                          required: true,
                        }
                      : {},
                  ],

                  {
                    label: "Note",
                    value: note,
                    required: true,
                  },
                ].map(
                  ({ header, onEdit, label, value, required }, i, arr) => {
                    const isFirst = arr[i - 1]?.header;
                    const isLast = !arr[i + 1] || arr[i + 1].header;

                    return (
                      <tr
                        key={i}
                        className={
                          header
                            ? "header"
                            : `${isFirst ? "first" : ""} ${
                                isLast ? "last" : ""
                              }`
                        }
                      >
                        {header ? (
                          <td colSpan={2}>
                            <span>{header}</span>
                            <IconButton
                              sx={{ ml: 1.5 }}
                              children={<EditOutlined />}
                              color="primary"
                              size="small"
                              onClick={onEdit}
                            />
                          </td>
                        ) : (
                          <>
                            <td>{label}</td>
                            <td
                              className="bold"
                              style={{
                                overflowWrap: "break-word",
                                maxWidth: 100,
                              }}
                            >
                              {value ||
                                (required && (
                                  <Box display="flex" alignItems="center">
                                    <ErrorOutline
                                      fontSize="small"
                                      color="error"
                                      style={{
                                        marginRight: 8,
                                      }}
                                    />
                                    Required
                                  </Box>
                                ))}
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {step !== 0 && (
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        )}
        {step === 0 && (
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        )}
        <Button
          onClick={handleNext}
          variant={
            isComplete(step) || step === steps.length - 1
              ? "contained"
              : "outlined"
          }
          disableElevation
          disabled={
            // step === steps.length - 1
            // &&
            disabled
          }
        >
          {step === steps.length - 1 ? "Save" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  </>;
};

export default AddPricingDialog;
