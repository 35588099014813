import { HighlightOff } from "@mui/icons-material";
import { Box, Divider, IconButton } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  authorizedFetch,
  drawer,
  getDarkModePreference,
  GlobalState,
} from "utils";
import { RETAIL_URL } from "utils/constants";
import VehicleMap from "views/Common/VehicleView/VehicleMonitoring/VehicleMap";

const DrawerContent = ({ trip }: any) => {
  // const { company, token } = useSelector((state: GlobalState) => state.global);

  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  const [activeTime, setActiveTime] = useState<any>();
  const [vehicleSnapshot, setVehicleSnapshot] = useState({});

  // const url = `${DATAFEED_URL}/tripV2?token=${token}&id=${company.id}&tripid=${trip?.tripId}&vin=${trip?.vin}`;
  // const { isLoading, data } = useQuery(
  //   ["getTrip", trip?.tripId, trip?.vin],
  //   () => authorizedFetch(url),
  //   { enabled: !!trip?.tripId && !!trip?.vin }
  // );

  const vmsTripUrl = `${RETAIL_URL}/trips/${trip?.tripId}?vin=${trip?.vin}`;
  const { isLoading: vmsTripLoading, data: vmsTripData } = useQuery(
    ["getVMSTrip", trip?.tripId, trip?.vin],
    () => authorizedFetch(vmsTripUrl)
  );

  const table = [
    { header: "Trip Info" },
    { label: "VIN", value: trip?.vin },
    { label: "Trip ID", value: trip?.tripId },
    {
      label: "Distance Travelled",
      value: `${
        typeof trip?.distance === "number"
          ? (trip.distance / 1000).toFixed(2)
          : 0
      } km`,
    },
    {
      label: "Driver Score",
      value: trip?.driverScore || "N/A",
    },
    { header: "Trip History" },
  ];

  return (
    <Box>
      <Box
        sx={{
          position: "sticky",
          zIndex: 2,
          top: 0,
          px: 3,
          py: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
          fontWeight: 500,
          color: "#fff",
        }}
      >
        <Box display="flex" alignItems="center">
          {trip?.tripId}
          <Divider flexItem orientation="vertical" sx={{ mx: 1 }} />
          {trip?.vin}
        </Box>

        <Box display="grid" gridTemplateColumns="repeat(1, auto)" gap={1}>
          <IconButton
            children={<HighlightOff />}
            color="inherit"
            size="small"
            onClick={() => drawer.close()}
          />
        </Box>
      </Box>
      <Box
        sx={{
          px: 3,
          pt: 2.5,
          "& .table": {
            borderCollapse: "collapse",
            width: 1,
            fontSize: 14,
            lineHeight: "16px",
            "& td": {
              py: 1,
              px: 2,
            },
            "& .bold": {
              fontWeight: 500,
            },
            "& .header": {
              px: 2,
              py: 1,
              position: "relative",
              "& td": {
                position: "absolute",
                verticalAlign: "middle",
                backgroundColor: (theme) => theme.customColors.header,
                width: 1,
                py: 2,
                borderRadius: "4px",
                fontSize: 16,
                fontWeight: 600,
                "& span": {
                  display: "inline-block",
                  transform: "translateY(1px)",
                },
              },
            },
            "& .first > td": {
              pt: 8.5,
            },
            "& .last > td": {
              pb: 3,
            },
          },
        }}
      >
        <table className="table">
          <tbody>
            {table.map(({ header, label, value }: any, i) => {
              const isFirst = table[i - 1]?.header;
              const isLast = !table[i + 1] || table[i + 1].header;

              return (
                <tr
                  key={i}
                  className={
                    header
                      ? "header"
                      : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                  }
                >
                  {header ? (
                    <td colSpan={2}>
                      <span>{header}</span>
                    </td>
                  ) : (
                    <>
                      <td className="bold">{label}</td>
                      <td>{value}</td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
      <Box mt={8.5} p={3} pt={0}>
        <Box width={1} height={400}>
          <VehicleMap
            key={trip?._id}
            loading={vmsTripLoading}
            trip={trip}
            tripData={vmsTripData?.data[0]}
            vehicleSnapshot={vehicleSnapshot}
            setVehicleSnapshot={setVehicleSnapshot}
            activeTime={activeTime}
            setActiveTime={setActiveTime}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DrawerContent;
