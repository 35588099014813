import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { queryClient } from "index";
import { useMutation } from "react-query";

import { snackbar, setLoader, authorizedFetch } from "utils";
import { LEASE_URL } from "utils/constants";

const UnAssignVehicles = ({ open, handleClose, hub, selected }: any) => {
  console.log("selected", selected);
  const mutation = useMutation(
    ["unassignVehicles", hub?._id],
    () =>
      authorizedFetch(`${LEASE_URL}/company/hubs/${hub?._id}/unassign`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          vehicles: selected,
        },
      }),
    {
      onSuccess: (res) => {
        setLoader(false);
        if (res.message.includes("Vehicle(s) unassigned from Hub")) {
          snackbar.success(res.message);
          queryClient.resetQueries("getAllHubs");
          queryClient.resetQueries("getHubVehicles");
          queryClient.resetQueries("getUnassignedHubVehicles");
          handleClose();
        } else {
          console.error(res);
          snackbar.error(res.msg);
        }
        handleClose();
      },
      onError: () => {
        setLoader(false);
        snackbar.error(`Error unassigning vehicles`);
      },
    }
  );
  function confirm() {
    setLoader(true);
    mutation.mutate();
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Unassign Vehicles</DialogTitle>
      <DialogContent className="py-1">
        Unassign selected vehicles?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={confirm}
        >
          Unassign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnAssignVehicles;
