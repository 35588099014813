import {
  DeleteOutline,
  EditOutlined,
  HighlightOff,
  InfoOutlined,
  OpenInNew,
  SearchOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepButton,
  Stepper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  Avatar,
} from "@mui/material";
import TableComponent from "components/Table";
import { queryClient } from "index";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  GlobalState,
  authorizedFetch,
  setLoader,
  snackbar,
  titleCase,
} from "utils";
import { BOLT_URL } from "utils/constants";
import CreateBusiness from "./CreateBusinessDialog";
import CircularLoader from "components/CircularLoader";

interface CUGDialogProps {
  open: boolean;
  handleClose: () => void;
  isEditMode?: boolean;
  data?: any;
}

type Input = {
  [key: string]: any | { [key: string]: any };
};

const inputObj: Input = {
  name: "",
  description: "",
  usageType: "", // BUSINESS | SELF
  business: {},
  assignChargers: "",
  chargers: [],
  userSearchType: "Name", // name | phone | email
  users: [],
  pricingName: "",
  pricingMode: "", // FREE | DISCOUNT
  discountType: "", // PERCENT | DISCOUNT
  pricingAmount: "", // positive_number
};

const CUGDialog: React.FC<CUGDialogProps> = ({
  open,
  handleClose,
  isEditMode = false,
  data: group,
}) => {
  const { company } = useSelector((state: GlobalState) => state.global);

  const userGroupUrl = `${BOLT_URL}/company/v2/usergroup/data/${group?._id}`;
  const { isLoading: userGroupLoading, data: userGroupData } = useQuery(
    ["getUserGroup", group?._id],
    () => authorizedFetch(userGroupUrl),
    {
      enabled: isEditMode && !!group?._id,
    }
  );

  // ******* LABEL'S *******
  const steps = [
    "Details & Usage",
    "Chargers",
    "Users",
    ...(!isEditMode ? ["Pricing"] : []),
    "Confirmation",
    "Payment",
  ];

  // ******* INPUT_STATE *******
  const [step, setStep] = useState<number>(0);
  const [input, setInput] = useState({ ...inputObj });
  const [createBusiness, setCreateBusiness] = useState({ open: false });
  const [error, setError] = useState<any>([]);
  const [tab, setTab] = useState(0);

  // ******* CHARGER AUTOCOMPLETE INPUT_STATE'S *******
  const [chargerValue, setChargerValue] = useState<{
    uid: string;
  } | null>(null);
  const [searchCharger, setSearchCharger] = useState("");
  const [chargerList, setChargerList] = useState([]);

  // ******* USER AUTOCOMPLETE INPUT_STATE'S *******
  const [userValue, setUserValue] = useState<{
    id: string;
    name: string;
    phone: string;
    email: string;
  } | null>(null);
  const [userList, setUserList] = useState([]);
  const [searchUser, setSearchUser] = useState("");

  const [createdUserGroupID, setCreatedUserGroupID] = useState("");

  // ******* DE-STRUCTURING INPUT_STATE *******
  const {
    name,
    description,
    usageType, // BUSINESS | SELF
    business,
    assignChargers,
    chargers,
    userSearchType, // name | phone | email
    users,
    pricingName,
    pricingMode, // FREE | DISCOUNT
    discountType, // PERCENT | DISCOUNT
    pricingAmount,
  } = input;

  // ******* API CALL'S *******
  // ******* FETCHING ALL VENDOR/BUSINESS DATA *******
  const businessUrl = `${BOLT_URL}/company/v2/usergroup/vendors`;
  const {
    isLoading: businessLoading,
    data: businessData,
    refetch,
  } = useQuery(
    ["getBusiness"], 
    () => authorizedFetch(businessUrl),
    { enabled: open }
  );

  // ******* FETCHING ALL CHARGER'S DATA *******
  const chargersParams = "&usageType=PUBLIC_PAID PUBLIC_FREE&hidden=false";
  const chargersUrl = `${BOLT_URL}/company/chargers?chargerId=${searchCharger}${chargersParams}`;
  const { isLoading: chargerLoading, data: chargerData } = useQuery(
    ["getChargers", searchCharger, chargersParams],
    () => authorizedFetch(chargersUrl),
    { enabled: open }
  );

  // ******* FETCHING ALL USER'S DATA *******
  const userUrl = `${BOLT_URL}/company/users?search=${searchUser}&type=APP_USER`;
  const { isLoading: userLoading, data: userData } = useQuery(
    ["getAppUsers", searchUser],
    () => authorizedFetch(userUrl),
    { enabled: open }
  );

  // ******* FETCHING CUG ESTIMATED AMOUNT *******
  const cugEstimateUrl = `${BOLT_URL}/company/v2/usergroup/estimate`;
  const { data: cugEstimateData } = useQuery(
    [
      "getCUGEstimate",
      input.users.length,
      input.pricingMode,
      input.discountType,
      input.pricingAmount,
    ],
    () =>
      authorizedFetch(cugEstimateUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          numberOfUsers:
            isEditMode ? Number(input.users.length > userGroupData.data.users.length
              ? input.users.filter(
                (el: any) =>
                  userGroupData.data.users.find(
                    (existingUser: any) => existingUser._id === el._id
                  ) === undefined
              ).map((el: any) => ({
                id: el._id,
                firstName: el.firstName,
                lastName: el.lastName,
                phone: el.phone,
                email: el.email,
              })).length
              : 0)
              : input.users.length,
          discountType: pricingMode === "FREE" ? "PERCENT" : input.discountType,
          discountValue:
            pricingMode === "FREE" ? 100 : parseFloat(input.pricingAmount),
        },
      }),
    {
      enabled:
        open &&
        (pricingMode === "FREE" ||
        (!!input.users.length && !!input.discountType && !!input.pricingAmount)),
    }
  );

  // ******* SIDE EFFECT'S *******
  useEffect(() => {
    if (!open) return;
    if (isEditMode && !!group && !!userGroupData?.data)
      setInput({
        ...inputObj,
        name: group.name,
        description: group.description,
        usageType: group.type,
        business: group.type === "SELF" ? ({}) : ({ _id: userGroupData.data.company[0]._id }),
        assignChargers: userGroupData?.data?.allChargersSelected
          ? "ALL"
          : "SEARCH",
        chargers: !userGroupData?.data?.allChargersSelected
          ? (userGroupData.data.chargers?.constructor === Array
            ? userGroupData.data.chargers
            : []
          ).map((el: any, i: number) => ({
            _id: i,
            uid: el,
          }))
          : [],
        users:
          userGroupData.data.users?.constructor === Array
            ? userGroupData.data.users
            : [],
        pricingMode: userGroupData.data.pricingDetails.pricing.type,
        discountType: userGroupData.data.pricingDetails.pricing.discountType,
        pricingAmount: userGroupData.data.pricingDetails.pricing.value,
      });
    else setInput({ ...inputObj });

    setStep(0);
    setError([]);
  }, [open, isEditMode, group, userGroupData]);

  // ******* LISTING CHARGER'S BASED ON UID *******
  useEffect(() => {
    if (chargerData?.data?.chargers?.constructor === Array) {
      const list: any = [];
      chargerData?.data?.chargers.map((el: any) =>
        el.charger.chargerId
          ? list.push({
            _id: el.charger._id,
            label: el.charger.chargerId,
          })
          : ""
      );
      setChargerList(list);
    }
  }, [chargerData]);

  // ******* LISTING USER'S BASED ON NAME/PHONE/EMAIL *******
  useEffect(() => {
    if (userData?.data?.users?.constructor === Array) {
      const list: any = [];
      if (userSearchType === "Name") {
        userData?.data?.users.map((el: any) =>
          list.push({
            _id: el.user._id,
            label: `${el?.user?.firstName === undefined ? "" : el.user.firstName
              }${el?.user?.lastName === undefined ? "" : ` ${el.user.lastName}`}`,
            firstName:
              el?.user?.firstName === undefined ? "" : el.user.firstName,
            lastName: el?.user?.lastName === undefined ? "" : el.user.lastName,
            phone:
              el.user.phone === undefined
                ? ""
                : el.user.phone.length > 10
                  ? `${el.user.phone.slice(0, 3)} - ${el.user.phone.slice(3)}`
                  : el.user.phone,
            email:
              el?.user?.email === undefined || el?.user?.email === ""
                ? ""
                : el.user.email,
          })
        );
      } else if (userSearchType === "Phone") {
        userData?.data?.users.map((el: any) =>
          list.push({
            _id: el.user._id,
            firstName:
              el?.user?.firstName === undefined ? "" : el.user.firstName,
            lastName: el?.user?.lastName === undefined ? "" : el.user.lastName,
            label:
              el.user.phone.length > 10
                ? `${el.user.phone.slice(0, 3)} - ${el.user.phone.slice(3)}`
                : el.user.phone,
            email:
              el?.user?.email === undefined || el?.user?.email === ""
                ? ""
                : el.user.email,
          })
        );
      } else if (userSearchType === "Email") {
        userData?.data?.users.map((el: any) =>
          el?.user?.email === undefined || el?.user?.email === ""
            ? ""
            : list.push({
              _id: el.user._id,
              firstName:
                el?.user?.firstName === undefined ? "" : el.user.firstName,
              lastName:
                el?.user?.lastName === undefined ? "" : el.user.lastName,
              phone:
                el.user.phone === undefined || el?.user?.phone === ""
                  ? ""
                  : el.user.phone,
              label: el.user.email,
            })
        );
      }
      setUserList(list);
    }
  }, [userData, userSearchType]);

  // ******* DAILOG CONTENT FUNCTION'S *******
  function handleChange(key: string, value: any) {
    setInput((prevInput) => ({ ...prevInput, [key]: value }));
  }

  // ******* ADDING SELECTED ITEMS TO USERS/CHARGERS ARRAY *******
  function addItems(type: string, item: any) {
    if (type === "charger" && item !== null) {
      if (
        chargers.find(
          (el: any) => el._id === item._id || el.uid === item.label
        ) === undefined
      ) {
        let format = {
          _id: item._id,
          uid: item.label,
        };
        setInput({ ...input, chargers: [...chargers, format] });
        // snackbar.success("Charger added");
      } else snackbar.error("Charger already added");
    } else if (type === "user" && item !== null) {
      if (
        users.find(
          (el: any) =>
            el._id === item._id ||
            el.phone ===
            (userSearchType === "Phone"
              ? item.label.split(" - ").join("")
              : item.phone.split(" - ").join(""))
        ) === undefined
      ) {
        let format;
        if (userSearchType === "Name") {
          format = {
            _id: item._id,
            firstName: item.firstName,
            lastName: item.lastName,
            phone: item.phone.split(" - ").join(""),
            email: item.email,
          };
        } else if (userSearchType === "Phone") {
          format = {
            _id: item._id,
            firstName: item.firstName,
            lastName: item.lastName,
            phone: item.label.split(" - ").join(""),
            email: item.email,
          };
        } else if (userSearchType === "Email") {
          format = {
            _id: item._id,
            firstName: item.firstName,
            lastName: item.lastName,
            phone: item.phone.split(" - ").join(""),
            email: item.label,
          };
        }
        setInput({ ...input, users: [...users, format] });
        // snackbar.success("User added");
      } else snackbar.error("User already added");
    }
  }

  function errorConflict(data: any) {
    let newArray = [];

    for (const conflict of data) {
      for (const user of conflict.users) {
        newArray.push({
          _id: isEditMode ? user._id : user.id,
          name: `${user.firstName}${user.lastName !== "" ? ` ${user.lastName}` : ""
            }`,
          group: isEditMode ? conflict.profile : conflict.name,
          status: "ACTIVE",
        });
      }
    }

    return newArray;
  }

  // ******* DAILOG ACTION FUNCTION'S *******
  function handleNext() {
    if (!isEditMode && step === 2) {
      setLoader(true);
      authorizedFetch(`${BOLT_URL}/company/v2/usergroup/validation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          selectedAllChargers: input.assignChargers === "ALL" ? true : false,
          users: input.users.map((el: any) => ({
            id: el._id,
            firstName: el.firstName,
            lastName: el.lastName,
          })),
          chargers:
            input.assignChargers === "ALL"
              ? []
              : input.chargers.map((el: any) => ({ uid: el.uid })),
        },
      })
        .then((res) => {
          setLoader(false);
          if (res.status === 200) {
            snackbar.success("User-charger pair validation successful");
            setStep(step + 1);
          } else {
            snackbar.error(res.message);
            setError(errorConflict(res?.error?.profiles?.userCharger?.data));
            setTab(1);
          }
        })
        .catch((err) => {
          snackbar.error("An error occurred");
          setLoader(false);
          console.log(err);
        });
    } else if (isEditMode ? step === 3 : step === 4) {
      setLoader(true);

      if (isEditMode) {
        const existingUsers = userGroupData.data.users || [];
        const insertedUsers = input.users.filter(
          (el: any) =>
            existingUsers.find(
              (existingUser: any) => existingUser._id === el._id
            ) === undefined
        ).map((el: any) => ({
          id: el._id,
          firstName: el.firstName,
          lastName: el.lastName,
          phone: el.phone,
          email: el.email,
        }));
        const deletedUsers = existingUsers.filter(
          (existingUser: any) =>
            input.users.find((user: any) => user._id === existingUser._id) ===
            undefined
        ).map((el: any) => ({
          id: el._id,
          firstName: el.firstName,
          lastName: el.lastName,
          phone: el.phone,
          email: el.email,
        }));

        const existingChargers = userGroupData.data.chargers || [];
        const insertedChargers = input.chargers
          .filter(
            (el: any) =>
              existingChargers.find(
                (existingCharger: any) => existingCharger === el.uid
              ) === undefined
          )
          .map((el: any) => ({ uid: el.uid }));
        const deletedChargers = existingChargers
          .filter(
            (existingCharger: any) =>
              input.chargers.find(
                (charger: any) => charger.uid === existingCharger
              ) === undefined
          )
          .map((el: any) => ({ uid: el }));

        authorizedFetch(`${BOLT_URL}/company/v2/usergroup/${group._id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            userGroupName: input.name,
            description: input.description,
            users: {
              INSERT: insertedUsers,
              DELETE: deletedUsers,
            },
            chargers: {
              INSERT: insertedChargers,
              DELETE: deletedChargers,
            },
          },
        }).then((res) => {
          setLoader(false);
          if (res.status === 200) {
            if (res.error.hasOwnProperty('profiles')) {
              snackbar.error("User-charger pair validation conflict");
              setError(errorConflict(res?.error?.profiles?.newUsers?.data));
              setTab(1);
              setStep(2);
            } else {
              snackbar.success("User group updated");
              queryClient.resetQueries("getClosedUserGroups");
              setCreatedUserGroupID(userGroupData.data._id);
              setStep(step + 1);
            }
          } else {
            snackbar.error(res.message || "An error occurred");
          }
        });
      } else {
        authorizedFetch(`${BOLT_URL}/company/v2/usergroup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            userGroupName: input.name,
            description: input.description,
            groupType: input.usageType,
            assignedTo: [
              input.usageType === "SELF" ? company?.id : input.business._id,
            ],
            status: "INACTIVE",
            selectedAllChargers: input.assignChargers === "ALL" ? true : false,
            chargers: input.assignChargers === "ALL" ? [] : input.chargers,
            users: input.users.map((el: any) => ({
              id: el._id,
              firstName: el.firstName,
              lastName: el.lastName,
            })),
            pricingDetails: {
              label: input.pricingName,
              pricing: {
                type: input.pricingMode,
                discountType:
                  input.pricingMode === "FREE" ? "PERCENT" : input.discountType,
                value:
                  input.pricingMode === "FREE" ? "100" : input.pricingAmount,
              },
            },
            currentCycleBalance: 0,
            currentCycleConsumption: 0,
          },
        })
          .then((res) => {
            setLoader(false);
            if (res.status === 200) {
              snackbar.success(`User group created`);
              queryClient.resetQueries("getClosedUserGroups");
              setCreatedUserGroupID(res.data._id);
              setStep(step + 1);
            } else {
              snackbar.error(res.message || "An error occurred");
            }
          })
          .catch((err) => {
            snackbar.error("An error occurred");
            setLoader(false);
            console.log(err);
          });
      }
    } else setStep(step + 1);
  }
  function handleBack() {
    setStep(step - 1);
  }

  // ******* STEP'S & NEXT/CONFIRM FUNCTIONALITY *******
  function isComplete(step: number) {
    if (isEditMode)
      switch (step) {
        case 0:
          return ![name, description].includes("");
        case 1:
          return (
            !!assignChargers &&
            (assignChargers === "ALL" ? true : chargers.length > 0)
          );
        case 2:
          return users.length > 0;
        default:
          return false;
      }
    else
      switch (step) {
        case 0:
          return (
            ![name, description, usageType].includes("") &&
            (usageType === "BUSINESS"
              ? Object.keys(business).length === 0 &&
                business.constructor === Object
                ? false
                : true
              : true)
          );
        case 1:
          return (
            !!assignChargers &&
            (assignChargers === "ALL" ? true : chargers.length > 0)
          );
        case 2:
          return users.length > 0;
        case 3:
          return (
            ![pricingName].includes("") &&
            (pricingMode === "FREE"
              ? true
              : discountType === "PERCENT"
                ? ![pricingAmount].includes("")
                : ![pricingAmount].includes(""))
          );
        default:
          return false;
      }
  }

  function isDisabled(step: number) {
    switch (step) {
      case 0:
        return (
          [name, description, usageType].includes("") ||
          (usageType === "BUSINESS"
            ? Object.keys(business).length === 0 &&
              business.constructor === Object
              ? true
              : false
            : false)
        );
      case 1:
        return (
          [assignChargers].includes("") ||
          (assignChargers === "SEARCH"
            ? chargers.length > 0
              ? false
              : true
            : chargers.length === 0 && false)
        );
      case 2:
        return users.length === 0 ? true : false;
      case 3:
        return isEditMode
          ? false
          : [pricingName, pricingMode].includes("") ||
          (pricingMode === "DISCOUNT"
            ? discountType === "PERCENT"
              ? [pricingAmount].includes("") || pricingAmount >= 100
              : [pricingAmount].includes("")
            : false);
      default:
        break;
    }
  }

  function handlePay() {
    setLoader(true);
    authorizedFetch(
      `${BOLT_URL}/company/v2/usergroup/${createdUserGroupID}/addToWallet?amount=${cugEstimateData.data}`,
      // `${BOLT_URL}/company/v2/usergroup/${"6524e619e424ddecb68956a0"}/addToWallet?amount=${"375"}`,
      {
        method: "POST",
      }
    )
      .then((res) => {
        setLoader(false);
        if (res.status === 200) {
          window.open(res.data.paymentlinks.web, "_blank");
          if (isEditMode) {
            queryClient.resetQueries("getUserGroup");
          }
          handleClose();
        } else {
          snackbar.error(res.message || "An error occurred");
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error("An error occurred");
      });
  }

  return <>
    <CreateBusiness
      open={createBusiness.open}
      handleClose={() => {
        setCreateBusiness({ ...createBusiness, open: !createBusiness.open });
        refetch();
      }}
    />
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 850,
          width: 1,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 1,
            p: "3.5px 5px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        {isEditMode ? "Edit" : "Create"} Closed User Group
        <Tooltip title="Close">
          <IconButton
            children={<HighlightOff />}
            color="inherit"
            onClick={handleClose}
            sx={{ transform: "translate(8px, -8px)" }}
          />
        </Tooltip>
      </DialogTitle>

      <DialogContent>
        <Stepper
          sx={{ mt: 2, mb: 4, mx: "auto", maxWidth: 730 }}
          activeStep={step}
          nonLinear={isEditMode}
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i} completed={isEditMode ? false : isComplete(i)}>
              <StepButton onClick={() => setStep(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>

        <Collapse in={isEditMode && userGroupLoading}>
          <Box py={4}>
            <CircularLoader />
          </Box>
        </Collapse>

        <Collapse in={step === 0 && (isEditMode ? !userGroupLoading : true)}>
          {/* DETAIL'S & USAGE SECTION */}
          <Box
            sx={{
              maxWidth: 500,
              mx: "auto",
              display: "grid",
              gridTemplateColumns: "1fr",
              gap: 3,
              py: 2,
            }}
          >
            <Box>
              <Typography className="label">Group Name</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Group Name"
                autoComplete="off"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: 10,
                    },
                  },
                }}
                inputProps={{ maxLength: 16 }}
                value={name}
                onChange={(e: any) => {
                  if (/^[A-Za-z0-9\s]*$/.test(e.target.value)) {
                    handleChange("name", e.target.value);
                  }
                }}
                disabled={isEditMode}
              />
            </Box>
            <Box>
              <Typography className="label">Group Description</Typography>
              <TextField
                multiline
                minRows={3}
                fullWidth
                placeholder="Group Description"
                autoComplete="off"
                sx={{
                  textArea: {
                    px: 1.75,
                    py: 1.25,
                    borderRadius: 40,
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: 4,
                    },
                  },
                }}
                value={description}
                onChange={(e: any) => {
                  if (/^[A-Za-z0-9\s]*$/.test(e.target.value)) {
                    handleChange("description", e.target.value);
                  }
                }}
                disabled={isEditMode}
              />
            </Box>
            {!isEditMode && (
              <Box>
                <Typography className="label">Usage Type</Typography>
                <RadioGroup
                  row
                  value={usageType}
                  onChange={(e) =>
                    setInput({
                      ...input,
                      business: {},
                      usageType: e.target.value,
                    })
                  }
                >
                  {[
                    {
                      title: "For Business",
                      value: "BUSINESS",
                      info: "Allow a business to have only view access of this group.",
                    },
                    {
                      title: "For Self",
                      value: "SELF",
                      info: "Only you can view and edit this group.",
                    },
                  ].map((el, i) => (
                    <Box
                      key={i}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        key={i}
                        value={el.value}
                        control={<Radio />}
                        label={el.title}
                        sx={{ mr: 1 }}
                      />
                      <Tooltip title={el.info}>
                        <InfoOutlined
                          sx={{
                            mr: i === 0 ? 3 : 0,
                            fontSize: 16,
                            cursor: "pointer",
                            color: (theme) => theme.customColors.action,
                          }}
                        />
                      </Tooltip>
                    </Box>
                  ))}
                </RadioGroup>

                <Collapse in={usageType === "BUSINESS"}>
                  <Box>
                    <Box
                      sx={{
                        mb: 1,
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography className="label" sx={{ mt: 4 }}>
                        Select Business
                      </Typography>
                      <Button
                        variant="outlined"
                        sx={{
                          borderRadius: 10,
                          textTransform: "none",
                        }}
                        onClick={() =>
                          setCreateBusiness({ open: !createBusiness.open })
                        }
                      >
                        Create Business
                      </Button>
                    </Box>

                    <Box sx={{ width: 500 }}>
                      <TableComponent
                        height={350}
                        px={0}
                        rowsPerPage={5}
                        small
                        loading={businessLoading}
                        rows={businessData?.data || []}
                        columns={[
                          {
                            key: "name",
                            label: "Business Name",
                          },
                          {
                            key: "assignedUsers",
                            label: "Assigned Users",
                          },
                          {
                            key: "assignedActiveCUGs",
                            label: "Active CUG",
                          },
                          {
                            key: "action",
                            label: "Action",
                            Render: (row) => (
                              <Button
                                key={row._id}
                                variant="outlined"
                                size="small"
                                sx={{
                                  borderRadius: 10,
                                  textTransform: "none",
                                }}
                                onClick={() => {
                                  if (
                                    Object.keys(business).length === 0 &&
                                    business.constructor === Object
                                  ) {
                                    setInput({ ...input, business: row });
                                  } else {
                                    setInput({ ...input, business: {} });
                                  }
                                }}
                                disabled={
                                  Object.keys(business).length !== 0 &&
                                  business.constructor === Object &&
                                  row._id !== business?._id
                                }
                              >
                                {row._id === business?._id
                                  ? "Unselect"
                                  : "Select"}
                              </Button>
                            ),
                          },
                        ]}
                      />
                    </Box>
                  </Box>
                </Collapse>
              </Box>
            )}
          </Box>
        </Collapse>

        <Collapse in={step === 1}>
          {/* ADD CHARGER'S SECTION */}
          <Box
            sx={{
              maxWidth: 500,
              mx: "auto",
              display: "grid",
              gridTemplateColumns: "1fr",
              gap: 2,
              py: 2,
            }}
          >
            {/* SEARCH OR SELECT ALL CHARGER'S SECTION */}
            <Box>
              <Typography className="label">Assign Chargers</Typography>
              <RadioGroup
                row
                value={assignChargers}
                onChange={(e: any) => {
                  handleChange("assignChargers", e.target.value);
                  if (e.target.value === "ALL") {
                    setInput({
                      ...input,
                      assignChargers: e.target.value,
                      chargers: [],
                    });
                  } else
                    setInput({ ...input, assignChargers: e.target.value });
                }}
              >
                {[
                  {
                    title: "Specific Chargers",
                    value: "SEARCH",
                    info: "Selected Chargers will have this Closed User Group's Pricing implemented for the users.",
                  },
                  { title: "All Chargers", value: "ALL", info: "" },
                ].map((el, i) => (
                  <Box
                    key={i}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      key={i}
                      value={el.value}
                      control={<Radio />}
                      label={el.title}
                      sx={{ mr: 1 }}
                    />
                    {i === 0 && (
                      <Tooltip title={el.info}>
                        <InfoOutlined
                          sx={{
                            mr: 3,
                            fontSize: 16,
                            cursor: "pointer",
                            color: (theme) => theme.customColors.action,
                          }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                ))}
              </RadioGroup>
            </Box>

            {/* SEARCH CHARGER'S SECTION */}
            <Collapse in={assignChargers === "SEARCH"}>
              <Autocomplete
                value={chargerValue}
                onChange={(event: any, newValue: { uid: string } | null) => {
                  addItems("charger", newValue);
                  setChargerValue(null);
                }}
                loading={chargerLoading}
                inputValue={searchCharger}
                onInputChange={(event, newInputValue) =>
                  setSearchCharger(newInputValue.toUpperCase())
                }
                size="small"
                options={chargerList}
                sx={{
                  width: 250,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: 10,
                    },
                  },
                  "& .MuiInputBase-input": {
                    height: "28px !important",
                  },
                  mb: 2,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={`Search Chargers...`}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ ml: 1, mr: 0 }}
                        >
                          <SearchOutlined />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end" sx={{ ml: 3.2 }}>
                          {params.InputProps.endAdornment}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              {/* CHARGER'S TABLE SECTION */}
              <TableComponent
                height={350}
                px={0}
                rowsPerPage={5}
                small
                rows={chargers || []}
                columns={[
                  { key: "uid", label: "Charger UID" },
                  {
                    key: "actions",
                    label: "Action",
                    Render: (row) => (
                      <Tooltip title="Delete">
                        <IconButton
                          color="error"
                          size="small"
                          onClick={() =>
                            setInput({
                              ...input,
                              chargers: chargers.filter(
                                (el: any) => el._id !== row._id
                              ),
                            })
                          }
                        >
                          <DeleteOutline fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    ),
                  },
                ]}
              />
            </Collapse>
          </Box>
        </Collapse>

        <Collapse in={step === 2}>
          {/* ADD USER'S SECTION */}
          <Box
            sx={{
              maxWidth: 550,
              mx: "auto",
              display: "grid",
              gridTemplateColumns: "1fr",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* SELECT USER'S SECTION */}
              <Box sx={{ display: "flex" }}>
                <Select
                  sx={{
                    maxHeight: 45,
                    width: 100,
                    mr: 2,
                  }}
                  style={{ borderRadius: "50px" }}
                  value={userSearchType}
                  onChange={(e) =>
                    handleChange("userSearchType", e.target.value)
                  }
                >
                  {["Name", "Phone", "Email"].map((item: any) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                <Autocomplete
                  value={userValue}
                  onChange={(
                    event: any,
                    newValue: {
                      id: string;
                      name: string;
                      phone: string;
                      email: string;
                    } | null
                  ) => {
                    addItems("user", newValue);
                    setUserValue(null);
                  }}
                  loading={userLoading}
                  inputValue={searchUser}
                  onInputChange={(event, newInputValue) => {
                    if (userSearchType === "Phone") {
                      if (/^[0-9]{0,10}$/.test(newInputValue)) {
                        setSearchUser(newInputValue);
                      }
                    } else {
                      setSearchUser(newInputValue);
                    }
                  }}
                  size="small"
                  options={userList}
                  renderOption={(props: any, option: any) => {
                    return (
                      <li {...props} key={option._id || 0}>
                        {option.label}
                      </li>
                    );
                  }}
                  sx={{
                    width: 250,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: 10,
                      },
                    },
                    "& .MuiInputBase-input": {
                      height: "28px !important",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={`Search ${userSearchType}...`}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            sx={{ ml: 1, mr: 0 }}
                          >
                            <SearchOutlined />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end" sx={{ ml: 3.2 }}>
                            {params.InputProps.endAdornment}
                          </InputAdornment>
                        ),
                        inputProps: {
                          ...params.inputProps,
                          maxLength: userSearchType === "Phone" ? 10 : 30,
                        },
                      }}
                    />
                  )}
                />
              </Box>
            </Box>

            {error.length !== 0 && (
              <>
                <Tabs
                  variant="scrollable"
                  allowScrollButtonsMobile
                  className="dense"
                  value={tab}
                  onChange={(e: any, tab: any) => setTab(tab)}
                >
                  <Tab key="users" label="Users" />
                  <Tab key="chargers" label="User Conflicts" />
                </Tabs>
              </>
            )}

            {/* USER'S TABLE SECTION */}
            <Box sx={{ width: 550 }}>
              {error.length !== 0 ? (
                <>
                  {tab === 0 && (
                    <>
                      <TableComponent
                        height={350}
                        px={0}
                        rowsPerPage={5}
                        small
                        rows={users || []}
                        columns={[
                          {
                            key: "name",
                            label: "Name",
                            Render: (row) => {
                              return row.firstName === "" &&
                                row.lastName === ""
                                ? ""
                                : `${row.firstName} ${row.lastName}`;
                            },
                          },
                          {
                            key: "phone",
                            label: "Phone",
                            Render: (row) => {
                              return row.phone !== undefined &&
                                row.phone.length > 10
                                ? `${row.phone.slice(0, 3)} ${row.phone.slice(
                                  3
                                )}`
                                : "-";
                            },
                          },
                          { key: "email", label: "Email" },
                          // { key: "existingCUG", label: "Existing CUG'S" },
                          {
                            key: "actions",
                            label: "Action",
                            Render: (row) => (
                              <Tooltip title="Delete">
                                <IconButton
                                  color="error"
                                  size="small"
                                  onClick={() => {
                                    if (error.length !== 0) {
                                      setInput({
                                        ...input,
                                        users: users.filter(
                                          (el: any) => el._id !== row._id
                                        ),
                                      });
                                      setError(
                                        error.filter(
                                          (el: any) => el._id !== row._id
                                        )
                                      );
                                    } else {
                                      setInput({
                                        ...input,
                                        users: users.filter(
                                          (el: any) => el._id !== row._id
                                        ),
                                      });
                                    }
                                  }}
                                >
                                  <DeleteOutline fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            ),
                          },
                        ]}
                      />
                    </>
                  )}
                  {tab === 1 && (
                    <>
                      <TableComponent
                        height={350}
                        px={0}
                        rowsPerPage={5}
                        small
                        rows={error || []}
                        columns={[
                          { key: "name", label: "User Name" },
                          { key: "group", label: "CUG Group" },
                          {
                            key: "status",
                            label: "Group Status",
                            Render: (row) => (
                              <Avatar
                                variant="status"
                                className={
                                  row?.status === "ACTIVE"
                                    ? "green"
                                    : row?.status === "INACTIVE"
                                      ? "yellow"
                                      : "red"
                                }
                              >
                                {titleCase(row?.status || "-")}
                              </Avatar>
                            ),
                          },
                          {
                            key: "actions",
                            label: "Action",
                            Render: (row) => (
                              <Tooltip title="Delete">
                                <IconButton
                                  color="error"
                                  size="small"
                                  onClick={() => {
                                    setInput({
                                      ...input,
                                      users: users.filter(
                                        (el: any) => el._id !== row._id
                                      ),
                                    });
                                    setError(
                                      error.filter(
                                        (el: any) => el._id !== row._id
                                      )
                                    );
                                  }}
                                >
                                  <DeleteOutline fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            ),
                          },
                        ]}
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  <TableComponent
                    height={350}
                    px={0}
                    rowsPerPage={5}
                    small
                    rows={users || []}
                    columns={[
                      {
                        key: "name",
                        label: "Name",
                        Render: (row) => {
                          return row.firstName === "" && row.lastName === ""
                            ? ""
                            : `${row.firstName} ${row.lastName}`;
                        },
                      },
                      {
                        key: "phone",
                        label: "Phone",
                        Render: (row) => {
                          return row.phone !== undefined &&
                            row.phone.length > 10
                            ? `${row.phone.slice(0, 3)} ${row.phone.slice(3)}`
                            : "-";
                        },
                      },
                      { key: "email", label: "Email" },
                      // { key: "existingCUG", label: "Existing CUG'S" },
                      {
                        key: "actions",
                        label: "Action",
                        Render: (row) => (
                          <Tooltip title="Delete">
                            <IconButton
                              color="error"
                              size="small"
                              onClick={() => {
                                if (error.length !== 0) {
                                  setInput({
                                    ...input,
                                    users: users.filter(
                                      (el: any) => el._id !== row._id
                                    ),
                                  });
                                  setError(
                                    error.filter(
                                      (el: any) => el._id !== row._id
                                    )
                                  );
                                } else {
                                  setInput({
                                    ...input,
                                    users: users.filter(
                                      (el: any) => el._id !== row._id
                                    ),
                                  });
                                }
                              }}
                            >
                              <DeleteOutline fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        ),
                      },
                    ]}
                  />
                </>
              )}
            </Box>
          </Box>
        </Collapse>

        <Collapse in={!isEditMode && step === 3}>
          {" "}
          {/* Skip step 3 in edit mode */}
          {/* PRICING SECTION */}
          <Box
            sx={{
              maxWidth: 500,
              mx: "auto",
              display: "grid",
              gridTemplateColumns: "1fr",
              gap: 3,
              py: 2,
            }}
          >
            <Box>
              <Typography className="label">Pricing Name</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Pricing Name"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: 10,
                    },
                  },
                }}
                inputProps={{ maxLength: 20 }}
                value={pricingName}
                onChange={(e: any) => {
                  if (/^[A-Za-z0-9\s]*$/.test(e.target.value)) {
                    handleChange("pricingName", e.target.value);
                  }
                }}
                autoComplete="off"
              />
            </Box>
            <Box>
              <Typography className="label">Pricing Mode</Typography>
              <RadioGroup
                row
                value={pricingMode}
                onChange={(e: any) => {
                  if (e.target.value === "FREE") {
                    setInput({
                      ...input,
                      pricingMode: e.target.value,
                      discountType: "",
                      pricingAmount: "",
                    });
                  } else setInput({ ...input, pricingMode: e.target.value });
                }}
              >
                {[
                  {
                    title: "Free Charging",
                    value: "FREE",
                    info: "Users won't be charged any booking amount in this mode.",
                  },
                  {
                    title: "Discounted Charging",
                    value: "DISCOUNT",
                    info: "Users will be discounted a percentage or a fixed amount in this mode based on units consumed.",
                  },
                ].map((el, i) => (
                  <Box
                    key={i}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      key={i}
                      value={el.value}
                      control={<Radio />}
                      label={el.title}
                      sx={{
                        mr: 1,
                      }}
                    />
                    <Tooltip title={el.info}>
                      <InfoOutlined
                        sx={{
                          mr: i === 0 ? 3 : 0,
                          fontSize: 16,
                          cursor: "pointer",
                          color: (theme) => theme.customColors.action,
                        }}
                      />
                    </Tooltip>
                  </Box>
                ))}
              </RadioGroup>
            </Box>
            <Collapse in={pricingMode === "DISCOUNT"}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                  gap: 3,
                }}
              >
                <Box>
                  <Typography className="label">Discount Type</Typography>
                  <RadioGroup
                    row
                    value={discountType}
                    onChange={(e: any) =>
                      handleChange("discountType", e.target.value)
                    }
                  >
                    {[
                      {
                        title: "Percentage Discount",
                        value: "PERCENT",
                        info: "Set a percentage discount for each unit consumed by the user. Users will still pay the remaining booking amount.",
                      },
                      {
                        title: "Fixed Amount Discount",
                        value: "FIXED",
                        info: "Set a fixed discount per unit consumed by the user. Users will still pay the remaining booking amount if amount exceeds the fixed discount.",
                      },
                    ].map((el, i) => (
                      <Box
                        key={i}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FormControlLabel
                          key={i}
                          value={el.value}
                          control={<Radio />}
                          label={el.title}
                          sx={{ mr: 1 }}
                        />
                        <Tooltip title={el.info}>
                          <InfoOutlined
                            sx={{
                              mr: i === 0 ? 3 : 0,
                              fontSize: 16,
                              cursor: "pointer",
                              color: (theme) => theme.customColors.action,
                            }}
                          />
                        </Tooltip>
                      </Box>
                    ))}
                  </RadioGroup>
                </Box>
                <Collapse in={!!discountType}>
                  <Box>
                    <Typography className="label">
                      Amount {discountType === "FIXED" && "(INR)"}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Amount"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: 10,
                          },
                        },
                      }}
                      inputProps={{
                        maxLength: discountType === "PERCENT" ? 2 : 6,
                      }}
                      value={pricingAmount}
                      onChange={(e: any) => {
                        if (/^[0-9]{0,10}$/.test(e.target.value)) {
                          handleChange("pricingAmount", e.target.value);
                        }
                      }}
                      InputProps={{
                        startAdornment:
                          discountType === "FIXED" ? (
                            <InputAdornment position="start" sx={{ ml: 1.5 }}>
                              ₹
                            </InputAdornment>
                          ) : undefined,
                        endAdornment: (
                          <InputAdornment position="end" sx={{ mr: 2 }}>
                            {discountType !== ""
                              ? discountType === "PERCENT"
                                ? "%"
                                : "/kwh"
                              : ""}
                          </InputAdornment>
                        ),
                      }}
                      disabled={discountType === ""}
                      autoComplete="off"
                    />
                  </Box>
                </Collapse>
              </Box>
            </Collapse>
            <Collapse in={cugEstimateData?.status === 200}>
              <Box>
                <Typography className="label">
                  Estimated Amount: ₹{cugEstimateData?.data}
                </Typography>
              </Box>
            </Collapse>
          </Box>
        </Collapse>

        <Collapse in={isEditMode ? step === 3 : step === 4}>
          {/* CONFIRMATION SECTION */}
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                },
                "& .bold": {
                  width: 300,
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    bgcolor: (theme: any) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {[
                  { header: "Details & Usage", onEdit: () => setStep(0) },
                  { label: "Name", value: name },
                  { label: "Description", value: description },
                  ...(!isEditMode
                    ? [
                      { label: "Usage Type", value: titleCase(usageType) },
                      ...(usageType === "BUSINESS"
                        ? [{ label: "Business", value: business.name }]
                        : []),
                    ]
                    : []),

                  { header: "Selected Chargers", onEdit: () => setStep(1) },
                  {
                    label: "Chargers",
                    value:
                      assignChargers === "ALL"
                        ? "All chargers selected"
                        : chargers,
                  },

                  { header: "Selected Users", onEdit: () => setStep(2) },
                  { label: "Users", value: users.length > 0 && users },

                  ...(!isEditMode
                    ? [
                      { header: "Pricing", onEdit: () => setStep(3) },
                      { label: "Pricing Name", value: pricingName },
                      {
                        label: "Pricing Mode",
                        value: titleCase(pricingMode),
                      },
                      ...(pricingMode === "DISCOUNT"
                        ? [
                          {
                            label: "Discount Type",
                            value: titleCase(discountType),
                          },
                          {
                            label: "Amount",
                            value:
                              discountType === "PERCENT"
                                ? `${pricingAmount}%`
                                : `₹${pricingAmount} / kwh`,
                          },
                        ]
                        : []),
                    ]
                    : []),
                ].map(({ header, onEdit, label, value }, i, arr) => {
                  const isFirst = arr[i - 1]?.header;
                  const isLast = !arr[i + 1] || arr[i + 1].header;

                  return (
                    <tr
                      key={i}
                      className={
                        header
                          ? "header"
                          : `${isFirst ? "first" : ""} ${isLast ? "last" : ""
                          }`
                      }
                    >
                      {header ? (
                        <td colSpan={2}>
                          <span>{header}</span>
                          <IconButton
                            sx={{ ml: 1.5 }}
                            children={<EditOutlined />}
                            color="primary"
                            size="small"
                            onClick={onEdit}
                          />
                        </td>
                      ) : (
                        <>
                          <td>{label}</td>
                          <td className="bold">
                            {value !== undefined &&
                              (value.constructor === Array &&
                                value.find((el) =>
                                  Object.keys(el).includes("_id")
                                ) !== undefined ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                  }}
                                >
                                  {value.map((el: any, i: number) =>
                                    Object.keys(el).includes("uid") ? (
                                      i < 5 ? (
                                        <Chip
                                          key={i}
                                          sx={{ mb: 1, mr: 0.25 }}
                                          label={el.uid}
                                        />
                                      ) : (
                                        ""
                                      )
                                    ) : i < 5 ? (
                                      <Chip
                                        key={i}
                                        sx={{ mb: 1, mr: 0.25 }}
                                        label={
                                          el.phone !== undefined &&
                                            el.phone.length > 10
                                            ? `${el.phone.slice(
                                              0,
                                              3
                                            )} ${el.phone.slice(3)}`
                                            : "-"
                                        }
                                      />
                                    ) : (
                                      ""
                                    )
                                  )}
                                  {value.length > 5 &&
                                    `... ${value.length - 5} more ${value.find((el) =>
                                      Object.keys(el).includes("uid")
                                    )
                                      ? `charger${value.length - 5 === 1 ? "" : "s"
                                      }`
                                      : `user${value.length - 5 === 1 ? "" : "s"
                                      }`
                                    }`}
                                </Box>
                              ) : value.constructor === Array ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  {value}
                                </Box>
                              ) : (
                                <Box display="flex" alignItems="center">
                                  {value}
                                </Box>
                              ))}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {
              isEditMode && (<Collapse in={cugEstimateData?.status === 200}>
                <Box>
                  <Typography className="label">
                    Estimated Amount: ₹{cugEstimateData?.data}
                  </Typography>
                </Box>
              </Collapse>)
            }
          </Box>
        </Collapse>

        <Collapse in={isEditMode ? step === 4 : step === 5}>
          {/* PAYMENT SECTION */}
          <Box
            sx={{
              maxWidth: 500,
              mx: "auto",
              display: "grid",
              gridTemplateColumns: "1fr",
              gap: 3,
              py: 2,
            }}
          >
            <Info>
              User group "<b>{name}</b>" has been {isEditMode ? "updated" : "created"} and is inactive. To
              activate it, please pay the estimated amount.
            </Info>
          </Box>
        </Collapse>
      </DialogContent>

      <DialogActions>
        {isEditMode
          ? step !== 4 ? (<>
            {step !== 0 && (
              <Button
                variant="outlined"
                sx={{
                  borderRadius: 10,
                  borderWidth: "1.5px !important",
                  textTransform: "none",
                  fontSize: "16px",
                }}
                onClick={handleBack}
              >
                Back
              </Button>
            )}
            <Button
              sx={{
                borderRadius: 10,
                textTransform: "none",
                fontSize: "16px",
              }}
              variant="contained"
              onClick={handleNext}
              disableElevation
              disabled={isDisabled(step)}
            >
              {step === steps.length - 2 ? "Update" : "Next"}
            </Button>
          </>) : (<>
            <Button
              variant="outlined"
              sx={{
                borderRadius: 10,
                textTransform: "none",
                fontSize: "16px",
              }}
              onClick={handleClose}
            >
              Pay Later
            </Button>
            <Button
              variant="contained"
              sx={{
                borderRadius: 10,
                textTransform: "none",
                fontSize: "16px",
              }}
              onClick={handlePay}
              disabled={typeof cugEstimateData?.data !== "number" || cugEstimateData?.data === 0}
              endIcon={
                typeof cugEstimateData?.data === "number" ? (
                  <OpenInNew sx={{ ml: 0.25 }} />
                ) : undefined
              }
            >
              {typeof cugEstimateData?.data !== "number"
                ? "Error fetching estimate"
                : `Pay ₹${cugEstimateData.data.toLocaleString()}`}
            </Button>
          </>)
          : step !== 5 ? (<>
            {step !== 0 && (
              <Button
                variant="outlined"
                sx={{
                  borderRadius: 10,
                  borderWidth: "1.5px !important",
                  textTransform: "none",
                  fontSize: "16px",
                }}
                onClick={handleBack}
              >
                Back
              </Button>
            )}
            <Button
              sx={{
                borderRadius: 10,
                textTransform: "none",
                fontSize: "16px",
              }}
              variant="contained"
              onClick={handleNext}
              disableElevation
              disabled={isDisabled(step)}
            >
              {step === steps.length - 2 ? "Create" : "Next"}
            </Button>
          </>) : (<>
            <Button
              variant="outlined"
              sx={{
                borderRadius: 10,
                textTransform: "none",
                fontSize: "16px",
              }}
              onClick={handleClose}
            >
              Pay Later
            </Button>
            <Button
              variant="contained"
              sx={{
                borderRadius: 10,
                textTransform: "none",
                fontSize: "16px",
              }}
              onClick={handlePay}
              disabled={typeof cugEstimateData?.data !== "number"}
              endIcon={
                typeof cugEstimateData?.data === "number" ? (
                  <OpenInNew sx={{ ml: 0.25 }} />
                ) : undefined
              }
            >
              {typeof cugEstimateData?.data !== "number"
                ? "Error fetching estimate"
                : `Pay ₹${cugEstimateData.data.toLocaleString()}`}
            </Button>
          </>)}
      </DialogActions>
    </Dialog>
  </>;
};

const Info = ({ children }: any) => {
  return (
    <Box
      sx={{
        backgroundColor: "#eee",
        borderRadius: "20px",
        mx: 4,
      }}
    >
      <Box sx={{ px: 2.8, py: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <InfoOutlined sx={{ color: "#4B4B4B", fontSize: "24px" }} />
          <Typography
            sx={{
              color: "#000",
              ml: 1,
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            Info
          </Typography>
        </Box>
        <Typography sx={{ color: "#4B4B4B", px: 0.5 }}>{children}</Typography>
      </Box>
    </Box>
  );
};

export default CUGDialog;
