import {
  DonutLarge,
  ElectricBike,
  Map,
  PinDrop,
  SaveAlt,
  Speed,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { saveReport, saveVehicleReport } from "actions";
// import RangePicker from "components/RangePicker";
import { sub } from "date-fns";
import { saveAs } from "file-saver";
import { queryClient } from "index";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  authorizedFetch,
  GlobalState,
  setLoader,
  snackbar,
  useDebounce,
} from "utils";
import {
  AUTH_URL,
  BOLT_URL,
  LEASE_URL,
  NEW_REPORTS_URL,
  REPORTS_URL,
  RETAIL_URL,
} from "utils/constants";
import ReportPreviewDialog from "./Drawer/ReportPreviewDialog";
import Selector from "./Drawer/Selector";
import ReportDialog from "./ReportDialog";
import RangeSelector from "components/RangeSelector";

const DownloadBar = () => {
  const dispatch = useDispatch();
  const { company, activeSection, masterView } = useSelector(
    (state: GlobalState) => state.global,
  );
  const isBoltEarth = company?.name === "REVOS";
  const isCUGMonitorAccount = company?.type === "CUG_VENDOR";
  const isTVS = company.name === "testTVS";

  let isCMS = activeSection === "charger";
  let isFMS = activeSection === "rental";

  const [toggle, setToggle] = useState(
    isCMS ? (isCUGMonitorAccount ? "users" : "vendors") : "all-vehicles",
  );

  const [search, setSearch] = useState("");

  const [selected, setSelected] = useState<any>(null);
  const [selectedBatch, setSelectedBatch] = useState<any>([]);
  const [format, setFormat] = useState("pdf");
  const [platform, setPlatform] = useState(isFMS ? "LEASE" : "RETAIL");

  const [previewDialog, setPreviewDialog] = useState<any>({
    open: false,
    data: null,
  });
  const [dialog, setDialog] = useState<any>({ open: false, data: null });

  const [range, setRange] = useState<any>([
    sub(new Date(), { months: 1 }),
    new Date(),
  ]);
  let startTime = moment(range[0]).toISOString();
  let endTime = moment(range[1]).toISOString();

  const maxListSize = 25;

  const chargersUrl = `${BOLT_URL}/company/chargers?first=${maxListSize}&chargerId=${search}`;
  const vendorsUrl = `${AUTH_URL}/company/vendor/list?orderBy=CREATED_AT_DESC&first=${maxListSize}&search=${search}`;
  const fmsVehiclesUrl = `${LEASE_URL}/company/vehicles?all=true`;
  const vmsVehiclesUrl = `${RETAIL_URL}/sold-vehicles?first=${maxListSize}&search=${search}`;
  const usersUrl = `${BOLT_URL}/company/v2/usergroup/users?first=${maxListSize}${
    search ? `&name=${search}` : ""
  }`;
  const cugsUrl = `${BOLT_URL}/company/v2/usergroup?first=${maxListSize}${
    search ? `&search=${search}` : ""
  }`;

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    console.log("debouncedSearch", debouncedSearch);
  }, [debouncedSearch]);

  const { isLoading: chargersLoading, data: chargersData } = useQuery(
    ["getChargers", debouncedSearch, masterView],
    () =>
      authorizedFetch(chargersUrl, {
        headers: {
          master: masterView,
        },
      }),
    {
      enabled:
        isCMS &&
        !isCUGMonitorAccount &&
        (toggle === "chargers" || toggle === "esg"),
    },
  );

  const { isLoading: vendorsLoading, data: vendorsData } = useQuery(
    ["getVendors", debouncedSearch],
    () => authorizedFetch(vendorsUrl),
    { enabled: isCMS && !isCUGMonitorAccount && toggle === "vendors" },
  );

  const { isLoading: fmsVehiclesLoading, data: fmsVehiclesData } = useQuery(
    ["getRentalVehicles"],
    () => authorizedFetch(fmsVehiclesUrl),
    { enabled: !isCMS },
  );

  const { isLoading: vmsVehiclesLoading, data: vmsVehiclesData } = useQuery(
    ["getSoldVehicles", debouncedSearch],
    () => authorizedFetch(vmsVehiclesUrl),
    { enabled: !isCMS },
  );

  const { isLoading: cugUsersLoading, data: cugUsersData } = useQuery(
    ["getMonitorUsersReport", debouncedSearch, isCUGMonitorAccount],
    () =>
      authorizedFetch(usersUrl, {
        headers: {
          monitoringAccount: isCUGMonitorAccount,
        },
      }),
    {
      enabled: toggle === "users",
    },
  );

  const { isLoading: cugsLoading, data: cugsData } = useQuery(
    ["getMonitorCUGReports", debouncedSearch, isCUGMonitorAccount],
    () =>
      authorizedFetch(cugsUrl, {
        headers: {
          monitoringAccount: isCUGMonitorAccount,
        },
      }),
    {
      enabled: toggle === "cugs",
    },
  );

  const chargerOptions = useMemo(() => {
    return [
      ...(chargersLoading || isBoltEarth
        ? []
        : [{ id: "all", label: "All Chargers" }]),
      ...(chargersData?.data?.chargers?.constructor === Array
        ? chargersData?.data?.chargers.map((el: any) => ({
            id: el.id,
            label: el.id,
          }))
        : []),
      ...(chargersData?.data?.chargers?.constructor === Array &&
      chargersData.data.chargersCount > maxListSize
        ? [
            {
              id: "more",
              label: `+${
                chargersData.data.chargersCount - maxListSize
              } more...${search ? ` (for "${search}")` : ""}`,
            },
          ]
        : []),
    ];
  }, [isBoltEarth, chargersLoading, chargersData, search]);

  const vendorOptions = useMemo(() => {
    return [
      ...(vendorsLoading ? [] : [{ id: "all", label: "All Vendors" }]),
      ...(vendorsData?.data?.constructor === Array ? vendorsData.data : []).map(
        (el: any) => ({ id: el._id, label: el.name }),
      ),
      ...(!!vendorsData?.meta?.totalCount &&
      vendorsData.meta.totalCount > maxListSize
        ? [
            {
              id: "more",
              label: `+${
                vendorsData.meta.totalCount - maxListSize
              } more...${search ? ` (for "${search}")` : ""}`,
            },
          ]
        : []),
    ];
  }, [vendorsLoading, vendorsData, search]);

  const userOptions = useMemo(() => {
    return [
      ...(cugUsersData?.data?.userDetails?.constructor === Array
        ? cugUsersData?.data?.userDetails
        : []
      ).map((el: any) => ({
        id: el._id,
        label: `${el.firstName} ${el.lastName}`,
      })),
    ];
  }, [cugUsersData]);

  const cugOptions = useMemo(() => {
    return [
      ...(cugsData?.data?.groups?.constructor === Array
        ? cugsData?.data?.groups
        : []
      ).map((el: any) => ({ id: el._id, label: el.name })),
    ];
  }, [cugsData]);

  const fmsVehicleOptions = useMemo(() => {
    return fmsVehiclesData?.data?.vehicles?.constructor === Array
      ? fmsVehiclesData.data.vehicles
          .map((el: any) => el.vin)
          .sort((a: any, b: any) => a.localeCompare(b))
      : [];
  }, [fmsVehiclesData]);

  const vmsVehicleOptions = useMemo(() => {
    return [
      ...(vmsVehiclesData?.data?.constructor === Array
        ? vmsVehiclesData.data
            .map((el: any) => el.vin)
            .sort((a: any, b: any) => a.localeCompare(b))
        : []),
      ...(vmsVehiclesData?.data?.constructor === Array &&
      vmsVehiclesData.meta.totalCount > maxListSize
        ? [
            `+${vmsVehiclesData.meta.totalCount - maxListSize} more...${
              search ? ` (for "${search}")` : ""
            }`,
          ]
        : []),
    ];
  }, [vmsVehiclesData, search]);

  function downloadReports(type?: string) {
    setLoader(true);
    let url = "";
    let token = 1234;

    if (isCMS) {
      if (toggle === "chargers" || toggle === "esg") {
        const reportTypes: any = {
          chargers: "CHARGER_BATCH_REPORT",
          esg: "CHARGER_ESG_REPORT",
          // vendors: "CHARGER_AGENCY_REPORT",
          // users: "USER_BATCH_REPORT",
          // cugs: "CHARGER_CUG_BATCH_REPORT",
        };

        const reportType = reportTypes[toggle];
        const batch = selectedBatch.map((el: any) => el.id).join(",");
        const getAll = selectedBatch.some((el: any) => el.id === "all");

        url = `${NEW_REPORTS_URL}/get-report?type=${reportType}&startTime=${startTime}&endTime=${endTime}&format=${format}${getAll ? "&downloadAllData=true" : "&batch=" + batch}&master=${masterView}`;
      } else {
        url =
          toggle === "vendors"
            ? selected?.id === "all"
              ? `${REPORTS_URL}/v2/chargerallagencyreport?start=${startTime}&end=${endTime}&token=${token}${
                  format && "&format=" + format
                }`
              : `${REPORTS_URL}/v2/chargeragencyreport2?agencyId=${selected?.id}&start=${startTime}&end=${endTime}&format=${format}&token=${token}`
            : toggle === "users"
              ? `${REPORTS_URL}/v2/cuguserreport?userId=${selected?.id}&start=${startTime}&end=${endTime}&token=${token}&format=${format}`
              : `${REPORTS_URL}/v2/cugreport?cugId=${selected?.id}&start=${startTime}&end=${endTime}&token=${token}&format=${format}`;
      }
    } else {
      if (toggle === "all-vehicles") {
        url = `${REPORTS_URL}/v2/report/summary?startTime=${startTime}&endTime=${endTime}&type=${platform}&token=${token}${
          format ? "&format=" + format : ""
        }`;
      } else {
        switch (type) {
          case "Summary":
            url = `${REPORTS_URL}/v2/report/summary?startTime=${startTime}&endTime=${endTime}&token=${token}&vin=${selected}&type=${platform}${
              format ? "&format=" + format : ""
            }`;
            break;
          case "Overspeed":
            url = `${REPORTS_URL}/v2/report/${selected}/overspeeding?startTime=${startTime}&endTime=${endTime}&token=${token}${
              format ? "&format=" + format : ""
            }`;
            break;
          case "Geofence":
            url = `${REPORTS_URL}/v2/report/${selected}/geofence?startTime=${startTime}&endTime=${endTime}&token=${token}${
              format ? "&format=" + format : ""
            }`;
            break;
          case "Trips":
            url = `${REPORTS_URL}/tripsreport?vin=${selected}&start=${startTime}&end=${endTime}&token=${token}&company=${company}${
              format ? "&format=" + format : ""
            }`;
            break;
          case "Mileage":
            url = `${REPORTS_URL}/v1/report/${selected}/trips-mileage?startTime=${startTime}&endTime=${endTime}`;
            break;
          default:
            break;
        }
      }
    }

    authorizedFetch(url)
      .then((data) => {
        setLoader(false);
        if (isCMS && ["chargers", "esg"].includes(toggle)) {
          if (data.meta.success) {
            snackbar.success(data.msg);
            queryClient.resetQueries(["get reports"]);
          } else {
            snackbar.error(data.msg);
          }
        } else if (data.file) {
          const formattedTime = (time: any) =>
            moment(time).format("DD-MM-YYYY");

          const fileUrl = data.file;
          let fileName = `${
            isCMS ? selected.label || "All" : selected || "All"
          }_${type}_Report_${formattedTime(startTime)}-${formattedTime(
            endTime,
          )}.${format === "excel" ? "xlsx" : "pdf"}`;

          saveAs(fileUrl, fileName);
          snackbar.success("Report downloaded");

          let payload = {
            name: fileName,
            createdAt: moment().valueOf(),
            url: fileUrl,
          };
          if (isCMS) dispatch(saveReport(payload));
          else dispatch(saveVehicleReport(payload));
        } else if (data.message) {
          snackbar.error(data.message);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error(error);
        snackbar.error(error.message || "An error occurred");
      });
  }

  useEffect(() => {
    setSelected(null);
    setSelectedBatch([]);
    if (toggle === "chargers") setFormat("pdf");
  }, [toggle]);

  useEffect(() => {
    setToggle(
      isCMS ? (isCUGMonitorAccount ? "users" : "vendors") : "all-vehicles",
    );
  }, [isCMS, isCUGMonitorAccount]);

  useEffect(() => {
    setPlatform(isFMS ? "LEASE" : "RETAIL");
  }, [isFMS]);

  return (
    <Box mb={4} position="relative">
      <Box border={0} m={-2} p={2} sx={{ overflowX: "auto" }}>
        <Paper
          sx={{
            py: 3,
            px: 4,
            borderRadius: "14px",
            boxShadow: (theme) => theme.customShadows.card,
            minWidth: "max-content",
          }}
        >
          <ReportDialog
            open={dialog.open}
            data={dialog.data}
            onClose={() => setDialog((prev: any) => ({ ...prev, open: false }))}
          />
          <ReportPreviewDialog
            open={previewDialog.open}
            handleClose={() =>
              setPreviewDialog({ ...previewDialog, open: false })
            }
          />
          <Box
            pb={1.5}
            display="flex"
            alignItems="end"
            sx={{
              "& > :not(:first-of-type)": { ml: 3 },
              "& > *": { flexShrink: 0 },
            }}
          >
            <Box>
              <Typography className="label" sx={{ mb: 2 }}>
                Reports For
              </Typography>
              <Select
                size="small"
                className="rounded"
                sx={{ width: 160 }}
                value={toggle}
                onChange={(e) => setToggle(e.target.value)}
              >
                {[
                  ...(isTVS
                    ? [
                        {
                          label: "Users",
                          value: "users",
                        },
                      ]
                    : isCMS
                      ? [
                          ...(!isCUGMonitorAccount
                            ? [
                                {
                                  label: "Vendors",
                                  value: "vendors",
                                },
                                {
                                  label: "Chargers",
                                  value: "chargers",
                                },
                              ]
                            : []),
                          {
                            label: "Users",
                            value: "users",
                          },
                          // {
                          //   label: "CUGs",
                          //   value: "cugs",
                          // },
                          {
                            label: "ESG",
                            value: "esg",
                          },
                        ]
                      : [
                          {
                            label: "All Vehicles",
                            value: "all-vehicles",
                          },
                          {
                            label: "Specific Vehicle",
                            value: "specific-vehicle",
                          },
                        ]),
                ].map((el, i) => (
                  <MenuItem key={i} value={el.value}>
                    {el.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {(isCMS || toggle === "specific-vehicle") && (
              <Box>
                <Typography className="label" sx={{ mb: 2 }}>
                  Select{" "}
                  {isTVS
                    ? "Users"
                    : isCMS
                      ? toggle === "chargers" || toggle === "esg"
                        ? "Charger(s)"
                        : toggle === "vendors"
                          ? "Vendor(s)"
                          : toggle === "users"
                            ? "User(s)"
                            : "CUG(s)"
                      : "Vehicle"}
                </Typography>
                <Selector
                  {...{
                    loading: !isCMS
                      ? isFMS
                        ? fmsVehiclesLoading
                        : vmsVehiclesLoading
                      : toggle === "chargers" || toggle === "esg"
                        ? chargersLoading
                        : toggle === "vendors"
                          ? vendorsLoading
                          : toggle === "users"
                            ? cugUsersLoading
                            : cugsLoading,
                    isCMS,
                    isFMS,
                    isCUGMonitorAccount,
                    toggle,
                    chargerOptions,
                    vendorOptions,
                    userOptions,
                    cugOptions,
                    fmsVehicleOptions,
                    vmsVehicleOptions,
                    selected,
                    setSelected,
                    selectedBatch,
                    setSelectedBatch,
                    search,
                    setSearch,
                  }}
                />
              </Box>
            )}
            <Box>
              <Typography className="label" sx={{ mb: 2 }}>
                Date Range
              </Typography>
              {/* <RangePicker range={range} setRange={setRange} /> */}
              <RangeSelector range={range} setRange={setRange} disableShadow />
            </Box>
            <Box>
              <Typography className="label" sx={{ mb: 2 }}>
                Format
              </Typography>
              <RadioGroup
                row
                value={format}
                onChange={(e) => setFormat(e.target.value)}
              >
                <FormControlLabel
                  value="pdf"
                  control={<Radio size="small" />}
                  label="PDF"
                />
                <FormControlLabel
                  value="excel"
                  control={<Radio size="small" />}
                  label="XLSX"
                />
              </RadioGroup>
            </Box>
            {isCMS && (
              <Box>
                <Button
                  sx={{ height: 40, textTransform: "none" }}
                  variant="contained"
                  size="large"
                  className="rounded"
                  onClick={() => downloadReports()}
                  startIcon={<SaveAlt />}
                  disabled={
                    isCMS && (toggle === "chargers" || toggle === "esg")
                      ? selectedBatch.length === 0
                      : !selected
                  }
                >
                  Download
                </Button>
              </Box>
            )}
          </Box>
          {!isCMS && (
            <Box mt={2}>
              <Typography
                className="label"
                sx={{ mb: 2, gridColumn: "span 2" }}
              >
                Download Reports
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  "& > :not(:first-of-type)": { ml: 2 },
                }}
              >
                <Button
                  className="rounded"
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  // color={toggle === "all-vehicles" ? "primary" : "inherit"}
                  startIcon={<DonutLarge />}
                  disabled={toggle === "specific-vehicle" && !selected}
                  onClick={() => downloadReports("Summary")}
                >
                  Summary
                </Button>
                {toggle === "all-vehicles" ? null : (
                  <>
                    <Button
                      className="rounded"
                      sx={{ textTransform: "none" }}
                      variant="contained"
                      // color="inherit"
                      startIcon={<Speed />}
                      disabled={toggle === "all-vehicles" || !selected}
                      onClick={() => downloadReports("Overspeed")}
                    >
                      Overspeed
                    </Button>
                    <Button
                      className="rounded"
                      sx={{ textTransform: "none" }}
                      variant="contained"
                      // color="inherit"
                      startIcon={<PinDrop />}
                      disabled={toggle === "all-vehicles" || !selected}
                      onClick={() => downloadReports("Geofence")}
                    >
                      Geofence
                    </Button>
                    <Button
                      className="rounded"
                      sx={{ textTransform: "none" }}
                      variant="contained"
                      // color="inherit"
                      startIcon={<Map />}
                      disabled={toggle === "all-vehicles" || !selected}
                      onClick={() => downloadReports("Trips")}
                    >
                      Trips
                    </Button>
                    {format === "excel" &&
                      company?._id === "64d1e10439a798de3db5aec6" && (
                        <Button
                          className="rounded"
                          sx={{ textTransform: "none" }}
                          variant="contained"
                          // color="inherit"
                          startIcon={<ElectricBike />}
                          disabled={toggle === "all-vehicles" || !selected}
                          onClick={() => downloadReports("Mileage")}
                        >
                          Mileage
                        </Button>
                      )}
                  </>
                )}
              </Box>
            </Box>
          )}
          {/* <Button
              sx={{ height: 40, ml: 2 }}
              variant="contained"
            // onClick={() => downloadReports()}
            >
              Preview Report
            </Button> */}
        </Paper>
      </Box>
    </Box>
  );
};

export default DownloadBar;
