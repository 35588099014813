import { Avatar, Box, Button, IconButton } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  GlobalState,
  authorizedFetch,
  drawer,
  getDarkModePreference,
  setLoader,
  snackbar,
} from "utils";

import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";

import { REPORTS_URL } from "utils/constants";

import saveAs from "file-saver";
import { FileDownload } from "@mui/icons-material";

const InfoDrawer = ({ order }: any) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state),
  );

  function downloadInvoice(bookingId: any) {
    setLoader(true);
    let request: any = authorizedFetch(
      `${REPORTS_URL}/v2/deviceorderinvoice?id=${bookingId}&format=pdf`,
    );
    request
      .then((result: any) => {
        setLoader(false);
        if (result.file) {
          const fileURL: any = result.file;
          saveAs(
            fileURL,
            `${order?.shippings[0]?.itemId ? order?.shippings[0]?.itemId + "_" + order?.shippings[0]?.shipmentId : order?._id}_${moment(order.createdAt).format("DD-MM-YYYY")}.pdf`,
          );
          snackbar.success("Downloaded invoice");
        } else if (result.message) {
          snackbar.error("Error downloading invoice");
          console.error(result);
        }
      })
      .catch((err: Error) => {
        console.error(err);
        setLoader(false);
        snackbar.error("Error downloading invoice");
      });
  }

  const table = [
    { header: "Order Details" },
    {
      label: "Created On",
      value: moment(order.createdAt).format("DD-MM-YYYY"),
    },
    {
      label: "Product",
      value: order?.product?.name || "N/A",
    },
    {
      label: "Status",
      value: (
        <Avatar
          variant="status"
          className={
            order?.status === "DELIVERED"
              ? "green"
              : order?.status === "DISPATCHED"
                ? "yellow"
                : order?.status === "CONFIRMED"
                  ? "blue"
                  : order?.status === "CANCELLED"
                    ? "red"
                    : "grey"
          }
        >
          {order?.status ? order?.status : "N/A"}
        </Avatar>
      ),
    },
    {
      label: "Order Id",
      value: order?._id || "N/A",
    },
    {
      label: "HSN/SAC",
      value: order?.product?.HSN_SAC || "N/A",
    },
    {
      label: "Quantity",
      value: order?.product?.quantity || "N/A",
    },
    {
      label: "Unit Price",
      value:
        (
          order?.receipt?.amountSplit?.baseAmount -
          (order?.receipt?.amountSplit?.gst?.igst +
            order?.receipt?.amountSplit?.gst?.cgst +
            order?.receipt?.amountSplit?.gst?.sgst)
        ).toFixed(2) || "N/A",
    },
    {
      label: "GST",
      value:
        (
          order?.receipt?.amountSplit?.gst?.igst +
          order?.receipt?.amountSplit?.gst?.cgst +
          order?.receipt?.amountSplit?.gst?.sgst
        ).toFixed(2) || "N/A",
    },
    {
      label: "Order Total",
      value: order?.amount?.toFixed(2) || "N/A",
    },
    {
      label: "Shipping Cost",
      value: order?.receipt?.amountSplit?.shipping?.toFixed(2) || "N/A",
    },
    {
      label: "Grand Total",
      value: order?.receipt?.amount?.toFixed(2) || "N/A",
    },
    { header: "Shipping Details" },
    {
      label: "Provider",
      value: order?.shippings[0]?.provider || "N/A",
    },
    {
      label: "Shipment Id",
      value: order?.shippings[0]?.shipmentId || "N/A",
    },
    {
      label: "BOLT UID",
      value: order?.shippings[0]?.itemId || "N/A",
    },
    {
      label: "Dispatch Date",
      value: moment(order?.shippings[0]?.dispatchDate).format(
        "DD-MM-YYYY hh:mm A",
      ),
    },
    {
      label: "Billing Address",
      value:
        order?.billingAddress?.address1 +
          ", " +
          order?.billingAddress?.address2 +
          ", " +
          order?.billingAddress?.city +
          ", " +
          order?.billingAddress?.state +
          ", " +
          order?.billingAddress?.postalCode || "N/A",
    },
    {
      label: "Shipping Address",
      value:
        order?.shippings[0]?.shippingAddress?.address1 +
          ", " +
          order?.shippings[0]?.shippingAddress?.address2 +
          ", " +
          order?.shippings[0]?.shippingAddress?.city +
          ", " +
          order?.shippings[0]?.shippingAddress?.state +
          ", " +
          order?.shippings[0]?.shippingAddress?.postalCode || "N/A",
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          {order?.user?.firstName + " " + order?.user?.lastName || "-"}
          <Box display={"flex"} alignItems={"center"}>
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              sx={{
                background: "none",
                ":hover": { background: "none" },
                maxHeight: 30,
                mr: 2,
              }}
              onClick={() => downloadInvoice(order?.invoice?._id)}
            >
              <span style={{ fontSize: 12 }}>Invoice</span>
              <FileDownload sx={{ ml: 0.5, fontSize: 14 }} />
            </Button>

            <IconButton
              children={<CloseIcon />}
              color="inherit"
              size="small"
              onClick={() => drawer.close()}
            />
          </Box>
        </Box>
        <Box flexGrow={1} overflow="auto" pb={4}>
          <Box
            sx={{
              px: 3,
              pt: 2.5,
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.5,
                  px: 2,
                  "&.secondary": {
                    color: "text.secondary",
                  },
                },
                "& .bold": {
                  fontWeight: 600,
                  width: "40%",
                },
                "& .header": {
                  position: "relative",
                  "& td": {
                    py: 2,
                    position: "absolute",
                    verticalAlign: "middle",
                    backgroundColor: (theme: any) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 8.5,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {table.map(({ header, label, value }, i) => {
                  const isFirst = table[i - 1]?.header;
                  const isLast = !table[i + 1] || table[i + 1].header;

                  return (
                    <tr
                      key={i}
                      className={
                        header
                          ? "header"
                          : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                      }
                    >
                      {header ? (
                        <td colSpan={2}>
                          <span>{header.toUpperCase()}</span>
                        </td>
                      ) : (
                        <>
                          <td className="bold">{label}</td>
                          <td
                            className={
                              value === "Loading..." ? "secondary" : ""
                            }
                          >
                            {value}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default InfoDrawer;
