import { Box, Paper, useTheme } from "@mui/material";
import ActiveUsers from "./ActiveUsers";
import ChargerAccess from "./ChargerAccess";
import ChargerAvailability from "./ChargerAvailability";
import OverviewMap from "./OverviewMap";
import NewUsers from "./NewUsers";
import OperationalStatus from "./OperationalStatus";
import TopHosts from "./TopHosts";
import TopLocations from "./TopLocations";
import { BOLT_URL, CMS_STATS_URL } from "utils/constants";
import { authorizedFetch } from "utils";
import { useQuery } from "react-query";
import TopChargers from "./TopChargers";

const Cards = ({ params }: any) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const {
    startTime,
    endTime,
    vehicleType,
    chargerType,
    accessType,
    city,
    masterView,
  } = params;

  const { isLoading: chargerCountLoading, data: ChargerCountData } = useQuery(
    [
      "GET cms-stats /metrics/chargers/counts",
      startTime,
      endTime,
      vehicleType,
      chargerType,
      accessType,
      city,
      masterView,
    ],
    () =>
      authorizedFetch(
        `${CMS_STATS_URL}/metrics/chargers/counts?${vehicleType}${chargerType}${city}&master=${masterView}`,
      ),
  );

  const locations = city.split("&city=").slice(1).join("_");

  const { isLoading: statsLoading, data: statsData } = useQuery(
    ["GET bolt /company/stats/all", masterView, locations],
    () =>
      authorizedFetch(`${BOLT_URL}/company/stats/all?city=${locations}`, {
        headers: {
          cache: "true",
          master: masterView,
        },
      }),
  );

  return (
    <>
      <Box
        sx={{
          pb: 4,
          display: "grid",
          gridTemplateColumns: {
            lg: "repeat(12, 1fr)",
          },
          gap: { xs: 2, md: 4.5 },
          "& .MuiPaper-root": {
            borderRadius: "14px",
            boxShadow: (theme) => theme.customShadows.card,
            height: { xs: 300, md: 438 },
            bgcolor: isDarkMode ? "#131313" : undefined,
            backgroundImage: isDarkMode ? "none" : undefined,
          },
        }}
      >
        <Paper
          sx={{
            p: { xs: 3, md: 3.5 },
            gridColumn: { lg: "span 6" },
          }}
        >
          <ChargerAvailability
            // {...{ chargerCountLoading, ChargerCountData }}
            {...{ statsLoading, statsData }}
          />
        </Paper>
        <Box sx={{ gridColumn: { lg: "span 6" } }}>
          <ChargerAccess {...{ chargerCountLoading, ChargerCountData }} />
        </Box>
        <Paper
          sx={{
            p: { xs: 3, md: 3.5 },
            gridColumn: { lg: "span 6" },
            height: { xs: "auto !important", md: 438 },
          }}
        >
          <OperationalStatus {...{ chargerCountLoading, ChargerCountData }} />
        </Paper>
        <Paper
          sx={{
            gridColumn: { lg: "span 6" },
            overflow: "hidden",
            height: { xs: 260, md: 438 },
          }}
        >
          <OverviewMap
            params={params}
            // isLoading={statsLoading}
            // data={statsData}
          />
        </Paper>
        <Box sx={{ gridColumn: { lg: "span 6" } }}>
          <TopLocations params={params} />
        </Box>
        <Box
          sx={{
            gridColumn: { lg: "span 6" },
            height: { xs: 390, md: 438 },
            "& > .MuiPaper-root": {
              height: "100% !important",
            },
          }}
        >
          <TopChargers params={params} />
        </Box>
        <Box sx={{ gridColumn: { lg: "span 6" } }}>
          <ActiveUsers params={params} />
        </Box>
        <Box sx={{ gridColumn: { lg: "span 6" } }}>
          <NewUsers params={params} />
        </Box>
        <Box
          sx={{
            gridColumn: { lg: "span 6" },
            height: { xs: 390, md: 438 },
            "& > .MuiPaper-root": {
              height: "100% !important",
            },
          }}
        >
          <TopHosts params={params} />
        </Box>
      </Box>
    </>
  );
};

export default Cards;
