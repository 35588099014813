import { Add, HighlightOff, InfoOutlined, Remove } from "@mui/icons-material";
import { AdapterDateFns as DateAdapter } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import CircularLoader from "components/CircularLoader";
import { queryClient } from "index";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

import { authorizedFetch, setLoader, snackbar } from "utils";
import { RETAIL_URL, TRIGGER_URL } from "utils/constants";
import storageManager from "utils/storageManager";
import validator from "validator";
import Commands from "./Commands";
import Config from "./Config";

// const TRIGGER_URL_PROD = "https://trigger.revos.in";÷ß

const VehicleSettings = ({ open, isLoading, vehicle, onClose }: any) => {
  const token = storageManager.get("companyToken") || 1234;
  const vin = vehicle?.vin || "";

  const defaultTab = 0;
  const [tab, setTab] = useState(defaultTab);

  const existingValues = useMemo(() => {
    const config =
      vehicle?.config?.vehicleConfigValues ||
      vehicle?.model?.config?.configs ||
      {};

    return {
      overVoltageLimit: Number(config?.overVoltageLimit || 0),
      underVoltageLimit: Number(config?.underVoltageLimit || 0),
      batteryMinVoltage: Number(config?.batteryMinVoltage || 0),
      batteryMaxVoltage: Number(config?.batteryMaxVoltage || 0),
      ...(vehicle?.model?.protocol !== "PNP"
        ? {
            speedLimit: Number(config?.speedLimit || 0),
            pickupControlLimit: Number(config?.pickupControlLimit || 0),
            brakeRegenLimit: Number(config?.brakeRegenLimit || 0),
            zeroThrottleRegenLimit: Number(config?.zeroThrottleRegenLimit || 0),
            currentLimit: Number(config?.currentLimit || 0),
            hillAssistStatus: Boolean(config?.hillAssistStatus),
            eabsStatus: Boolean(config?.eabsStatus),
          }
        : {}),
    };
  }, [vehicle]);

  // const settingsObj: { [key: string]: any } = {
  //   Antitheft: { enabled: false },
  //   "Geofence Alert": { enabled: false },
  //   Ignition: { enabled: false },
  //   "Device Plugged/Unplugged/Power Cut Off": { enabled: false },
  //   Overspeed: { enabled: false, value: "" },
  //   Curfew: { enabled: false, value: "" },
  //   "Offline Time": { enabled: false, value: "" },
  //   "Stop Time": { enabled: false, value: "" },
  //   "Idle Time": { enabled: false, value: "" },
  // };

  const [values, setValues] = useState<any>({ ...existingValues });
  // const [settings, setSettings] = useState<{ [key: string]: any }>({
  //   ...settingsObj,
  // });
  const [ampm, setAmpm] = useState(false);
  const [curfewStart, setCurfewStart] = useState<Date | null>(null);
  const [curfewEnd, setCurfewEnd] = useState<Date | null>(null);
  const [recepientPhone, setRecepientPhone] = useState("");
  const [recepientEmail, setRecepientEmail] = useState("");

  const [validations, setValidations] = useState({
    phone: true,
    email: true,
  });

  console.log(values);

  // const {
  //   data: notificationsData,
  //   isLoading: notificationsLoading,
  //   // remove,
  //   // refetch,
  // } = useQuery(
  //   ["getNotificationSettings", token, vin],
  //   () =>
  //     fetch(`${TRIGGER_URL_PROD}/getsettings?token=${token}&vin=${vin}`, {
  //       method: "GET",
  //       headers: {
  //         token: "1234",
  //       },
  //     }).then((res) => res.json()),
  //   {
  //     enabled: Boolean(vin),
  //   }
  // );

  useEffect(() => {
    if (open) {
      setTab(defaultTab);
    }
  }, [open]);

  useEffect(() => {
    setValues({ ...existingValues });
  }, [existingValues]);

  // useEffect(() => {
  //   if (notificationsData && notificationsData.status === 200) {
  //     let { channels, notification } = notificationsData.response;

  //     setRecepientPhone(
  //       (channels || []).find((el: any) => el.type === "SMS")?.value || ""
  //     );
  //     setRecepientEmail(
  //       (channels || []).find((el: any) => el.type === "Email")?.value || ""
  //     );
  //     // if (channels.length) {
  //     //   setRecepientPhone(channels[0]);
  //     //   if (channels.length > 1) {
  //     //     setRecepientEmail(channels[1]);
  //     //   }
  //     // }

  //     let newSettings = { ...settingsObj };
  //     Object.keys(settings).forEach((el) => {
  //       let existing = notification.find(
  //         (existing: any) => existing.name === el
  //       );
  //       if (existing) {
  //         newSettings[el].enabled = true;
  //         if (
  //           [
  //             "Overspeed",
  //             "Curfew",
  //             "Offline Time",
  //             "Stop Time",
  //             "Idle Time",
  //           ].includes(el)
  //         ) {
  //           let value = existing.trigger?.value;
  //           value = el === "Curfew" ? value?.equalTo : value?.greaterThan;
  //           newSettings[el].value = value;
  //           if (el === "Curfew") {
  //             setCurfewStart(moment(value?.split("R")[0], "HH:mm:ss").toDate());
  //             setCurfewEnd(moment(value?.split("R")[1], "HH:mm:ss").toDate());
  //           }
  //         }
  //       }
  //     });
  //     setSettings(newSettings);
  //   }
  //   // eslint-disable-next-line
  // }, [notificationsData, notificationsLoading]);

  // function handleChange(label: string, value?: any) {
  //   if (value === undefined) {
  //     setSettings((prev) => {
  //       prev[label].enabled = !prev[label].enabled;
  //       return { ...prev };
  //     });
  //   } else {
  //     setSettings((prev) => {
  //       prev[label].value = value;
  //       return { ...prev };
  //     });
  //   }
  // }

  function handleConfigSave() {
    setLoader(true);
    authorizedFetch(
      `${RETAIL_URL}/sold-vehicles/vehicle-config/${vehicle?._id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          vehicleConfigValues: values,
        },
      },
    ).then((res) => {
      setLoader(false);
      if (res?.meta?.success) {
        snackbar.success("Updated vehicle config");
        queryClient.resetQueries("getVehicle");
      } else {
        snackbar.success("Error updating vehicle config");
        console.error(res);
      }
    });
  }

  // function handleNotificationsSave() {
  //   let isPhoneValid = recepientPhone ? recepientPhone.length === 10 : true;
  //   let isEmailValid = recepientEmail
  //     ? validator.isEmail(recepientEmail)
  //     : true;
  //   setValidations({
  //     phone: isPhoneValid,
  //     email: isEmailValid,
  //   });
  //   if (!isPhoneValid || !isEmailValid) return;
  //   setLoader(true);
  //   const channel = [
  //     {
  //       type: "SMS",
  //       value: recepientPhone,
  //     },
  //     {
  //       type: "Email",
  //       value: recepientEmail,
  //     },
  //   ];
  //   let data: any = [];
  //   Object.keys(settings).forEach((el: any) => {
  //     if (settings[el].enabled) {
  //       data.push({
  //         name: el,
  //         value:
  //           el === "Curfew"
  //             ? moment(curfewStart).format("HH:mm:ss") +
  //               "R" +
  //               moment(curfewEnd).format("HH:mm:ss")
  //             : Object.keys(settings[el]).find((el) => el === "value")
  //             ? settings[el].value
  //             : "",
  //       });
  //     }
  //   });
  //   fetch(`${TRIGGER_URL_PROD}/notificationconfig`, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       data,
  //       vin: vehicle?.vin,
  //       type: "VEHICLE",
  //       channel,
  //     }),
  //   })
  //     .then((res) => {
  //       setLoader(false);
  //       remove();
  //       refetch();
  //       snackbar.success("Saved changes");
  //     })
  //     .catch((err) => {
  //       setLoader(false);
  //       snackbar.error("An error occurred");
  //       console.error(err);
  //     });
  // }

  const isPnp = vehicle?.model?.protocol === "PNP";
  const hasChanges = JSON.stringify(existingValues) !== JSON.stringify(values);

  // Notification Settings

  // APIS

  const vehicleTriggersURL = `${TRIGGER_URL}/vehicle-trigger/${vin}`;

  const { data: triggerData, isLoading: triggersLoading } = useQuery(
    ["getTriggersSettings", token, vin],
    () => authorizedFetch(vehicleTriggersURL),
    {
      enabled: Boolean(vin),
    },
  );

  const compatibleTriggersURL = `${TRIGGER_URL}/triggers/${vin}`;

  const { data: compatibleData, isLoading: compatibleLoading } = useQuery(
    ["getComaptibleTriggers", token, vin],
    () => authorizedFetch(compatibleTriggersURL),
    {
      enabled: Boolean(vin),
    },
  );

  // Storing data

  const [compatibleSettingObj, setCompatibleSettingsObj] = useState<any>(null);
  const [enabledSettingObj, setEnabledSettingsObj] = useState<any>({});

  useEffect(() => {
    if (compatibleData) {
      let newSettings: any = {};
      const sortedData = [...compatibleData?.data];
      sortedData.forEach(function (item: any, i: any) {
        if (!item.inputType) {
          sortedData.splice(i, 1);
          sortedData.unshift(item);
        }
      });
      sortedData.forEach(function (item: any, i: any) {
        if (item.key === "ANTITHEFT") {
          sortedData.splice(i, 1);
          sortedData.unshift(item);
        }
      });
      sortedData?.forEach((el: any) => {
        newSettings[el.key] = {
          enabled: false,
          inputType: el.inputType ? el.inputType : "CHECKBOX",
          description: el.description,
        };
        if (el.inputType) {
          newSettings[el.key]["value"] =
            el.dataType === "time_int"
              ? {
                  minutes: 0,
                  hours: 0,
                  days: 0,
                }
              : el.dataType === "int"
                ? 0
                : "";
        }
        if (el.options) {
          newSettings[el.key]["options"] = el.options;
        }
      });

      setCompatibleSettingsObj(newSettings);
    }
  }, [compatibleData]);

  useEffect(() => {
    if (
      compatibleSettingObj &&
      triggerData &&
      triggerData?.data?.length !== 0
    ) {
      let newSettings = { ...compatibleSettingObj };
      Object.keys(compatibleSettingObj).forEach((el) => {
        let existing = triggerData?.data[0]?.triggers?.find(
          (existing: any) => existing.key === el,
        );

        if (existing) {
          newSettings[el].enabled = true;
          if (
            [
              "BATTERY_LOW",
              "OVERSPEEDING",
              "OFFLINE_TIME",
              "STOP_TIME",
              "IDLE_TIME",
            ].includes(el)
          ) {
            let value =
              existing?.key === "CURFEW"
                ? existing?.range
                : ["OFFLINE_TIME", "IDLE_TIME", "STOP_TIME"].includes(
                      existing.key,
                    )
                  ? convertMsToTime(existing?.threshold)
                  : existing?.threshold;

            newSettings[el].value = value;
          }
          if (el === "CURFEW") {
            setCurfewEnd(new Date(existing?.range?.high));
            setCurfewStart(new Date(existing?.range?.low));
            newSettings[el].value = existing?.range;
          }
        }
      });

      setEnabledSettingsObj(newSettings);
      setRecepientEmail(triggerData?.data[0]?.otherSubscribers?.email[0] || "");
      setRecepientPhone(triggerData?.data[0]?.otherSubscribers?.phone[0] || "");
    } else {
      setEnabledSettingsObj(compatibleSettingObj);
    }
  }, [compatibleSettingObj, triggerData]);

  // Helper functions

  function handleChangeNew(label: string, value?: any) {
    if (value === undefined) {
      setEnabledSettingsObj((prev: any) => {
        prev[label].enabled = !prev[label].enabled;
        return { ...prev };
      });
    } else {
      setEnabledSettingsObj((prev: any) => {
        prev[label].value = value;
        return { ...prev };
      });
    }
  }

  function convertMsToTime(ms: number) {
    let seconds = Math.floor(ms / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    hours = hours % 24;
    minutes = minutes % 60;

    return {
      days: days,
      hours: hours,
      minutes: minutes,
    };
  }

  function convertTimeToMs({ days = 0, hours = 0, minutes = 0 }) {
    const totalSeconds = minutes * 60 + hours * 60 * 60 + days * 24 * 60 * 60;
    return totalSeconds * 1000;
  }

  const formatLabel = (word: string) => {
    console.log(word);
    const words = word.toLowerCase().split("_");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1),
    );
    const result = capitalizedWords.join(" ");

    return result;
  };

  // POST updates

  function handleNotificationsSaveNew() {
    let isPhoneValid =
      recepientPhone.constructor === String
        ? recepientPhone.length === 10
        : true;
    let isEmailValid =
      recepientEmail.constructor === String
        ? validator.isEmail(recepientEmail)
        : true;
    setValidations({
      phone: isPhoneValid,
      email: isEmailValid,
    });
    if (!isPhoneValid || !isEmailValid) return;
    setLoader(true);
    const otherSubscribers = {
      email: [recepientEmail],
      phone: [recepientPhone],
    };

    let trigger: any = [];
    Object.keys(enabledSettingObj).forEach((el: any) => {
      if (enabledSettingObj[el].enabled) {
        let triggerObj: any = {};
        triggerObj["key"] = el;
        if ("value" in enabledSettingObj[el] && el !== "CURFEW") {
          triggerObj["threshold"] = [
            "OFFLINE_TIME",
            "IDLE_TIME",
            "STOP_TIME",
          ].includes(el)
            ? Number(convertTimeToMs(enabledSettingObj[el]?.value))
            : Number(enabledSettingObj[el]?.value);
        }
        if (el === "CURFEW") {
          triggerObj["range"] = {
            high: curfewEnd?.getTime(),
            low: curfewStart?.getTime(),
          };
        }
        trigger.push(triggerObj);
      }
    });

    authorizedFetch(`${TRIGGER_URL}/vehicle-trigger/${vehicle?.vin}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: "1234",
      },
      body: {
        channels: ["EMAIL", "SMS", "POPUP_NOTIFICATION", "INAPP_NOTIFICATION"],
        otherSubscribers,
        triggers: trigger,
      },
    }).then((res) => {
      setLoader(false);
      if (res?.meta?.success) {
        snackbar.success("Updated vehicle notification settings");
        queryClient.resetQueries("getTriggersSettings");
        queryClient.resetQueries("getCompatibleTriggers");
      } else {
        snackbar.error("Error updating vehicle notification settings");
      }
    });
  }

  // function checkNullValues(obj: any) {
  //   for (var key in obj) {
  //     if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box display="flex" alignItems="center">
          Settings
          <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
          <Typography color="text.secondary">Vin: {vehicle?.vin}</Typography>
        </Box>
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={onClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent>
        <Box pt={1} pb={4}>
          <Tabs sx={{ mb: 4 }} value={tab} onChange={(e, tab) => setTab(tab)}>
            <Tab label="Commands" />
            <Tab
              iconPosition="end"
              label="Limits"
              icon={
                <Tooltip
                  title={"Voltage limits can't be set from Vehicle Settings"}
                >
                  <InfoOutlined
                    sx={{ fontSize: 14, cursor: "pointer" }}
                    color="disabled"
                  />
                </Tooltip>
              }
            />
            <Tab label="Notifications" />
          </Tabs>
          {isLoading ? (
            <CircularLoader />
          ) : (
            <>
              {tab === 0 && <Commands {...{ vehicle }} />}
              {tab === 1 && <Config {...{ isPnp, values, setValues }} />}
              {/* {tab === 2 && (
                <Box
                  sx={{
                    width: 1,
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr",
                    rowGap: 1.5,
                    columnGap: 1,
                  }}
                >
                  {notificationsLoading ? (
                    <CircularProgress />
                  ) : (
                    Object.keys(settings).map((label, i) => {
                      if (i > 3)
                        return (
                          <Box
                            key={i}
                            sx={{
                              gridColumn: "span 2",
                              display: "grid",
                              gridTemplateColumns: "minmax(130px, auto) 1fr",
                              gap: 1,
                              alignItems: "start",
                            }}
                          >
                            <FormControlLabel
                              key={i}
                              control={
                                <Checkbox
                                  // size="small"
                                  checked={settings[label].enabled}
                                  onChange={() => handleChange(label)}
                                />
                              }
                              label={label}
                            />
                            {label === "Offline Time" ? (
                              <Collapse in={settings[label].enabled}>
                                <Box display="flex" alignItems={"center"}>
                                  <Box display="flex" alignItems={"center"}>
                                    <Box
                                      sx={{
                                        width: 100,
                                        height: 40,
                                        border: "1px solid #00000050",
                                        borderRadius: 5,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        px: 2,
                                      }}
                                    >
                                      <Typography sx={{ fontSize: 14 }}>
                                        {offLineValues.minValue}
                                      </Typography>
                                      <Typography>mins</Typography>
                                    </Box>
                                    <Button
                                      sx={{
                                        ml: 1,
                                        minWidth: 30,
                                        height: 30,
                                        p: 0,
                                        borderRadius: 1,
                                      }}
                                      variant="outlined"
                                      color="primary"
                                      disabled={offLineValues.minValue <= 0}
                                      onClick={() => {
                                        setOfflineValues({
                                          ...offLineValues,
                                          minValue: offLineValues.minValue - 15,
                                        });
                                      }}
                                    >
                                      <Remove fontSize="small" />
                                    </Button>
                                    <Button
                                      sx={{
                                        ml: 1,
                                        minWidth: 30,
                                        height: 30,
                                        p: 0,
                                        borderRadius: 1,
                                      }}
                                      variant="outlined"
                                      color="primary"
                                      disabled={offLineValues.minValue >= 45}
                                      onClick={() => {
                                        setOfflineValues({
                                          ...offLineValues,
                                          minValue: offLineValues.minValue + 15,
                                        });
                                      }}
                                    >
                                      <Add fontSize="small" />
                                    </Button>
                                  </Box>
                                  <Box
                                    display="flex"
                                    alignItems={"center"}
                                    ml={2}
                                  >
                                    <Box
                                      sx={{
                                        width: 100,
                                        height: 40,
                                        border: "1px solid #00000050",
                                        borderRadius: 5,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        px: 2,
                                      }}
                                    >
                                      <Typography sx={{ fontSize: 14 }}>
                                        {offLineValues.hourValue}
                                      </Typography>
                                      <Typography>hrs</Typography>
                                    </Box>
                                    <Button
                                      sx={{
                                        ml: 1,
                                        minWidth: 30,
                                        height: 30,
                                        p: 0,
                                        borderRadius: 1,
                                      }}
                                      variant="outlined"
                                      color="primary"
                                      disabled={offLineValues.hourValue <= 0}
                                      onClick={() => {
                                        setOfflineValues({
                                          ...offLineValues,
                                          hourValue:
                                            offLineValues.hourValue - 1,
                                        });
                                      }}
                                    >
                                      <Remove fontSize="small" />
                                    </Button>
                                    <Button
                                      sx={{
                                        ml: 1,
                                        minWidth: 30,
                                        height: 30,
                                        p: 0,
                                        borderRadius: 1,
                                      }}
                                      variant="outlined"
                                      color="primary"
                                      disabled={offLineValues.hourValue >= 45}
                                      onClick={() => {
                                        setOfflineValues({
                                          ...offLineValues,
                                          hourValue:
                                            offLineValues.hourValue + 1,
                                        });
                                      }}
                                    >
                                      <Add fontSize="small" />
                                    </Button>
                                  </Box>
                                  <Box
                                    display="flex"
                                    alignItems={"center"}
                                    ml={2}
                                  >
                                    <Box
                                      sx={{
                                        width: 100,
                                        height: 40,
                                        border: "1px solid #00000050",
                                        borderRadius: 5,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        px: 2,
                                      }}
                                    >
                                      <Typography sx={{ fontSize: 14 }}>
                                        {offLineValues.minValue}
                                      </Typography>
                                      <Typography>days</Typography>
                                    </Box>
                                    <Button
                                      sx={{
                                        ml: 1,
                                        minWidth: 30,
                                        height: 30,
                                        p: 0,
                                        borderRadius: 1,
                                      }}
                                      variant="outlined"
                                      color="primary"
                                      disabled={offLineValues.minValue <= 0}
                                      onClick={() => {
                                        setOfflineValues({
                                          ...offLineValues,
                                          minValue: offLineValues.minValue - 15,
                                        });
                                      }}
                                    >
                                      <Remove fontSize="small" />
                                    </Button>
                                    <Button
                                      sx={{
                                        ml: 1,
                                        minWidth: 30,
                                        height: 30,
                                        p: 0,
                                        borderRadius: 1,
                                      }}
                                      variant="outlined"
                                      color="primary"
                                      disabled={offLineValues.minValue >= 45}
                                      onClick={() => {
                                        setOfflineValues({
                                          ...offLineValues,
                                          minValue: offLineValues.minValue + 15,
                                        });
                                      }}
                                    >
                                      <Add fontSize="small" />
                                    </Button>
                                  </Box>
                                </Box>
                              </Collapse>
                            ) : label !== "Curfew" ? (
                              <Collapse in={settings[label].enabled}>
                                <FormControl size="small">
                                  <OutlinedInput
                                    sx={{ maxWidth: 150 }}
                                    size="small"
                                    value={settings[label]?.value || ""}
                                    onChange={(e) => {
                                      if (/^[0-9]{0,4}$/.test(e.target.value))
                                        handleChange(label, e.target.value);
                                    }}
                                    disabled={!settings[label]?.enabled}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        {label === "Overspeed"
                                          ? "km/h"
                                          : "minutes"}
                                      </InputAdornment>
                                    }
                                  />
                                  <FormHelperText>Greater than</FormHelperText>
                                </FormControl>
                              </Collapse>
                            ) : (
                              <LocalizationProvider dateAdapter={DateAdapter}>
                                <Collapse in={settings[label].enabled}>
                                  <Box>
                                    <Box
                                      sx={{
                                        mb: 1,
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <TimePicker
                                        ampm={ampm}
                                        views={["hours", "minutes"]}
                                        label="Start"
                                        OpenPickerButtonProps={{
                                          size: "small",
                                        }}
                                        value={curfewStart}
                                        onChange={(value) => {
                                          setCurfewStart(value);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            size="small"
                                            error={false}
                                          />
                                        )}
                                        disabled={!settings[label]?.enabled}
                                      />
                                      <Box mx={1}>to</Box>
                                      <TimePicker
                                        ampm={ampm}
                                        views={["hours", "minutes"]}
                                        label="End"
                                        OpenPickerButtonProps={{
                                          size: "small",
                                        }}
                                        value={curfewEnd}
                                        onChange={(value) => {
                                          setCurfewEnd(value);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            size="small"
                                            error={false}
                                          />
                                        )}
                                        disabled={!settings[label]?.enabled}
                                      />
                                    </Box>
                                    <FormControlLabel
                                      sx={{ ml: 0 }}
                                      control={
                                        <Switch
                                          size="small"
                                          checked={ampm}
                                          onChange={(e) =>
                                            setAmpm(e.target.checked)
                                          }
                                        />
                                      }
                                      label="AM/PM"
                                    />
                                  </Box>
                                </Collapse>
                              </LocalizationProvider>
                            )}
                          </Box>
                        );
                      else
                        return (
                          <FormControlLabel
                            key={i}
                            control={
                              <Checkbox
                                // size="small"
                                checked={settings[label].enabled}
                                onChange={() => handleChange(label)}
                              />
                            }
                            label={label}
                          />
                        );
                    })
                  )} */}
              {tab === 2 && (
                <Box
                  sx={{
                    width: 1,
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr",
                    rowGap: 5,
                    columnGap: 1,
                    px: 2,
                  }}
                >
                  {compatibleLoading &&
                  triggersLoading &&
                  compatibleSettingObj === null ? (
                    <CircularProgress />
                  ) : (
                    enabledSettingObj !== null &&
                    Object.keys(enabledSettingObj).map((label: any, i) => {
                      if (enabledSettingObj[label]?.inputType !== "CHECKBOX")
                        return (
                          <Box
                            key={i}
                            sx={{
                              gridColumn: "span 2",
                              display: "grid",
                              gridTemplateColumns: "minmax(130px, auto) 1fr",
                              gap: 1,
                              alignItems: "start",
                            }}
                          >
                            {console.log(label)}

                            <FormControlLabel
                              key={i}
                              control={
                                <Checkbox
                                  // size="small"
                                  checked={enabledSettingObj[label].enabled}
                                  onChange={() => handleChangeNew(label)}
                                />
                              }
                              label={
                                <Box width={120}>
                                  <Typography
                                    display="flex"
                                    alignItems={"flex-start"}
                                    // width={200}
                                  >
                                    {formatLabel(label.toString())}

                                    <Tooltip
                                      title={
                                        enabledSettingObj[label]?.description
                                      }
                                    >
                                      <InfoOutlined
                                        sx={{
                                          fontSize: 12,
                                          opacity: 0.5,
                                          ml: 0.5,
                                        }}
                                      />
                                    </Tooltip>
                                  </Typography>
                                </Box>
                              }
                            />

                            {[
                              "OFFLINE_TIME",
                              "IDLE_TIME",
                              "STOP_TIME",
                            ].includes(label) ? (
                              <Collapse in={enabledSettingObj[label].enabled}>
                                <Box display="flex" alignItems={"center"}>
                                  {"MINUTES" in
                                    enabledSettingObj[label]?.options && (
                                    <Box display="flex" alignItems={"center"}>
                                      <Box
                                        sx={{
                                          width: 100,
                                          height: 40,
                                          border: "1px solid #00000030",
                                          borderRadius: 1,
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          px: 2,
                                        }}
                                      >
                                        <Typography sx={{ fontSize: 14 }}>
                                          {
                                            enabledSettingObj[label]?.value
                                              ?.minutes
                                          }
                                        </Typography>
                                        <Typography>mins</Typography>
                                      </Box>
                                      <Button
                                        sx={{
                                          ml: 1,
                                          minWidth: 30,
                                          height: 30,
                                          p: 0,
                                          borderRadius: 1,
                                        }}
                                        variant="outlined"
                                        color="primary"
                                        disabled={
                                          enabledSettingObj[label]?.value
                                            ?.minutes <= 0
                                        }
                                        onClick={() => {
                                          setEnabledSettingsObj((prev: any) => {
                                            prev[label].value.minutes =
                                              prev[label].value.minutes - 15;
                                            return { ...prev };
                                          });
                                          // setOfflineValues({
                                          //   ...offLineValues,
                                          //   minValue:
                                          //   enabledSettingObj[label]?.value?.minutes - 15,
                                          // });
                                        }}
                                      >
                                        <Remove fontSize="small" />
                                      </Button>
                                      <Button
                                        sx={{
                                          ml: 1,
                                          minWidth: 30,
                                          height: 30,
                                          p: 0,
                                          borderRadius: 1,
                                        }}
                                        variant="outlined"
                                        color="primary"
                                        disabled={
                                          enabledSettingObj[label]?.value
                                            ?.minutes >= 45
                                        }
                                        onClick={() => {
                                          setEnabledSettingsObj((prev: any) => {
                                            prev[label].value.minutes =
                                              prev[label].value.minutes + 15;
                                            return { ...prev };
                                          });
                                          // setOfflineValues({
                                          //   ...offLineValues,
                                          //   minValue:
                                          //     offLineValues.minValue + 15,
                                          // });
                                        }}
                                      >
                                        <Add fontSize="small" />
                                      </Button>
                                    </Box>
                                  )}
                                  {"HOURS" in
                                    enabledSettingObj[label]?.options && (
                                    <Box
                                      display="flex"
                                      alignItems={"center"}
                                      ml={3}
                                    >
                                      <Box
                                        sx={{
                                          width: 100,
                                          height: 40,
                                          border: "1px solid #00000030",
                                          borderRadius: 1,
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          px: 2,
                                        }}
                                      >
                                        <Typography sx={{ fontSize: 14 }}>
                                          {
                                            enabledSettingObj[label]?.value
                                              ?.hours
                                          }
                                        </Typography>
                                        <Typography>hours</Typography>
                                      </Box>
                                      <Button
                                        sx={{
                                          ml: 1,
                                          minWidth: 30,
                                          height: 30,
                                          p: 0,
                                          borderRadius: 1,
                                        }}
                                        variant="outlined"
                                        color="primary"
                                        disabled={
                                          enabledSettingObj[label]?.value
                                            ?.hours <= 0
                                        }
                                        onClick={() => {
                                          setEnabledSettingsObj((prev: any) => {
                                            prev[label].value.hours =
                                              prev[label].value.hours - 1;
                                            return { ...prev };
                                          });
                                        }}
                                      >
                                        <Remove fontSize="small" />
                                      </Button>
                                      <Button
                                        sx={{
                                          ml: 1,
                                          minWidth: 30,
                                          height: 30,
                                          p: 0,
                                          borderRadius: 1,
                                        }}
                                        variant="outlined"
                                        color="primary"
                                        disabled={
                                          enabledSettingObj[label]?.value
                                            ?.hours >= 23
                                        }
                                        onClick={() => {
                                          setEnabledSettingsObj((prev: any) => {
                                            prev[label].value.hours =
                                              prev[label].value.hours + 1;
                                            return { ...prev };
                                          });
                                        }}
                                      >
                                        <Add fontSize="small" />
                                      </Button>
                                    </Box>
                                  )}
                                  {"DAYS" in
                                    enabledSettingObj[label]?.options && (
                                    <Box
                                      display="flex"
                                      alignItems={"center"}
                                      ml={3}
                                    >
                                      <Box
                                        sx={{
                                          width: 100,
                                          height: 40,
                                          border: "1px solid #00000030",
                                          borderRadius: 1,
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          px: 2,
                                        }}
                                      >
                                        <Typography sx={{ fontSize: 14 }}>
                                          {
                                            enabledSettingObj[label]?.value
                                              ?.days
                                          }
                                        </Typography>
                                        <Typography>days</Typography>
                                      </Box>
                                      <Button
                                        sx={{
                                          ml: 1,
                                          minWidth: 30,
                                          height: 30,
                                          p: 0,
                                          borderRadius: 1,
                                        }}
                                        variant="outlined"
                                        color="primary"
                                        disabled={
                                          enabledSettingObj[label]?.value
                                            ?.days <= 0
                                        }
                                        onClick={() => {
                                          setEnabledSettingsObj((prev: any) => {
                                            prev[label].value.days =
                                              prev[label].value.days - 1;
                                            return { ...prev };
                                          });
                                        }}
                                      >
                                        <Remove fontSize="small" />
                                      </Button>
                                      <Button
                                        sx={{
                                          ml: 1,
                                          minWidth: 30,
                                          height: 30,
                                          p: 0,
                                          borderRadius: 1,
                                        }}
                                        variant="outlined"
                                        color="primary"
                                        disabled={
                                          enabledSettingObj[label]?.value
                                            ?.days >= 30
                                        }
                                        onClick={() => {
                                          setEnabledSettingsObj((prev: any) => {
                                            prev[label].value.days =
                                              prev[label].value.days + 1;
                                            return { ...prev };
                                          });
                                        }}
                                      >
                                        <Add fontSize="small" />
                                      </Button>
                                    </Box>
                                  )}
                                </Box>
                              </Collapse>
                            ) : label !== "CURFEW" ? (
                              <Collapse
                                in={enabledSettingObj[label].enabled}
                                sx={{ mt: -3 }}
                              >
                                <FormControl size="small">
                                  <FormHelperText>
                                    {label === "OVERSPEEDING"
                                      ? "Greater"
                                      : "Lower"}{" "}
                                    than
                                  </FormHelperText>
                                  <OutlinedInput
                                    sx={{ maxWidth: 150 }}
                                    size="small"
                                    type="number"
                                    value={
                                      enabledSettingObj[label]?.value || ""
                                    }
                                    onChange={(e) => {
                                      if (/^[0-9]{0,4}$/.test(e.target.value))
                                        handleChangeNew(
                                          label,
                                          label === "OVERSPEEDING" &&
                                            Number(e.target.value) > 200
                                            ? "0"
                                            : label === "BATTERY_LOW" &&
                                                Number(e.target.value) > 100
                                              ? "0"
                                              : e.target.value,
                                        );
                                    }}
                                    disabled={
                                      !enabledSettingObj[label]?.enabled
                                    }
                                    endAdornment={
                                      <InputAdornment position="end">
                                        {label === "OVERSPEEDING"
                                          ? "km/h"
                                          : "%"}
                                      </InputAdornment>
                                    }
                                  />
                                  <FormHelperText>
                                    Should be between 0 -{" "}
                                    {label === "OVERSPEEDING" ? "200" : "100"}
                                  </FormHelperText>
                                </FormControl>
                              </Collapse>
                            ) : (
                              <LocalizationProvider dateAdapter={DateAdapter}>
                                <Collapse in={enabledSettingObj[label].enabled}>
                                  <Box>
                                    <Box
                                      sx={{
                                        mb: 1,
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <TimePicker
                                        ampm={ampm}
                                        views={["hours", "minutes"]}
                                        label="Start"
                                        value={curfewStart}
                                        onChange={(value) => {
                                          console.log(
                                            value?.getHours(),
                                            // moment(value).format("HH:mm")
                                          );
                                          setCurfewStart(value);
                                        }}
                                        // renderInput={(params) => (
                                        //   <TextField
                                        //     {...params}
                                        //     size="small"
                                        //     error={false}
                                        //   />
                                        // )}
                                        // componentsProps={{
                                        //   openPickerButton: {
                                        //     size: "small",
                                        //   },
                                        // }}
                                        slotProps={{
                                          textField: {
                                            size: "small",
                                            error: false,
                                          },
                                          openPickerButton: {
                                            size: "small"
                                          }
                                        }}
                                        disabled={
                                          !enabledSettingObj[label]?.enabled
                                        }
                                      />
                                      <Box mx={1}>to</Box>
                                      <TimePicker
                                        ampm={ampm}
                                        views={["hours", "minutes"]}
                                        label="End"
                                        value={curfewEnd}
                                        onChange={(value) => {
                                          setCurfewEnd(value);
                                        }}
                                        // renderInput={(params) => (
                                        //   <TextField
                                        //     {...params}
                                        //     size="small"
                                        //     error={false}
                                        //   />
                                        // )}
                                        // componentsProps={{
                                        //   openPickerButton: {
                                        //     size: "small",
                                        //   },
                                        // }}
                                        slotProps={{
                                          textField: {
                                            size: "small",
                                            error: false,
                                          },
                                          openPickerButton: {
                                            size: "small"
                                          }
                                        }}
                                        disabled={
                                          !enabledSettingObj[label]?.enabled
                                        }
                                      />
                                    </Box>

                                    <FormControlLabel
                                      sx={{ ml: 0 }}
                                      control={
                                        <Switch
                                          size="small"
                                          checked={ampm}
                                          onChange={(e) =>
                                            setAmpm(e.target.checked)
                                          }
                                        />
                                      }
                                      label="AM/PM"
                                    />
                                  </Box>
                                </Collapse>
                              </LocalizationProvider>
                            )}
                          </Box>
                        );
                      else
                        return (
                          <FormControlLabel
                            key={i}
                            control={
                              <Checkbox
                                // size="small"
                                checked={enabledSettingObj[label].enabled}
                                onChange={() => handleChangeNew(label)}
                              />
                            }
                            label={
                              <Typography
                                display="flex"
                                alignItems={"flex-start"}
                              >
                                {formatLabel(label.toString())}
                                <Tooltip
                                  title={enabledSettingObj[label]?.description}
                                >
                                  <InfoOutlined
                                    sx={{
                                      fontSize: 12,
                                      opacity: 0.5,
                                      ml: 0.5,
                                    }}
                                  />
                                </Tooltip>
                              </Typography>
                            }
                          />
                        );
                    })
                  )}
                  <Box
                    gridColumn="span 2"
                    display="flex"
                    flexDirection="column"
                  >
                    <Divider sx={{ mt: 1, mb: 1.5 }} />
                    <Typography variant="h6" sx={{ mb: 1.75 }}>
                      Specify Recepients
                    </Typography>
                    <Box sx={{ maxWidth: 250, width: 1 }}>
                      <TextField
                        fullWidth
                        sx={{ mb: 2 }}
                        size="small"
                        label="Phone"
                        type="number"
                        value={recepientPhone}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              +91
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          if (e.target.value.length <= 10)
                            setRecepientPhone(e.target.value);
                        }}
                        placeholder="10-digit phone number"
                        error={!validations.phone}
                        helperText={
                          !validations.phone
                            ? "Please enter a valid 10-digit phone number"
                            : undefined
                        }
                      />
                      <TextField
                        fullWidth
                        size="small"
                        label="Email"
                        value={recepientEmail}
                        onChange={(e) => setRecepientEmail(e.target.value)}
                        placeholder="example@domain.com"
                        error={
                          recepientEmail !== "" &&
                          !validator.isEmail(recepientEmail)
                        }
                        helperText={
                          recepientEmail !== "" &&
                          !validator.isEmail(recepientEmail)
                            ? "Please enter a valid email"
                            : undefined
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      </DialogContent>
      {tab !== 0 && (
        <DialogActions>
          {tab === 1 && hasChanges && (
            <Button onClick={() => setValues({ ...existingValues })}>
              Undo Changes
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => {
              if (tab === 1) handleConfigSave();
              if (tab === 2) handleNotificationsSaveNew();
            }}
            disabled={
              tab === 2
                ? recepientEmail === "" ||
                  !validator.isEmail(recepientEmail) ||
                  recepientPhone.length !== 10
                : tab === 1
                  ? true
                  : // checkNullValues(values)
                    false
            }
          >
            Save
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default VehicleSettings;
