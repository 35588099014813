import { useEffect, useState } from "react";
import AppBar from "./AppBar";
import { appBar, drawer } from "utils";
import { Avatar, Box, Paper } from "@mui/material";
import moment from "moment";
import Table from "components/Table";

import { useQuery } from "react-query";
import InfoDrawer from "./InfoDrawer";
import { MARKET_URL } from "utils/constants";

const OTA = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const {
    isLoading: ordersLoading,
    data: ordersData,
    refetch: refectData,
  } = useQuery(["getOrdersData"], () =>
    fetch(`${MARKET_URL}/orders`).then((res) => res.json()),
  );

  useEffect(() => {
    appBar.open(<AppBar orders={ordersData} />);
    return () => {
      appBar.close();
      drawer.close();
    };
  }, [ordersData]);

  return (
    <Paper
      sx={{
        width: 1,
        boxShadow: (theme) => theme.customShadows.card,
        borderRadius: 2,
        pt: 3,
      }}
    >
      <Table
        loading={ordersLoading}
        rowCount={ordersData?.data?.length}
        rows={
          ordersData?.data?.sort(
            (a: any, b: any) => +new Date(b.createdAt) - +new Date(a.createdAt),
          ) || []
        }
        activePage={page}
        onRowClick={(row) =>
          drawer.open(<InfoDrawer order={row} refectData={refectData} />)
        }
        activePageSize={pageSize}
        onPageChange={(value) => setPage(value)}
        onPageSizeChange={(value) => setPageSize(value)}
        columns={[
          {
            key: "fullName",
            label: "Full Name",
            valueGetter(row) {
              return `${row?.user?.firstName} ${row?.user?.lastName}`;
            },
          },
          { key: "user.email", label: "Email" },

          { key: "user.phone", label: "Phone No." },

          {
            key: "status",
            label: "Status",
            Render: ({ status }) => {
              return (
                <Avatar
                  variant="status"
                  className={
                    status === "DELIVERED"
                      ? "green"
                      : status === "DISPATCHED"
                        ? "yellow"
                        : status === "CONFIRMED"
                          ? "blue"
                          : status === "CANCELLED"
                            ? "red"
                            : "grey"
                  }
                >
                  {status ? status : "N/A"}
                </Avatar>
              );
            },
          },
          {
            key: "updatedAt",
            label: "Updated On",
            Render: (row: any) => (
              <Box>{moment(row.updatedAt).format("YYYY-MM-DD")}</Box>
            ),
          },
          {
            key: "createddAt",
            label: "Created On",
            Render: (row: any) => (
              <Box>{moment(row.createdAt).format("YYYY-MM-DD")}</Box>
            ),
          },
        ]}
      />
    </Paper>
  );
};

export default OTA;
