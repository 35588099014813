import { InfoOutlined } from "@mui/icons-material";
import { Avatar, Box, Hidden, IconButton, Paper } from "@mui/material";
import Search from "components/Search";
import Table from "components/Table";
import moment from "moment";
import { useEffect } from "react";

import { drawer } from "utils";

import DrawerContent from "./DrawerContent";

const List = ({
  page,
  setPage,
  pageSize,
  setPageSize,
  // search,
  setSearch,
  vendorsLoading,
  vendorsData,
  refetchVendors,
  refetchVendorCounts,
}: any) => {
  useEffect(() => {
    return () => {
      drawer.close();
    };
  }, []);

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);

  return (
    <Paper
      sx={{
        width: 1,
        boxShadow: (theme) => theme.customShadows.small,
        borderRadius: 2,
        pt: 3,
      }}
    >
      <Box
        sx={{
          width: 1,
          px: 3,
          pb: 2.75,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" sx={{ ml: "auto" }}>
          <Hidden implementation="css" mdDown>
            <Box>
              <Search
                handleSearch={(value) => {
                  setSearch(value);
                }}
                persist
                enableClear
              />
            </Box>
          </Hidden>
        </Box>
      </Box>

      <Table
        loading={vendorsLoading}
        rowCount={vendorsData?.data?.count || 0}
        serverSidePagination
        activePage={page}
        activePageSize={pageSize}
        onPageChange={(value) => setPage(value)}
        onPageSizeChange={(value) => setPageSize(value)}
        rows={
          vendorsData?.data?.vendors?.filter((el: any) => el !== null) || []
        }
        columns={[
          {
            key: "name",
            label: "Company Name",
          },
          {
            key: "count",
            label: "Assigned Vehicles",
            format: (value) => (value ? value : 0),
          },
          {
            key: "status",
            label: "Status",
            Render: ({ status }) => (
              <Avatar
                variant="status"
                className={status !== "ACTIVE" ? "yellow" : undefined}
              >
                {status}
              </Avatar>
            ),
          },
          {
            key: "createdAt",
            label: "Created At",
            format: (value) => moment(value).format("DD/MM/YY, hh:mm a"),
          },

          {
            key: "actions",
            label: "Actions",
            Render: (row) => (
              <IconButton
                size="small"
                sx={{
                  color: (theme) => theme.customColors.action,
                }}
                children={<InfoOutlined fontSize="small" />}
                onClick={() =>
                  drawer.open(
                    <DrawerContent
                      key={row.id}
                      vendor={row}
                      refetchVendors={refetchVendors}
                      refetchVendorCounts={refetchVendorCounts}
                    />
                  )
                }
              />
            ),
          },
        ]}
      />
    </Paper>
  );
};

export default List;
