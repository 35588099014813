import { useEffect, useState } from "react";
import { Box, IconButton, Paper } from "@mui/material";
import { Search, Close } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { routes } from "routes";
import { useSelector } from "react-redux";
import { GlobalState, getDarkModePreference } from "utils";

function highlight(search: string, text: string) {
  let re = new RegExp(search, "gi");
  return text.replace(re, (str) => `<b>${str}</b>`);
}

const SearchBox = () => {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState("");

  const activeSection = useSelector(
    (state: GlobalState) => state.global.activeSection,
  );
  const state = useSelector((state: GlobalState) => state);
  const isDarkMode = getDarkModePreference(state);

  // Activate on '/' keypress
  useEffect(() => {
    function handleActivate(e: KeyboardEvent) {
      if (
        (e.key === "/" &&
          !active &&
          !["INPUT", "TEXTAREA"].includes(
            document.activeElement?.tagName || "",
          )) ||
        (e.key === "Escape" && active)
      ) {
        e.preventDefault();
        const searchBar: HTMLInputElement = document.querySelector("#search")!;
        if (!active) searchBar.focus();
        else searchBar.blur();
      }
    }
    document.addEventListener("keydown", handleActivate);
    return function cleanUp() {
      document.removeEventListener("keydown", handleActivate);
    };
  }, [active]);

  const results = activeSection
    ? routes[activeSection]?.filter((route) => {
        let name = route.name.toLowerCase();
        // let description = route.description.toLowerCase();
        let searchValue = value.toLowerCase();
        return name.includes(searchValue);
        // || description.includes(searchValue);
      })
    : [];

  return (
    <Box
      sx={{
        position: "relative",
        "& input": {
          // mr: 0.75,
          p: 1,
          pl: 4.25,
          bgcolor: (theme) =>
            isDarkMode ? theme.palette.background.default : "#f7f7f7",
          border: "1px solid transparent",
          borderRadius: 1,
          color: "text.primary",
          width: "13.15em",
          transition: "all 100ms",
          ":hover": {
            bgcolor: "action.hover",
          },
          "::placeholder": {
            color: "text.secondary",
          },
          ":focus": {
            border: (theme) => "1px solid " + theme.palette.primary.light,
            outline: "none",
            width: "14.5em",
          },
        },
      }}
    >
      <Search
        sx={{
          position: "absolute",
          top: 8,
          left: 8,
          pointerEvents: "none",
        }}
        fontSize="small"
        color="action"
      />
      <input
        id="search"
        type="text"
        placeholder="Jump to..."
        onFocus={() => {
          setActive(true);
        }}
        onBlur={() => {
          setActive(false);
        }}
        autoComplete="off"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {!active && (
        <Box
          sx={{
            position: "absolute",
            top: 5,
            right: 11,
            px: 1,
            pointerEvents: "none",
            userSelect: "none",
            color: "text.secondary",
            borderRadius: 0.5,
            border: (theme) => `1px solid ${theme.customColors.border}`,
          }}
        >
          /
        </Box>
      )}
      {value && active && (
        <>
          <IconButton
            size="small"
            sx={{ position: "absolute", top: 2.5, right: 9.5 }}
            onMouseDown={(e) => e.preventDefault()}
            onClick={(e) => {
              e.preventDefault();
              setValue("");
            }}
          >
            <Close fontSize="small" />
          </IconButton>
          <Paper
            elevation={8}
            sx={{
              position: "absolute",
              minWidth: 232,
              overflow: "hidden",
              top: "2.5em",
              right: 0,
              borderRadius: 1.5,
              boxShadow: (theme) => theme.shadows[3],
            }}
            onMouseDown={(e) => e.preventDefault()}
          >
            {results.length > 0 ? (
              <Box
                sx={{
                  maxHeight: "50vh",
                  overflow: "auto",
                }}
              >
                {results.map((result, i) => (
                  <Box
                    key={i}
                    component={NavLink}
                    to={result.path}
                    sx={{
                      m: 0.75,
                      mb: 0,
                      px: 1,
                      py: 0.75,
                      display: "flex",
                      flexDirection: "column",
                      color: "text.primary",
                      textDecoration: "none",
                      borderRadius: 1,
                      "&:hover": {
                        background: (theme) => theme.palette.action.hover,
                      },
                      "& .title": {
                        fontSize: "1em",
                        fontWeight: 500,
                        mb: 0.5,
                      },
                      "& .description": {
                        fontSize: "0.9em",
                        color: "text.secondary",
                        display: "flex",
                        flexDirection: "column",
                      },
                      // '&:first-of-type': {
                      //   mt: 0.75,
                      // },
                      "&:last-of-type": {
                        mb: 0.75,
                      },
                    }}
                    onClick={() => {
                      setValue("");
                      const searchBar: HTMLInputElement =
                        document.querySelector("#search")!;
                      searchBar.blur();
                    }}
                  >
                    <span
                      className="title"
                      dangerouslySetInnerHTML={{
                        __html: highlight(value, result.name),
                      }}
                    />
                    {/* <span className="description">
                      {result.description.split(" | ").map((el, i, arr) => (
                        <Box key={i} display="flex">
                          <Divider
                            flexItem
                            orientation="vertical"
                            sx={{ mx: 1 }}
                          />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: highlight(value, el),
                            }}
                          />
                        </Box>
                      ))}
                    </span> */}
                  </Box>
                ))}
              </Box>
            ) : (
              <Box
                sx={{
                  width: 1,
                  height: 100,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                No results found for "{value}"
              </Box>
            )}
          </Paper>
        </>
      )}
    </Box>
  );
};

export default SearchBox;
