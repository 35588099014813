import { InfoOutlined, Sort } from "@mui/icons-material";
import {
  Avatar,
  Box,
  FormControl,
  Hidden,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Tooltip,
} from "@mui/material";
import Search from "components/Search";
import Table from "components/Table";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { authorizedFetch, drawer, titleCase } from "utils";
import { LEASE_URL } from "utils/constants";
import DrawerContent from "./DrawerContent";

const List = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(0);

  const usersUrl = `${LEASE_URL}/company/users/all?first=${pageSize}&skip=${
    pageSize * (page - 1)
  }&search=${search}&status=${
    status === 0
      ? ""
      : status === 1
      ? "ACTIVE"
      : status === 2
      ? "INACTIVE"
      : status === 3
      ? "DELETED"
      : status === 4
      ? "PENDING_APPROVAL"
      : "INCOMPLETE_PROFILE"
  }`;

  const { isLoading, data } = useQuery(
    ["getUsers", page, pageSize, search, status],
    () => authorizedFetch(usersUrl)
  );

  useEffect(() => {
    return () => {
      drawer.close();
    };
  }, []);

  return (
    <Paper
      sx={{
        width: 1,
        boxShadow: (theme) => theme.customShadows.small,
        borderRadius: 2,
        pt: 3,
      }}
    >
      <Box
        sx={{
          width: 1,
          px: 3,
          pb: 2.75,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" width={1}>
          <Tooltip title="User Status" placement="top-start">
            <FormControl sx={{ mr: 2 }}>
              <Select
                size="small"
                sx={{ width: "auto" }}
                value={status}
                onChange={(e: any) => setStatus(e.target.value)}
                startAdornment={<Sort sx={{ mr: 1 }} />}
                renderValue={(selected) =>
                  selected === 0
                    ? "Status : All"
                    : selected === 1
                    ? "Status: Active"
                    : selected === 2
                    ? "Status: Inactive"
                    : selected === 3
                    ? "Status: Deleted"
                    : selected === 4
                    ? "Status: Pending Approval"
                    : "Status: Incomplete Profile"
                }
              >
                <MenuItem value={0}>All</MenuItem>
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={2}>Inactive</MenuItem>
                <MenuItem value={3}>Deleted</MenuItem>
                <MenuItem value={4}>Pending Approval</MenuItem>
                <MenuItem value={5}>Incomplete Profile</MenuItem>
              </Select>
            </FormControl>
          </Tooltip>
        </Box>

        <Box display="flex" sx={{ ml: "auto" }}>
          <Hidden implementation="css" mdDown>
            <Box>
              <Search
                handleSearch={(value) => {
                  setSearch(value);
                }}
                persist
                enableClear
              />
            </Box>
          </Hidden>
        </Box>
      </Box>

      <Table
        loading={isLoading}
        rowCount={data?.data?.count || 0}
        serverSidePagination
        activePage={page}
        activePageSize={pageSize}
        onPageChange={(value) => setPage(value)}
        onPageSizeChange={(value) => setPageSize(value)}
        rows={data?.data?.users?.filter((el: any) => el !== null) || []}
        columns={[
          {
            key: "firstName",
            label: "Name",
            Render: (row) => {
              let name = row.firstName
                ? row.firstName.charAt(0).toUpperCase() +
                  row.firstName.slice(1) +
                  " " +
                  row.lastName.charAt(0).toUpperCase() +
                  row.lastName.slice(1)
                : "Not Found";
              return name;
            },
          },
          {
            key: "phone",
            label: "Phone Number",
            Render: (row) => {
              let phone = row.phone
                ? row.phone.charAt(0) === `+`
                  ? row.phone.slice(0, 3) + " - " + row.phone.slice(3)
                  : "+91 - " + row.phone
                : "Not Found";
              return phone;
            },
          },
          {
            key: "email",
            label: "Email",
            format: (value) => (value ? value.toLowerCase() : "Not Found"),
          },
          {
            key: "status",
            label: "Status",
            Render: ({ status }) => (
              <Avatar
                variant="status"
                className={
                  status === "ACTIVE"
                    ? "green"
                    : status === "INACTIVE"
                    ? "grey"
                    : status === "DELETED"
                    ? "red"
                    : status === "PENDING_APPROVAL"
                    ? "blue"
                    : status === "INCOMPLETE_PROFILE"
                    ? "yellow"
                    : ""
                }
              >
                {status ? status.split("_").map(titleCase).join(" ") : "N/A"}
              </Avatar>
            ),
          },
          {
            key: "actions",
            label: "Actions",
            Render: (row) => (
              <IconButton
                size="small"
                sx={{
                  color: (theme) => theme.customColors.action,
                }}
                children={<InfoOutlined fontSize="small" />}
                onClick={() =>
                  drawer.open(<DrawerContent key={row.id} user={row} />)
                }
              />
            ),
          },
        ]}
      />
    </Paper>
  );
};

export default List;
