import {
  CheckBox,
  CheckBoxOutlineBlank,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Checkbox,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

const Selector = ({
  loading,
  isCMS,
  isFMS,
  isCUGMonitorAccount,
  toggle,
  chargerOptions,
  vendorOptions,
  userOptions,
  cugOptions,
  fmsVehicleOptions,
  vmsVehicleOptions,
  selected,
  setSelected,
  selectedBatch,
  setSelectedBatch,
  search,
  setSearch,
}: any) => {
  const [focused, setFocused] = useState(false);
  const isMultiple = isCMS && (toggle === "chargers" || toggle === "esg");
  const isVMS = !isCMS && !isFMS;

  const realWidth = 280;
  const width = focused && selectedBatch.length > 2 ? 420 : realWidth;

  return (
    <Box width={realWidth} height={40}>
      <Autocomplete
        {...(isMultiple
          ? {
              multiple: true,
              disableCloseOnSelect: true,
              renderOption: (props, option: any, { selected }) => {
                const isMoreLabel = String(option.label || "").includes(
                  "more...",
                );

                return (
                  <li {...props} style={{ padding: "2px 6px" }}>
                    {!isMoreLabel ? (
                      <Checkbox
                        size="small"
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBox fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                    ) : null}
                    <Typography p={isMoreLabel ? 1 : 0}>
                      {option.label}
                    </Typography>
                  </li>
                );
              },
            }
          : isVMS
            ? {
                renderOption: (props, option) => {
                  const isMoreLabel = String(option || "").includes("more...");
                  return (
                    <li {...props}>
                      <Typography p={isMoreLabel ? 1 : 0}>{option}</Typography>
                    </li>
                  );
                },
              }
            : {})}
        loading={loading}
        sx={{ width: width, position: "absolute", zIndex: 3 }}
        limitTags={1}
        size="small"
        inputValue={search}
        onInputChange={(e, value) => setSearch(value)}
        popupIcon={<KeyboardArrowDown />}
        value={isMultiple ? selectedBatch : selected}
        getOptionLabel={(option: any) =>
          typeof option === "string" ? option : option.label
        }
        getOptionDisabled={(option: any) => {
          if (option?.label?.includes("more...")) return true;
          else if (
            isMultiple &&
            selectedBatch.length >= 10 &&
            !selectedBatch.some((el: any) => el.id === option.id)
          )
            return true;
          else if (
            selectedBatch.some((el: any) => el.id === "all") &&
            option.id !== "all"
          )
            return true;
          else return false;
        }}
        onChange={(e, newValue: any) => {
          if (isCMS && (toggle === "chargers" || toggle === "esg"))
            setSelectedBatch(newValue);
          else setSelected(newValue);
        }}
        isOptionEqualToValue={(option: any, value: any) =>
          !!option.id ? option.id === value.id : option === value
        }
        options={
          !isCMS
            ? isFMS
              ? fmsVehicleOptions
              : vmsVehicleOptions
            : toggle === "chargers" || toggle === "esg"
              ? chargerOptions
              : toggle === "vendors"
                ? vendorOptions
                : toggle === "users"
                  ? userOptions
                  : cugOptions
        }
        noOptionsText={
          search ? `No results found for "${search}"` : "No results"
        }
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={isMultiple || isVMS ? "Search..." : "Choose"}
            helperText={isMultiple ? "Select up to 10" : undefined}
            InputProps={{
              ...params.InputProps,
              slotProps: { root: { sx: { borderRadius: "20px" } } },
            }}
            sx={{
              "& .MuiFormHelperText-root": {
                bgcolor: (theme) => theme.palette.background.default,
                px: 1,
                py: 0.25,
                // border: 1,
                borderRadius: "12px",
                position: "absolute",
                left: -8,
                bottom: -24 - 2,
              },
            }}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
          />
        )}
      />
    </Box>
  );
};

export default Selector;
