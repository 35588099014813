import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Add, HighlightOff, Remove } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Hidden,
  IconButton,
  Tab,
  Tabs,
} from "@mui/material";
import CircularLoader from "components/CircularLoader";
import {
  authorizedFetch,
  drawer,
  getDarkModePreference,
  GlobalState,
  titleCase,
} from "utils";

// import { LEASE_URL } from "utils/constants";
import { useQuery } from "react-query";

import Table from "components/Table";
import moment from "moment";
import AssignDialog from "./AssignDialog";
import UnAssignVehicles from "./UnAssignVehicles";
import Search from "components/Search";
import StatusDialog from "./StatusDialog";

import Map from "components/Map";
import DeleteDialog from "./DeleteDialog";
import { LEASE_URL } from "utils/constants";

const InfoDrawer = ({ hub, refetchAllHubs }: any) => {
  const [table, setTable] = useState([
    { header: "Hub Info" },
    { label: "Hub Name", value: "" },
    { label: "local", value: "" },
    { label: "City/District", value: "" },
    { label: "State", value: "" },
    { label: "Pincode", value: "" },
    { label: "Created At", value: "" },
    { label: "Created By", value: "" },
    { header: "User Info" },
    { label: "First Name", value: "" },
    { label: "Last Name", value: "" },
    { label: "Phone", value: "" },
    { label: "Email", value: "" },
  ]);

  const open = useSelector((state: GlobalState) => state.global.drawer.open);

  const [tab, setTab] = useState(0);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");

  const [statusDialog, setStatusDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const hubVehiclesUrl = `${LEASE_URL}/company/hubs/${
    hub?._id
  }/vehicles?first=${pageSize}&skip=${pageSize * (page - 1)}&search=${search}`;

  const {
    isLoading: hubVehiclesLoading,
    data: hubVehiclesData,
    // refetch: refetchVHubVehicles,
  } = useQuery(["getHubVehicles", hub, page, pageSize, search], () =>
    authorizedFetch(hubVehiclesUrl)
  );

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);

  useEffect(() => {
    if (hub) {
      setTable([
        { header: "Hub Info" },
        { label: "Hub Name", value: hub?.name },
        { label: "Local", value: hub?.address?.local },
        { label: "City/District", value: hub?.address?.district },
        { label: "State", value: hub?.address?.state },
        { label: "Pincode", value: hub?.address?.pincode },
        {
          label: "Created At",
          value: moment(hub?.createdAt).format("DD/MM/YY, hh:mm a"),
        },
        // { label: "Created By", value: hub?.createdBy },
        { header: "User Info" },
        { label: "First Name", value: hub?.user?.firstName },
        { label: "Last Name", value: hub?.user?.lastName },
        { label: "Phone", value: hub?.user?.phone },
        { label: "Email", value: hub?.user?.email },
      ]);
    }
  }, [hub]);

  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );

  useEffect(() => {
    if (open) {
      setTab(0);
    }
  }, [open]);

  const [assignDialog, setAssignDialog] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [unassignVehicles, setUnassignVehicles] = useState({
    open: false,
    data: [],
  });

  let rowCount = hubVehiclesData?.data?.count || 0;
  let rows =
    hubVehiclesData?.data?.vehicles?.constructor === Array
      ? hubVehiclesData.data.vehicles
      : [];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: isDarkMode ? "#000" : "#3D3D3D",
            fontWeight: 500,
            color: "#fff",
          }}
        >
          {hub?.name ? hub?.name : "Vendor"}
          <Box display="grid" gridTemplateColumns="repeat(2, auto)" gap={1}>
            <Box>
              <Button
                variant="contained"
                size="small"
                sx={{ mr: 1 }}
                onClick={() => setStatusDialog(true)}
              >
                {hub?.status === "ACTIVE" ? "DEACTIVATE" : "ACTIVATE"}
              </Button>
              {/* <IconButton
                sx={{ mr: 1 }}
                children={<DeleteOutline />}
                color="inherit"
                size="small"
                onClick={() => {
                  setDeleteDialog(true);
                }}
              /> */}
              <IconButton
                children={<HighlightOff />}
                color="inherit"
                size="small"
                onClick={() => drawer.close()}
              />
            </Box>
          </Box>
        </Box>
        <Box px={2} pt={2}>
          <Tabs
            className="less-dense"
            value={tab}
            onChange={(e, tab) => setTab(tab)}
          >
            <Tab label="Basic Details" />
            <Tab label="Map View" />

            <Tab
              label="Assigned Vehicles"
              className="hasCount"
              sx={{
                "&:after": {
                  content: `"${rowCount || 0}"`,
                },
              }}
            />
          </Tabs>
        </Box>
        {tab === 0 &&
          (!hub ? (
            <Box mt={5}>
              <CircularLoader />
            </Box>
          ) : (
            <Box flexGrow={1} overflow="auto">
              <Box
                sx={{
                  px: 3,
                  pt: 2.5,
                  "& .table": {
                    borderCollapse: "collapse",
                    width: 1,
                    fontSize: 14,
                    lineHeight: "16px",
                    "& td": {
                      py: 2,
                      px: 2,
                    },
                    "& .bold": {
                      fontWeight: 500,
                    },
                    "& .header": {
                      px: 2,
                      py: 1,
                      position: "relative",
                      "& td ": {
                        position: "absolute",
                        verticalAlign: "middle",
                        backgroundColor: (theme) => theme.customColors.header,
                        width: 1,
                        borderRadius: "4px",
                        fontSize: 16,
                        fontWeight: 600,
                        "& span": {
                          display: "inline-block",
                          transform: "translateY(1px)",
                        },
                      },
                      "& .textVehicle ": {
                        position: "absolute",
                        verticalAlign: "middle",
                        backgroundColor: (theme) => theme.customColors.header,
                        width: 1,
                        borderRadius: "4px",
                        fontSize: 16,
                        fontWeight: 600,
                        "& span": {
                          display: "inline-block",
                          transform: "translateY(1px)",
                        },
                      },
                    },
                    "& .first > td": {
                      pt: 9,
                    },
                    "& .last > td": {
                      pb: 3,
                    },
                  },
                }}
              >
                <table className="table">
                  <tbody>
                    {table.map(({ header, label, value }, i) => {
                      const isFirst = table[i - 1]?.header;
                      const isLast = !table[i + 1] || table[i + 1].header;

                      return (
                        <>
                          <tr
                            key={i}
                            className={
                              header
                                ? "header"
                                : `${isFirst ? "first" : ""} ${
                                    isLast ? "last" : ""
                                  }`
                            }
                          >
                            {[value].includes("") ? (
                              ""
                            ) : header ? (
                              <td colSpan={2}>
                                <span>{header}</span>
                              </td>
                            ) : (
                              <>
                                <td className="bold">{label}</td>

                                <td>{value}</td>
                              </>
                            )}
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </Box>
            </Box>
          ))}

        {tab === 1 && (
          <Box p={2.5} height={436}>
            <Map
              loading={false}
              type="hub"
              borderRadius={1}
              location={{
                latitude: hub?.location?.coordinates?.[1],
                longitude: hub?.location?.coordinates?.[0],
              }}
            />
          </Box>
        )}

        {tab === 2 && (
          <Box mt={1.5} flexGrow={1} overflow="auto">
            <Box
              sx={{
                px: 3,
                pr: 4,

                // py: 1,
                mb: 1.5,
                display: "flex",
                justifyContent: "space-between",
                // alignItems: "center",
              }}
            >
              <Box display="flex" sx={{ mr: "auto", ml: 0 }}>
                <Hidden implementation="css" mdDown>
                  <Box>
                    <Search
                      handleSearch={(value) => {
                        setSearch(value);
                      }}
                      persist
                      enableClear
                      disableFocus
                    />
                  </Box>
                </Hidden>
              </Box>
              <Button
                color="primary"
                sx={{
                  textTransform: "none",
                  mr: 0,
                  ml: "auto",
                  borderRadius: 10,
                }}
                startIcon={
                  selectedRows.length ? (
                    <Remove sx={{ mr: -0.5, mt: -0.2 }} />
                  ) : (
                    <Add sx={{ mr: -0.5, mt: -0.2 }} />
                  )
                }
                onClick={() => {
                  selectedRows.length
                    ? setUnassignVehicles({
                        open: true,
                        data: selectedRows,
                      })
                    : setAssignDialog(true);
                }}
                variant="outlined"
              >
                {selectedRows.length ? "Unassign" : "Assign New"}
              </Button>
            </Box>
            <Table
              idKey="vin"
              px={0}
              small
              smallPagination
              selectable
              selectOnClick
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              loading={hubVehiclesLoading}
              rowCount={rowCount}
              serverSidePagination={true}
              activePage={page}
              activePageSize={pageSize}
              onPageChange={(value) => setPage(value)}
              onPageSizeChange={(value) => setPageSize(value)}
              rows={rows}
              columns={[
                { key: "vin", label: "VIN" },

                {
                  key: "health",
                  label: "Health",
                  Render: ({ health }: any) => {
                    return (
                      <Avatar
                        variant="status"
                        className={
                          health === "active"
                            ? ""
                            : health === "moderate"
                            ? "yellow"
                            : health === "critical"
                            ? "red"
                            : "grey"
                        }
                      >
                        {health ? titleCase(health) : "Inactive"}
                      </Avatar>
                    );
                  },
                },
                {
                  key: "rentalStatus",
                  label: "Availability",
                  Render: ({ rentalStatus }: any) => {
                    return (
                      <Avatar
                        variant="status"
                        className={
                          rentalStatus === "AVAILABLE"
                            ? ""
                            : rentalStatus === "BOOKED"
                            ? "blue"
                            : "grey"
                        }
                      >
                        {rentalStatus ? titleCase(rentalStatus) : "Inactive"}
                      </Avatar>
                    );
                  },
                },
              ]}
            />
          </Box>
        )}
      </Box>
      <DeleteDialog
        open={deleteDialog}
        handleClose={() => {
          drawer.close();
          setDeleteDialog(false);
          refetchAllHubs();
        }}
        hub={hub}
      />
      <StatusDialog
        open={statusDialog}
        handleClose={() => {
          drawer.close();
          setStatusDialog(false);
          refetchAllHubs();
        }}
        hub={hub}
      />
      <AssignDialog
        open={assignDialog}
        handleClose={() => {
          setAssignDialog(false);
        }}
        hub={hub}
      />
      <UnAssignVehicles
        open={unassignVehicles.open}
        handleClose={() => {
          setUnassignVehicles({ ...unassignVehicles, open: false });
          setSelectedRows([]);
        }}
        selected={unassignVehicles.data}
        hub={hub}
      />
    </>
  );
};

export default InfoDrawer;
