import {
  EditOutlined,
  // ImageOutlined
} from "@mui/icons-material";
import {
  // Avatar,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  // InputAdornment,
} from "@mui/material";
import { setGlobalState } from "actions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  authorizedFetch,
  getPermissions,
  GlobalState,
  setLoader,
  snackbar,
} from "utils";
import { AUTH_URL } from "utils/constants";

const CompanyDetails = () => {
  const { company } = useSelector((state: GlobalState) => state.global);
  const dispatch = useDispatch();
  const [isEditMode, setEditMode] = useState(false);

  const isSuperAdmin = getPermissions("charger:admin");

  const [input, setInput] = useState({
    name: company?.name || "",
    phone: company?.phone || "",
    address: company?.address || "",
    gstNumber: company?.gstNumber || "",
    panNumber: company?.panNumber || "",
    cinNumber: company?.cinNumber || "",
  });

  const { name, phone, address, gstNumber, panNumber, cinNumber } = input;

  function handleChange(key: string, value: string) {
    setInput((prev) => ({ ...prev, [key]: value }));
  }

  function refetchCompany() {
    authorizedFetch(`${AUTH_URL}/company`).then((res) => {
      let { company } = res.data;
      if (company) {
        dispatch(setGlobalState({ company }));
      }
    });
  }

  function handleSubmit() {
    setLoader(true);
    authorizedFetch(`${AUTH_URL}/company/update`, {
      method: "POST",
      body: {
        name,
        phone,
        address,
        gstNumber,
        panNumber,
        cinNumber,
      },
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      setLoader(false);
      if (res.status >= 400) snackbar.error("An error occurred");
      else {
        refetchCompany();
        setEditMode(false);
        snackbar.success("Saved changes");
      }
    });
  }

  // console.log(company);

  return (
    <Box
      sx={{
        "& .form": {
          display: "flex",
          flexDirection: "column",
        },
        "& .fields": {
          display: "flex",
          alignItems: "center",
        },
        "& .label": {
          // margin: "0 0 0 0",
          marginBottom: 0,
          width: "30%",
        },
        "& .value": {
          display: "flex",
          alignItems: "center",
          margin: "0 0 0 0",
          width: "100%",
        },
      }}
    >
      <Box
        className="header"
        sx={{
          justifyContent: isEditMode ? "space-between" : "start",
        }}
      >
        <Typography className="heading">Company Details</Typography>
        {isEditMode ? (
          <Box display="grid" gridTemplateColumns="auto auto" gap={1}>
            <Button
              sx={{ height: 34 }}
              variant="contained"
              onClick={handleSubmit}
            >
              Save
            </Button>
            <Button
              sx={{ height: 34 }}
              variant="outlined"
              onClick={() => setEditMode(false)}
            >
              Cancel
            </Button>
          </Box>
        ) : (
          isSuperAdmin && (
            <IconButton
              sx={{ ml: 2.25 }}
              size="small"
              color="primary"
              onClick={() => setEditMode(true)}
            >
              <EditOutlined />
            </IconButton>
          )
        )}
      </Box>
      <Box className="form">
        <Box className="fields">
          <Typography className="label">Company Name</Typography>
          {isEditMode ? (
            <TextField
              autoComplete="off"
              size="small"
              disabled
              fullWidth
              placeholder="Company Name"
              value={name}
              inputProps={{ maxLength: 30 }}
              onChange={(e) => {
                handleChange(
                  "name",
                  e.target.value.length > 0
                    ? e.target.value.replace(/[^a-zA-Z ]/, "")
                    : e.target.value
                );
              }}
            />
          ) : (
            <Typography className="value">{company?.name || "-"}</Typography>
          )}
          {/* <Typography className="value">{company?.name || "-"}</Typography> */}
        </Box>
        <Box className="fields">
          <Typography className="label">Phone Number</Typography>
          {isEditMode ? (
            <TextField
              autoComplete="off"
              disabled
              size="small"
              fullWidth
              type="tel"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => {
                if (e.target.value.length <= 10)
                  handleChange(
                    "phone",
                    e.target.value?.length > 0
                      ? e.target.value.replace(/[^0-9]/, "")
                      : e.target.value
                  );
              }}
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start" sx={{ ml: 1 }}>
              //       +91
              //     </InputAdornment>
              //   ),
              // }}
            />
          ) : (
            <Typography className="value">
              {company?.phone
                ? company?.phone[0] === "+"
                  ? company?.phone
                  : "+91 - " + company?.phone // (company?.phone)
                : "-"}
            </Typography>
          )}
        </Box>
        <Box className="fields" gridColumn="span 2">
          <Typography className="label">Address</Typography>
          {isEditMode ? (
            <TextField
              autoComplete="off"
              multiline
              minRows={3}
              size="small"
              fullWidth
              placeholder="Address"
              value={address}
              inputProps={{ maxLength: 40 }}
              onChange={(e) => handleChange("address", e.target.value)}
            />
          ) : (
            <Typography
              mb={2}
              className="value"
              sx={{ whiteSpace: "pre-line" }}
            >
              {company?.address || "-"}
            </Typography>
          )}
        </Box>
        <Box className="fields">
          <Typography className="label">GST Number</Typography>
          {isEditMode ? (
            <TextField
              autoComplete="off"
              size="small"
              fullWidth
              placeholder="GST Number"
              value={gstNumber}
              inputProps={{
                maxLength: 15,
                style: { textTransform: "uppercase" },
              }}
              onChange={(e) => {
                handleChange(
                  "gstNumber",
                  e.target.value.length > 15
                    ? e.target.value.replace(/[^A-Z0-9]/, "")
                    : e.target.value
                );
              }}
            />
          ) : (
            <Typography className="value">
              {company?.gstNumber?.toUpperCase() || "-"}
            </Typography>
          )}
        </Box>
        <Box className="fields">
          <Typography className="label">PAN Number</Typography>
          {isEditMode ? (
            <TextField
              autoComplete="off"
              size="small"
              fullWidth
              placeholder="PAN Number"
              value={panNumber}
              inputProps={{
                maxLength: 10,
                style: { textTransform: "uppercase" },
              }}
              onChange={(e) => {
                handleChange(
                  "panNumber",
                  e.target.value.length > 10
                    ? e.target.value.replace(/^[A-Z][0-9]/, "")
                    : e.target.value
                );
              }}
            />
          ) : (
            <Typography className="value">
              {company?.panNumber?.toUpperCase() || "-"}
            </Typography>
          )}
        </Box>
        <Box className="fields">
          <Typography className="label">CIN Number</Typography>
          {isEditMode ? (
            <TextField
              autoComplete="off"
              size="small"
              fullWidth
              placeholder="CIN Number"
              value={cinNumber}
              inputProps={{ maxLength: 21 }}
              onChange={(e) => {
                handleChange("cinNumber", e.target.value);
              }}
            />
          ) : (
            <Typography className="value">
              {company?.cinNumber || "-"}
            </Typography>
          )}
        </Box>
        {/* <Box
          // className="fields"
          gridColumn="span 2"
        >
          <Typography className="label" sx={{ marginBottom: "12px" }}>
            Company Logo
          </Typography>
          <Box
            sx={{
              p: 3,
              pb: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid",
              borderColor: (theme) => theme.customColors.border,
              borderRadius: 1,
            }}
          >
            <Avatar
              variant="icon"
              sx={{
                mb: 2,
                color: "primary.main",
                borderColor: "#29CB971A",
                borderRadius: 2,
                height: 75,
                width: 95,
                fontSize: 38,
              }}
            >
              <ImageOutlined fontSize="inherit" />
            </Avatar>
            <Box display="flex" alignItems="center">
              <Typography fontSize={14}>Drag and drop image here or</Typography>
              <Button
                sx={{
                  textTransform: "none",
                  fontSize: 14,
                  fontWeight: 700,
                }}
                size="small"
              >
                Browse
              </Button>
            </Box>
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

export default CompanyDetails;
