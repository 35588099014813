// import firebase from "firebase/app";
// import "firebase/auth";
// var config = {
//   apiKey: "AIzaSyBK4gJHom3WhHenDqE_Cz6alrirbcuJZkc",
//   authDomain: "revmode-98dec.firebaseapp.com",
//   databaseURL: "https://revmode-98dec.firebaseio.com",
//   projectId: "revmode-98dec",
//   storageBucket: "revmode-98dec.appspot.com",
//   messagingSenderId: "1014539855897",
// };
// firebase.initializeApp(config);

// export const auth = firebase.auth();
// export const storageKey = "KEY_FOR_LOCAL_STORAGE";

// export const isAuthenticated = () => {
//   return !!auth.currentUser || !!localStorage.getItem(storageKey);
// };

// export default firebase;

import { initializeApp } from "firebase/app";
import { initializeAuth } from "firebase/auth";
import { REACT_APP_ENVIRONMENT } from "utils/constants";

var config =
  REACT_APP_ENVIRONMENT === "PREPRODUCTION"
    ? {
        apiKey: "AIzaSyD1otZBO17jXdkKD6iwIyhioXJWDO6oa2E",
        authDomain: "bolt-preprod.firebaseapp.com",
        projectId: "bolt-preprod",
        storageBucket: "bolt-preprod.appspot.com",
        messagingSenderId: "907912853136",
        appId: "1:907912853136:web:9c2406fd576d12e4348a15",
        measurementId: "G-TFJBN6GL2G",
      }
    : {
        apiKey: "AIzaSyBK4gJHom3WhHenDqE_Cz6alrirbcuJZkc",
        authDomain: "revmode-98dec.firebaseapp.com",
        databaseURL: "https://revmode-98dec.firebaseio.com",
        projectId: "revmode-98dec",
        storageBucket: "revmode-98dec.appspot.com",
        messagingSenderId: "1014539855897",
      };

const firebaseApp = initializeApp(config);
export const firebaseAuth = initializeAuth(firebaseApp)
export const storageKey = "KEY_FOR_LOCAL_STORAGE";

export const isAuthenticated = () => {
  return !!firebaseAuth.currentUser || !!localStorage.getItem(storageKey);
};