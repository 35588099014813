import { CheckCircleOutline, HighlightOff } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CircularLoader from "components/CircularLoader";
import RedAsterisk from "components/RedAsterisk";
import { queryClient } from "index";
import { Fragment, useEffect, useState } from "react";
import { useQueries, useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  authorizedFetch,
  drawer,
  GlobalState,
  setLoader,
  snackbar,
} from "utils";
import { RETAIL_URL } from "utils/constants";
import RemapDialog from "./RemapDialog";
var RegexParser = require("regex-parser");

const AddVehicleDialog = ({
  open,
  handleClose,
  model: modelInfo,
  isEditMode = false,
  vehicle,
  onSave,
}: any) => {
  const { company } = useSelector((state: GlobalState) => state.global);

  const isOldFlow = company?.id === "64cc991fa5f7d1d9d5cfc5ac";

  const [form, setForm] = useState<any>({ vin: "", specs: {} });
  const [specsFields, setSpecsFields] = useState<any>({});
  const [remapDialog, setRemapDialog] = useState({ open: false, message: "" });

  const [selectedController, setSelectedController] = useState<any>(null);

  const modelUrl = `${RETAIL_URL}/assembly/model/${modelInfo?._id}`;
  const { isLoading: modelLoading, data: modelData } = useQuery(
    ["getModel", modelInfo?._id],
    () => authorizedFetch(modelUrl),
    { enabled: !!modelInfo?._id },
  );
  const model = modelData?.data?.[0] || {};

  // Get compatible controllers for the model

  // const componentsUrl = `${RETAIL_URL}/inventory/components?all=true&inventoryStatus=&vehicleModel=${model?._id}`;
  // const { isLoading: componentsLoading, data: componentsData } = useQuery(
  //   ["getComponents"],
  //   () => authorizedFetch(componentsUrl),
  //   { enabled: open }
  // );

  const componentsUrl = `${RETAIL_URL}/inventory/components?vehicleModel=${model?._id}&all=true&assigned=true`;
  const { isLoading: componentsLoading, data: componentsData } = useQuery(
    ["getComponents", open, model?._id],
    () => authorizedFetch(componentsUrl),
    { enabled: open },
  );

  const controllerObj = model?.components?.find(
    (el: any) => el.category === "CONTROLLER",
  );
  const controllerType = controllerObj?.modelId?.[0]?.key;

  // Get existing data for Edit Vehicle Dialog
  const vehicleUrl = `${RETAIL_URL}/assembly/vehicle/${vehicle?._id}`;
  const { isLoading: vehicleLoading, data: vehicleData } = useQuery(
    ["getVehicle", vehicle?._id],
    () => authorizedFetch(vehicleUrl),
    { enabled: isEditMode && Boolean(vehicle?._id) },
  );

  const colorsUrl = `${RETAIL_URL}/assembly/model/${model?._id}/colors`;
  const { data: colorsData } = useQuery(
    ["getModelColors", model],
    () => authorizedFetch(colorsUrl),
    { enabled: open && Object.keys(model).length > 0 },
  );

  // Get specs fields for all device types available with the model (e.g. TELEMATICS) except CONTROLLER
  const specsData = useQueries(
    [
      "VEHICLE",
      ...(model?.components?.constructor === Array
        ? model.components
            .filter((el: any) => el.category !== "CONTROLLER")
            .map((el: any) => el.category)
        : []),
    ].map((category: any) => ({
      queryKey: ["getSpecs", category],
      queryFn: () =>
        authorizedFetch(`${RETAIL_URL}/inventory/product-specs/${category}`),
      onSuccess: (data: any) => {
        setSpecsFields((prev: any) => ({
          ...prev,
          [category]: data.data,
        }));
      },
    })),
  );
  const specsLoading = (specsData?.constructor === Array ? specsData : []).some(
    (el: any) => el.isLoading,
  );

  // Set fields and values on dialog open
  useEffect(() => {
    const model = modelData?.data?.[0] || {};
    if (open && model) {
      if (isEditMode && vehicleData) {
        let vehicle = vehicleData.data?.[0] || {};

        let specs: any = {};
        vehicle.components?.forEach((el: any) => {
          specs[el.category] = el.id.specs;
        });

        setForm({
          vin: vehicle.vin,
          components: vehicle.components
            ?.filter((el: any) => el.category !== "CONTROLLER")
            ?.map((el: any) => ({
              uid: el.uid,
              category: el.category,
              modelId: el.id?.parentModel?._id,
            })),
          specs: {
            ...(vehicle.specs
              ? {
                  VEHICLE: vehicle.specs,
                }
              : {}),
            ...specs,
          },
        });

        setSelectedController(
          vehicle.components?.find((el: any) => el.category === "CONTROLLER")
            ?.id,
        );
      } else {
        setForm({
          vin: "",
          specs: {},
          components: model?.components
            ?.filter((el: any) => el.category !== "CONTROLLER")
            ?.map((el: any) => ({
              uid: "",
              category: el.category,
              modelId: "",
            })),
        });
        setSelectedController(null);
      }
    }
  }, [open, isEditMode, vehicleData, modelData]);

  // Hotfix for removing macId when ICONCOX not selected
  useEffect(() => {
    const model = modelData?.data?.[0] || {};

    let isIconcoxSelected = model?.components?.some(
      (el: any) =>
        el.modelId?.find((model: any) => model.key === "ICONCOX")?._id ===
        form?.components?.find(({ category }: any) => category === "TELEMATICS")
          ?.modelId,
    );

    let telematicsSpecs = form.specs.TELEMATICS || {};

    if (!isIconcoxSelected && Object.hasOwn(telematicsSpecs, "macId")) {
      delete telematicsSpecs.macId;
      setForm((prev: any) => ({
        ...prev,
        specs: {
          ...prev.specs,
          TELEMATICS: telematicsSpecs,
        },
      }));
    }
  }, [form, modelData]);

  // Validate for "Save" button disabled state
  function isSaveDisabled() {
    if (isOldFlow) {
      if (!form?.vin || form?.vin?.length > 17) {
        return true;
      }
    } else {
      if (!isEditMode && (!form?.vin || form?.vin?.length > 17)) return true;

      const hasController = model?.components?.some(
        (el: any) => el.category === "CONTROLLER",
      );
      if (hasController && selectedController === null) return true;

      // CONTROLLER category has been filtered out
      for (const category in specsFields) {
        if (
          specsFields[category]?.constructor === Array &&
          category !== "VEHICLE" // No vehicle specs required for now
        ) {
          for (const spec of specsFields[category]) {
            let { key, required } = spec;
            const regex = spec?.validators?.[0]?.regex;
            const value = form?.specs?.[category]?.[key];

            const component = form?.components?.find(
              (el: any) => el?.category === category,
            );
            if (!component?.modelId) {
              return true;
            }

            if (category === "TELEMATICS") {
              if (component?.modelId !== "64672f6efe8d05dc27d1da57") {
                if (!/^[0-9]+$/.test(component?.uid)) return true;
              } else {
                return false;
              }
            }
            // if (category === "CONTROLLER") {
            //   if (!/^[a-zA-Z0-9]+$/.test(component?.uid)) return true;
            // }

            if (required && [null, undefined, ""].includes(value)) {
              return true;
            }

            if (category === "TELEMATICS" && key === "macId") {
              // Check for macId only if selected device is ICONCOX
              const selectedDeviceID = component.modelId;
              const iconcoxDeviceID = model?.components
                ?.find((el: any) => el.category === "TELEMATICS")
                ?.modelId?.find((el: any) => el.key === "ICONCOX")?._id;

              const isIconcox = selectedDeviceID === iconcoxDeviceID;

              if (isIconcox) {
                if (regex && !RegexParser(regex).test(value)) return true;
              }
            }

            if (
              regex &&
              !RegexParser(regex).test(value) &&
              key !== "macId" // Ignore for macId because already checked in previous step
            ) {
              return true;
            }
          }
        }
      }
      return false;
    }
  }

  function handleSave(shouldRemap?: boolean) {
    setLoader(true);
    let createUrl = `${RETAIL_URL}/assembly/vehicle/create`;
    let updateUrl = `${RETAIL_URL}/assembly/vehicle/${vehicle?._id}`;
    authorizedFetch(isEditMode ? updateUrl : createUrl, {
      method: isEditMode ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        ...(!isEditMode
          ? {
              vin: form.vin,
              model: model._id,
            }
          : {}),
        specs: form.specs,
        components: form.components,
        ...(!isEditMode
          ? {
              ...(selectedController
                ? { controller: selectedController._id }
                : {}),
            }
          : {
              ...(selectedController._id !==
              vehicle?.components?.find(
                ({ type }: any) => type === "CONTROLLER",
              )?._id
                ? { controller: selectedController._id }
                : {}),
            }),
        ...(shouldRemap ? { remap: true } : {}),
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.meta.success) {
          snackbar.success(`${isEditMode ? "Updated" : "Added"} vehicle`);
          handleClose();
          onSave && onSave();
          queryClient.resetQueries("getRetailVehicles");
          queryClient.resetQueries("getComponents");
          if (isEditMode) {
            queryClient.resetQueries("getRetailVehicles");
            queryClient.resetQueries("getVehicle");
            queryClient.resetQueries("getComponents");
            queryClient.resetQueries("getSpecs");
            drawer.close();
          }
        } else {
          if (
            String(res.msg).includes("Component(s) with uids") &&
            String(res.msg).includes("already")
          ) {
            setRemapDialog({ open: true, message: res.msg });
          } else {
            snackbar.error("Error: " + res.msg);
          }
        }
      })
      .catch((err: any) => {
        setLoader(false);
        snackbar.error("An error occurred");
        console.error(err);
      });
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <RemapDialog
        open={remapDialog.open}
        message={remapDialog.message}
        onClose={() => setRemapDialog((prev) => ({ ...prev, open: false }))}
        onConfirm={() => handleSave(true)}
      />
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {isEditMode ? "Edit" : "Add"} Vehicle
          <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
          <Typography color="text.secondary">
            {isEditMode ? vehicle?.vin : model?.name}
          </Typography>
        </Box>
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent>
        <Collapse in={modelLoading || specsLoading}>
          <Box my={5}>
            <CircularLoader />
          </Box>
        </Collapse>
        <Collapse
          in={
            isEditMode
              ? !vehicleLoading && !modelLoading && !specsLoading
              : !modelLoading && !specsLoading
          }
        >
          <Box sx={{ mx: "auto", maxWidth: 550 }}>
            <Typography variant="body2" color="text.secondary">
              <RedAsterisk /> Required fields
            </Typography>
            <Box
              sx={{
                my: 3,
                mx: 1,
                display: "grid",
                gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                gap: 3,
              }}
            >
              <Box gridColumn={{ sm: "span 2" }}>
                <Typography className="label">
                  VIN <RedAsterisk />
                </Typography>
                <TextField
                  fullWidth
                  sx={{ maxWidth: 320 }}
                  size="small"
                  placeholder="VIN"
                  value={form?.vin}
                  onChange={(e) => {
                    if (/^[a-zA-Z0-9]*$/.test(e.target.value))
                      setForm((prev: any) => ({
                        ...prev,
                        vin: e.target.value,
                      }));
                  }}
                  disabled={isEditMode}
                  error={!isEditMode && form?.vin?.length > 17}
                  helperText={
                    !isEditMode && form?.vin?.length > 17
                      ? "VIN cannot be more than 17 characters long"
                      : undefined
                  }
                />
              </Box>
              <Box>
                <Typography className="label">Color</Typography>
                <Select
                  size="small"
                  fullWidth
                  placeholder="Select"
                  displayEmpty
                  value={form?.specs?.VEHICLE?.color || ""}
                  renderValue={
                    form?.specs?.VEHICLE?.color
                      ? undefined
                      : () => <em>Select</em>
                  }
                  onChange={(e) =>
                    setForm((prev: any) => ({
                      ...prev,
                      specs: {
                        ...prev.specs,
                        VEHICLE: {
                          ...(prev.specs.VEHICLE || {}),
                          color: e.target.value,
                        },
                      },
                    }))
                  }
                >
                  {(colorsData?.data || [])?.map((el: any, i: number) => (
                    <MenuItem key={i} value={el.key}>
                      {el.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              {!isOldFlow &&
                model?.components?.map((el: any, i: number) => {
                  let component = form?.components?.find(
                    ({ category }: any) => category === el.category,
                  );

                  let isICONCOX =
                    el.modelId?.find((model: any) => model.key === "ICONCOX")
                      ?._id === component?.modelId;

                  function getLabel(key: string) {
                    switch (key) {
                      case "macId":
                        return "MAC ID";
                      case "firmware":
                        return "Firmware";
                      case "expectedFirmware":
                        return "Expected Firmware";
                      default:
                        return key;
                    }
                  }

                  // For type and UID
                  function handleChange(
                    key: string,
                    value: any,
                    specificCategory?: any,
                  ) {
                    setForm((prev: any) => {
                      let newComponents = [...prev.components];
                      let existing = newComponents.find(
                        ({ category }: any) =>
                          category ===
                          (specificCategory ? specificCategory : el.category),
                      );

                      if (existing) existing[key] = value;
                      else {
                        existing = {
                          category: el.category,
                          [key]: value,
                        };
                        newComponents.push(existing);
                      }

                      return { ...prev, components: newComponents };
                    });
                  }

                  // For other details (e.g. MAC ID, MSISDN Number)
                  function handleSpecsChange(key: string, value: any) {
                    setForm((prev: any) => ({
                      ...prev,
                      specs: {
                        ...(prev.specs || {}),
                        [el.category]: {
                          ...(prev.specs[el.category] || {}),
                          [key]: value,
                        },
                      },
                    }));
                  }

                  return (
                    <Fragment key={i}>
                      <Box sx={{ mb: -1, gridColumn: { sm: "span 2" } }}>
                        <Typography variant="overline">
                          {el.category}
                        </Typography>
                        <Divider />
                      </Box>
                      {el.category === "CONTROLLER" ? (
                        <>
                          <Box>
                            <Typography className="label">Type</Typography>
                            <TextField
                              fullWidth
                              size="small"
                              value={controllerType}
                              disabled
                            />
                          </Box>
                          <Box>
                            <Typography className="label">
                              Serial Number <RedAsterisk />
                            </Typography>
                            <Autocomplete
                              size="small"
                              options={componentsData?.data || []}
                              loading={componentsLoading}
                              getOptionLabel={(option: any) =>
                                option.serialNumber || "-"
                              }
                              isOptionEqualToValue={(option, value) =>
                                option._id === value._id
                              }
                              disableClearable
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Serial Number"
                                />
                              )}
                              value={selectedController}
                              onChange={(e, value) => {
                                setSelectedController(value);
                                const telematics = form?.components?.find(
                                  ({ category }: any) =>
                                    category === "TELEMATICS",
                                );
                                if (
                                  telematics?.modelId ===
                                  "64672f6efe8d05dc27d1da57"
                                ) {
                                  handleChange(
                                    "uid",
                                    value?.specs?.macId,
                                    "TELEMATICS",
                                  );
                                }
                              }}
                            />
                          </Box>
                          <Box>
                            <Typography className="label">MAC ID</Typography>
                            <TextField
                              fullWidth
                              size="small"
                              placeholder="MAC ID"
                              value={selectedController?.specs?.macId || ""}
                              disabled
                            />
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box>
                            <Typography className="label">
                              Type <RedAsterisk />
                            </Typography>
                            <Select
                              size="small"
                              fullWidth
                              placeholder="Select"
                              displayEmpty
                              value={component?.modelId || ""}
                              renderValue={
                                component?.modelId !== ""
                                  ? undefined
                                  : () => <em>Select</em>
                              }
                              onChange={(e) => {
                                handleChange("modelId", e.target.value);
                                if (
                                  component?.modelId ===
                                  "64672f6efe8d05dc27d1da57"
                                ) {
                                  handleChange(
                                    "uid",
                                    selectedController?.specs?.macId,
                                  );
                                }
                              }}
                            >
                              {el.modelId?.map((el: any, i: number) => (
                                <MenuItem key={i} value={el._id}>
                                  {el.key}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                          <Box>
                            <Typography className="label">
                              UID <RedAsterisk />
                            </Typography>
                            <TextField
                              fullWidth
                              size="small"
                              placeholder="UID"
                              value={component?.uid}
                              disabled={
                                component?.modelId ===
                                "64672f6efe8d05dc27d1da57"
                              }
                              onChange={(e) =>
                                handleChange("uid", e.target.value)
                              }
                              error={
                                el.category === "TELEMATICS" &&
                                component?.modelId !==
                                  "64672f6efe8d05dc27d1da57"
                                  ? !/^[0-9]*$/.test(component?.uid)
                                  : // : el.category === "CONTROLLER"
                                    // ? !/^[a-zA-Z0-9]*$/.test(component?.uid)
                                    undefined
                              }
                              helperText={
                                el.category === "TELEMATICS"
                                  ? component?.modelId ===
                                    "64672f6efe8d05dc27d1da57"
                                    ? "UID will be same as Controller MacId"
                                    : "UID must be numeric"
                                  : // : el.category === "CONTROLLER"
                                    // ? "UID must be alphanumeric"
                                    undefined
                              }
                              InputProps={{
                                endAdornment:
                                  el.category === "TELEMATICS" &&
                                  /^[0-9]+$/.test(component?.uid) ? (
                                    // || (el.category === "CONTROLLER" &&
                                    // /^[a-zA-Z0-9]+$/.test(component?.uid))
                                    <InputAdornment position="end">
                                      <CheckCircleOutline color="primary" />
                                    </InputAdornment>
                                  ) : undefined,
                              }}
                            />
                          </Box>
                          {specsFields[el.category]?.map(
                            (spec: any, i: number) =>
                              spec.key !== "macId" ||
                              (spec.key === "macId" &&
                                (isICONCOX || el.category !== "TELEMATICS")) ? (
                                <Fragment key={i}>
                                  <Box>
                                    <Typography className="label">
                                      {getLabel(spec.key)}{" "}
                                      {(spec.required ||
                                        (isICONCOX &&
                                          spec.key === "macId")) && (
                                        <RedAsterisk />
                                      )}
                                    </Typography>
                                    <TextField
                                      fullWidth
                                      size="small"
                                      placeholder={getLabel(spec.key)}
                                      value={
                                        form?.specs?.[el.category]?.[
                                          spec.key
                                        ] || ""
                                      }
                                      onChange={(e) => {
                                        handleSpecsChange(
                                          spec.key,
                                          e.target.value,
                                        );
                                      }}
                                      helperText={
                                        spec?.validators?.[0]?.hint || undefined
                                      }
                                      {...(spec?.validators?.[0]?.regex &&
                                      form?.specs?.[el.category]?.[spec.key]
                                        ? {
                                            error: !RegexParser(
                                              spec.validators[0].regex,
                                            ).test(
                                              form.specs[el.category][spec.key],
                                            ),
                                            InputProps: {
                                              endAdornment: RegexParser(
                                                spec.validators[0].regex,
                                              ).test(
                                                form.specs[el.category][
                                                  spec.key
                                                ],
                                              ) ? (
                                                <InputAdornment position="end">
                                                  <CheckCircleOutline color="primary" />
                                                </InputAdornment>
                                              ) : undefined,
                                            },
                                          }
                                        : {})}
                                    />
                                  </Box>
                                </Fragment>
                              ) : null,
                          )}
                        </>
                      )}
                    </Fragment>
                  );
                })}
            </Box>
          </Box>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={isSaveDisabled()}
          onClick={() => handleSave()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddVehicleDialog;
