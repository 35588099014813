import etoLogo from "assets/images/logos/eto-motors.png";

export const REACT_APP_ENVIRONMENT: string =
  process.env.REACT_APP_ENVIRONMENT || "";
export const GMAPS_API_KEY: string = process.env.REACT_APP_GMAPS_API_KEY || "";
export const AUTH_URL: string = process.env.REACT_APP_AUTH_URL || "";
export const WALLET_URL: string = process.env.REACT_APP_WALLET_URL || "";
export const BOLT_URL: string = process.env.REACT_APP_BOLT_URL || "";
export const BOLT_SERVICE_URL: string =
  process.env.REACT_APP_BOLT_SERVICE_URL || "";
export const GQL_URL: string = process.env.REACT_APP_GQL_URL || "";
export const API_URL: string = process.env.REACT_APP_API_URL || "";
export const DATAFEED_URL: string = process.env.REACT_APP_DATAFEED_URL || "";
export const TRIGGER_URL: string = process.env.REACT_APP_TRIGGER_URL || "";
export const TRIGGER_WS_URL: string =
  process.env.REACT_APP_TRIGGER_WS_URL || "";
export const REPORTS_URL: string = process.env.REACT_APP_REPORTS_URL || "";
export const NEW_REPORTS_URL: string =
  process.env.REACT_APP_NEW_REPORTS_URL || "";
export const CONSUMER_REPORTS_URL: string =
  process.env.REACT_APP_CONSUMER_REPORTS_URL || "";
export const OCPP_URL: string = process.env.REACT_APP_OCPP_URL || "";
export const WEBHOOK_URL: string = process.env.REACT_APP_WEBHOOK_URL || "";
export const NOTIFIER_URL: string = process.env.REACT_APP_NOTIFIER_URL || "";
export const HEALTH_URL: string = process.env.REACT_APP_HEALTH_URL || "";
export const KYC_URL: string = process.env.REACT_APP_KYC_URL || "";
export const HOTFIX_URL: string = process.env.REACT_APP_HOTFIX_URL || "";
export const LEASE_URL: string = process.env.REACT_APP_LEASE_URL || "";
export const RETAIL_URL: string = process.env.REACT_APP_RETAIL_URL || "";
export const MARKET_URL: string = process.env.REACT_APP_MARKET_URL || "";
export const USERTRACK_URL: string = process.env.REACT_APP_USERTRACK_URL || "";
export const PAYMENTS_URL: string = process.env.REACT_APP_PAYMENTS_URL || "";
export const NOTIFICATION_URL: string =
  process.env.REACT_APP_NOTIFICATION_URL || "";
export const WIFI_URL: string = process.env.REACT_APP_WIFI_URL || "";
export const TRIPS_URL: string = process.env.REACT_APP_TRIPS_URL || "";
export const TICKETS_URL: string = process.env.REACT_APP_TICKETS_URL || "";
export const SCM_URL: string = process.env.REACT_APP_SCM_URL || "";
export const CHIMERA_URL: string = process.env.REACT_APP_CHIMERA_URL || "";
export const CMS_URL: string = process.env.REACT_APP_CMS_URL || "";
export const VEHICLE_SUBSCRIPTION_URL: string =
  process.env.REACT_APP_VEHICLE_SUBSCRIPTION_URL || "";
export const CMS_STATS_URL: string = process.env.REACT_APP_CMS_STATS_URL || "";
export const HOST_SERVICE_URL: string =
  process.env.REACT_APP_HOST_SERVICE_URL || "";

export const REVENUE_SPLIT_URL_TEMP: string =
  process.env.REACT_APP_REVENUE_SPLIT_URL_TEMP || "";

function getSubdomain(hostname: string) {
  if (["eto.revos.in", "eto.bolt.earth"].includes(hostname))
    return { name: "ETO Motors", icon: etoLogo };
  else return { name: "REVOS", icon: "" };
}

export const subdomain = getSubdomain(window.location.hostname);

export const BOLT_EARTH_COMPANY_ID = "5cfa443da7b11b00073f9657";

export const sectionIDs = [
  "charger",
  "rental",
  "retail",
  "housekeeping",
] as const;

export type SectionID = (typeof sectionIDs)[number];

export const pageIDs = [
  "retail:overview",
  "retail:inventory",
  "retail:scm",
  // "retail:oem",
  "retail:vehicles",
  "retail:distribution",
  "retail:assigned_vehicles",
  "retail:sold_vehicles",
  // "retail:support",
  // "retail:users",
  // "retail:invoices",
  // "retail:kyc",
  "retail:reports",
  "retail:admin",
  "rental:overview",
  "rental:vehicles",
  // "rental:notifications",
  "rental:leases",
  "rental:hubs",
  "rental:trips",
  "rental:users",
  "rental:vendors",
  "rental:kyc",
  "rental:geofence",
  "rental:reports",
  // "rental:invoices",
  "rental:assetControl",
  "rental:admin",
  "charger:overview",
  "charger:chargers",
  "charger:loadManagment",
  "charger:vendors",
  "charger:bookings",
  // "charger:payments",
  // "charger:subscriptions",
  "charger:users",
  // "charger:coupons",
  "charger:kyc",
  // "charger:notifications",
  "charger:reports",
  // "charger:invoices",
  "charger:admin",
  "charger:test",
  "charger:track",
  "housekeeping:charger_tickets",
  "housekeeping:ota_management",
  "housekeeping:diagnostics",
  "housekeeping:components",
  "housekeeping:ota",
  "housekeeping:orders",
  "housekeeping:models",
] as const;

export type PageID = (typeof pageIDs)[number];

export const getSectionName = (sectionId: string) => {
  switch (sectionId) {
    case "charger":
      return "Charger Management";
    case "rental":
      return "Vehicle Lease Management";
    case "retail":
      return "Vehicle Management";
    case "housekeeping":
      return "In-House Management";
  }
};

export const CRM_EMAILS = [
  "thameeza.banu@bolt.earth",
  "babafakruddin@bolt.earth",
  "aswathy.tp@bolt.earth",
  "rahul.hanchinmani@bolt.earth",
  "nirmalya.acharya@bolt.earth",
  "tester@revos.in",
  "aryan.chander@bolt.earth",
  "stalin.varghese@bolt.earth",
  "rakshith.vl@bolt.earth",
  "amal.rajagopal@bolt.earth",
  "pratik.var@bolt.earth",
  "mayank.shrivastava@bolt.earth",
  "diptiranjan.h@bolt.earth",
  "mohit@bolt.earth",
  "mohit.gupta@bolt.earth",
  "abhishek.kh@bolt.earth",
  "athul.sivadas@bolt.earth",
];
