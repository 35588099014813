import { Box, Paper, Skeleton, Typography, useTheme } from "@mui/material";
import InfoLabel from "components/InfoLabel";
import moment from "moment";
import { useMemo } from "react";
import { Line } from "react-chartjs-2";
import { useQuery } from "react-query";
import { authorizedFetch } from "utils";
import { CMS_STATS_URL } from "utils/constants";

const ActiveUsers = ({ params }: any) => {
  const theme = useTheme();

  const {
    startTime,
    endTime,
    vehicleType,
    chargerType,
    accessType,
    city,
    masterView,
  } = params;

  const granularity =
    moment(endTime).diff(moment(startTime), "years") >= 1
      ? "month"
      : moment(endTime).diff(moment(startTime), "months") > 1
        ? "week"
        : "day";

  const { isLoading, data } = useQuery(
    [
      "GET cms-stats /tabular/active_users",
      startTime,
      endTime,
      granularity,
      vehicleType,
      chargerType,
      accessType,
      city,
      masterView,
    ],
    () =>
      authorizedFetch(
        `${CMS_STATS_URL}/tabular/active_users?start_time=${startTime}&end_time=${endTime}&granularity=${granularity}${vehicleType}${chargerType}${accessType}${city}&master=${masterView}`,
      ),
  );

  const { chartData, count, average } = useMemo(() => {
    let chartData: any[] = [];
    let count = 0;
    let average = 0;

    if (!data?.data) return { chartData, count, average };

    Object.entries(data.data.plot || {}).forEach(([key, value]: any) => {
      // let parsedValue = value ? parseFloat(value.slice(2)) : 0;
      chartData.push({
        x:
          granularity === "month"
            ? moment(key).format("MMM 'YY")
            : moment(key).format("DD MMM"),
        y: value,
      });
    });
    count = data.data.total;
    average = data.data.daily_average.toLocaleString(undefined, {
      maximumFractionDigits: 1,
    });
    // average = Math.round(
    //   chartData.reduce((acc, cur: any) => acc + cur.y, 0) /
    //     (chartData.length || 1),
    // );
    return { chartData, count, average };
  }, [data, granularity]);

  return (
    <Paper
      sx={{
        p: { xs: 3, md: 3.5 },
        pb: { xs: 1.5, md: 2.5 },
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          mb: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h3" mr={1.25}>
            Active Users
          </Typography>
          {isLoading ? (
            <>
              <Skeleton
                variant="rectangular"
                width={80}
                height={24}
                sx={{ borderRadius: 10, mr: 1.25 }}
              />
              <Skeleton
                variant="rectangular"
                width={80}
                height={24}
                sx={{ borderRadius: 10 }}
              />
            </>
          ) : (
            <>
              <InfoLabel
                dense
                label="Total"
                value={(count || 0).toLocaleString()}
                mr={1.25}
              />
              <InfoLabel
                dense
                label="Daily Average"
                value={(average || 0).toLocaleString()}
              />
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          minHeight: 0,
        }}
      >
        <Line
          data={(canvas) => {
            let color = "#CC52E2";
            return {
              labels: chartData?.map((el: any) => el.x) || [],
              datasets: [
                {
                  fill: true,
                  data: chartData?.map((el: any) => el.y) || [],
                  borderColor: color,
                  backgroundColor: color + "0D",
                  tension: 0,
                  borderWidth: 3,
                  pointBackgroundColor: color,
                  pointBorderColor: "white",
                  pointHoverBorderColor: "white",
                  pointRadius: 0,
                  pointBorderWidth: 0,
                  pointHoverRadius: 8,
                  pointHoverBorderWidth: 2,
                  borderCapStyle: "round",
                  borderJoinStyle: "round",
                },
              ],
            };
          }}
          options={{
            scales: {
              xAxis: {
                // type: 'time',
                grid: {
                  display: false,
                  tickWidth: 0,
                  tickLength: 16,
                  drawBorder: false,
                },
                ticks: {
                  color: theme.palette.text.secondary,
                },
                title: {
                  display: true,
                  text:
                    granularity === "month"
                      ? "Month"
                      : granularity === "week"
                        ? "Start of Week"
                        : "Day",
                  padding: {
                    top: 8,
                  },
                },
              },
              yAxis: {
                title: {
                  display: true,
                  text: "Users",
                  padding: {
                    top: 0,
                    bottom: 8,
                  },
                },
                beginAtZero: true,
                ticks: {
                  color: theme.palette.text.secondary,
                  precision: 0,
                },
                suggestedMax: 10,
                grid: {
                  borderDash: [4],
                  tickWidth: 0,
                  tickLength: 16,
                  drawBorder: false,
                },
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                caretSize: 0,
                mode: "index",
                intersect: false,
                yAlign: "center",
                displayColors: false,
                caretPadding: 16,
                titleFont: {
                  weight: "400",
                },
                bodyFont: {
                  weight: "500",
                },
              },
            },
            interaction: {
              mode: "index",
              intersect: false,
            },
          }}
        />
      </Box>
    </Paper>
  );
};

export default ActiveUsers;
