import {
  Add,
  EditOutlined,
  HelpOutline,
  HighlightOff,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Step,
  StepButton,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PhoneTextField from "components/PhoneTextField";
import { queryClient } from "index";
import { useEffect, useState } from "react";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { AUTH_URL } from "utils/constants";
import validator from "validator";

interface Props {
  open: boolean;
  handleClose: () => void;
  setPaymentDialog: (value: any) => void;
}

const AddDialog: React.FC<Props> = ({ open, handleClose }) => {
  const [step, setStep] = useState(0);
  const lastStep = 2;
  const steps = [
    "Company Info",
    "User Info",
    "Create Vendor",
    "Add Payment Details",
  ];
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [input, setInput] = useState<any>({
    companyName: "",
    companyPhone: "",
    address: "",
    firstName: "",
    lastName: "",
    userPhone: "",
    email: "",
    domains: [],
  });
  const [domainInput, setDomainInput] = useState("");
  const isDomainValid = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(domainInput);

  function handleChange(key: string, value: string) {
    setInput((prev: any) => ({ ...prev, [key]: value }));
  }

  function handleNext() {
    if (step === lastStep) {
      handleSave();
    } else setStep(step + 1);
  }
  function handleBack() {
    setStep(step - 1);
  }

  const {
    companyName,
    companyPhone,
    address,
    firstName,
    lastName,
    userPhone,
    email,
    domains,
  } = input;

  function handleSave() {
    setLoader(true);
    authorizedFetch(`${AUTH_URL}/company/vendor/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        company: {
          name: companyName,
          address: address,
          phone: companyPhone,
          // gstNumber: String(gstNumber),
          plan: "BASIC",
          product: "cms",
          domains,
        },
        user: {
          firstName,
          lastName,
          phone: userPhone,
          email,
          password,
        },
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.meta.success) {
          ["allStats", "getVendors"].forEach((query) => {
            queryClient.resetQueries(query);
          });
          snackbar.success("Vendor created. Please add payment details.");
          handleClose();
          // let id = data.company.createSubCompany.user.id;
          // setPaymentDialog({
          //   open: true,
          //   data: {
          //     _id: id,
          //     firstName,
          //     lastName,
          //     phone: userPhone,
          //   },
          // });
        } else {
          snackbar.error(
            `Error${res.msg ? ": " + res.msg : " creating vendor"}`,
          );
        }
      })
      .catch((err) => {
        setLoader(false);
        snackbar.error("Error creating vendor");
      });
  }

  useEffect(() => {
    if (open) {
      setStep(0);
      setPassword("");
      setPassword2("");
      setInput({
        companyName: "",
        companyPhone: "",
        address: "",
        firstName: "",
        lastName: "",
        userPhone: "",
        email: "",
        domains: [],
      });
    }
  }, [open]);

  function isComplete(step: number) {
    switch (step) {
      case 0:
        return (
          ![companyName, companyPhone, address].includes("") &&
          domains.length !== 0
        );
      case 1:
        return ![
          firstName,
          lastName,
          userPhone,
          email,
          password,
          password2,
        ].includes("");
      default:
        break;
    }
  }

  const validateEmail = (email: any) => {
    return validator.isEmail(email);
  };

  const validatePassword = (password: any) => {
    const re =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.#/_-])[A-Za-z\d@$!%*?&.#/_-]{6,}$/;
    return re.test(password);
  };

  const validatePhone = (phone: string) => {
    return isValidPhoneNumber(phone);
  };

  const disabled =
    [
      companyName,
      companyPhone,
      address,
      // gstNumber,
      firstName,
      lastName,
      userPhone,
      email,
    ].includes("") ||
    domains.length === 0 ||
    !validateEmail(email) ||
    !validatePhone(userPhone) ||
    !validatePhone(companyPhone) ||
    !validatePassword(password) ||
    password !== password2;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 800,
          width: 1,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Create New Vendor
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent sx={{ pb: "16px !important" }}>
        <Stepper
          sx={{ my: 4, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          // nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton onClick={() => setStep(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {step === 0 && (
          <Box
            sx={{
              maxWidth: { xs: 280, sm: 560 },
              mx: "auto",
              py: 2,
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
              gap: 3,
            }}
          >
            <Box
            // gridColumn={{ sm: "span 2" }}
            >
              <Typography className="label">Company Name</Typography>
              <TextField
                required
                fullWidth
                size="small"
                value={companyName}
                placeholder="Company Name"
                onChange={(e: any) => {
                  handleChange("companyName", e.target.value);
                }}
                autoComplete="off"
              />
            </Box>
            {/* <Box>
              <Typography className="label">GST Number</Typography>
              <TextField
                fullWidth
                size="small"
                value={gstNumber}
                placeholder="GST Number"
                onChange={(e: any) => {
                  setInput({
                    ...input,
                    gstNumber:
                      e.target.value?.length > 0
                        ? e.target.value.replace(/[^a-zA-Z0-9 ]/g, "")
                        : e.target.value,
                  });
                }}
              // onInput={(e: any) => {
              //   e.target.value = e.target.value
              //     ? Math.max(0, parseInt(e.target.value))
              //       .toString()
              //       .slice(0, 15)
              //     : "";
              // }}
              />
            </Box> */}
            <Box>
              <Typography className="label">Phone</Typography>
              <PhoneInput
                placeholder="Enter phone number"
                value={companyPhone}
                onChange={(value) => handleChange("companyPhone", value || "")}
                defaultCountry="IN"
                international
                limitMaxLength
                inputComponent={PhoneTextField}
                error={!!companyPhone && !isValidPhoneNumber(companyPhone)}
              />
            </Box>
            <Box gridColumn={{ sm: "span 2" }}>
              <Typography className="label">Address</Typography>
              <TextField
                multiline
                size="small"
                minRows={3}
                fullWidth
                value={address}
                placeholder="Address"
                // onChange={(e) => {
                //   handleChange("address", e.target.value);
                // }}
                onChange={(e: any) => {
                  setInput({
                    ...input,
                    address:
                      e.target.value?.length > 0
                        ? e.target.value.replace(/[^a-zA-Z0-9 ]/g, "")
                        : e.target.value,
                  });
                }}
              />
            </Box>
            <Box gridColumn="1/-1">
              <Typography className="label" display="flex" alignItems="center">
                Domains
                <Tooltip
                  title="Allowed domains for this company"
                  placement="right"
                >
                  <HelpOutline color="disabled" sx={{ ml: 1, fontSize: 18 }} />
                </Tooltip>
              </Typography>
              <Box
                display="flex"
                alignItems="start"
                component="form"
                onSubmit={(e: any) => {
                  e.preventDefault();
                  if (
                    !!domainInput &&
                    isDomainValid &&
                    !domains.includes(domainInput)
                  ) {
                    setInput((prev: any) => ({
                      ...prev,
                      domains: [...prev.domains, domainInput],
                    }));
                    setDomainInput("");
                  }
                }}
              >
                <TextField
                  sx={{ width: 1, maxWidth: 200 }}
                  size="small"
                  value={domainInput}
                  placeholder="Domain"
                  onChange={(e: any) => setDomainInput(e.target.value)}
                  autoComplete="off"
                  helperText={`E.g. "gmail.com"`}
                  error={!!domainInput && !isDomainValid}
                />
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ ml: 1, height: 40 }}
                  onClick={() => {}}
                  startIcon={<Add />}
                  type="submit"
                >
                  Add
                </Button>
              </Box>
              <Collapse in={domains.length > 0}>
                <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap" }}>
                  {domains.map((domain: string, i: number) => (
                    <Chip
                      key={i}
                      label={domain}
                      variant="outlined"
                      color="info"
                      sx={{ mr: 1, mb: 1 }}
                      onDelete={() =>
                        setInput((prev: any) => ({
                          ...prev,
                          domains: prev.domains.filter(
                            (_: string, j: number) => j !== i,
                          ),
                        }))
                      }
                    />
                  ))}
                </Box>
              </Collapse>
            </Box>
          </Box>
        )}

        {step === 1 && (
          <Box
            sx={{
              maxWidth: { xs: 280, sm: 560 },
              mx: "auto",
              py: 2,
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
              gap: 3,
            }}
          >
            <Box>
              <Typography className="label">First Name</Typography>
              <TextField
                required
                fullWidth
                size="small"
                placeholder="First Name"
                value={firstName}
                onChange={(e: any) => {
                  handleChange("firstName", e.target.value);
                }}
                autoComplete="off"
              />
            </Box>
            <Box>
              <Typography className="label">Last Name</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Last Name"
                value={lastName}
                onChange={(e: any) => {
                  handleChange("lastName", e.target.value);
                }}
                autoComplete="off"
              />
            </Box>
            <Box>
              <Typography className="label">Phone</Typography>
              <PhoneInput
                placeholder="Enter phone number"
                value={userPhone}
                onChange={(value) => handleChange("userPhone", value || "")}
                defaultCountry="IN"
                international
                limitMaxLength
                inputComponent={PhoneTextField}
                error={!!userPhone && !isValidPhoneNumber(userPhone)}
              />
            </Box>
            <Box>
              <Typography className="label">Email</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Email"
                value={email}
                error={!!input.email && !validateEmail(input.email)}
                onChange={(e: any) => {
                  handleChange("email", e.target.value);
                }}
                autoComplete="off"
              />
            </Box>
            <Box>
              <Typography className="label">Password</Typography>
              <Box width={1} position="relative">
                <TextField
                  autoComplete="new-password"
                  sx={{ "& input": { pr: 7 } }}
                  placeholder="Password"
                  size="small"
                  fullWidth
                  error={!!password && !validatePassword(password)}
                  helperText={
                    password && !validatePassword(password)
                      ? "Use at least 6 characters, including one uppercase letter, one lowercase letter, one number, and one symbol."
                      : password
                        ? ""
                        : "Enter Password"
                  }
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e: any) => {
                    setPassword(e.currentTarget.value);
                  }}
                />
                <IconButton
                  size="small"
                  onClick={() => setShowPassword((value) => !value)}
                  sx={{ position: "absolute", right: 8, top: 5 }}
                >
                  {showPassword ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              </Box>
            </Box>
            <Box>
              <Typography className="label">Confirm Password</Typography>
              <Box width={1} position="relative">
                <TextField
                  autoComplete="new-password"
                  sx={{ "& input": { pr: 7 } }}
                  placeholder="Confirm Password"
                  size="small"
                  fullWidth
                  error={!!password2 && password !== password2}
                  helperText={
                    password2 && password !== password2
                      ? "Passwords don't match"
                      : ""
                  }
                  type={showPassword ? "text" : "password"}
                  value={password2}
                  onChange={(e: any) => {
                    setPassword2(e.currentTarget.value);
                  }}
                />
                <IconButton
                  size="small"
                  onClick={() => setShowPassword((value) => !value)}
                  sx={{ position: "absolute", right: 8, top: 5 }}
                >
                  {showPassword ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              </Box>
            </Box>
          </Box>
        )}

        {step === 2 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    bgcolor: (theme: any) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {[
                  { header: "Company Info", onEdit: () => setStep(0) },
                  { label: "Company Name", value: input.companyName },
                  // { label: "GST Number", value: input.gstNumber },
                  {
                    label: "Phone",
                    value: formatPhoneNumberIntl(input.companyPhone),
                  },
                  { label: "Address", value: input.address },
                  { label: "Domains", value: input.domains.join(", ") },

                  { header: "User Info", onEdit: () => setStep(1) },
                  { label: "First Name", value: input.firstName },
                  { label: "Last Name", value: input.lastName },
                  {
                    label: "Phone",
                    value: formatPhoneNumberIntl(input.userPhone),
                  },
                  { label: "Email", value: input.email },
                  // { label: "Password", value: "*********" },
                ].map(({ header, onEdit, label, value }, i, arr) => {
                  const isFirst = arr[i - 1]?.header;
                  const isLast = !arr[i + 1] || arr[i + 1].header;

                  return (
                    <tr
                      key={i}
                      className={
                        header
                          ? "header"
                          : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                      }
                    >
                      {header ? (
                        <td colSpan={2}>
                          <span>{header}</span>
                          <IconButton
                            sx={{ ml: 1.5 }}
                            children={<EditOutlined />}
                            color="primary"
                            size="small"
                            onClick={onEdit}
                          />
                        </td>
                      ) : (
                        <>
                          <td>{label}</td>
                          <td className="bold">{value}</td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {step !== 0 && (
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        )}
        <Button
          onClick={handleNext}
          variant={
            isComplete(step) || step === lastStep ? "contained" : "outlined"
          }
          // color={
          //   isComplete(step) || step === lastStep
          //     ? "primary"
          //     : "inherit"
          // }
          disableElevation
          disabled={step === lastStep && disabled}
        >
          {step === lastStep ? "Save" : isComplete(step) ? "Next" : "Skip"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDialog;
