import { Autocomplete, Box, Paper, TextField } from "@mui/material";
import { useMemo } from "react";
import { currencies } from "utils/currencies";

const CurrencyInput = ({
  currency,
  setCurrency,
  amount,
  setAmount,
  ...props
}: any) => {
  // useMemo to avoid re-rendering

  const options = useMemo(() => {
    return currencies.map((currency) => ({
      label: `${currency.name} (${currency.cc})`,
      value: currency.cc,
    }));
  }, []);

  return (
    <Box sx={{ display: "flex", alignItems: "start" }}>
      <Autocomplete
        sx={{ mr: 0.5, width: 110 }}
        size="small"
        disableClearable
        popupIcon={null}
        options={options}
        value={currency}
        onChange={(e, newValue) => setCurrency(newValue.value)}
        isOptionEqualToValue={(option, value) => option.value === value}
        renderInput={(params) => <TextField {...params} />}
        renderOption={(props, option) => (
          <Box
            {...props}
            component="li"
            sx={{ fontSize: 14, px: "12px !important" }}
          >
            {option.label}
          </Box>
        )}
        filterOptions={(options, { inputValue }) =>
          options.filter((option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
          )
        }
        PaperComponent={({ children }) => (
          <Paper sx={{ width: 250 }}>{children}</Paper>
        )}
      />
      <TextField
        size="small"
        type="number"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        {...props}
      />
    </Box>
  );
};

export default CurrencyInput;
