import {
  CheckCircleOutlineOutlined,
  ErrorOutlineOutlined,
  // OpenInFull,
} from "@mui/icons-material";
import {
  // Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";

import { LEASE_URL, PAYMENTS_URL } from "utils/constants";
import { useMutation, useQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar } from "utils";

import { CountdownCircleTimer } from "react-countdown-circle-timer";

const PaymentDialog = ({
  open,
  data,
  handleClose,
  amount,
  resetQueries,
}: any) => {
  const [isRunning, setIsRunning] = useState(true);

  const [orderStatus, setOrderStatus] = useState("NEW");

  const startTime = Date.now() / 1000;
  const endTime = startTime + 300;

  const remainingTime = endTime - startTime;
  const orderId =
    data && data?.paymentOrderId && data?.paymentOrderId[0]
      ? data?.paymentOrderId[0]
      : "";

  const getStatus = `${PAYMENTS_URL}/v2/juspay/orders/${orderId}`;

  const { data: statusData, refetch: refetchStatus } = useQuery(
    `getPaymentStatus`,
    () => authorizedFetch(getStatus),

    {
      enabled: orderId !== "",
    },
  );

  useEffect(() => {
    if (
      open &&
      amount !== 0 &&
      data &&
      ["NEW", "STARTED", "PENDING_VBV"].includes(orderStatus)
    ) {
      const status = setInterval(() => {
        refetchStatus();
      }, 5000);
      return () => {
        clearInterval(status);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data, orderStatus]);

  const cancel = `${LEASE_URL}/company/booking/${data?._id}/payment/cancel`;

  const { mutate: failMutation } = useMutation(
    `addVehicleBooking`,
    () =>
      authorizedFetch(cancel, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {},
      }),
    {
      onSuccess: () => {
        setLoader(false);
        snackbar.success("Payment Cancelled");
        resetQueries();
      },
    },
  );

  useEffect(() => {
    if (statusData) {
      setOrderStatus(statusData?.data?.status);
      if (statusData?.status !== 200) {
        failMutation();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusData]);

  useEffect(() => {
    if (!open) {
      setOrderStatus("NEW");
    }
  }, [open]);

  const timerProps = {
    isPlaying: true,
    size: 200,
    strokeWidth: 3,
  };

  const children = ({ remainingTime }: any) => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    if (remainingTime === 0) {
      setIsRunning(false);
    }

    return (
      <Typography fontSize={32} color="#00000090">{`${minutes}:${
        seconds < 10 ? `0${seconds}` : seconds
      }`}</Typography>
    );
  };

  useEffect(() => {
    if (!isRunning) {
      failMutation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRunning]);

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          maxWidth: 700,
          width: 1,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 1,
            p: "3.5px 5px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      ></DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mb: 5,
        }}
      >
        <Typography
          variant="caption"
          component="div"
          fontSize={14}
          textAlign="center"
          sx={{ mb: 5 }}
        >
          {["NEW", "STARTED", "PENDING_VBV"].includes(orderStatus) ? (
            isRunning ? (
              <>
                A{" "}
                <a
                  href={data?.paymentLink?.data?.paymentlinks?.web}
                  target="_blank"
                  rel="noreferrer"
                >
                  Payment link
                </a>{" "}
                has been sent to the <strong>Email Id</strong> &{" "}
                <strong>Phone Number</strong> of the User.
                <br />
                Please complete the transaction before the timer ends.
              </>
            ) : (
              <>Payment window is closed. Please create another Booking.</>
            )
          ) : orderStatus === "CHARGED" ? (
            <Box display="flex" alignItems="center">
              {amount === 0 ? <>Booking Created</> : <>Payment Successful</>}
            </Box>
          ) : (
            <>Error Processing Payment</>
          )}
        </Typography>
        {["NEW", "STARTED", "PENDING_VBV"].includes(orderStatus) ? (
          <CountdownCircleTimer
            {...timerProps}
            children={children}
            colors="#3CB99E"
            duration={300}
            initialRemainingTime={300}
            onComplete={(totalElapsedTime) => ({
              shouldRepeat: remainingTime - totalElapsedTime > 0,
            })}
          ></CountdownCircleTimer>
        ) : orderStatus === "CHARGED" ? (
          <CheckCircleOutlineOutlined color="primary" sx={{ fontSize: 100 }} />
        ) : (
          <ErrorOutlineOutlined
            sx={{ fontSize: 100, color: "red" }}
          ></ErrorOutlineOutlined>
        )}

        {["NEW", "STARTED", "PENDING_VBV"].includes(orderStatus) ? (
          <Button
            sx={{ mt: 5 }}
            variant="outlined"
            size="large"
            onClick={() => {
              failMutation();
              resetQueries();
              handleClose();
            }}
          >
            Cancel Booking
          </Button>
        ) : orderStatus === "CHARGED" ? (
          <Button
            sx={{ mt: 5 }}
            variant="outlined"
            size="large"
            onClick={() => {
              resetQueries();
              handleClose();
            }}
          >
            Back to Leases
          </Button>
        ) : (
          <Button
            sx={{ mt: 5 }}
            variant="outlined"
            size="large"
            onClick={() => {
              resetQueries();
              handleClose();
            }}
          >
            Back to Leases
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PaymentDialog;
