import {
  AddRounded,
  DeleteOutline,
  EditOutlined,
  ErrorOutline,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Step,
  StepButton,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { AUTH_URL, RETAIL_URL } from "utils/constants";

import { queryClient } from "index";

interface Props {
  open: boolean;
  handleClose: () => void;
  companyId?: any;
  modelId?: any;
}

const InputData: any = {
  companyName: "",
  model: "",
};

const EditUserDialog: React.FC<Props> = ({
  open,
  handleClose,
  companyId,
  modelId,
}) => {
  const [step, setStep] = useState(0);
  const steps = ["Model Details", "Finish"];

  const [input, setInput] = useState({ ...InputData });
  const [initialVariants, setInitialVariants] = useState([]);

  const [variants, setVariants] = useState<any>([]);

  const { companyName, model } = input;

  console.log(modelId);

  useEffect(() => {
    if (companyId && modelId) {
      setInput({
        companyName: companyId,
        model: modelId,
      });
    }
  }, [companyId, modelId]);

  const colorsUrl = `${RETAIL_URL}/assembly/model/${model}/default-colors`;
  const { isLoading: colorsLoading, data: colorsData } = useQuery(
    ["getModelColors"],
    () => authorizedFetch(colorsUrl),
    { enabled: !!model },
  );

  const variantsUrl = `${RETAIL_URL}/assembly/model/${model}/variants`;
  const { isLoading: variantsLoading, data: variantsData } = useQuery(
    ["getModelVariants", model],
    () => authorizedFetch(variantsUrl),
    { enabled: !!model },
  );

  useEffect(() => {
    const fetchAllIcons = async () => {
      if (open && model && variantsData) {
        if (variantsData?.data.length !== 0) {
          try {
            // Map each variant to a promise to fetch its icon as a file
            const fetchPromises = variantsData.data.map(
              async (variant: any) => {
                const file = await fetchIconAsFile(
                  variant.icon,
                  `${variant.name.replace(/\s+/g, "_").toLowerCase()}.png`,
                );

                return {
                  id: variant._id,
                  image: variant.icon,
                  color: variant.name
                    .toLowerCase()
                    .split(" ")
                    .map(
                      (word: any) =>
                        word.charAt(0).toUpperCase() + word.slice(1),
                    )
                    .join(" "),
                  file: file,
                };
              },
            );

            // Wait for all fetch operations to complete
            const fetchedVariants: any = await Promise.all(fetchPromises);

            // Then set the variants with the fetched files
            setInitialVariants(fetchedVariants);
            setVariants(fetchedVariants);
          } catch (error) {
            console.error("Error fetching variant icons:", error);
            // Handle any errors, such as by setting a default state or showing an error message
          }
        } else {
          setVariants([
            {
              id: Date.now(),
              image: "",
              color: "",
              file: "",
            },
          ]);
        }
      }
    };

    fetchAllIcons();
  }, [open, model, variantsData]); // Dependencies array

  console.log(variants);

  const hasVariantsChanged = () => {
    return JSON.stringify(initialVariants) !== JSON.stringify(variants);
  };

  function handleChange(key: string, value: string) {
    setInput((prevInput: any) => ({ ...prevInput, [key]: value }));
  }

  const handleSave = () => {
    setLoader(true);
    const formData = new FormData();

    variants.forEach((variant: any) => {
      formData.append(variant.color, variant.file);
    });

    authorizedFetch(`${RETAIL_URL}/assembly/model/${model}/add-variants`, {
      method: "POST",
      onlyBody: formData,
    })
      .then((res) => {
        setLoader(false);
        if (
          res.meta.success === true ||
          res.msg === "Model variants uploaded successfully"
        ) {
          snackbar.success(`Success. Model variants added successfully`);
          queryClient.resetQueries("getModelVariants");
          queryClient.resetQueries("getModelColors");
          queryClient.resetQueries("getModelList");
          handleClose();
        } else {
          console.error(res);
          snackbar.error(res.msg || "An error occured");
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error("An error occured");
      });

    handleClose();
  };

  function handleNext() {
    if (step === steps.length - 1) {
      handleSave();
    } else setStep(step + 1);
  }
  function handleBack() {
    setStep(step - 1);
  }

  function isComplete(step: number) {
    const basicInfoComplete = ![companyName, model].includes("");

    const variantsComplete = variants.every((variant: any) => {
      // Check if both the image and color fields are not empty
      return variant.image && variant.color;
    });

    switch (step) {
      case 0:
        return (
          basicInfoComplete &&
          variants.length !== 0 &&
          variantsComplete &&
          hasVariantsChanged()
        );
      // Implement other cases as needed for different steps
      default:
        return false;
    }
  }

  console.log(variants, "variants");

  const disabled =
    [companyName, model].includes("") ||
    variants.length === 0 ||
    variants.some((variant: any) => {
      // Check if either the image or color fields are empty
      return !variant.image || !variant.color;
    }) ||
    !hasVariantsChanged();

  useEffect(() => {
    if (!open) {
      setInput({
        companyName: "",
        model: "",
      });
      setStep(0);
      setVariants([]);
      setInitialVariants([]);
    }
  }, [open]);

  // Function to handle adding new variant
  const addVariant = () => {
    setVariants([
      ...variants,
      { id: Date.now(), image: "", color: "", file: "" },
    ]);
  };

  // Function to handle removing a variant
  const removeVariant = (id: any) => {
    setVariants(variants.filter((variant: any) => variant.id !== id));
  };

  // Function to handle image change
  const handleImageChange = (id: any, files: any) => {
    if (files.length) {
      const file = files[0];
      const updatedVariants = variants.map((variant: any) => {
        if (variant.id === id) {
          const url = URL.createObjectURL(files[0]);
          return { ...variant, image: url, file: file };
        }
        return variant;
      });
      setVariants(updatedVariants);
    }
  };

  const handleVariantColor = (id: any, color: any) => {
    const updatedVariants = variants.map((variant: any) => {
      if (variant.id === id) {
        return { ...variant, color: color };
      }
      return variant;
    });
    setVariants(updatedVariants);
  };

  const [companies, setCompanies] = useState<any>([]);
  const [vehicleModels, setVehicleModels] = useState<any>([]);

  useEffect(() => {
    if (open) {
      authorizedFetch(`${AUTH_URL}/company/list`)
        .then((res) => setCompanies(res?.data))
        .catch((err) => console.error(err));
    }
  }, [open]);

  useEffect(() => {
    if (companyName) {
      authorizedFetch(`${RETAIL_URL}/assembly/model/company/${companyName}`)
        .then((res) => setVehicleModels(res?.data))
        .catch((err) => console.error(err));
    }
  }, [companyId, companyName]);

  const getFilteredColors = (variantId: any) => {
    const selectedColors = variants
      .filter((variant: any) => variant.id !== variantId)
      .map((variant: any) => variant.color);

    return (
      colorsData?.data
        ?.filter((el: any) => !selectedColors.includes(el.name))
        .map((el: any) =>
          el.name
            .toLowerCase()
            .split(" ")
            .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
        ) || []
    );
  };

  async function fetchIconAsFile(iconUrl: any, fileName: any) {
    try {
      const response = await fetch(iconUrl);
      if (!response.ok) throw new Error("Network response was not ok");
      const blob = await response.blob();
      return new File([blob], fileName, { type: blob.type });
    } catch (error) {
      console.error("Could not fetch icon:", error);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 1200,
          width: 1,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 10,
            p: "3.5px 5px",
          },
        },
      }}
    >
      <DialogTitle>Add Model Details</DialogTitle>
      <DialogContent sx={{ pb: "16px !important" }}>
        <Stepper
          sx={{ my: 4, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton onClick={() => setStep(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {step === 0 && (
          <>
            <Box sx={{ maxWidth: { xs: 280, sm: 750 }, mx: "auto" }}>
              <Typography sx={{ fontSize: "0.875rem" }}>
                All fields with ( * ) are required
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: { xs: 280, sm: 750 },
                mx: "auto",
                py: 2,
                display: "grid",
                gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                gap: 3,
                "& .required": {
                  color: "red",
                },
                "& .MuiInputBase-root": { borderRadius: 10 },
              }}
            >
              <Box>
                <Typography className="label">
                  Company <span className="required">&#x2a;</span>
                </Typography>
                <Select
                  size="medium"
                  fullWidth
                  displayEmpty
                  renderValue={
                    companyName !== "" ? undefined : () => <em>Select</em>
                  }
                  value={companyName}
                  onChange={(e) => {
                    handleChange("companyName", e.target.value);
                  }}
                >
                  <MenuItem disabled value={""}>
                    {<em>Choose a company</em>}
                  </MenuItem>
                  {companies.length > 0 &&
                    companies?.map((model: any) => (
                      <MenuItem key={model._id} value={model._id}>
                        {model.name}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
              <Box>
                <Typography className="label">
                  Model <span className="required">&#x2a;</span>
                </Typography>

                <Select
                  size="medium"
                  fullWidth
                  disabled={companyName?.length === 0}
                  value={model}
                  renderValue={model !== "" ? undefined : () => <em>Select</em>}
                  onChange={(e: any) => {
                    handleChange("model", e.target.value);
                  }}
                  displayEmpty
                >
                  <MenuItem disabled value={""}>
                    {<em>Select a model</em>}
                  </MenuItem>
                  {vehicleModels?.map((model: any) => (
                    <MenuItem key={model._id} value={model._id}>
                      {model.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <Box sx={{ gridColumn: { sm: "span 2" } }}>
                <Typography className="label">
                  Add Variant <span className="required">&#x2a;</span>
                </Typography>
                {!model ? (
                  <Box
                    sx={{
                      gridColumn: {
                        sm: "span 2",
                      },
                      height: 200,
                      border: "1px solid #00000015",
                      borderRadius: 3,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    <Typography sx={{ color: "#00000050", fontSize: 14 }}>
                      Select Company & Model to add variants
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#A5A5A5",
                        }}
                      >
                        Upload Image & choose color
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#A5A5A5",
                        }}
                      >
                        {variants.length} variant
                        {variants.length > 1 ? "s" : ""}
                      </Typography>
                    </Box>
                    <Divider sx={{ mt: 0.5 }} />

                    {variantsLoading ? (
                      <Box
                        sx={{
                          gridColumn: {
                            sm: "span 2",
                          },
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          mt: 10,
                        }}
                      >
                        <CircularProgress size={22} />
                      </Box>
                    ) : (
                      <>
                        {variants.map((variant: any, index: any) => {
                          return <>
                            {index !== 0 && <Divider />}
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                gap: 2,
                                my: 3,
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                              key={variant.id}
                            >
                              <Box
                                sx={{
                                  width: "50%",
                                  display: "flex",
                                  gap: 2,

                                  alignItems: "center",
                                }}
                              >
                                {variant.image !== "" ? (
                                  <>
                                    <Box
                                      sx={{
                                        width: 88,
                                        height: 88,
                                        borderRadius: 2,
                                        border: "1px solid #E0E0E0",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src={variant.image}
                                        style={{
                                          width: "100%",
                                          height: "auto",
                                          objectFit: "contain",
                                        }}
                                        alt={`Variant ${index + 1}`}
                                      />
                                    </Box>
                                    <Button variant="text" component="label">
                                      Change Image
                                      <input
                                        type="file"
                                        hidden
                                        multiple
                                        name="myImage"
                                        accept="image/*"
                                        onChange={(event) =>
                                          handleImageChange(
                                            variant.id,
                                            event.target.files,
                                          )
                                        }
                                      />
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      sx={{
                                        width: 88,
                                        height: 88,
                                        borderRadius: 2,
                                      }}
                                      variant="outlined"
                                      component="label"
                                    >
                                      <AddRounded />
                                      <input
                                        type="file"
                                        hidden
                                        multiple
                                        name="myImage"
                                        accept="image/*"
                                        onChange={(event) =>
                                          handleImageChange(
                                            variant.id,
                                            event.target.files,
                                          )
                                        }
                                      />
                                    </Button>
                                    <Typography
                                      sx={{ fontSize: 14, fontWeight: 600 }}
                                    >
                                      Upload Image
                                    </Typography>
                                  </>
                                )}
                              </Box>
                              <Box
                                sx={{
                                  width: "50%",
                                  display: "flex",
                                  alignItems: "flex-start",
                                }}
                              >
                                <Box sx={{ width: "90%" }}>
                                  <Typography className="label">
                                    Color Variant Name
                                    <span className="required">&#x2a;</span>
                                  </Typography>
                                  <Autocomplete
                                    loading={colorsLoading}
                                    disabled={!model}
                                    freeSolo={true}
                                    size="medium"
                                    popupIcon={<KeyboardArrowDown />}
                                    value={variant.color}
                                    onInputChange={(event, newInputValue) => {
                                      if (
                                        typeof newInputValue === "string" &&
                                        !/^\d+$/.test(newInputValue)
                                      ) {
                                        handleVariantColor(
                                          variant.id,
                                          newInputValue,
                                        );
                                      }
                                    }}
                                    getOptionLabel={(option) => option}
                                    options={getFilteredColors(variant.id)}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select a Color..."
                                        value={variant.color}
                                      />
                                    )}
                                  />
                                </Box>

                                {variants.length > 1 && (
                                  <Tooltip title="Remove Variant">
                                    <IconButton
                                      sx={{
                                        width: "10%",
                                        mt: -1,
                                        opacity: 0.8,
                                      }}
                                      children={<DeleteOutline />}
                                      color="inherit"
                                      size="small"
                                      onClick={() =>
                                        removeVariant(variant.id)
                                      }
                                    />
                                  </Tooltip>
                                )}
                              </Box>
                            </Box>
                          </>;
                        })}
                        <Button onClick={addVariant}>Add Variant</Button>
                      </>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </>
        )}
        {step === 1 && (
          <Box
            sx={{
              maxWidth: 750,
              mx: "auto",
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    bgcolor: (theme) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {[
                  { header: "Model Details", onEdit: () => setStep(0) },
                  {
                    label: "Company Name",
                    value: companies.find((el: any) => el._id === companyName)
                      ?.name,
                    required: true,
                  },
                  {
                    label: "Model",
                    value: vehicleModels.find((el: any) => el._id === model)
                      ?.name,
                    required: true,
                  },
                ].map(({ header, onEdit, label, value, required }, i, arr) => {
                  const isFirst = arr[i - 1]?.header;
                  const isLast = !arr[i + 1] || arr[i + 1].header;

                  return (
                    <tr
                      key={i}
                      className={
                        header
                          ? "header"
                          : `${isFirst ? "first" : ""} ${isLast ? "last" : ""}`
                      }
                    >
                      {header ? (
                        <td colSpan={2}>
                          <span>{header}</span>
                          <IconButton
                            sx={{ ml: 1.5 }}
                            children={<EditOutlined />}
                            color="primary"
                            size="small"
                            onClick={onEdit}
                          />
                        </td>
                      ) : (
                        <>
                          <td>{label}</td>
                          <td className="bold">
                            {value ||
                              (required && (
                                <Box display="flex" alignItems="center">
                                  <ErrorOutline
                                    fontSize="small"
                                    color="error"
                                    style={{ marginRight: 8 }}
                                  />
                                  Required
                                </Box>
                              ))}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
                {variants.map((variant: any, index: any) => (
                  <React.Fragment key={variant.id}>
                    <tr>
                      <td>Variant No. {index + 1}</td>
                      <td className="bold">
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              width: 88,
                              height: 88,
                              borderRadius: 2,
                              border: "1px solid #E0E0E0",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={variant.image}
                              style={{
                                width: "100%",
                                height: "auto",
                                objectFit: "contain",
                              }}
                              alt={`Variant ${index + 1}`}
                            />
                          </Box>
                          Color: {variant.color || "N/A"}
                        </Box>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {step !== 0 && (
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        )}
        {step === 0 && (
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        )}
        <Button
          onClick={handleNext}
          variant={
            isComplete(step) || step === steps.length - 1
              ? "contained"
              : "outlined"
          }
          disableElevation
          disabled={disabled}
        >
          {step === steps.length - 1 ? "Save" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserDialog;
