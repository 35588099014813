import { useEffect, useState } from "react";
import AppBar from "./AppBar";
import { appBar, authorizedFetch, drawer } from "utils";
import { Box, Paper, useMediaQuery, useTheme } from "@mui/material";
import moment from "moment";
import Table from "components/Table";
import { AUTH_URL, RETAIL_URL } from "utils/constants";
import { useQuery } from "react-query";
import InfoDrawer from "./InfoDrawer";
import Search from "components/Search";

const OTA = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");

  const first = pageSize;
  const skip = pageSize * (page - 1);

  const {
    isLoading: modelLoading,
    data: modelData,
    refetch: refetchData,
  } = useQuery(["getModelList", first, skip, search], () =>
    authorizedFetch(
      `${RETAIL_URL}/assembly/model?first=${first}&skip=${skip}&search=${search}`,
    ),
  );

  useEffect(() => {
    appBar.open(<AppBar ota={modelData} />);
    return () => {
      appBar.close();
      drawer.close();
    };
  }, [modelData]);

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const [companies, setCompanies] = useState<any>([]);

  useEffect(() => {
    authorizedFetch(`${AUTH_URL}/company/list`)
      .then((res) => setCompanies(res?.data))
      .catch((err) => console.error(err));
  }, []);

  return (
    <Box
      sx={{
        // px: { xs: 2, md: 7 },
        // pt: { xs: 2, md: 4.75 },
        pb: { xs: 3, md: 7 },
      }}
    >
      <Paper
        sx={{
          width: 1,
          boxShadow: (theme) => theme.customShadows.card,
          borderRadius: 2,
          pt: 3,
        }}
      >
        <Box
          sx={{
            px: isMdDown ? 1 : 2.5,
            py: 2.5,
            display: isMdDown ? "" : "flex",
            flexDirection: isMdDown ? "" : "row-reverse",
            width: "100%",
          }}
        >
          <Search
            handleSearch={(input) => setSearch(input)}
            persist
            enableClear
          />
        </Box>
        {console.log(modelData?.data, "data")}
        <Table
          loading={modelLoading}
          rowCount={modelData?.meta?.totalCount}
          rows={modelData?.data || []}
          serverSidePagination
          activePage={page}
          onRowClick={(row) =>
            drawer.open(
              <InfoDrawer
                model={row}
                companies={companies}
                refectData={refetchData}
              />,
            )
          }
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          columns={[
            {
              key: "company.name",
              label: "Company",
              Render: (row: any) => (
                <Box>
                  {companies.find((el: any) => el._id === row.company)?.name ||
                    "-"}
                </Box>
              ),
            },
            { key: "name", label: "Model" },

            {
              key: "variant",
              label: "Variant",
              Render: (row: any) => (
                <Box>{row.variants ? row.variants.length : 0}</Box>
              ),
            },

            {
              key: "createddAt",
              label: "Created On",
              Render: (row: any) => (
                <Box>{moment(row.createdAt).format("YYYY-MM-DD")}</Box>
              ),
            },
          ]}
        />
      </Paper>
    </Box>
  );
};

export default OTA;
