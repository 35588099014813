import { EditOutlined, HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import CircularLoader from "components/CircularLoader";
import PhoneTextField from "components/PhoneTextField";
import { queryClient } from "index";
import { useEffect, useState } from "react";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useQuery } from "react-query";
import { authorizedFetch, drawer, setLoader, snackbar } from "utils";
import { AUTH_URL } from "utils/constants";
import validator from "validator";

const inputObj = {
  companyName: "",
  companyPhone: "",
  companyAddress: "",
  gstNumber: "",

  userFirstName: "",
  userLastName: "",
  userPhone: "",
  userEmail: "",
};

const EditDialog = ({ open, handleClose, vendor, initialStep }: any) => {
  const [step, setStep] = useState<number>(0);
  const steps = ["Company Info", "User Info", "Finish"];
  const [input, setInput] = useState({ ...inputObj });

  const vendorUrl = `${AUTH_URL}/company/vendor/${vendor._id}`;
  const { isLoading, data } = useQuery(["getVendor", vendor._id], () =>
    authorizedFetch(vendorUrl)
  );
  const vendorData = data?.data?.[0];

  useEffect(() => {
    if (open) {
      setStep(initialStep || 0);
    }
  }, [open, initialStep]);

  useEffect(() => {
    if (!open) return;
    setInput({
      ...inputObj,
      companyName: vendor?.name || "",
      companyPhone: vendor?.phone || "",
      companyAddress: vendor?.address || "",
      userFirstName: vendorData?.user?.firstName || "",
      userLastName: vendorData?.user?.lastName || "",
      userPhone: vendorData?.user?.phone || "",
      userEmail: vendorData?.user?.email || "",
      gstNumber: vendor?.gstNumber || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, vendor, data]);

  const {
    companyName,
    companyPhone,
    companyAddress,
    gstNumber,

    userFirstName,
    userLastName,
    userPhone,
    userEmail,
  } = input;

  function handleChange(key: string, value: string) {
    setInput((prevInput) => ({ ...prevInput, [key]: value }));
  }

  // const isValid = {
  //   gstNumber:
  //     gstNumber === ""
  //       ? true
  //       : /^[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
  //           gstNumber
  //         ),
  // };

  function isComplete(step: number) {
    switch (step) {
      case 0:
        return ![companyName, companyPhone, companyAddress, gstNumber].includes(
          ""
        );
      case 1:
        return ![userFirstName, userLastName, userPhone, userEmail].includes(
          ""
        );
      default:
        break;
    }
  }

  function handleSave() {
    setLoader(true);
    authorizedFetch(`${AUTH_URL}/company/vendor/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        company: {
          _id: vendor?._id,
          name: companyName,
          address: companyAddress,
          phone: companyPhone,
          gstNumber: gstNumber,
        },
        user: {
          _id: vendorData?.user?._id,
          firstName: userFirstName,
          lastName: userLastName,
          // email: userEmail,
          // phone: userPhone,
        },
      },
    })
      .then((res) => {
        setLoader(false);
        if (res.meta.success) {
          snackbar.success("Vendor updated");
          ["getVendors", ["getVendorChargers", vendor?._id]].forEach((key) => {
            queryClient.resetQueries(key);
          });
          drawer.close();
          handleClose();
        } else {
          snackbar.error(
            `Error${res.msg ? `: ${res.msg}` : " updating vendor"}`
          );
        }
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        snackbar.error("Error updating vendor");
      });
  }

  function handleNext() {
    if (step === steps.length - 1) {
      handleSave();
    } else setStep(step + 1);
  }
  function handleBack() {
    setStep(step - 1);
  }

  const validateEmail = (email: string) => {
    return validator.isEmail(email);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 800,
          width: 1,
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Edit Vendor
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent sx={{ pb: "16px !important" }}>
        <Stepper
          sx={{ my: { xs: 2, md: 4 }, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton onClick={() => setStep(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>

        <Collapse in={isLoading}>
          <Box my={10}>
            <CircularLoader />
          </Box>
        </Collapse>

        <Collapse in={!isLoading}>
          {step === 0 && (
            <Box
              sx={{
                maxWidth: { xs: 280, sm: 560 },
                mx: "auto",
                py: 2,
                display: "grid",
                gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                gap: 3,
              }}
            >
              <Box
              // gridColumn={{ sm: "span 2" }}
              >
                <Typography className="label">Company Name</Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Company Name"
                  value={companyName}
                  onChange={(e: any) => {
                    handleChange("companyName", e.target.value);
                  }}
                />
              </Box>
              <Box>
                <Typography className="label">Phone Number</Typography>
                <PhoneInput
                  placeholder="Enter phone number"
                  value={companyPhone}
                  onChange={(value) =>
                    setInput({ ...input, companyPhone: value || "" })
                  }
                  defaultCountry="IN"
                  international
                  limitMaxLength
                  inputComponent={PhoneTextField}
                  error={!!companyPhone && !isValidPhoneNumber(companyPhone)}
                />
              </Box>
              {/* <Box>
              <Typography className="label">GST Number</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="GST Number"
                value={gstNumber}
                onChange={(e: any) => {
                  setInput({
                    ...input,
                    gstNumber:
                      e.target.value?.length > 0
                        ? e.target.value.replace(/[^a-zA-Z0-9 ]/g, "")
                        : e.target.value,
                  });
                }}
              />
            </Box> */}
              <Box gridColumn={{ sm: "span 2" }}>
                <Typography className="label">Address</Typography>
                <TextField
                  multiline
                  size="small"
                  minRows={3}
                  fullWidth
                  value={companyAddress}
                  placeholder="Address"
                  onChange={(e: any) => {
                    setInput({
                      ...input,
                      companyAddress:
                        e.target.value?.length > 0
                          ? e.target.value.replace(/[^a-zA-Z0-9 ]/g, "")
                          : e.target.value,
                    });
                  }}
                />
              </Box>
              <Box>
                <Typography className="label">GST Number</Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="GST Number"
                  value={gstNumber}
                  onChange={(e: any) => {
                    setInput({
                      ...input,
                      gstNumber:
                        e.target.value?.length > 0
                          ? e.target.value.replace(/[^a-zA-Z0-9 ]/g, "")
                          : e.target.value,
                    });
                  }}
                />
              </Box>
            </Box>
          )}

          {step === 1 && (
            <Box
              sx={{
                maxWidth: { xs: 280, sm: 560 },
                mx: "auto",
                py: 2,
                display: "grid",
                gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                gap: 3,
              }}
            >
              <Box>
                <Typography className="label">First Name</Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="First Name"
                  value={userFirstName}
                  onChange={(e: any) => {
                    handleChange("userFirstName", e.target.value);
                  }}
                />
              </Box>
              <Box>
                <Typography className="label">Last Name</Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Last Name"
                  value={userLastName}
                  onChange={(e: any) => {
                    handleChange("userLastName", e.target.value);
                  }}
                />
              </Box>
              <Box>
                <Typography className="label">Phone Number</Typography>
                <PhoneInput
                  placeholder="Enter phone number"
                  value={userPhone}
                  onChange={(value) =>
                    setInput({ ...input, userPhone: value || "" })
                  }
                  defaultCountry="IN"
                  international
                  limitMaxLength
                  inputComponent={PhoneTextField}
                  error={!!userPhone && !isValidPhoneNumber(userPhone)}
                  disabled
                />
              </Box>

              <Box>
                <Typography className="label">Email</Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Email"
                  value={userEmail}
                  error={!validateEmail(userEmail)}
                  helperText={
                    !!userEmail && !validateEmail(userEmail)
                      ? "Invalid Email"
                      : userEmail
                        ? ""
                        : "Enter Email"
                  }
                  onChange={(e: any) => {
                    handleChange("userEmail", e.target.value);
                  }}
                  disabled
                />
              </Box>
            </Box>
          )}

          {step === 2 && (
            <Box
              sx={{
                maxWidth: 560,
                mx: "auto",
                "& .table": {
                  borderCollapse: "collapse",
                  width: 1,
                  fontSize: 14,
                  lineHeight: "16px",
                  "& td": {
                    py: 1.25,
                    px: 2,
                  },
                  "& .bold": {
                    fontWeight: 500,
                  },
                  "& .header": {
                    px: 2,
                    py: 1,
                    position: "relative",
                    "& td": {
                      position: "absolute",
                      verticalAlign: "middle",
                      bgcolor: (theme: any) => theme.customColors.header,
                      width: 1,
                      borderRadius: "4px",
                      fontSize: 16,
                      fontWeight: 600,
                      "& span": {
                        display: "inline-block",
                        transform: "translateY(1px)",
                      },
                    },
                  },
                  "& .first > td": {
                    pt: 9,
                  },
                  "& .last > td": {
                    pb: 3,
                  },
                },
              }}
            >
              <table className="table">
                <tbody>
                  {[
                    // Company Info
                    { header: "Company Info", onEdit: () => setStep(0) },
                    { label: "Company Name", value: companyName },
                    {
                      label: "Phone Number",
                      value: formatPhoneNumberIntl(companyPhone),
                    },
                    { label: "Address", value: companyAddress },
                    { label: "GST Number", value: gstNumber },

                    // User Info
                    { header: "User Info", onEdit: () => setStep(1) },
                    { label: "First Name", value: userFirstName },
                    { label: "Last Name", value: userLastName },
                    {
                      label: "Phone Number",
                      value: formatPhoneNumberIntl(userPhone),
                    },
                    { label: "Email", value: userEmail },
                  ].map(({ header, onEdit, label, value }, i, arr) => {
                    const isFirst = arr[i - 1]?.header;
                    const isLast = !arr[i + 1] || arr[i + 1].header;

                    return (
                      <tr
                        key={i}
                        className={
                          header
                            ? "header"
                            : `${isFirst ? "first" : ""} ${
                                isLast ? "last" : ""
                              }`
                        }
                      >
                        {header ? (
                          <td colSpan={2}>
                            <span>{header}</span>
                            <IconButton
                              sx={{ ml: 1.5 }}
                              children={<EditOutlined />}
                              color="primary"
                              size="small"
                              onClick={onEdit}
                            />
                          </td>
                        ) : (
                          <>
                            <td>{label}</td>
                            <td className="bold">
                              {value !== "" ? (
                                <Box display="flex" alignItems="center">
                                  {value}
                                  {/* {isValid === false && (
                                    <>
                                      <WarningAmber
                                        fontSize="small"
                                        color="warning"
                                        sx={{ ml: 2, mr: 1 }}
                                      />
                                      Invalid
                                    </>
                                  )} */}
                                </Box>
                              ) : // required && (
                              //   <Box display="flex" alignItems="center">
                              //     <ErrorOutline
                              //       fontSize="small"
                              //       color="error"
                              //       sx={{ mr: 1 }}
                              //     />
                              //     Required
                              //   </Box>
                              // )
                              null}
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Box>
          )}
        </Collapse>
      </DialogContent>
      <DialogActions>
        {step !== 0 && (
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        )}
        <Button
          onClick={handleNext}
          variant="contained"
          color={
            isComplete(step) || step === steps.length - 1
              ? "primary"
              : "inherit"
          }
          disableElevation
        >
          {step === steps.length - 1
            ? "Save"
            : isComplete(step)
              ? "Next"
              : "Skip"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;
