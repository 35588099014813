import {
  HighlightOff,
  LocationOn,
  Lock,
  LockOpen,
  PowerSettingsNew,
} from "@mui/icons-material";
import { Alert, Box, Fab, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { authorizedFetch, GlobalState, setLoader, snackbar } from "utils";
import { API_URL, LEASE_URL, RETAIL_URL } from "utils/constants";

const Commands = ({ vehicle }: any) => {
  // const deviceType = vehicle?.device?.type || "";
  const deviceType =
    vehicle?.components?.find(
      (component: any) => component.category === "TELEMATICS",
    )?.id?.parentModel?.key || "";

  const { rentalStatus } = vehicle;
  const isRetail = vehicle?.status === "SOLD";
  // const isRental = vehicle?.status === "RENTAL";

  const { activeSection } = useSelector((state: GlobalState) => state.global);
  const isVMS = activeSection === "retail";

  function sendCommand(command: string) {
    // if (!deviceType) {
    //   snackbar.error("No peripheral device found");
    //   return;
    // }

    setLoader(true);

    if (deviceType === "POINTER") {
      if (["LOCK", "IGNITION_OFF"].includes(command)) {
        command = "DC";
      }
      if (["UNLOCK", "IGNITION_ON"].includes(command)) {
        command = "DD";
      }
    }

    let url = "";

    if (command === "WHEEL_LOCK") {
      url = `${LEASE_URL}/vehicles/${vehicle.vin}/disableKey`;
    }
    if (command === "WHEEL_UNLOCK") {
      url = `${LEASE_URL}/vehicles/${vehicle.vin}/enableKey`;
    }

    if (isVMS) {
      if (["LOCK", "IGNITION_OFF"].includes(command)) {
        url = `${RETAIL_URL}/vehicles/lock/${vehicle.vin}`;
      } else if (["UNLOCK", "IGNITION_ON"].includes(command)) {
        url = `${RETAIL_URL}/vehicles/unlock/${vehicle.vin}`;
      } else if (command === "FIND") {
        url = `${RETAIL_URL}/vehicles/locate/${vehicle.vin}`;
      } else {
        url = `${API_URL}/v2/vehicles/${vehicle.vin}/exec`;
      }
      if (command === "WHEEL_LOCK") {
        url = `${LEASE_URL}/vehicles/${vehicle.vin}/disableKey`;
      }
      if (command === "WHEEL_UNLOCK") {
        url = `${LEASE_URL}/vehicles/${vehicle.vin}/enableKey`;
      }
    } else {
      if (!isRetail) {
        if (["LOCK", "IGNITION_OFF"].includes(command)) {
          url = `${LEASE_URL}/vehicles/${vehicle.vin}/lock`;
        } else if (["UNLOCK", "IGNITION_ON"].includes(command)) {
          url = `${LEASE_URL}/vehicles/${vehicle.vin}/unlock`;
        } else if (command === "FIND") {
          url = `${LEASE_URL}/vehicles/${vehicle.vin}/locate`;
        }
      } else {
        url = `${API_URL}/v2/vehicles/${vehicle.vin}/exec`;
      }
    }

    authorizedFetch(url, {
      method: "POST",
      ...(url.includes(API_URL)
        ? {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({ command }),
          }
        : {}),
    })
      .then((res) => {
        setLoader(false);
        console.log(res);
        if (
          url.includes(API_URL)
            ? res.status === 200
            : url.includes(LEASE_URL)
              ? res.status === 200 ||
                res.msg === "\u0001ok!" ||
                res.msg === "SUCCESS." ||
                res.msg === "\u0001Cut off the fuel supply: Success!" ||
                res.msg === "\u0001Restore fuel supply: Success!" ||
                res.msg === "OK!" ||
                res.msg === "Terminal exit lock car status!" ||
                res.msg === "Remote disarm success!"
              : res.msg?.status === 200
        )
          snackbar.success(
            url.includes(API_URL) ? res.data : `Success: ${res.msg}`,
          );
        else
          snackbar.error(
            url.includes(API_URL)
              ? res.data
              : url.includes(LEASE_URL)
                ? `Error${res.msg ? ": " + res.msg : ""}`
                : `Error${res.msg?.data ? ": " + res.msg.data : ""}`,
          );
      })
      .catch((err) => {
        setLoader(false);
        snackbar.error("An error occurred");
        console.error(err);
      });
  }

  const theme = useTheme();

  // if TBIT or ICONCOX:       include "Locate""Wheel Lock/Unlock"
  // if FMS && vehicle booked: include "Start/End"
  // if TBIT:                  include "Ignition On/Off"
  // always                    include "Lock/Unlock"
  //                                       └── if not TBIT, send command "Ignition Off/On"

  return (
    <Box
      sx={{
        pt: 8,
        pb: 10,
        mx: "auto",
        width: "fit-content",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: 4,
      }}
    >
      {["TBIT", "ICONCOX", "V5"].includes(deviceType) && (
        <Box
          sx={{
            gridColumn: "span 2",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Fab variant="extended" onClick={() => sendCommand("FIND")}>
            <LocationOn sx={{ mr: 1 }} />
            Locate
          </Fab>
        </Box>
      )}

      {deviceType === "TBIT" && (
        <Box
          sx={{
            gridColumn: "span 2",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: 4,
          }}
        >
          <Fab variant="extended" onClick={() => sendCommand("IGNITION_ON")}>
            <PowerSettingsNew sx={{ mr: 1 }} />
            Ignition On
          </Fab>
          <Fab variant="extended" onClick={() => sendCommand("IGNITION_OFF")}>
            <HighlightOff sx={{ mr: 1 }} />
            Ignition Off
          </Fab>
        </Box>
      )}
      <Fab
        variant="extended"
        onClick={() =>
          sendCommand(deviceType === "TBIT" ? "LOCK" : "IGNITION_OFF")
        }
        sx={{
          backgroundColor: theme.customColors.redSecondary,
          color: "#fff",
          borderColor: theme.customColors.redSecondary,
          "&:hover": {
            backgroundColor: "#fff",
            color: theme.customColors.redSecondary,
            borderColor: theme.customColors.redSecondary,
          },
        }}
      >
        <Lock sx={{ mr: 1 }} />
        Lock
      </Fab>
      <Fab
        variant="extended"
        onClick={() =>
          sendCommand(deviceType === "TBIT" ? "UNLOCK" : "IGNITION_ON")
        }
        sx={{
          backgroundColor: theme.customColors.green,
          color: "#fff",
          "&:hover": {
            backgroundColor: "#fff",
            color: theme.customColors.green,
          },
        }}
      >
        <LockOpen sx={{ mr: 1 }} />
        Unlock
      </Fab>
      {["ICONCOX", "V5"].includes(deviceType) && (
        <>
          <Fab variant="extended" onClick={() => sendCommand("WHEEL_LOCK")}>
            <Lock sx={{ mr: 1 }} />
            Wheel Lock
          </Fab>
          <Fab variant="extended" onClick={() => sendCommand("WHEEL_UNLOCK")}>
            <LockOpen sx={{ mr: 1 }} />
            Wheel Unlock
          </Fab>
        </>
      )}
      {rentalStatus === "AVAILABLE" && isRetail && (
        <Alert sx={{ gridColumn: "span 2" }} severity="info">
          No active booking
        </Alert>
      )}
      {/* {!rentalStatus || rentalStatus === 'INSTOCK' &&
        <Box sx={{ gridColumn: "span 2", display: "flex", justifyContent: "center" }}>
          <Fab variant="extended">
            <DoubleArrow sx={{ mr: 1 }} />
            Deploy Vehicle
          </Fab>
        </Box>
      } */}
    </Box>
  );
};

export default Commands;
