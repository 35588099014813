import { Edit } from "@mui/icons-material";
import { Box, IconButton, Paper, Tab, Tabs, Tooltip } from "@mui/material";
import Search from "components/Search";
import Table from "components/Table";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { appBar, authorizedFetch, drawer, titleCase } from "utils";
import { AUTH_URL } from "utils/constants";
import AppBar from "./AppBar";
import EditPermsDialog from "./components/EditPermsDialog";

const Admin = () => {
  const [tab, setTab] = useState(0);
  const [editDialog, setEditDialog] = useState({ open: false, user: {} });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");

  const employeesUrl = `${AUTH_URL}/company/users?first=${pageSize}&skip=${
    pageSize * (page - 1)
  }&search=${search}`;

  const {
    isLoading: employeesLoading,
    data: employeesData,
    remove,
    refetch,
  } = useQuery(["getEmployees", page, pageSize, search], () =>
    authorizedFetch(employeesUrl)
  );

  useEffect(() => {
    appBar.open(<AppBar />);
    return () => {
      appBar.close();
      drawer.close();
    };
  }, []);

  return (
    <Box
      sx={{
        px: { xs: 2, md: 7 },
        pt: { xs: 2, md: 4.75 },
        pb: { xs: 3, md: 7 },
      }}
    >
      <EditPermsDialog
        open={editDialog.open}
        handleClose={() => setEditDialog((prev) => ({ ...prev, open: false }))}
        user={editDialog.user}
        refresh={() => {
          remove();
          refetch();
        }}
      />
      <Paper
        sx={{
          width: 1,
          boxShadow: (theme) => theme.customShadows.card,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            width: 1,
            p: 3,
            pb: 2.75,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box width="fit-content">
            <Tabs value={tab} onChange={(e, tab) => setTab(tab)}>
              <Tab
                label="Members"
                className="hasCount"
                sx={{
                  "&:after": {
                    content: `"${employeesData?.data?.count || "-"}"`,
                  },
                }}
              />
            </Tabs>
          </Box>
          <Search
            handleSearch={(value) => {
              setPage(1);
              setSearch(value);
            }}
            persist
            enableClear
          />
        </Box>
        <Table
          idKey="_id"
          serverSidePagination
          rowCount={employeesData?.data?.count || 0}
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          loading={employeesLoading}
          rows={employeesData?.data?.users || []}
          columns={[
            {
              key: "name",
              label: "Name",
              Render: (row) => {
                let { firstName, lastName } = row;
                return firstName || lastName
                  ? `${firstName ? titleCase(firstName) : ""} ${
                      lastName ? titleCase(lastName) : ""
                    }`
                  : "-";
              },
            },
            {
              key: "email",
              label: "Email",
            },
            {
              key: "phone",
              label: "Phone",
              Render: (row) => {
                return (
                  <>
                    {row.phone ? (
                      <Box>
                        {row.phone.charAt(0) === `+`
                          ? row.phone.slice(0, 3) + " - " + row.phone.slice(3)
                          : "+91 - " + row.phone}
                      </Box>
                    ) : (
                      <Box>Not Found</Box>
                    )}
                  </>
                );
              },
            },
            {
              key: "permissions",
              label: "Permissions",
              Render: (row) => {
                let { permissions } = row;

                let permission = permissions?.includes("dashboard:*")
                  ? "Admin"
                  : permissions?.includes("dashboard:*:*:READ")
                  ? "Read"
                  : permissions?.includes("dashboard:*:*:WRITE")
                  ? "Write"
                  : "Scoped";
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width={90}
                  >
                    {permission}
                    <Tooltip title="Edit">
                      <IconButton
                        sx={{ ml: 1 }}
                        children={<Edit fontSize="small" />}
                        size="small"
                        onClick={() => setEditDialog({ open: true, user: row })}
                      />
                    </Tooltip>
                  </Box>
                );
              },
            },
          ]}
        />
      </Paper>
    </Box>
  );
};

export default Admin;
