// import { gql, useQuery as useQueryGQL } from "@apollo/client";
import {
  AddCircleOutline,
  ArrowBack,
  BarChart,
  BuildCircleOutlined,
  // CheckBox,
  // CheckBoxOutlineBlank,
  Clear,
  ElectricMopedOutlined,
  InfoOutlined,
  MonetizationOnOutlined,
  MonitorHeartOutlined,
  MonitorOutlined,
  RemoveCircleOutline,
  SaveAlt,
  // QrCodeScannerOutlined,
  Sort,
  StraightenOutlined,
  Troubleshoot,
} from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  // Checkbox,
  // Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Hidden,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  // Tab,
  // Tabs,
  TextField,
  Tooltip,
  Typography,
  // Typography,
  useMediaQuery,
  useTheme,
  alpha,
} from "@mui/material";
import Search from "components/Search";
import Table from "components/Table";
import { saveAs } from "file-saver";
import { queryClient } from "index";
import moment from "moment";
// import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import {
  GlobalState,
  appBar,
  authorizedFetch,
  drawer,
  getDuration,
  getPermissions,
  getUrlParams,
  setLoader,
  snackbar,
  titleCase,
} from "utils";
import { LEASE_URL, RETAIL_URL } from "utils/constants";
import VehicleDrawer from "views/Common/VehicleView/VehicleDrawer";
import AddModelDialog from "../../Common/AddModelDialog";
import AddPricingDialog from "./AddPricingDialog";
import AddVehicleDialog from "./AddVehicleDialog";
import AppBar from "./AppBar";
import AssignPricingDialog from "./AssignPricing";
import { useSelector } from "react-redux";

const idKey = "_id";

const List = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const { canWrite } = getPermissions("rental:vehicles");
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const history = useHistory();

  const [activeView, setActiveView] = useState<"vehicles" | "upload history">(
    "vehicles",
  );
  const [tab, setTab] = useState(0);
  const [healthTab, setHealthTab] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [addModelDialog, setAddModelDialog] = useState(false);
  const [addPricingDialog, setAddPricingDialog] = useState(false);
  const [addVehicleDialog, setAddVehicleDialog] = useState(false);

  const [sortBy, setSortBy] = useState("1");

  const [changeStatusDialog, setChangeStatusDialog] = useState<{
    open: boolean;
    data: any;
    type: "maintenance" | "instock" | "deploy" | "undeploy";
  }>({
    open: false,
    data: [],
    type: "maintenance",
  });

  const [assignPricingDialog, setAssignPricingDialog] = useState({
    open: false,
    data: "",
  });
  const [search, setSearch] = useState("");

  const [value, setValue] = useState<any>("All Models");

  const [inputValue, setInputValue] = useState("");

  const first = pageSize;
  const skip = pageSize * (page - 1);
  const sort = ["1", "4", "6", "7"].includes(sortBy) ? -1 : 1;
  const sortByParam = ["1", "2"].includes(sortBy)
    ? "createdAt"
    : ["3", "4"].includes(sortBy)
    ? "vin"
    : ["5", "6"].includes(sortBy)
    ? "odo"
    : "lastPinged";
  let status =
    tab === 0
      ? ""
      : tab === 1
      ? "AVAILABLE"
      : tab === 2
      ? "BOOKED"
      : tab === 3
      ? "INSTOCK"
      : "UNDER_MAINTENANCE";
  const health =
    healthTab === 0
      ? ""
      : healthTab === 1
      ? "active"
      : healthTab === 2
      ? "moderate"
      : healthTab === 3
      ? "critical"
      : "inactive";
  const model = value === "All Models" ? "" : value;

  const vehiclesURL = `${LEASE_URL}/company/vehicles?first=${first}&skip=${skip}&search=${search}&sort=${sort}&sortBy=${sortByParam}&status=${status}&model=${model}&health=${health}`;

  const {
    isLoading: vehiclesLoading,
    data: vehiclesData,
    refetch: refetchVehicles,
  } = useQuery(
    [
      "getVehicles",
      first,
      skip,
      search,
      sort,
      sortByParam,
      status,
      model,
      health,
    ],
    () => authorizedFetch(vehiclesURL),
  );

  const [modelList, setModelList] = useState([]);

  const modelsURL = `${RETAIL_URL}/assembly/model?ascending=true`;
  const {
    isLoading: modelsLoading,
    data: modelsData,
    refetch: refetchModels,
  } = useQuery("getModels", () => authorizedFetch(modelsURL));

  const statsUrl = `${LEASE_URL}/company/vehicles/stats`;

  const { data: statsData, refetch: refetchStats } = useQuery(
    "getVehicleStats",
    () => authorizedFetch(statsUrl),
  );

  const pricingUrl = `${LEASE_URL}/company/pricing/all`;
  const {
    isLoading: pricingLoading,
    data: pricingData,
    refetch: refetchPricing,
  } = useQuery(["getPricings"], () => authorizedFetch(pricingUrl));

  const uploadHistoryUrl = `${RETAIL_URL}/assembly/vehicle/bulkUpload/getUploadHistory?first=${first}&skip=${skip}`;
  const { isLoading: uploadHistoryLoading, data: uploadHistoryData } = useQuery(
    ["getUploadHistory", first, skip],
    () => authorizedFetch(uploadHistoryUrl),
  );

  useEffect(() => {
    setPage(1);
    setPageSize(10);
  }, [activeView]);

  useEffect(() => {
    if (modelsData?.data?.constructor === Array) {
      const list = modelsData.data.map((el: any) => el.name);
      list.sort((a: any, b: any) => a.localeCompare(b));
      list.unshift("All Models");
      setModelList(list);
    }
  }, [modelsData]);

  const healthUrl = `${LEASE_URL}/company/vehicles/health`;

  const { data: healthData } = useQuery("getHealthStats", () =>
    authorizedFetch(healthUrl),
  );

  useEffect(() => {
    appBar.open(
      <AppBar
        {...{
          setAddModelDialog,
          setAddVehicleDialog,
          setAddPricingDialog,
          setActiveView,
        }}
      />,
    );
    return () => {
      appBar.close();
      drawer.close();
    };
  }, []);

  useEffect(() => {
    if (window.location.search) {
      console.log(window.location);
      let params = getUrlParams();
      console.log(params);
      if (params[0]["vin"]) {
        let vin = params.find((el: any) => Boolean(el.vin))?.vin;
        if (vin) {
          setSearch(vin);
        }
      }
      if (params[0]["status"]) {
        let status = params.find((el: any) => Boolean(el.status))?.status;

        switch (status) {
          case "Available":
            setTab(1);
            break;
          case "Booked":
            setTab(2);
            break;
          case "InStock":
            setTab(3);
            break;
          case "UnderMaintenance":
            setTab(4);
            break;
        }
      }
      if (params[0]["health"]) {
        let healthParam = params.find((el: any) => Boolean(el.health))?.health;

        switch (healthParam) {
          case "Active":
            setHealthTab(1);
            break;
          case "Moderate":
            setHealthTab(2);
            break;
          case "Critical":
            setHealthTab(3);
            break;
          case "Inactive":
            setHealthTab(4);
            break;
        }
      }
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPage(1);
    // setPageSize(10);
    setSelectedRows([]);
  }, [tab, sortBy, search]);

  useEffect(() => {
    setSelectedRows([]);
  }, [page]);

  const formatLastPing = (value: any) => {
    let lastPing: any;
    value
      ? (moment().valueOf() - moment(value).valueOf()) / 60000 < 360
        ? (lastPing = getDuration(
            Math.abs(moment().valueOf() - moment(value).valueOf()) / 60000,
          ))
        : (lastPing = "Dormant")
      : (lastPing = "Dormant");

    return lastPing;
  };

  let userEmail = useSelector((state: GlobalState) => state.global.user.email);
  const inBounce = userEmail === "srikhantan.s@bounceshare.com";

  const { masterView } = useSelector((state: GlobalState) => state.global);

  const { isLoading: vehicleStatsLoading, data: vehiclesStatsData } = useQuery(
    ["getFMSVehicleStats", masterView],
    () =>
      authorizedFetch(
        `${LEASE_URL}/company/vehicles/stats?master=${masterView}`,
      ),
  );

  const { isLoading: distanceStatsLoading, data: distanceStatsData } = useQuery(
    ["getFMSDistanceStats", masterView],
    () =>
      authorizedFetch(
        `${LEASE_URL}/company/bookings/distance/stats?master=${masterView}`,
      ),
  );

  const totalVehicles =
    vehiclesStatsData?.data?.[0]?.count?.toLocaleString() || 0;
  const totalDistance = `${(
    (distanceStatsData?.data?.total_distance || 0) / 1000
  ).toLocaleString(undefined, { maximumFractionDigits: 2 })} km`;

  return (
    <>
      <AddPricingDialog
        open={addPricingDialog}
        handleClose={() => {
          setAddPricingDialog(false);
        }}
        refetchPricing={refetchPricing}
      />
      <AddModelDialog
        open={addModelDialog}
        handleClose={() => {
          setAddModelDialog(false);
        }}
        isRental={true}
        refetchModels={refetchModels}
      />
      <AddVehicleDialog
        open={addVehicleDialog}
        handleClose={() => {
          setAddVehicleDialog(false);
        }}
        refetchVehicles={refetchVehicles}
        refetchStats={refetchStats}
        modelsData={modelsData}
        isFMS={true}
      />
      <Box
        sx={{
          px: { xs: 2, md: 7 },
          pt: { xs: 2, md: 4.75 },
          pb: { xs: 3, md: 7 },
        }}
      >
        {inBounce && (
          <Box
            sx={{
              mb: { xs: 3, md: 4 },
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr 1fr",
                lg: "repeat(4, 1fr)",
              },
              gap: { xs: 1.75, md: 4 },
              gridColumn: "1 / -1",
              "& .card": {
                height: "auto !important",
                width: 1,
                // borderRadius: 0,
                py: { xs: 1.25, md: 2 },
                px: { xs: 1.25, md: 3 },
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                boxShadow: (theme) => theme.customShadows.card,
                "& .icon": {
                  borderRadius: 50,
                  height: { xs: 32, md: 72, xl: 98 },
                  width: { xs: 32, md: 72, xl: 98 },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  bgcolor: (theme) => alpha(theme.palette.primary.main, 0.2),
                  color: (theme) => theme.palette.primary.main,
                  fontSize: { xs: 18, md: 32, xl: 44 },
                },
                "& .description": {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  "& .label": {
                    mb: { xs: 1, md: 1.5 },
                    textAlign: "end",
                    fontSize: { xs: "12px", md: 16, xl: 18 },
                    fontWeight: 600,
                    lineHeight: { xs: "16px", md: "24.55px" },
                    color: (theme) => theme.customColors.text.grey,
                  },
                  "& .value": {
                    textAlign: "end",
                    fontSize: { xs: "16px", md: "20px", xl: "30px" },
                    fontWeight: 700,
                    lineHeight: { xs: "19.1px", md: "44px" },
                    letterSpacing: "0em",
                  },
                  "& .greyed": {
                    color: (theme) => theme.palette.text.disabled,
                    userSelect: "none",
                  },
                },
              },
            }}
          >
            <Paper className="card">
              <Box className="icon">
                <ElectricMopedOutlined fontSize="inherit" />
              </Box>
              <Box className="description">
                <span className="label">Total Vehicles</span>
                <span className="value">
                  {vehicleStatsLoading ? (
                    <Skeleton width={60} />
                  ) : (
                    totalVehicles
                  )}
                </span>
              </Box>
            </Paper>
            <Paper className="card">
              <Box className="icon">
                <StraightenOutlined fontSize="inherit" />
              </Box>
              <Box className="description">
                <span className="label">Total Distance</span>
                <span className="value">
                  {distanceStatsLoading ? (
                    <Skeleton width={60} />
                  ) : (
                    totalDistance
                  )}
                </span>
              </Box>
            </Paper>
          </Box>
        )}
        <Paper
          sx={{
            width: 1,
            overflow: "hidden",
            borderRadius: "14px",
            boxShadow: (theme) => theme.customShadows.card,
          }}
        >
          {activeView === "vehicles" && (
            <Box
              sx={{
                width: 1,
                p: 3,
                pb: 2.75,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                overflowX: "auto",
              }}
            >
              <Box display="flex" width={1}>
                <Tooltip
                  title="Sort By"
                  placement="top-start"
                  disableInteractive
                >
                  <FormControl sx={{ mr: 2 }}>
                    <Select
                      className="rounded"
                      size="small"
                      sx={{ width: "auto" }}
                      value={sortBy}
                      onChange={(e) => setSortBy(e.target.value)}
                      startAdornment={
                        <Sort
                          color="inherit"
                          sx={{
                            ml: -0.5,
                            mr: 1,
                            color: (theme) => theme.customColors.action,
                          }}
                        />
                      }
                    >
                      <MenuItem value="1">Date Created (Newest First)</MenuItem>
                      <MenuItem value="2">Date Created (Oldest First)</MenuItem>
                      <MenuItem value="3">VIN (A to Z)</MenuItem>
                      <MenuItem value="4">VIN (Z to A)</MenuItem>
                      <MenuItem value="5">Odo (Min to Max)</MenuItem>
                      <MenuItem value="6">Odo (Max to Min)</MenuItem>
                      <MenuItem value="7">Last Pinged (Newest First)</MenuItem>
                      <MenuItem value="8">Last Pinged (Oldest First)</MenuItem>
                    </Select>
                  </FormControl>
                </Tooltip>
                <Tooltip
                  title="Vehicle Status"
                  placement="top-start"
                  disableInteractive
                >
                  <Select
                    className="rounded"
                    size="small"
                    sx={{
                      width: "auto",
                      mr: 2,
                    }}
                    value={tab}
                    onChange={(e: any) => setTab(e.target.value)}
                    startAdornment={
                      <Troubleshoot
                        color="inherit"
                        sx={{
                          ml: -0.5,
                          mr: 1,
                          color: (theme) => theme.customColors.action,
                        }}
                      />
                    }
                    renderValue={(selected) =>
                      selected === 0
                        ? "Status: All"
                        : selected === 1
                        ? "Status: Available"
                        : selected === 2
                        ? "Status: Booked"
                        : selected === 3
                        ? "Status: In Stock"
                        : "Status: Under Maintenance"
                    }
                  >
                    <MenuItem value={0}>
                      All
                      <span style={{ fontSize: 10, marginLeft: 5 }}>
                        ({statsData?.data?.[0]?.count || 0})
                      </span>
                    </MenuItem>
                    <MenuItem value={1}>
                      Available
                      <span style={{ fontSize: 10, marginLeft: 5 }}>
                        ({statsData?.data?.[2]?.count || 0})
                      </span>
                    </MenuItem>
                    <MenuItem value={2}>
                      Booked
                      <span style={{ fontSize: 10, marginLeft: 5 }}>
                        ({statsData?.data?.[1]?.count || 0})
                      </span>
                    </MenuItem>
                    <MenuItem value={3}>
                      In Stock
                      <span style={{ fontSize: 10, marginLeft: 5 }}>
                        ({statsData?.data?.[3]?.count || 0})
                      </span>
                    </MenuItem>
                    <MenuItem value={4}>
                      Under Maintenance
                      <span style={{ fontSize: 10, marginLeft: 5 }}>
                        ({statsData?.data?.[4]?.count || 0})
                      </span>
                    </MenuItem>
                  </Select>
                </Tooltip>
                <Tooltip
                  title="Vehicle Health"
                  placement="top-start"
                  disableInteractive
                >
                  <Select
                    className="rounded"
                    size="small"
                    sx={{
                      width: "auto",
                      mr: 2,
                    }}
                    value={healthTab}
                    onChange={(e: any) => setHealthTab(e.target.value)}
                    startAdornment={
                      <MonitorHeartOutlined
                        color="inherit"
                        sx={{
                          ml: -0.5,
                          mr: 1,
                          color: (theme) => theme.customColors.action,
                        }}
                      />
                    }
                    renderValue={(selected) =>
                      selected === 0
                        ? "Health: All"
                        : selected === 1
                        ? "Health: Active"
                        : selected === 2
                        ? "Health: Moderate"
                        : selected === 3
                        ? "Health: Critical"
                        : "Health: Inactive"
                    }
                  >
                    <MenuItem value={0}>
                      All
                      <span style={{ fontSize: 10, marginLeft: 5 }}>
                        ({healthData?.data?.total || 0})
                      </span>
                    </MenuItem>
                    <MenuItem value={1}>
                      Active
                      <span style={{ fontSize: 10, marginLeft: 5 }}>
                        ({healthData?.data?.active || 0})
                      </span>
                    </MenuItem>
                    <MenuItem value={2}>
                      Moderate
                      <span style={{ fontSize: 10, marginLeft: 5 }}>
                        ({healthData?.data?.moderate || 0})
                      </span>
                    </MenuItem>
                    <MenuItem value={3}>
                      Critical
                      <span style={{ fontSize: 10, marginLeft: 5 }}>
                        ({healthData?.data?.critical || 0})
                      </span>
                    </MenuItem>
                    <MenuItem value={4}>
                      Inactive
                      <span style={{ fontSize: 10, marginLeft: 5 }}>
                        ({healthData?.data?.inactive || 0})
                      </span>
                    </MenuItem>
                  </Select>
                </Tooltip>
                <Autocomplete
                  className="rounded"
                  value={value}
                  loading={modelsLoading}
                  onChange={(event: any, newValue: string | null) => {
                    newValue === null
                      ? setValue("All Models")
                      : setValue(newValue);
                  }}
                  clearIcon={
                    value === "All Models" ? <></> : <Clear fontSize="small" />
                  }
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  size="small"
                  id="controllable-states-demo"
                  options={modelList}
                  sx={{ width: 275 }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>
              <Box display="flex" ml={2}>
                <Hidden implementation="css" mdDown>
                  <Box width="auto">
                    <Search
                      value={search}
                      handleSearch={(value) => {
                        setSearch(value);
                      }}
                      persist
                      enableClear
                    />
                  </Box>
                </Hidden>
              </Box>
            </Box>
          )}
          {activeView === "upload history" && (
            <Box
              sx={{
                px: 2,
                py: 2.75,
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                sx={{ mr: 1 }}
                onClick={() => setActiveView("vehicles")}
              >
                <ArrowBack />
              </IconButton>
              <Typography variant="h6">Upload History</Typography>
            </Box>
          )}
          {activeView === "vehicles" ? (
            <Table
              idKey={idKey}
              serverSidePagination
              activePage={page}
              activePageSize={pageSize}
              onPageChange={(value) => setPage(value)}
              onPageSizeChange={(value) => setPageSize(value)}
              rowCount={vehiclesData?.data?.totalCount}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              selectable={
                tab === 1 || tab === 3 || tab === 4 ? canWrite : false
              }
              px={isMdUp ? 3 : 2}
              loading={vehiclesLoading}
              rows={vehiclesData?.data?.vehicles || []}
              columns={[
                {
                  key: "vin",
                  label: "vin",
                },
                {
                  key: "model.name",
                  label: "Model",
                },
                // {
                //   key: "model.protocol",
                //   label: "Protocol",
                // },
                ...(inBounce
                  ? []
                  : [
                      {
                        key: "lastPinged",
                        label: "Last Ping",
                        format: (value: any) => formatLastPing(value),
                      },
                    ]),
                {
                  key: "odo",
                  label: "Odometer",
                  valueGetter: (row) => row.odo?.odometer,
                  format: (value) =>
                    value ? `${(value / 1000).toFixed(2)} km` : "0 km",
                },
                ...(inBounce
                  ? []
                  : [
                      {
                        key: "rentalStatus",
                        label: "Rental Status",
                        Render: ({ rentalStatus }: any) => {
                          return (
                            <Avatar
                              variant="status"
                              className={
                                rentalStatus === "INSTOCK"
                                  ? "blue"
                                  : rentalStatus === "BOOKED"
                                  ? "yellow"
                                  : rentalStatus === "AVAILABLE"
                                  ? "green"
                                  : "grey"
                              }
                            >
                              {rentalStatus
                                ? rentalStatus === "INSTOCK"
                                  ? "In Stock"
                                  : titleCase(rentalStatus.split("_").join(" "))
                                : "N/A"}
                            </Avatar>
                          );
                        },
                      },
                    ]),

                {
                  key: "health",
                  label: "Health",
                  Render: ({ health }: any) => {
                    return (
                      <Avatar
                        variant="status"
                        className={
                          health === "active"
                            ? ""
                            : health === "moderate"
                            ? "yellow"
                            : health === "critical"
                            ? "red"
                            : "grey"
                        }
                      >
                        {health ? titleCase(health) : "Inactive"}
                      </Avatar>
                    );
                  },
                },

                {
                  key: "actions",
                  label: "Actions",
                  Render: (row) => (
                    <Box display="flex" alignItems="center">
                      <Tooltip title="Info" followCursor>
                        <IconButton
                          size="small"
                          sx={{ color: (theme) => theme.customColors.action }}
                          children={<InfoOutlined fontSize="small" />}
                          onClick={() =>
                            drawer.open(
                              <VehicleDrawer
                                key={row[idKey]}
                                id={row[idKey]}
                                row={row}
                              />,
                            )
                          }
                        />
                      </Tooltip>
                      <Tooltip title="Stats" followCursor>
                        <IconButton
                          size="small"
                          sx={{
                            ml: 0.5,
                            color: (theme) => theme.customColors.action,
                          }}
                          children={<BarChart fontSize="small" />}
                          onClick={() =>
                            drawer.open(
                              <VehicleDrawer
                                key={row[idKey]}
                                id={row[idKey]}
                                openToTab={1}
                                row={row}
                              />,
                            )
                          }
                        />
                      </Tooltip>
                      <Tooltip title="Monitor" followCursor>
                        <IconButton
                          size="small"
                          sx={{
                            ml: 0.5,
                            color: (theme) => theme.customColors.action,
                          }}
                          children={<MonitorOutlined fontSize="small" />}
                          onClick={() => {
                            history.push("/vehicles/" + row[idKey]);
                          }}
                        />
                      </Tooltip>
                    </Box>
                  ),
                },
              ]}
              toolbar={() => (
                <>
                  {tab === 3 && (
                    <Button
                      startIcon={<BuildCircleOutlined />}
                      onClick={() => {
                        setChangeStatusDialog({
                          open: true,
                          data: selectedRows,
                          type: "maintenance",
                        });
                      }}
                    >
                      Send to Maintenance
                    </Button>
                  )}
                  {tab === 4 && (
                    <Button
                      startIcon={<AddCircleOutline />}
                      onClick={() => {
                        setChangeStatusDialog({
                          open: true,
                          data: selectedRows,
                          type: "instock",
                        });
                      }}
                    >
                      Move to In Stock
                    </Button>
                  )}
                  {(tab === 1 || tab === 3) && (
                    <Button
                      sx={{ ml: 1.5 }}
                      startIcon={
                        tab === 1 ? (
                          <RemoveCircleOutline />
                        ) : (
                          <AddCircleOutline />
                        )
                      }
                      onClick={() => {
                        setChangeStatusDialog({
                          open: true,
                          data: selectedRows,
                          type: tab === 1 ? "undeploy" : "deploy",
                        });
                      }}
                    >
                      {tab === 1 ? "Undeploy" : "Deploy"}
                    </Button>
                  )}
                  <Button
                    sx={{ ml: 1.5 }}
                    startIcon={<MonetizationOnOutlined />}
                    onClick={() => {
                      setAssignPricingDialog({
                        open: true,
                        data: selectedRows,
                      });
                    }}
                  >
                    Assign Pricing
                  </Button>
                </>
              )}
            />
          ) : (
            <Table
              serverSidePagination
              activePage={page}
              activePageSize={pageSize}
              onPageChange={(value) => setPage(value)}
              onPageSizeChange={(value) => setPageSize(value)}
              rowCount={uploadHistoryData?.meta?.totalCount}
              loading={uploadHistoryLoading}
              rows={uploadHistoryData?.data || []}
              columns={[
                {
                  key: "actions",
                  label: "",
                  Render: (row) => (
                    <Box
                      sx={{
                        width: 1,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip title="Download File" followCursor>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => {
                            saveAs(row.fileURL, row.fileKey);
                            snackbar.success(
                              "Your file has been successfully downloaded",
                            );
                          }}
                        >
                          <SaveAlt />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ),
                },
                {
                  key: "fileKey",
                  label: "Uploaded File",
                  Render: (row) => (
                    <Box sx={{ py: 1 }}>
                      <Typography>{row.fileKey}</Typography>
                      <Typography fontSize={12} color="text.secondary">
                        {moment(row.createdAt).format("DD-MMM-YYYY, hh:mm A")}
                      </Typography>
                    </Box>
                  ),
                },
                {
                  key: "status",
                  label: "Status",
                  Render: (row) => (
                    <Avatar
                      variant="status"
                      className={`outlined ${
                        row.passedEntries === 0 ? "red" : "green"
                      }`}
                    >
                      {row.passedEntries === 0 ? "Failed" : "Success"}
                    </Avatar>
                  ),
                },
                {
                  key: "total",
                  label: "Total Vehicles",
                  valueGetter: (row) => row.passedEntries + row.failedEntries,
                  format: (value) => value || 0,
                },
                {
                  key: "passedEntries",
                  label: "Success",
                  format: (value) => value || 0,
                },
                {
                  key: "failedEntries",
                  label: "Failed",
                  format: (value) => value || 0,
                  Render: (row) => (
                    <Typography color="error.light">
                      {row.failedEntries}
                    </Typography>
                  ),
                },
              ]}
            />
          )}
          <ChangeStatusDialog
            open={changeStatusDialog.open}
            selected={changeStatusDialog.data}
            type={changeStatusDialog.type}
            handleClose={() => {
              setChangeStatusDialog({ ...changeStatusDialog, open: false });
            }}
            setSelected={setSelectedRows}
          />
          <AssignPricingDialog
            open={assignPricingDialog.open}
            selected={assignPricingDialog.data}
            handleClose={() => {
              setAssignPricingDialog({ ...assignPricingDialog, open: false });
            }}
            refetchVehicles={refetchVehicles}
            setSelected={setSelectedRows}
            pricingLoading={pricingLoading}
            pricingData={pricingData}
          />
        </Paper>
      </Box>
    </>
  );
};

interface ChangeStatusDialogProps {
  open: boolean;
  handleClose: () => void;
  type: "maintenance" | "instock" | "deploy" | "undeploy";
  selected: any;
  setSelected: React.Dispatch<any>;
}

const ChangeStatusDialog: React.FC<ChangeStatusDialogProps> = ({
  open,
  handleClose,
  selected,
  type,
  setSelected,
}) => {
  const s = selected?.length > 1 ? "s" : "";

  const handleAction = () => {
    const urls = {
      deploy: `${LEASE_URL}/company/vehicles/deploy`,
      instock: `${LEASE_URL}/company/vehicles/undeploy`,
      undeploy: `${LEASE_URL}/company/vehicles/undeploy`,
      maintenance: `${LEASE_URL}/company/vehicles/maintainence`,
    };

    const successMessage = {
      deploy: `Vehicle${s} deployed`,
      instock: `Vehicle${s} moved to In Stock`,
      undeploy: `Vehicle${s} undeployed`,
      maintenance: `Vehicle${s} sent to maintenance`,
    };

    const errorMessage = {
      deploy: `Error deploying vehicle${s}`,
      instock: `Error moving vehicle${s} to In Stock`,
      undeploy: `Error undeploying vehicle${s}`,
      maintenance: `Error sending vehicle${s} to maintenance`,
    };

    setLoader(true);
    authorizedFetch(urls[type], {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        vehicles: selected,
      },
    })
      .then((res) => {
        setLoader(false);
        setSelected([]);

        snackbar.success(successMessage[type]);
        queryClient.resetQueries("getVehicles");
        queryClient.resetQueries("getVehicleStats");

        handleClose();
      })
      .catch((err) => {
        setLoader(false);
        snackbar.error(errorMessage[type]);
      });
  };

  const title = {
    deploy: `Deploy vehicle${s}?`,
    instock: `Move vehicle${s} to In Stock?`,
    undeploy: `Undeploy vehicle${s}?`,
    maintenance: `Send vehicle${s} to maintenance?`,
  };

  const msg = {
    deploy: [`deploy`, `vehicle${s}?`],
    instock: [`move`, `vehicle${s} to In Stock?`],
    undeploy: [`undeploy`, `vehicle${s}?`],
    maintenance: [`send`, `vehicle${s} to maintenance?`],
  };

  const btn = {
    deploy: "Deploy",
    instock: "Move to In Stock",
    undeploy: "Undeploy",
    maintenance: "Send to maintenance",
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>{title[type]}</DialogTitle>
      <DialogContent className="py-1">
        Are you sure you want to {msg[type][0]}{" "}
        <strong>{selected.length}</strong> {msg[type][1]}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleAction} variant="contained">
          {btn[type]}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default List;
