// import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Paper,
  // Tab,
  // Tabs,
  // IconButton,
  // Hidden,
  Avatar,
  Button,
  // Tab,
  // Tabs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  Hidden,
} from "@mui/material";
import Table from "components/Table";
// import AddImageDialog from "./AddImageDialog";
import Search from "components/Search";

import { useEffect, useState } from "react";
import {
  authorizedFetch,
  drawer,
  GlobalState,
  // getPermissions,
  setLoader,
  snackbar,
  titleCase,
} from "utils";
// import DrawerContent from "./DrawerContent";

// import Filter from "../../../components/Filter";
import { LEASE_URL, REPORTS_URL } from "utils/constants";
import { useMutation } from "react-query";
// import Search from "../../../components/Search";
import moment from "moment";
import { FileDownload, InfoOutlined, Sort } from "@mui/icons-material";
import DrawerContent from "./DrawerContent";
import saveAs from "file-saver";
import { useSelector } from "react-redux";

const List = ({
  page,
  setPage,
  tab,
  setTab,
  pageSize,
  setPageSize,
  leaseLoading,
  leasesData,
  refetchBookings,
  sortByAscending,
  setSortByAscending,
  setSearch,
  type,
  setType,
  statsData,
  refetchStats,
}: any) => {
  const companyId = useSelector(
    (state: GlobalState) => state.global.company.id
  );

  function downloadLeaseReport(individualLease: any) {
    setLoader(true);
    let request: any = authorizedFetch(
      `${REPORTS_URL}/v2/lease/report?format=pdf&id=${individualLease.id}&vin=${individualLease.vin}`
    );
    request
      .then((result: any) => {
        setLoader(false);
        if (result.file) {
          const fileURL: any = result.file;
          saveAs(
            fileURL,
            `${individualLease.id}_${moment(individualLease.bookingTime).format(
              "DD-MM-YYYY"
            )}.pdf`
          );
          snackbar.success("Downloaded lease report");
        } else if (result.message) {
          snackbar.error("Error downloading lease report");
          console.error(result);
        }
      })
      .catch((err: Error) => {
        console.error(err);
        setLoader(false);
        snackbar.error("Error downloading lease report");
      });
  }

  const [payDialog, setPayDialog] = useState({
    open: false,
    data: {},
  });

  const [startDialog, setStartDialog] = useState({
    open: false,
    data: {},
  });

  const [endDialog, setEndDialog] = useState({
    open: false,
    data: {},
  });

  const [cancelDialog, setCancelDialog] = useState({
    open: false,
    data: {},
  });

  const [terminateDialog, setTerminateDialog] = useState({
    open: false,
    data: {},
  });

  useEffect(() => {
    return () => {
      drawer.close();
    };
  }, []);

  useEffect(() => {
    setPage(1);
    setPageSize(10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, type]);

  return (
    <>
      <Paper
        sx={{
          width: 1,
          boxShadow: (theme) => theme.customShadows.small,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            width: "100%",
            p: 3,
            pb: 2.75,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Tooltip title="Sort By" placement="top">
              <Select
                size="small"
                sx={{ mr: 2, width: "100%" }}
                value={sortByAscending}
                onChange={(e) => setSortByAscending(e.target.value)}
                startAdornment={<Sort sx={{ mr: 1 }} />}
                renderValue={(selected) =>
                  selected === "1"
                    ? "Created On: New to Old"
                    : selected === "2"
                    ? "Created On: Old to New"
                    : selected === "3"
                    ? "Vin: A to Z"
                    : selected === "4"
                    ? "Vin: Z to A"
                    : selected === "5"
                    ? "Amount: Low to High"
                    : "Amount: High to Low"
                }
              >
                <MenuItem value="1">Created On: New to Old</MenuItem>
                <MenuItem value="2">Created On: Old to New</MenuItem>
                <MenuItem value="3">Vin: A to Z</MenuItem>
                <MenuItem value="4">Vin: Z to A</MenuItem>
                <MenuItem value="5">Amount: Low to High</MenuItem>
                <MenuItem value="6">Amount: High to Low</MenuItem>
              </Select>
            </Tooltip>
            <Tooltip title="Lease Status" placement="top">
              <Select
                size="small"
                sx={{ mr: 2, width: "100%" }}
                value={tab}
                onChange={(e) => setTab(e.target.value)}
                startAdornment={<Sort sx={{ mr: 1 }} />}
                renderValue={(selected) =>
                  selected === 0
                    ? "Status: All"
                    : selected === 1
                    ? "Status: Active"
                    : selected === 2
                    ? "Status: Booked"
                    : selected === 3
                    ? "Status: Payment Initialised"
                    : selected === 4
                    ? "Status: Terminated"
                    : selected === 5
                    ? "Status: Terminated Failed Payment"
                    : "Status: Ended"
                }
              >
                <MenuItem value={0}>
                  All{" "}
                  <span style={{ fontSize: 10, marginLeft: 5 }}>
                    ({statsData?.data?.total_leases || "0"})
                  </span>
                </MenuItem>
                <MenuItem value={1}>
                  Active{" "}
                  <span style={{ fontSize: 10, marginLeft: 5 }}>
                    ({statsData?.data?.active || "0"})
                  </span>
                </MenuItem>
                <MenuItem value={2}>
                  Booked{" "}
                  <span style={{ fontSize: 10, marginLeft: 5 }}>
                    ({statsData?.data?.booked || "0"})
                  </span>
                </MenuItem>
                <MenuItem value={3}>
                  Payment Initialised{" "}
                  <span style={{ fontSize: 10, marginLeft: 5 }}>
                    ({statsData?.data?.payment_initialised || "0"})
                  </span>
                </MenuItem>
                <MenuItem value={4}>
                  Terminated{" "}
                  <span style={{ fontSize: 10, marginLeft: 5 }}>
                    ({statsData?.data?.terminated || "0"})
                  </span>
                </MenuItem>
                <MenuItem value={5}>
                  Terminated Failed Payment{" "}
                  <span style={{ fontSize: 10, marginLeft: 5 }}>
                    ({statsData?.data?.terminated_failed_payment || "0"})
                  </span>
                </MenuItem>
                <MenuItem value={6}>
                  Ended{" "}
                  <span style={{ fontSize: 10, marginLeft: 5 }}>
                    ({statsData?.data?.ended || "0"})
                  </span>
                </MenuItem>
              </Select>
            </Tooltip>
            <Tooltip title="Type" placement="top">
              <Select
                size="small"
                sx={{ mr: 2, width: "100%" }}
                value={type}
                onChange={(e) => setType(e.target.value)}
                startAdornment={<Sort sx={{ mr: 1 }} />}
                renderValue={(selected) =>
                  selected === 0
                    ? "Type: All"
                    : selected === 1
                    ? "Type: Short Term"
                    : "Type: Long Term"
                }
              >
                <MenuItem value={0}>
                  All{" "}
                  <span style={{ fontSize: 10, marginLeft: 5 }}>
                    ({statsData?.data?.total_leases || "0"})
                  </span>
                </MenuItem>
                <MenuItem value={1}>
                  Short Term{" "}
                  <span style={{ fontSize: 10, marginLeft: 5 }}>
                    ({statsData?.data?.short_term || "0"})
                  </span>
                </MenuItem>
                <MenuItem value={2}>
                  Long Term{" "}
                  <span style={{ fontSize: 10, marginLeft: 5 }}>
                    ({statsData?.data?.long_term || "0"})
                  </span>
                </MenuItem>
              </Select>
            </Tooltip>
          </Box>
          <Box display="flex">
            <Hidden implementation="css" mdDown>
              <Box>
                <Search
                  handleSearch={(value) => {
                    setSearch(value);
                  }}
                  persist
                  enableClear
                />
              </Box>
            </Hidden>
          </Box>
        </Box>

        <Table
          idKey="id"
          rowCount={leasesData?.data?.count}
          serverSidePagination={true}
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          loading={leaseLoading}
          // setSelectedRows={setSelectedRows}
          // selectedRows={selectedRows}
          // selectable={canWrite}
          // selectOnClick
          rows={leasesData?.data?.bookings || []}
          columns={[
            {
              key: "vin",
              label: "vin",
            },
            // {
            //   key: "user",
            //   label: "User",
            //   Render: (row) => {
            //     return <Box>{row?.leasee?.user?.firstName}</Box>;
            //   },
            // },
            {
              key: "startTime",
              label: "Start Time",
              format: (value) => moment(value).format("DD/MM/YY, hh:mm a"),
            },
            {
              key: "endTime",
              label: "End Time",
              format: (value) => moment(value).format("DD/MM/YY, hh:mm a"),
            },
            {
              key: "type",
              label: "Type",
            },

            {
              key: "status",
              label: "Status",
              Render: ({ status }) => {
                return (
                  <Avatar
                    variant="status"
                    className={
                      status === "ACTIVE" || status === "BOOKED"
                        ? "green"
                        : status === "PENDING_PAYMENT"
                        ? "yellow"
                        : status === "PAYMENT_INITIALISED"
                        ? "blue"
                        : status === "TERMINATED" ||
                          status === "TERMINATED_FAILED_PAYMENT"
                        ? "red"
                        : "grey"
                    }
                  >
                    {status
                      ? status.split("_").map(titleCase).join(" ")
                      : "N/A"}
                  </Avatar>
                );
              },
            },

            {
              key: "bookingAmount",
              label: "Amount",
              Render: (row) => {
                return (
                  <Box>
                    {row?.bookingAmount
                      ? "₹ " + row?.bookingAmount?.toFixed()
                      : "₹ 0"}
                  </Box>
                );
              },
            },

            {
              key: "actions",
              label: "Actions",
              Render: (row) => (
                <Box display="flex" alignItems="center" gap={1}>
                  <IconButton
                    size="small"
                    sx={{
                      ml: 0.5,
                      color: (theme) => theme.customColors.action,
                    }}
                    children={<InfoOutlined fontSize="small" />}
                    onClick={() =>
                      drawer.open(
                        <DrawerContent
                          key={row.id}
                          vehicle={row}
                          refetchBookings={refetchBookings}
                          refetchStats={refetchStats}
                        />
                      )
                    }
                  />

                  {companyId === "5f44a1e3e03dd800075dd466" && (
                    <Tooltip title="Download Report">
                      <IconButton
                        size="small"
                        sx={{ color: (theme: any) => theme.customColors.grey }}
                        onClick={() => downloadLeaseReport(row)}
                        children={<FileDownload fontSize="small" />}
                      />
                    </Tooltip>
                  )}

                  {/* {["BOOKED"].includes(row?.status) ? (
                    <Tooltip title="Start Time must be more than current time">
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setImageDialog({
                            open: true,
                            data: row,
                            type: "start",
                          });
                        }}
                        disabled={
                          new Date(row.startTime).getTime() >
                            new Date().getTime() ||
                          new Date(row.endTime).getTime() < new Date().getTime()
                        }
                      >
                        Start
                      </Button>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  {["BOOKED", "ACTIVE"].includes(row?.status) ? (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        setImageDialog({ open: true, data: row, type: "end" });
                      }}
                      disabled={
                        new Date(row.startTime).getTime() >
                          new Date().getTime() ||
                        new Date(row.endTime).getTime() < new Date().getTime()
                      }
                    >
                      End
                    </Button>
                  ) : (
                    ""
                  )}

                  {["PENDING_PAYMENT", "PAYMENT_INITIALISED"].includes(
                    row?.status
                  ) ? (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setPayDialog({ open: true, data: row });
                      }}
                    >
                      Pay
                    </Button>
                  ) : (
                    ""
                  )}
                  {["BOOKED"].includes(row?.status) ? (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        setCancelDialog({ open: true, data: row });
                      }}
                    >
                      Cancel
                    </Button>
                  ) : (
                    ""
                  )}
                  {["PENDING_PAYMENT", "PAYMENT_INITIALISED"].includes(
                    row?.status
                  ) ? (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        setTerminateDialog({ open: true, data: row });
                      }}
                    >
                      TERMINATE
                    </Button>
                  ) : (
                    ""
                  )} */}
                  {/* <IconButton
                    size="small"
                    sx={{
                      ml: 0.5,
                      color: (theme) => theme.customColors.action,
                    }}
                    children={<InfoOutlined fontSize="small" />}
                    onClick={() =>
                      drawer.open(<DrawerContent key={row.id} vehicle={row} />)
                    }
                  /> */}
                </Box>
              ),
            },
          ]}
        />
      </Paper>
      {/* <AddImageDialog
        open={imageDialog.open}
        data={imageDialog.data}
        handleClose={() => {
          setImageDialog({ ...imageDialog, open: false });
        }}
        refetchBookings={refetchBookings}
        type={imageDialog.type}
      /> */}
      <PaymentDialog
        open={payDialog.open}
        handleClose={() => {
          setPayDialog({ ...payDialog, open: false });
        }}
        booking={payDialog.data}
        refetchBookings={refetchBookings}
        refetchStats={refetchStats}
      />
      <StartDialog
        open={startDialog.open}
        handleClose={() => {
          setStartDialog({ ...startDialog, open: false });
        }}
        booking={startDialog.data}
        refetchBookings={refetchBookings}
        refetchStats={refetchStats}
      />
      <EndDialog
        open={endDialog.open}
        handleClose={() => {
          setEndDialog({ ...endDialog, open: false });
        }}
        booking={endDialog.data}
        refetchBookings={refetchBookings}
        refetchStats={refetchStats}
      />
      <CancelDialog
        open={cancelDialog.open}
        handleClose={() => {
          setCancelDialog({ ...cancelDialog, open: false });
        }}
        booking={cancelDialog.data}
        refetchBookings={refetchBookings}
        refetchStats={refetchStats}
      />
      <TerminateDialog
        open={terminateDialog.open}
        handleClose={() => {
          setTerminateDialog({ ...terminateDialog, open: false });
        }}
        booking={terminateDialog.data}
        refetchBookings={refetchBookings}
        refetchStats={refetchStats}
      />
    </>
  );
};

const PaymentDialog = ({
  open,
  handleClose,
  booking,
  refetchBookings,
}: any) => {
  const payInitialiseURL = `${LEASE_URL}/company/booking/${booking?.id}/payment/init`;

  const initMutation = useMutation(
    `paymentInitialisation`,
    () =>
      authorizedFetch(payInitialiseURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          amount: 0,
          amountPaid: 0,
          remark: "",
          stage: "TEST",
        },
      }),
    {
      onSuccess: () => {
        setLoader(false);
      },
    }
  );

  const payConfirmURL = `${LEASE_URL}/company/booking/${booking?.id}/payment/confirm`;

  const confirmMutation = useMutation(
    `paymentInitialisation`,
    () =>
      authorizedFetch(payConfirmURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    {
      onSuccess: () => {
        snackbar.success("Payment Confirmed");
        refetchBookings();
        setLoader(false);
      },
      onError: () => {
        snackbar.success("Error confirming Payment");
      },
    }
  );

  useEffect(() => {
    if (open) {
      initMutation.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onConfirm = () => {
    setLoader(true);
    confirmMutation.mutate();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Comfirm Payment</DialogTitle>
      <DialogContent sx={{ marginTop: "20px" }}>
        Are you sure you want to
        <span style={{ fontWeight: "bold" }}> Comfirm Payment</span>?
      </DialogContent>
      <DialogActions sx={{ margin: "20px 20px 20px 0" }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const StartDialog = ({ open, handleClose, booking, refetchBookings }: any) => {
  const startURL = `${LEASE_URL}/company/booking/${booking?.id}/start`;

  const startMutation = useMutation(
    `startBooking`,
    () =>
      authorizedFetch(startURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    {
      onSuccess: () => {
        snackbar.success("Booking Started");
        refetchBookings();
        setLoader(false);
      },
      onError: () => {
        snackbar.success("Error starting booking");
      },
    }
  );

  const onStart = () => {
    setLoader(true);
    startMutation.mutate();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Start Booking</DialogTitle>
      <DialogContent sx={{ marginTop: "20px" }}>
        Are you sure you want to
        <span style={{ fontWeight: "bold" }}> Start Booking</span>?
      </DialogContent>
      <DialogActions sx={{ margin: "20px 20px 20px 0" }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={onStart}
        >
          Start
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EndDialog = ({ open, handleClose, booking, refetchBookings }: any) => {
  const endURL = `${LEASE_URL}/company/booking/${booking?.id}/end`;

  const endMutation = useMutation(
    `endBooking`,
    () =>
      authorizedFetch(endURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    {
      onSuccess: () => {
        snackbar.success("Booking Ended");
        refetchBookings();
        setLoader(false);
      },
      onError: () => {
        snackbar.success("Error ending booking");
      },
    }
  );

  const onEnd = () => {
    setLoader(true);
    endMutation.mutate();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>End Booking</DialogTitle>
      <DialogContent sx={{ marginTop: "20px" }}>
        Are you sure you want to
        <span style={{ fontWeight: "bold" }}> End Booking</span>?
      </DialogContent>
      <DialogActions sx={{ margin: "20px 20px 20px 0" }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={onEnd}
        >
          End
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CancelDialog = ({
  open,
  handleClose,
  booking,
  refetchBookings,
  refetchStats,
}: any) => {
  const cancelURL = `${LEASE_URL}/company/booking/${booking?.id}/cancel`;

  const cancelMutation = useMutation(
    `cancelBooking`,
    () =>
      authorizedFetch(cancelURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    {
      onSuccess: () => {
        snackbar.success("Booking Cancelled");
        refetchBookings();
        refetchStats();
        setLoader(false);
      },
      onError: () => {
        snackbar.success("Error cancelling booking");
      },
    }
  );

  const onCancel = () => {
    setLoader(true);
    cancelMutation.mutate();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Cancel Booking</DialogTitle>
      <DialogContent sx={{ marginTop: "20px" }}>
        Are you sure you want to
        <span style={{ fontWeight: "bold" }}> Cancel Booking</span>?
      </DialogContent>
      <DialogActions sx={{ margin: "20px 20px 20px 0" }}>
        <Button onClick={handleClose}>Back</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={onCancel}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TerminateDialog = ({
  open,
  handleClose,
  booking,
  refetchBookings,
  refetchStats,
}: any) => {
  const terminateURL = `${LEASE_URL}/company/booking/${booking?.id}/payment/fail`;

  const terminateMutation = useMutation(
    `terminateBooking`,
    () =>
      authorizedFetch(terminateURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    {
      onSuccess: () => {
        snackbar.success("Booking Terminated");
        refetchBookings();
        refetchStats();
        setLoader(false);
      },
      onError: () => {
        snackbar.success("Error terminating booking");
      },
    }
  );

  const onTerminate = () => {
    setLoader(true);
    terminateMutation.mutate();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Terminate Booking</DialogTitle>
      <DialogContent sx={{ marginTop: "20px" }}>
        Are you sure you want to
        <span style={{ fontWeight: "bold" }}> Terminate Booking</span>?
      </DialogContent>
      <DialogActions sx={{ margin: "20px 20px 20px 0" }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={onTerminate}
        >
          Terminate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default List;
