import { useEffect, useRef, useState } from "react";
import { Box, TextField } from "@mui/material";
import { Wrapper } from "@googlemaps/react-wrapper";
import { useSelector } from "react-redux";
import { getDarkModePreference, GlobalState } from "utils";
import HubPinIcon from "assets/images/hub-pin.svg";

const MapComponent: React.FC<any> = ({ setMarkerPosition, markerPosition }) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: 1,
        height: 400,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 1,
        overflow: "hidden",
      }}
    >
      <Wrapper
        libraries={["visualization", "places", "drawing", "geometry"]}
        apiKey={"AIzaSyDqq6Ywsf6L3lrgg_P8BI1Z7hvNbsjMQzY"}
      >
        <GoogleMap
          setMarkerPosition={setMarkerPosition}
          markerPosition={markerPosition}
        />
      </Wrapper>
    </Box>
  );
};

const GoogleMap: React.FC<Partial<any>> = ({
  setMarkerPosition,
  markerPosition,
}) => {
  const isDarkMode = useSelector((state: GlobalState) =>
    getDarkModePreference(state)
  );
  const ref = useRef<HTMLElement | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);

  useEffect(() => {
    if (!ref.current) return;
    setMap(
      new window.google.maps.Map(
        ref.current as HTMLElement,
        {
          mapId: isDarkMode ? "e2d8edaa01f13e66" : "ad2188b39fd828f6",
          center: {
            lat: markerPosition?.lat ? markerPosition?.lat : 12.9716,
            lng: markerPosition?.lng ? markerPosition?.lng : 77.5946,
          },
          zoom: 15,
          streetViewControl: false,
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          fullscreenControl: true,
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
          },
        } as google.maps.MapOptions
      )
    );
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [isDarkMode]);

  const autocompleteRef = useRef(null);
  const markerRef = useRef<google.maps.Marker | null>(null);

  useEffect(() => {
    if (!map) return;

    if (!markerRef.current) {
      const marker = new google.maps.Marker({
        position: markerPosition,
        map: map,
        draggable: true,
        icon: {
          url: HubPinIcon,
          anchor: new google.maps.Point(29, 58),
        },
      });

      markerRef.current = marker;

      // Update state on marker drag
      marker.addListener("dragend", () => {
        const newPos: any = marker.getPosition();
        setMarkerPosition({ lat: newPos.lat(), lng: newPos.lng() });
      });
    } else {
      // Update marker position without re-creating it
      markerRef.current.setPosition(markerPosition);
    }

    if (autocompleteRef.current) {
      const autocomplete = new google.maps.places.Autocomplete(
        autocompleteRef.current,
        { types: ["geocode"] }
      );
      autocomplete.bindTo("bounds", map);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) return;

        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location!);
          map.setZoom(17);
        }

        markerRef?.current?.setPosition(place.geometry.location!);
        setMarkerPosition({
          lat: place.geometry.location!.lat(),
          lng: place.geometry.location!.lng(),
        });
      });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [map, markerPosition]);

  return (
    <>
      <Box ref={ref} sx={{ position: "absolute", width: 1, height: 1 }} />
      <TextField
        inputRef={autocompleteRef}
        sx={{ position: "absolute", top: 10, right: 10 }}
        size="small"
        placeholder="Search..."
      />
    </>
  );
};

export default MapComponent;
