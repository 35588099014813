import { InfoOutlined } from "@mui/icons-material";
import { Box, Paper, Skeleton, Tooltip } from "@mui/material";
import RangeSelector from "components/RangeSelector";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import { authorizedFetch, formatCompactNumber } from "utils";
import { CMS_STATS_URL } from "utils/constants";
import { Trend } from "views/Charger/Overview/Highlights";
// import FiltersDrawer from "./FiltersDrawer";

export const filterOptions = {};

export const filtersObj = {
  time: [moment().subtract({ months: 1 }), moment()],
};

const Highlights = ({ masterView }: any) => {
  const [filters, setFilters] = useState<any>({ ...filtersObj });
  // const [drawerOpen, setDrawerOpen] = useState(false);

  const startTime = filters.time[0].toISOString();
  const endTime = filters.time[1].toISOString();

  const { isLoading: userStatsLoading, data: userStatsData } = useQuery(
    ["GET cms-stats /metrics/users", startTime, endTime, masterView],
    () =>
      authorizedFetch(
        `${CMS_STATS_URL}/metrics/users?start_time=${startTime}&end_time=${endTime}&master=${masterView}`,
      ),
  );

  const { isLoading: bookingsStatsLoading, data: bookingsStatsData } = useQuery(
    ["GET cms-stats /metrics/bookings", startTime, endTime, masterView],
    () =>
      authorizedFetch(
        `${CMS_STATS_URL}/metrics/bookings?start_time=${startTime}&end_time=${endTime}&master=${masterView}`,
      ),
  );

  const userStats = userStatsData?.data || {};
  const bookingsStats = bookingsStatsData?.data || {};

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box
          sx={{
            fontSize: { xs: 18, sm: 20, md: 24 },
            color: (theme) => theme.customColors.text.plain,
            fontWeight: 700,
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Users highlights</span>
          <Tooltip
            title="The highlights shown below are updated every 4 hours"
            placement="top"
            arrow
          >
            <InfoOutlined
              sx={{
                ml: 1,
                fontSize: { xs: 16, lg: 20 },
                color: (theme) => theme.customColors.action,
              }}
            />
          </Tooltip>
        </Box>

        {/* Filters */}
        <Box display="flex" alignItems="center">
          {/* Mobile version */}
          {/* <Box display={{ xs: "flex", lg2: "none" }} justifyContent="flex-end">
            <IconButton
              size="small"
              sx={{
                boxShadow: (theme) => theme.customShadows.card,
                background: (theme) => theme.palette.background.paper,
                border: (theme) => `1px solid ${theme.customColors.border}`,
              }}
              onClick={() => setDrawerOpen(true)}
            >
              <Badge
                color="primary"
                variant="dot"
                invisible={![].some((el) => el.length > 0)}
              >
                <FilterAltOutlined />
              </Badge>
            </IconButton>
            <FiltersDrawer
              {...{
                open: drawerOpen,
                onClose: () => setDrawerOpen(false),
                filters,
                setFilters,
                range: filters.time,
                setLocations: (val: any) =>
                  setFilters((prev: any) => ({
                    ...prev,
                    locations: val,
                  })),
                activeView: tab === 0 ? "chargers" : "bookings",
              }}
            />
          </Box> */}

          {/* Desktop version */}
          <Box
            // display={{ xs: "none", lg2: "flex" }}
            display="flex"
            alignItems="center"
            sx={{
              "& > :not(:first-of-type)": { ml: 2 },
            }}
          >
            <RangeSelector
              initialRange="Last 30 days"
              range={filters.time}
              setRange={(range: any) => {
                setFilters((prev: any) => ({
                  ...prev,
                  time: range,
                }));
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* Cards */}
      <Box mt={3} mb={5}>
        <Box border={0} m={-3} p={3} sx={{ overflowX: "auto" }}>
          <Box
            display="grid"
            gridTemplateColumns="2fr 3fr"
            gap={3}
            minWidth={{ xs: 1250, lg: 1500 }}
          >
            <Paper
              sx={{
                p: 2,
                borderRadius: "14px",
                boxShadow: (theme) => theme.customShadows.card,
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: 2,
              }}
            >
              <Card
                title="Users"
                isLoading={userStatsLoading}
                value={formatCompactNumber(userStats.users?.value || 0, {
                  disableCompact: userStats.users?.value < 10000,
                })}
                growth={userStats.users?.growth}
                title2="Active Users"
                value2={formatCompactNumber(
                  userStats.active_users?.value || 0,
                  {
                    disableCompact: userStats.active_users?.value < 10000,
                  },
                )}
                growth2={userStats.active_users?.growth}
              />
              <Card
                title="New Users"
                isLoading={userStatsLoading}
                value={formatCompactNumber(userStats.new_users?.value || 0, {
                  disableCompact: userStats.new_users?.value < 10000,
                })}
                growth={userStats.new_users?.growth}
                title2="Weekly avg. new users"
                value2={formatCompactNumber(
                  userStats.weekly_average_new_users?.value || 0,
                  {
                    disableCompact:
                      userStats.weekly_average_new_users?.value < 10000,
                  },
                )}
                growth2={userStats.weekly_average_new_users?.growth}
              />
            </Paper>

            <Paper
              sx={{
                p: 2,
                borderRadius: "14px",
                boxShadow: (theme) => theme.customShadows.card,
                minWidth: 420,
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: 2,
              }}
            >
              <Card
                title="Bookings"
                isLoading={bookingsStatsLoading}
                value={formatCompactNumber(
                  bookingsStats.total_bookings?.value || 0,
                  {
                    disableCompact: bookingsStats.total_bookings?.value < 10000,
                  },
                )}
                growth={bookingsStats.total_bookings?.growth}
                title2="Avg. bookings per user"
                value2={formatCompactNumber(
                  bookingsStats.average_bookings_per_user?.value || 0,
                  {
                    disableCompact:
                      bookingsStats.average_bookings_per_user?.value < 10000,
                  },
                )}
                growth2={bookingsStats.average_bookings_per_user?.growth}
              />
              <Card
                title="Energy Consumption"
                isLoading={bookingsStatsLoading}
                value={formatCompactNumber(
                  bookingsStats.energy_consumption?.value >= 1000
                    ? bookingsStats.energy_consumption?.value / 1000
                    : bookingsStats.energy_consumption?.value,
                  {
                    maximumFractionDigits:
                      bookingsStats.energy_consumption?.value < 10
                        ? 2
                        : bookingsStats.energy_consumption?.value < 100 ||
                            bookingsStats.energy_consumption?.value >= 1000
                          ? 1
                          : 0,
                  },
                )}
                unit={
                  bookingsStats.energy_consumption?.value >= 1000
                    ? " MWh"
                    : " kWh"
                }
                growth={bookingsStats.energy_consumption?.growth}
                title2="Avg. consumption per user"
                value2={formatCompactNumber(
                  bookingsStats.average_consumption_per_user?.value >= 1000
                    ? bookingsStats.average_consumption_per_user?.value / 1000
                    : bookingsStats.average_consumption_per_user?.value,
                  {
                    maximumFractionDigits:
                      bookingsStats.average_consumption_per_user?.value < 10
                        ? 2
                        : bookingsStats.average_consumption_per_user?.value <
                              100 ||
                            bookingsStats.average_consumption_per_user?.value >=
                              1000
                          ? 1
                          : 0,
                  },
                )}
                unit2={
                  bookingsStats.average_consumption_per_user?.value >= 1000
                    ? " MWh"
                    : " kWh"
                }
                growth2={bookingsStats.average_consumption_per_user?.growth}
              />
              <Card
                title="Revenue"
                isLoading={bookingsStatsLoading}
                value={
                  "₹" +
                  formatCompactNumber(bookingsStats.revenue?.value || 0, {
                    locale: "en-IN",
                    maximumFractionDigits: 2,
                    disableCompact: bookingsStats.revenue?.value < 10000,
                  })
                }
                growth={bookingsStats.revenue?.growth}
                title2="Avg. revenue per user"
                value2={
                  "₹" +
                  formatCompactNumber(
                    bookingsStats.average_revenue_per_user?.value || 0,
                    {
                      locale: "en-IN",
                      maximumFractionDigits: 2,
                      disableCompact:
                        bookingsStats.average_revenue_per_user?.value < 10000,
                    },
                  )
                }
                growth2={bookingsStats.average_revenue_per_user?.growth}
              />
            </Paper>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

const Card = ({
  title,
  isLoading,
  value,
  unit,
  growth,
  title2,
  value2,
  unit2,
  growth2,
}: any) => {
  return (
    <Box
      p={2.5}
      borderRadius="8px"
      bgcolor={(theme) => theme.customColors.background.lightGrey}
      height="100%"
    >
      <Box
        bgcolor={(theme) => theme.customColors.background.grey}
        color={(theme) => theme.customColors.text.plain}
        py={0.5}
        px={1.5}
        borderRadius={50}
        width="fit-content"
        fontSize={{ xs: 14, lg: 16 }}
      >
        {title}
      </Box>
      <Box
        mt={3.25}
        display="flex"
        justifyContent="space-between"
        alignItems="end"
        lineHeight={1}
      >
        <Box
          fontSize={{ xs: 28, lg: 36 }}
          fontWeight={800}
          color="primary.main"
          whiteSpace="nowrap"
          mr={1}
          flexGrow={1}
        >
          {isLoading ? (
            <Skeleton width="60%" />
          ) : (
            <>
              {value || 0}
              {unit && (
                <Box component="span" fontSize={{ xs: 24, lg: 28 }}>
                  {unit}
                </Box>
              )}
            </>
          )}
        </Box>
        <Trend percentage={growth || null} />
      </Box>
      <Box mt={3.5}>
        <Box
          fontSize={{ xs: 12, lg: 14 }}
          color={(theme) => theme.customColors.text.plain}
        >
          {title2}
        </Box>
        <Box
          mt={1.5}
          display="flex"
          justifyContent="space-between"
          alignItems="end"
          lineHeight={1}
        >
          <Box
            fontSize={{ xs: 22, lg: 28 }}
            fontWeight={800}
            color={(theme) => theme.customColors.text.plain}
            flexGrow={1}
          >
            {isLoading ? (
              <Skeleton width="50%" />
            ) : (
              <>
                {value2 || 0}
                {unit2 && (
                  <Box component="span" fontSize={{ xs: 18, lg: 22 }}>
                    {unit2}
                  </Box>
                )}
              </>
            )}
          </Box>
          <Trend percentage={growth2 || null} />
        </Box>
      </Box>
    </Box>
  );
};

export default Highlights;
