import { AccountCircleOutlined } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { setGlobalState, signUp } from "actions";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "utils";
import { subdomain } from "utils/constants";
import { countries } from "utils/countries";
import { Heading } from ".";

const UserSignUp = ({ match }: any) => {
  const { signUpResponse = {} } = useSelector(
    (state: GlobalState) => state.global
  );
  const dispatch = useDispatch();

  const [isLinkExpired, setExpired] = useState(false);

  // const [step, setStep] = useState(0);

  // useEffect(() => {
  //   if (signUpResponse?.step === 1) setStep(1);
  // }, [signUpResponse]);

  const formTemplate = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    password: "",
    confirmPass: "",
    code: "",
  };
  const [countryCode, setCountryCode] = useState(
    countries.find((el) => el.name === "India")
  );
  const [form, setForm] = useState({ ...formTemplate });
  const [showPass, setShowPass] = useState(false);
  const [valid, setValid] = useState(true);

  const { firstName, lastName, email, phone, address, password, confirmPass } =
    form;

  useEffect(() => {});

  function handleChange(key: string, value: string) {
    setForm((prev) => ({ ...prev, [key]: value }));
  }

  useEffect(() => {
    const { inviteData } = match.params;
    try {
      let parsed: any = jwt_decode(inviteData);
      let { email, exp } = parsed;
      if (moment(exp * 1000) < moment()) setExpired(true);
      handleChange("email", email || "");
      handleChange("code", inviteData);
    } catch (err) {
      console.error(err);
    }
  }, [match.params]);

  function validatePassword() {
    if (password === "" && confirmPass === "") return "Enter a password";
    else if (password !== confirmPass) return "Passwords don't match";
    else if (password.length < 8)
      return "Use 8 characters or more for your password";
    else if (
      !/[A-Za-z]/.test(password) ||
      !/[0-9]/.test(password) ||
      !/[^A-Za-z0-9]/.test(password)
    )
      return "Please choose a stronger password. Try a mix of letters, numbers, and symbols.";
    else return null;
  }

  function handleSubmit() {
    dispatch(setGlobalState({ signUpResponse: null }));

    if (validatePassword()) {
      setValid(false);
    }
    if ([firstName, lastName, phone].includes("")) setValid(false);
    else {
      setValid(true);
      dispatch(
        signUp({
          signUpForm: {
            ...form,
            phone: "+" + countryCode?.code + phone,
            password,
          },
        })
      );
    }
  }
  return <>
    <Helmet>
      <title>Sign up - {subdomain.name}</title>
    </Helmet>
    {isLinkExpired ? (
      <Alert severity="error">Invite has expired.</Alert>
    ) : (
      <form
        style={{ width: "100%", maxWidth: 450 }}
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Box display="grid">
          <Heading value="Create your Bolt.Earth account" />
          <Alert severity="info" icon={<AccountCircleOutlined />}>
            {/* Signing up for <b>{email}</b> */}
            <AlertTitle sx={{ mb: 0 }}>{email}</AlertTitle>
          </Alert>
          <Typography variant="body1" mt={2}>
            {"Provide details and set a Password to sign up"}
          </Typography>
          {(signUpResponse?.code || signUpResponse?.status >= 400) && (
            <Typography mt={1} color="error" variant="body2">
              {signUpResponse?.message ===
              "A unique constraint would be violated on User. Details: Field name = phone"
                ? "The phone number is already in use by another account."
                : signUpResponse?.message}
            </Typography>
          )}

          <>
            <Box display="grid" mt={2.5} gap={2.5}>
              <TextField
                value={password}
                onChange={(e) => handleChange("password", e.target.value)}
                fullWidth
                label="Password"
                type={showPass ? "text" : "password"}
                autoComplete="new-password"
                error={
                  !valid && (password === "" || Boolean(validatePassword()))
                }
                inputProps={{
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
              <TextField
                value={confirmPass}
                onChange={(e) => handleChange("confirmPass", e.target.value)}
                fullWidth
                label="Confirm"
                type={showPass ? "text" : "password"}
                autoComplete="new-password"
                error={
                  !valid &&
                  (confirmPass === "" || Boolean(validatePassword()))
                }
                inputProps={{
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            </Box>
            <Typography
              variant="body2"
              sx={{
                py: 1,
                px: 1.75,
                color: !valid ? "error.main" : "text.secondary",
              }}
            >
              {!valid
                ? validatePassword()
                : validatePassword()
                ? "Use 8 or more characters with a mix of letters, numbers & symbols"
                : null}
            </Typography>
            <FormControlLabel
              control={<Checkbox size="small" />}
              value={showPass}
              onChange={() => setShowPass((value) => !value)}
              label="Show password"
            />
          </>

          <Box
            sx={{
              mt: 3,
              mb: 1,
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: 3,
            }}
          >
            <TextField
              size="small"
              value={firstName}
              onChange={(e) => {
                if (e.target.value.length <= 30) {
                  handleChange("firstName", e.target.value);
                }
              }}
              fullWidth
              label="First Name"
              error={!valid && firstName === ""}
              helperText={!valid && firstName === "" && "Enter first name"}
            />
            <TextField
              size="small"
              value={lastName}
              onChange={(e) => {
                if (e.target.value.length <= 30) {
                  handleChange("lastName", e.target.value);
                }
              }}
              fullWidth
              label="Last Name"
              error={!valid && lastName === ""}
              helperText={!valid && lastName === "" && "Enter last name"}
            />
            <TextField
              sx={{ gridColumn: "span 2" }}
              size="small"
              value={email}
              fullWidth
              label="Email"
              name="email"
              disabled
            />
            <Box
              sx={{
                gridColumn: "span 2",
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                gap: 2,
              }}
            >
              <Autocomplete
                sx={{ width: 180 }}
                size="small"
                options={countries}
                autoHighlight
                getOptionLabel={(option) =>
                  `${option.icon} ${option.name} (+${option.code})`
                }
                value={countryCode}
                onChange={(e, newValue) => setCountryCode(newValue)}
                disableClearable
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.abbr.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.abbr.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.name} (+{option.code})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country Code"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <TextField
                size="small"
                value={phone}
                onChange={(e: any) => {
                  if (/^[0-9]{0,10}$/.test(e.target.value))
                    handleChange("phone", e.target.value);
                }}
                fullWidth
                label="Phone"
                name="phone"
                error={!valid && phone === ""}
                // inputProps={{
                //   maxLength: 10,
                // }}
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="start">+91</InputAdornment>
                //   ),
                // }}
                helperText={
                  !valid &&
                  phone === "" &&
                  "Enter a valid 10-digit phone number"
                }
              />
            </Box>
            <TextField
              multiline
              minRows={2}
              value={address}
              onChange={(e) => handleChange("address", e.target.value)}
              label="Address (Optional)"
              name="address"
              sx={{ gridColumn: "span 2" }}
            />
          </Box>

          <Box
            mt={2}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <Button type="submit" variant="contained" size="large">
              {"Sign up"}
            </Button>
            {/* <Button size="large" onClick={() => setView("sign-in")}>
              Sign in instead
            </Button> */}
          </Box>
        </Box>
      </form>
    )}
  </>;
};

export default UserSignUp;
