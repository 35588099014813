import {
  CheckBox,
  CheckBoxOutlineBlank,
  FilterAltOutlined,
  IndeterminateCheckBox,
  InfoOutlined,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  useTheme,
} from "@mui/material";
import RangeSelector from "components/RangeSelector";
import { useState } from "react";
import { filterOptions, filterValues } from "..";
import FiltersDrawer from "./FiltersDrawer";
import LocationSelector from "./LocationSelector";

const FiltersBar = ({ filters, setFilters, setDateRangeInWords }: any) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Box
      sx={{
        // ml: "auto",
        width: "fit-content",
        // position: "sticky",
        // top: { xs: 16, md: 32 },
        // right: { xs: 16, md: 32 },
        zIndex: 3,
      }}
    >
      {/* Mobile version */}
      <Box
        sx={{
          display: { xs: "flex", lg2: "none" },
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          size="small"
          sx={{
            boxShadow: (theme) => theme.customShadows.card,
            background: (theme) => theme.palette.background.paper,
            border: (theme) => `1px solid ${theme.customColors.border}`,
          }}
          onClick={() => setDrawerOpen(true)}
        >
          <Badge
            color="primary"
            variant="dot"
            invisible={
              ![
                filters.vehicleType,
                filters.chargerType,
                filters.accessType,
              ].some((el) => el.length > 0)
            }
          >
            <FilterAltOutlined />
          </Badge>
        </IconButton>
        <FiltersDrawer
          {...{
            open: drawerOpen,
            onClose: () => setDrawerOpen(false),
            filters,
            setFilters,
            range: filters.time,
            setLocations: (val: any) =>
              setFilters((prev: any) => ({
                ...prev,
                locations: val,
              })),
            setDateRangeInWords,
          }}
        />
      </Box>

      {/* Desktop version */}
      <Box
        display={{ xs: "none", lg2: "flex" }}
        alignItems="center"
        sx={{
          "& > :not(:first-of-type)": { ml: 2 },
        }}
      >
        <FormControl size="small">
          <InputLabel shrink id="vehicle-type-label">
            Vehicle Type
          </InputLabel>
          <Select
            className="shadow rounded"
            labelId="vehicle-type-label"
            label="Vehicle Type"
            sx={{
              width: 170,
              bgcolor: (theme) =>
                // filters.vehicleType.length > 0
                filters.vehicleType.length > 0 &&
                filters.vehicleType.length !== filterValues.vehicleType.length
                  ? isDarkMode
                    ? "#1b2b1c"
                    : "#e9f9ea"
                  : undefined,
            }}
            multiple
            value={filters.vehicleType}
            onChange={(e) => {
              // On autofill, we get a string
              const newValues =
                typeof e.target.value === "string"
                  ? e.target.value.split(",")
                  : e.target.value;
              setFilters((prev: any) => ({
                ...prev,
                // vehicleType: newValues.includes("all") ? [] : newValues,
                vehicleType: newValues.includes("all")
                  ? filterValues.vehicleType
                  : newValues,
              }));
            }}
            displayEmpty
            renderValue={(selected) =>
              // filters.vehicleType.length === 0
              filters.vehicleType.length === filterValues.vehicleType.length
                ? "All Vehicles"
                : filterOptions.vehicleType
                    .filter((el: any) => selected.includes(el.value))
                    .map((el: any) => el.label)
                    .join(", ")
            }
          >
            {filterOptions.vehicleType.map((el: any, i: number) => (
              <MenuItem key={i} value={el.value}>
                {/* {filters.vehicleType.includes(el.value) ||
                (filters.vehicleType.length === 0 && el.value === "all") ? ( */}
                {filters.vehicleType.includes(el.value) ||
                (filters.vehicleType.length ===
                  filterValues.vehicleType.length &&
                  el.value === "all") ? (
                  <CheckBox
                    fontSize="small"
                    color="primary"
                    sx={{
                      ml: el.value === "all" ? 0 : 2,
                      mr: 1,
                    }}
                  />
                ) : (
                  <CheckBoxOutlineBlank
                    fontSize="small"
                    color="action"
                    sx={{
                      ml: el.value === "all" ? 0 : 2,
                      mr: 1,
                    }}
                  />
                )}
                <ListItemText primary={el.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small">
          <InputLabel shrink id="charger-type-label">
            Charger Type
          </InputLabel>
          <Select
            className="shadow rounded"
            labelId="charger-type-label"
            label="Charger Type"
            sx={{
              width: 170,
              bgcolor: (theme) =>
                // filters.chargerType.length > 0
                filters.chargerType.length > 0 &&
                filters.chargerType.length !== filterValues.chargerType.length
                  ? isDarkMode
                    ? "#1b2b1c"
                    : "#e9f9ea"
                  : undefined,
            }}
            multiple
            value={filters.chargerType}
            onChange={(e) => {
              // On autofill, we get a string
              const newValues =
                typeof e.target.value === "string"
                  ? e.target.value.split(",")
                  : e.target.value;

              if (newValues.some((el: string) => el.includes("header-")))
                return;

              setFilters((prev: any) => ({
                ...prev,
                // chargerType: newValues.includes("all") ? [] : newValues,
                chargerType: newValues.includes("all")
                  ? filterValues.chargerType
                  : newValues,
              }));
            }}
            displayEmpty
            renderValue={(selected) =>
              filters.chargerType.length === filterValues.chargerType.length
                ? "All Chargers"
                : filterOptions.chargerType
                    .filter((el: any) => selected.includes(el.value))
                    .map((el: any) => el.label)
                    .join(", ")
            }
          >
            {filterOptions.chargerType.map((el: any, i: number) => {
              const isHeader = el.type === "header";
              const subItems = filterOptions.chargerType.filter(
                (subEl: any) => subEl.parent === el.value,
              );
              const isChecked = subItems.every((subEl: any) =>
                filters.chargerType.includes(subEl.value),
              );
              const isIndeterminate =
                !isChecked &&
                subItems.some((subEl: any) =>
                  filters.chargerType.includes(subEl.value),
                );

              return (
                <MenuItem
                  key={i}
                  value={el.value}
                  onClick={(e) => {
                    if (!isHeader) return;
                    let existingValues = filters.chargerType;
                    // remove all subitems from existing values
                    existingValues = existingValues.filter(
                      (el: string) =>
                        !subItems.map((el: any) => el.value).includes(el),
                    );

                    if (!isChecked) {
                      // add all subitems
                      existingValues = [
                        ...existingValues,
                        ...subItems.map((el: any) => el.value),
                      ];
                    }

                    setFilters((prev: any) => ({
                      ...prev,
                      chargerType: existingValues,
                    }));
                  }}
                >
                  {isHeader ? (
                    isIndeterminate ? (
                      <IndeterminateCheckBox
                        fontSize="small"
                        color="primary"
                        sx={{ ml: 2, mr: 1 }}
                      />
                    ) : isChecked ? (
                      <CheckBox
                        fontSize="small"
                        color="primary"
                        sx={{ ml: 2, mr: 1 }}
                      />
                    ) : (
                      <CheckBoxOutlineBlank
                        fontSize="small"
                        color="action"
                        sx={{ ml: 2, mr: 1 }}
                      />
                    )
                  ) : // ) : filters.chargerType.includes(el.value) ||
                  //   (filters.chargerType.length === 0 && el.value === "all") ? (
                  filters.chargerType.includes(el.value) ||
                    (filters.chargerType.length ===
                      filterValues.chargerType.length &&
                      el.value === "all") ? (
                    <CheckBox
                      fontSize="small"
                      color="primary"
                      sx={{
                        // ml: !!el.parent ? 2 : 0,
                        ml: el.value === "all" ? 0 : !!el.parent ? 4 : 2,
                        mr: 1,
                      }}
                    />
                  ) : (
                    <CheckBoxOutlineBlank
                      fontSize="small"
                      color="action"
                      sx={{
                        // ml: !!el.parent ? 2 : 0,
                        ml: el.value === "all" ? 0 : !!el.parent ? 4 : 2,
                        mr: 1,
                      }}
                    />
                  )}
                  <ListItemText primary={el.label} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Tooltip
          title="This indicates the current access type of the charger"
          placement="top"
        >
          <FormControl size="small">
            <InputLabel
              shrink
              id="access-type-label"
              sx={{ display: "flex", alignItems: "center" }}
            >
              Access Type
              <InfoOutlined sx={{ fontSize: "inherit", ml: 0.5 }} />
            </InputLabel>
            <Select
              className="shadow rounded"
              labelId="access-type-label"
              label="Access Type &nbsp;&nbsp;&nbsp;"
              sx={{
                // width: 170,
                width: 175,
                bgcolor: (theme) =>
                  // filters.accessType.length > 0
                  filters.accessType.length > 0 &&
                  filters.accessType.length !== filterValues.accessType.length
                    ? isDarkMode
                      ? "#1b2b1c"
                      : "#e9f9ea"
                    : undefined,
              }}
              multiple
              value={filters.accessType}
              onChange={(e) => {
                // On autofill, we get a string
                const newValues =
                  typeof e.target.value === "string"
                    ? e.target.value.split(",")
                    : e.target.value;
                setFilters((prev: any) => ({
                  ...prev,
                  accessType: newValues.includes("all")
                    ? filterValues.accessType
                    : newValues,
                }));
              }}
              displayEmpty
              renderValue={(selected) =>
                filters.accessType.length === filterValues.accessType.length
                  ? "All Access Types"
                  : filterOptions.accessType
                      .filter((el: any) => selected.includes(el.value))
                      .map((el: any) => el.label)
                      .join(", ")
              }
            >
              {filterOptions.accessType.map((el: any, i: number) => (
                <MenuItem key={i} value={el.value}>
                  {/* {filters.accessType.includes(el.value) ||
                (filters.accessType.length === 0 && el.value === "all") ? ( */}
                  {filters.accessType.includes(el.value) ||
                  (filters.accessType.length ===
                    filterValues.accessType.length &&
                    el.value === "all") ? (
                    <CheckBox
                      fontSize="small"
                      color="primary"
                      sx={{
                        ml: el.value === "all" ? 0 : 2,
                        mr: 1,
                      }}
                    />
                  ) : (
                    <CheckBoxOutlineBlank
                      fontSize="small"
                      color="action"
                      sx={{
                        ml: el.value === "all" ? 0 : 2,
                        mr: 1,
                      }}
                    />
                  )}
                  <ListItemText primary={el.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Tooltip>
        <LocationSelector
          locations={filters.locations}
          setLocations={(val) =>
            setFilters((prev: any) => ({
              ...prev,
              locations: val,
            }))
          }
          highlight={filters.locations.length > 0}
        />
        <RangeSelector
          initialRange="Last 30 days"
          range={filters.time}
          setRange={(range: any) => {
            setFilters((prev: any) => ({
              ...prev,
              time: range,
            }));
          }}
          setRangeInWords={setDateRangeInWords}
        />
      </Box>
    </Box>
  );
};

export default FiltersBar;
