import {
  AddRounded,
  CheckBox,
  CheckBoxOutlineBlank,
  FilterAltOutlined,
  IndeterminateCheckBox,
  InfoOutlined,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Tooltip,
  useMediaQuery,
  useTheme,
  // Tab,
  // Tabs,
} from "@mui/material";
import RangeSelector from "components/RangeSelector";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { authorizedFetch, formatCompactNumber, GlobalState } from "utils";
import { CMS_STATS_URL } from "utils/constants";
import { filterOptions as overviewFilterOptions } from "views/Charger/Overview";
import LocationSelector from "views/Charger/Overview/Filters/LocationSelector";
import { Trend } from "views/Charger/Overview/Highlights";
import AddChargerDialog from "../AddChargerDialog";
import FiltersDrawer from "./FiltersDrawer";

export const filterOptions = {
  chargerType: [...overviewFilterOptions.chargerType],
  health: [
    { label: "All", value: "all" },
    { label: "Healthy", value: "HEALTHY" },
    { label: "Moderate", value: "MODERATE" },
    { label: "Critical", value: "CRITICAL" },
    { label: "Inactive", value: "INACTIVE" },
  ],
  settlementStatus: [
    { label: "All", value: "all" },
    { label: "Settled", value: "SETTLED" },
    // { label: "Pending", value: "PENDING" },
    // { label: "N/A", value: "NA" },
    { label: "Pending", value: "PENDING&settlement_status=NA" }, // Pending + N/A
  ],
};

export const filtersObj = {
  chargerType: [],
  health: [],
  locations: [],
  settlementStatus: [],
  time: [moment().subtract({ months: 1 }), moment()],
};

const Highlights = ({ tab, masterView, drawerState }: any) => {
  let { user, company } = useSelector((state: GlobalState) => state.global);

  const shouldNotShowAddCharger = user.email === "adi.setiakarsa@alvaauto.com";
  const isCUGMonitorAccount = company?.type === "CUG_VENDOR";

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const isLg2Up = useMediaQuery(theme.breakpoints.up("lg2"));

  const [addChargerDialog, setAddChargerDialog] = useState(false);

  const [filters, setFilters] = useState<any>({ ...filtersObj });
  const [drawerOpen, setDrawerOpen] = useState(false);

  const startTime = filters.time[0].toISOString();
  const endTime = filters.time[1].toISOString();

  const chargerType = filters.chargerType.length
    ? filters.chargerType.map((el: string) => `&charger_type=${el}`).join("")
    : "";
  const health = filters.health.length
    ? filters.health.map((el: string) => `&health=${el}`).join("")
    : "";
  const settlementStatus = filters.settlementStatus.length
    ? filters.settlementStatus
        .map((el: string) => `&settlement_status=${el}`)
        .join("")
    : "";
  const city = filters.locations.length
    ? filters.locations.map((el: string) => `&city=${el}`).join("")
    : "";

  const { isLoading: chargersStatsLoading, data: chargersStatsData } = useQuery(
    [
      "GET cms-stats /metrics/chargers",
      startTime,
      endTime,
      chargerType,
      health,
      city,
      masterView,
    ],
    () =>
      authorizedFetch(
        `${CMS_STATS_URL}/metrics/chargers?start_time=${startTime}&end_time=${endTime}${chargerType}${health}${city}&master=${masterView}`,
      ),
    {
      enabled: tab === 0,
    },
  );
  const { isLoading: bookingsStatsLoading, data: bookingsStatsData } = useQuery(
    [
      "GET cms-stats /metrics/bookings",
      startTime,
      endTime,
      settlementStatus,
      city,
      masterView,
    ],
    () =>
      authorizedFetch(
        `${CMS_STATS_URL}/metrics/bookings?start_time=${startTime}&end_time=${endTime}${settlementStatus}${city}&master=${masterView}`,
      ),
    {
      enabled: tab === 1,
    },
  );

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box
          sx={{
            fontSize: { xs: 18, sm: 20, md: 24 },
            color: (theme) => theme.customColors.text.plain,
            fontWeight: 700,
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>{tab === 0 ? "Chargers" : "Bookings"} highlights</span>
          <Tooltip
            title="The highlights shown below are updated every 4 hours"
            placement="top"
            arrow
          >
            <InfoOutlined
              sx={{
                ml: 1,
                fontSize: { xs: 16, lg: 20 },
                color: (theme) => theme.customColors.action,
              }}
            />
          </Tooltip>
        </Box>

        {/* Filters */}
        <Box display="flex" alignItems="center">
          {/* Mobile version */}
          {(!isLg2Up || drawerState.open) && (
            <Box
              // display={{ xs: "flex", lg2: "none" }}
              display="flex"
              justifyContent="flex-end"
            >
              <IconButton
                size="small"
                sx={{
                  boxShadow: (theme) => theme.customShadows.card,
                  background: (theme) => theme.palette.background.paper,
                  border: (theme) => `1px solid ${theme.customColors.border}`,
                }}
                onClick={() => setDrawerOpen(true)}
              >
                <Badge
                  color="primary"
                  variant="dot"
                  invisible={
                    ![
                      ...(tab === 0
                        ? [
                            filters.chargerType,
                            filters.health,
                            filters.locations,
                          ]
                        : []),
                      ...(tab === 1
                        ? [filters.settlementStatus, filters.locations]
                        : []),
                    ].some((el) => el.length > 0)
                  }
                >
                  <FilterAltOutlined />
                </Badge>
              </IconButton>
              <FiltersDrawer
                {...{
                  open: drawerOpen,
                  onClose: () => setDrawerOpen(false),
                  filters,
                  setFilters,
                  range: filters.time,
                  setLocations: (val: any) =>
                    setFilters((prev: any) => ({
                      ...prev,
                      locations: val,
                    })),
                  activeView: tab === 0 ? "chargers" : "bookings",
                }}
              />
            </Box>
          )}

          {/* Desktop version */}
          {isLg2Up && !drawerState.open && (
            <Box
              // display={{ xs: "none", lg2: "flex" }}
              display="flex"
              alignItems="center"
              sx={{
                "& > :not(:first-of-type)": { ml: 2 },
              }}
            >
              {tab === 0 && (
                <>
                  <FormControl size="small">
                    <InputLabel id="charger-type-label">
                      Charger Type
                    </InputLabel>
                    <Select
                      className="shadow rounded"
                      labelId="charger-type-label"
                      label="Charger Type"
                      sx={{
                        width: 170,
                        bgcolor: (theme) =>
                          filters.chargerType.length > 0
                            ? isDarkMode
                              ? "#1b2b1c"
                              : "#e9f9ea"
                            : undefined,
                      }}
                      multiple
                      value={filters.chargerType}
                      onChange={(e) => {
                        // On autofill, we get a string
                        const newValues =
                          typeof e.target.value === "string"
                            ? e.target.value.split(",")
                            : e.target.value;

                        if (
                          newValues.some((el: string) => el.includes("header-"))
                        )
                          return;

                        setFilters((prev: any) => ({
                          ...prev,
                          chargerType: newValues.includes("all")
                            ? []
                            : newValues,
                        }));
                      }}
                      renderValue={(selected) =>
                        filterOptions.chargerType
                          .filter((el: any) => selected.includes(el.value))
                          .map((el: any) => el.label)
                          .join(", ")
                      }
                    >
                      {filterOptions.chargerType.map((el: any, i: number) => {
                        const isHeader = el.type === "header";
                        const subItems = filterOptions.chargerType.filter(
                          (subEl: any) => subEl.parent === el.value,
                        );
                        const isChecked = subItems.every((subEl: any) =>
                          filters.chargerType.includes(subEl.value),
                        );
                        const isIndeterminate =
                          !isChecked &&
                          subItems.some((subEl: any) =>
                            filters.chargerType.includes(subEl.value),
                          );

                        return (
                          <MenuItem
                            key={i}
                            value={el.value}
                            onClick={(e) => {
                              if (!isHeader) return;
                              let existingValues = filters.chargerType;
                              // remove all subitems from existing values
                              existingValues = existingValues.filter(
                                (el: string) =>
                                  !subItems
                                    .map((el: any) => el.value)
                                    .includes(el),
                              );

                              if (!isChecked) {
                                // add all subitems
                                existingValues = [
                                  ...existingValues,
                                  ...subItems.map((el: any) => el.value),
                                ];
                              }

                              setFilters((prev: any) => ({
                                ...prev,
                                chargerType: existingValues,
                              }));
                            }}
                          >
                            {isHeader ? (
                              isIndeterminate ? (
                                <IndeterminateCheckBox
                                  fontSize="small"
                                  color="primary"
                                  sx={{ mr: 1 }}
                                />
                              ) : isChecked ? (
                                <CheckBox
                                  fontSize="small"
                                  color="primary"
                                  sx={{ mr: 1 }}
                                />
                              ) : (
                                <CheckBoxOutlineBlank
                                  fontSize="small"
                                  color="action"
                                  sx={{ mr: 1 }}
                                />
                              )
                            ) : filters.chargerType.includes(el.value) ||
                              (filters.chargerType.length === 0 &&
                                el.value === "all") ? (
                              <CheckBox
                                fontSize="small"
                                color="primary"
                                sx={{ ml: !!el.parent ? 2 : 0, mr: 1 }}
                              />
                            ) : (
                              <CheckBoxOutlineBlank
                                fontSize="small"
                                color="action"
                                sx={{ ml: !!el.parent ? 2 : 0, mr: 1 }}
                              />
                            )}
                            <ListItemText primary={el.label} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl size="small">
                    <InputLabel id="health-status-label">
                      Health Status
                    </InputLabel>
                    <Select
                      className="shadow rounded"
                      labelId="health-status-label"
                      label="Health Status"
                      sx={{
                        width: 170,
                        bgcolor: (theme) =>
                          filters.health.length > 0
                            ? isDarkMode
                              ? "#1b2b1c"
                              : "#e9f9ea"
                            : undefined,
                      }}
                      multiple
                      value={filters.health}
                      onChange={(e) => {
                        // On autofill, we get a string
                        const newValues =
                          typeof e.target.value === "string"
                            ? e.target.value.split(",")
                            : e.target.value;
                        setFilters((prev: any) => ({
                          ...prev,
                          health: newValues.includes("all") ? [] : newValues,
                        }));
                      }}
                      renderValue={(selected: any) =>
                        filterOptions.health
                          .filter((el: any) => selected.includes(el.value))
                          .map((el: any) => el.label)
                          .join(", ")
                      }
                    >
                      {filterOptions.health.map((el: any, i: number) => (
                        <MenuItem key={i} value={el.value}>
                          {filters.health.includes(el.value) ||
                          (filters.health.length === 0 &&
                            el.value === "all") ? (
                            <CheckBox
                              fontSize="small"
                              color="primary"
                              sx={{ mr: 1 }}
                            />
                          ) : (
                            <CheckBoxOutlineBlank
                              fontSize="small"
                              color="action"
                              sx={{ mr: 1 }}
                            />
                          )}
                          <ListItemText primary={el.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
              {tab === 1 && (
                <>
                  <FormControl size="small">
                    <InputLabel id="settlement-status-label">
                      Settlement Status
                    </InputLabel>
                    <Select
                      className="shadow rounded"
                      labelId="settlement-status-label"
                      label="Settlement Status"
                      sx={{
                        width: 180,
                        bgcolor: (theme) =>
                          filters.settlementStatus.length > 0
                            ? isDarkMode
                              ? "#1b2b1c"
                              : "#e9f9ea"
                            : undefined,
                      }}
                      multiple
                      value={filters.settlementStatus}
                      onChange={(e) => {
                        // On autofill, we get a string
                        const newValues =
                          typeof e.target.value === "string"
                            ? e.target.value.split(",")
                            : e.target.value;
                        setFilters((prev: any) => ({
                          ...prev,
                          settlementStatus: newValues.includes("all")
                            ? []
                            : newValues,
                        }));
                      }}
                      renderValue={(selected: any) =>
                        filterOptions.settlementStatus
                          .filter((el: any) => selected.includes(el.value))
                          .map((el: any) => el.label)
                          .join(", ")
                      }
                    >
                      {filterOptions.settlementStatus.map(
                        (el: any, i: number) => (
                          <MenuItem key={i} value={el.value}>
                            {filters.settlementStatus.includes(el.value) ||
                            (filters.settlementStatus.length === 0 &&
                              el.value === "all") ? (
                              <CheckBox
                                fontSize="small"
                                color="primary"
                                sx={{ mr: 1 }}
                              />
                            ) : (
                              <CheckBoxOutlineBlank
                                fontSize="small"
                                color="action"
                                sx={{ mr: 1 }}
                              />
                            )}
                            <ListItemText primary={el.label} />
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                </>
              )}

              <LocationSelector
                locations={filters.locations}
                setLocations={(val) =>
                  setFilters((prev: any) => ({
                    ...prev,
                    locations: val,
                  }))
                }
                highlight={filters.locations.length > 0}
              />
              <RangeSelector
                initialRange="Last 30 days"
                range={filters.time}
                setRange={(range: any) => {
                  setFilters((prev: any) => ({
                    ...prev,
                    time: range,
                  }));
                }}
              />
            </Box>
          )}

          {/* Add Charger button */}
          {!shouldNotShowAddCharger && !isCUGMonitorAccount && tab === 0 && (
            <Box>
              <Box display={{ xs: "block", md: "none" }}>
                <Button
                  sx={{
                    ml: 2.25,
                    px: 0.5,
                    minWidth: 0,
                  }}
                  variant="outlined"
                  onClick={() => setAddChargerDialog(true)}
                >
                  <AddRounded />
                </Button>
              </Box>
              <Box display={{ xs: "none", md: "block" }}>
                <Button
                  sx={{
                    // width: { md: 300 },
                    ml: 2,
                    borderRadius: 10,
                    borderWidth: "1.5px !important",
                    borderColor: (theme) => theme.palette.primary.main,
                    textTransform: "none",
                    fontWeight: 500,
                  }}
                  variant="outlined"
                  startIcon={<AddRounded />}
                  onClick={() => setAddChargerDialog(true)}
                >
                  Add Charger
                </Button>
              </Box>
              <AddChargerDialog
                open={addChargerDialog}
                handleClose={() => setAddChargerDialog(false)}
              />
            </Box>
          )}
        </Box>
      </Box>
      {/* <Box width="fit-content" mt={2} mb={3}>
        <Tabs
          className="dense"
          value={tab}
          onChange={(e, value) => setTab(value)}
          sx={{
            "& .MuiTab-root": {
              fontSize: 16,
              fontWeight: 600,
              letterSpacing: "normal",
            },
          }}
        >
          <Tab label="Chargers" />
          <Tab label="Bookings" />
        </Tabs>
      </Box> */}
      <Box mt={3} mb={5}>
        {tab === 0 && (
          <ChargersStats
            isLoading={chargersStatsLoading}
            data={chargersStatsData}
          />
        )}
        {tab === 1 && (
          <BookingsStats
            isLoading={bookingsStatsLoading}
            data={bookingsStatsData}
          />
        )}
      </Box>
    </div>
  );
};

const ChargersStats = ({ isLoading, data }: any) => {
  const stats = data?.data || {};
  return (
    <Box border={0} m={-3} p={3} sx={{ overflowX: "auto" }}>
      <Box
        display="grid"
        gridTemplateColumns="1fr 3fr"
        gap={3}
        minWidth={{ xs: 1000, lg: 1200 }}
      >
        <Paper
          sx={{
            p: 2,
            borderRadius: "14px",
            boxShadow: (theme) => theme.customShadows.card,
          }}
        >
          <Card
            title="Total Active Chargers"
            isLoading={isLoading}
            value={formatCompactNumber(stats.active_chargers?.value || 0, {
              disableCompact: stats.active_chargers?.value < 10000,
            })}
            growth={stats.active_chargers?.growth}
            title2="Idle Chargers"
            value2={formatCompactNumber(stats.inactive_chargers?.value || 0, {
              disableCompact: stats.inactive_chargers?.value < 10000,
            })}
            growth2={stats.inactive_chargers?.growth}
          />
        </Paper>

        <Paper
          sx={{
            p: 2,
            borderRadius: "14px",
            boxShadow: (theme) => theme.customShadows.card,
            minWidth: 420,
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 2,
          }}
        >
          <Card
            title="Bookings"
            isLoading={isLoading}
            value={formatCompactNumber(stats.total_bookings?.value || 0, {
              disableCompact: stats.total_bookings?.value < 10000,
            })}
            growth={stats.total_bookings?.growth}
            title2="Avg. bookings per charger"
            value2={formatCompactNumber(
              stats.average_bookings_per_charger?.value || 0,
              {
                disableCompact:
                  stats.average_bookings_per_charger?.value < 10000,
              },
            )}
            growth2={stats.average_bookings_per_charger?.growth}
          />
          <Card
            title="Energy Dispensed"
            isLoading={isLoading}
            value={formatCompactNumber(
              stats.energy_consumption?.value >= 1000000
                ? stats.energy_consumption?.value / 1000000
                : stats.energy_consumption?.value >= 1000
                  ? stats.energy_consumption?.value / 1000
                  : stats.energy_consumption?.value,
              {
                maximumFractionDigits:
                  stats.energy_consumption?.value < 10
                    ? 2
                    : stats.energy_consumption?.value < 100 ||
                        stats.energy_consumption?.value >= 1000
                      ? 1
                      : 0,
              },
            )}
            unit={
              stats.energy_consumption?.value >= 1000000
                ? " GWh"
                : stats.energy_consumption?.value >= 1000
                  ? " MWh"
                  : " kWh"
            }
            growth={stats.energy_consumption?.growth}
            title2="Avg. energy per charger"
            value2={formatCompactNumber(
              stats.average_consumption_per_charger?.value >= 1000000
                ? stats.average_consumption_per_charger?.value / 1000000
                : stats.average_consumption_per_charger?.value >= 1000
                  ? stats.average_consumption_per_charger?.value / 1000
                  : stats.average_consumption_per_charger?.value,
              {
                maximumFractionDigits:
                  stats.average_consumption_per_charger?.value < 10
                    ? 2
                    : stats.average_consumption_per_charger?.value < 100 ||
                        stats.average_consumption_per_charger?.value >= 1000
                      ? 1
                      : 0,
              },
            )}
            unit2={
              stats.average_consumption_per_charger?.value >= 1000000
                ? " GWh"
                : stats.average_consumption_per_charger?.value >= 1000
                  ? " MWh"
                  : " kWh"
            }
            growth2={stats.average_consumption_per_charger?.growth}
          />
          <Card
            title="Revenue"
            isLoading={isLoading}
            value={
              "₹" +
              formatCompactNumber(stats.revenue?.value || 0, {
                locale: "en-IN",
                maximumFractionDigits: 2,
                disableCompact: stats.revenue?.value < 10000,
              })
            }
            growth={stats.revenue?.growth}
            title2="Avg. revenue per charger"
            value2={
              "₹" +
              formatCompactNumber(
                stats.average_revenue_per_charger?.value || 0,
                {
                  locale: "en-IN",
                  maximumFractionDigits: 2,
                  disableCompact:
                    stats.average_revenue_per_charger?.value < 10000,
                },
              )
            }
            growth2={stats.average_revenue_per_charger?.growth}
          />
        </Paper>
      </Box>
    </Box>
  );
};

const BookingsStats = ({ isLoading, data }: any) => {
  const stats = data?.data || {};
  return (
    <Box border={0} m={-3} p={3} sx={{ overflowX: "auto" }}>
      <Box
        display="grid"
        gridTemplateColumns="1fr 3fr"
        gap={3}
        minWidth={{ xs: 1000, lg: 1200 }}
      >
        <Paper
          sx={{
            p: 2,
            borderRadius: "14px",
            boxShadow: (theme) => theme.customShadows.card,
          }}
        >
          <Card
            title="Bookings"
            isLoading={isLoading}
            value={formatCompactNumber(stats.total_bookings?.value || 0, {
              disableCompact: stats.total_bookings?.value < 10000,
            })}
            growth={stats.total_bookings?.growth}
            title2="No. of active chargers"
            value2={formatCompactNumber(
              stats.total_active_chargers?.value || 0,
              {
                disableCompact: stats.total_active_chargers?.value < 10000,
              },
            )}
            growth2={stats.total_active_chargers?.growth}
          />
        </Paper>

        <Paper
          sx={{
            p: 2,
            borderRadius: "14px",
            boxShadow: (theme) => theme.customShadows.card,
            minWidth: 420,
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 2,
          }}
        >
          <Card
            title="Users"
            isLoading={isLoading}
            value={formatCompactNumber(stats.active_users?.value || 0, {
              disableCompact: stats.active_users?.value < 10000,
            })}
            growth={stats.active_users?.growth}
            title2="Avg. bookings per user"
            value2={formatCompactNumber(
              stats.average_bookings_per_user?.value || 0,
              {
                disableCompact: stats.average_bookings_per_user?.value < 10000,
              },
            )}
            growth2={stats.average_bookings_per_user?.growth}
          />
          <Card
            title="Energy Dispensed"
            isLoading={isLoading}
            value={formatCompactNumber(
              stats.energy_consumption?.value >= 1000000
                ? stats.energy_consumption?.value / 1000000
                : stats.energy_consumption?.value >= 1000
                  ? stats.energy_consumption?.value / 1000
                  : stats.energy_consumption?.value,
              {
                maximumFractionDigits:
                  stats.energy_consumption?.value < 10
                    ? 2
                    : stats.energy_consumption?.value < 100 ||
                        stats.energy_consumption?.value >= 1000
                      ? 1
                      : 0,
              },
            )}
            unit={
              stats.energy_consumption?.value >= 1000000
                ? " GWh"
                : stats.energy_consumption?.value >= 1000
                  ? " MWh"
                  : " kWh"
            }
            growth={stats.energy_consumption?.growth}
            title2="Avg. energy per booking"
            value2={formatCompactNumber(
              stats.average_consumption_per_booking?.value >= 1000000
                ? stats.average_consumption_per_booking?.value / 1000000
                : stats.average_consumption_per_booking?.value >= 1000
                  ? stats.average_consumption_per_booking?.value / 1000
                  : stats.average_consumption_per_booking?.value,
              {
                maximumFractionDigits:
                  stats.average_consumption_per_booking?.value < 10
                    ? 2
                    : stats.average_consumption_per_booking?.value < 100 ||
                        stats.average_consumption_per_booking?.value >= 1000
                      ? 1
                      : 0,
              },
            )}
            unit2={
              stats.average_consumption_per_booking?.value >= 1000000
                ? " GWh"
                : stats.average_consumption_per_booking?.value >= 1000
                  ? " MWh"
                  : " kWh"
            }
            growth2={stats.average_consumption_per_booking?.growth}
          />
          <Card
            title="Revenue"
            isLoading={isLoading}
            value={
              "₹" +
              formatCompactNumber(stats.revenue?.value || 0, {
                locale: "en-IN",
                maximumFractionDigits: 2,
                disableCompact: stats.revenue?.value < 10000,
              })
            }
            growth={stats.revenue?.growth}
            title2="Avg. revenue per booking"
            value2={
              "₹" +
              formatCompactNumber(
                stats.average_revenue_per_booking?.value || 0,
                {
                  locale: "en-IN",
                  maximumFractionDigits: 2,
                  disableCompact:
                    stats.average_revenue_per_booking?.value < 10000,
                },
              )
            }
            growth2={stats.average_revenue_per_booking?.growth}
          />
        </Paper>
      </Box>
    </Box>
  );
};

const Card = ({
  title,
  isLoading,
  value,
  unit,
  growth,
  title2,
  value2,
  unit2,
  growth2,
}: any) => {
  return (
    <Box
      p={2.5}
      borderRadius="8px"
      bgcolor={(theme) => theme.customColors.background.lightGrey}
      height="100%"
    >
      <Box
        bgcolor={(theme) => theme.customColors.background.grey}
        color={(theme) => theme.customColors.text.plain}
        py={0.5}
        px={1.5}
        borderRadius={50}
        width="fit-content"
        fontSize={{ xs: 14, lg: 16 }}
      >
        {title}
      </Box>
      <Box
        mt={3.25}
        display="flex"
        justifyContent="space-between"
        alignItems="end"
        lineHeight={1}
      >
        <Box
          fontSize={{ xs: 28, lg: 36 }}
          fontWeight={800}
          color="primary.main"
          whiteSpace="nowrap"
          mr={1}
          flexGrow={1}
        >
          {isLoading ? (
            <Skeleton width="60%" />
          ) : (
            <>
              {value || 0}
              {unit && (
                <Box component="span" fontSize={{ xs: 24, lg: 28 }}>
                  {unit}
                </Box>
              )}
            </>
          )}
        </Box>
        <Trend percentage={growth || null} />
      </Box>
      <Box mt={3.5}>
        <Box
          fontSize={{ xs: 12, lg: 14 }}
          color={(theme) => theme.customColors.text.plain}
        >
          {title2}
        </Box>
        <Box
          mt={1.5}
          display="flex"
          justifyContent="space-between"
          alignItems="end"
          lineHeight={1}
        >
          <Box
            fontSize={{ xs: 22, lg: 28 }}
            fontWeight={800}
            color={(theme) => theme.customColors.text.plain}
            flexGrow={1}
          >
            {isLoading ? (
              <Skeleton width="50%" />
            ) : (
              <>
                {value2 || 0}
                {unit2 && (
                  <Box component="span" fontSize={{ xs: 18, lg: 22 }}>
                    {unit2}
                  </Box>
                )}
              </>
            )}
          </Box>
          <Trend percentage={growth2 || null} />
        </Box>
      </Box>
    </Box>
  );
};

export default Highlights;
