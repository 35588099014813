import {
  Battery5Bar,
  DeviceThermostatOutlined,
  LocalGasStationOutlined,
  LockOpenOutlined,
  LockOutlined,
} from "@mui/icons-material";
import { Box, Paper, Skeleton, alpha } from "@mui/material";
import Mileage from "assets/images/icons/Icon.svg";
import Odometer from "assets/images/icons/Odometer.svg";
import BIIcon from "assets/images/icons/battery-index.svg";
import CO2Icon from "assets/images/icons/co2icon.svg";
import cycleIcon from "assets/images/icons/cycle.svg";
import RangeIcon from "assets/images/icons/range-icon.svg";
import SOCIcon from "assets/images/icons/soc-icon.svg";

import moment from "moment";
// import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { GlobalState, authorizedFetch } from "utils";
import { LEASE_URL, RETAIL_URL, TRIPS_URL } from "utils/constants";

// const Loading = () => (
//   <Box>
//     <Skeleton width={38} height={38} variant="circular" />
//     <Typography variant="h5" className="value">
//       <Skeleton height={20} width="30%" />
//     </Typography>
//     <Typography variant="body2" className="title">
//       <Skeleton width="60%" />
//     </Typography>
//   </Box>
// );

function formatValue(value: any, decimal = 2) {
  return parseFloat(value || 0).toLocaleString(undefined, {
    maximumFractionDigits: decimal,
  });
}

const Stats = ({
  loading,
  vehicle,
  vehicleSnapshot,
  vehicleLogs,
  activeTime,
  tripData,
  isTripsPage,
}: any) => {
  // let speed = 0,
  //   maxSpeed = 80;

  const config =
    vehicle?.config?.vehicleConfigValues ||
    vehicle?.model?.config?.configs ||
    {};

  // let { wheelDiameter } =
  //   vehicle?.config?.vehicleConfigValues ||
  //   vehicle?.model?.config?.configs ||
  //   {};

  // const hasWheelRpm = ![null, undefined].includes(
  //   vehicleLogs?.can
  //     ? vehicleLogs?.can[0]?.wheelRpm
  //     : vehicleLogs?.uart?.wheelRpm
  // );
  // const hasWheelDiameter = ![null, undefined].includes(wheelDiameter);

  // if (hasWheelRpm && hasWheelDiameter) {
  //   speed = vehicleLogs?.can
  //     ? vehicleLogs.can[0].wheelRpm / wheelDiameter
  //     : vehicleLogs.uart.wheelRpm / wheelDiameter;
  // } else {
  //   speed = vehicleLogs?.location?.gpsSpeed || 0;
  // }

  // if (speed < 0) speed = 0;

  // maxSpeed = config.maxSpeed;
  // if (maxSpeed && speed >= maxSpeed) {
  //   speed = maxSpeed;
  // }

  // speed = Number(speed.toFixed(0));

  // if (
  //   [batteryMaxVoltage, batteryMinVoltage, voltage].some((el) =>
  //     [null, undefined].includes(el)
  //   )
  // )
  //   battery = "N/A";
  // else
  //   battery =
  //     Number(
  //       ((voltage - batteryMinVoltage) * 100) /
  //         (batteryMaxVoltage - batteryMinVoltage)
  //     ).toFixed(2) + "%";
  const { user } = useSelector((state: GlobalState) => state.global);

  const [tripsPageData, setTripsPageData] = useState<any>({
    voltage: 0,
    soc: 0,
    mileage: 0,
    index: 0,
    equivalentCycles: 0,
    range: 0,
    odometer: 0,
    temperature: 0,
    battery: 0,
    current: 0,
  });

  const companyId = useSelector(
    (state: GlobalState) => state.global.company.id
  );

  const isVeghAccount = companyId === "65646b19a63f9f4a2ea90e63" ? true : false;

  const isSmartVehicle = ["SMART", "SMART_PLUS"].includes(
    vehicle?.model?.protocol
  );

  const emmissionsUrl = `${TRIPS_URL}/emissionAndSavings/vehicle/${vehicle?._id}`;
  const { data: emmissionsData, isLoading: emmissionsLoading } = useQuery(
    ["getEmissions"],
    () => authorizedFetch(emmissionsUrl)
  );

  let battery,
    voltage,
    batteryCurrent,
    soc,
    mileage,
    equivalentCycles,
    range,
    odometer,
    temperature;
  let { batteryMaxVoltage, batteryMinVoltage } = config;

  const fmsTripsUrl = `${LEASE_URL}/company/vehicle/${vehicle?.vin}/trips?first=1&skip=0&heatMap=false`;
  const { isLoading: fmsTripsLoading, data: fmsTripsData } = useQuery(
    ["getFMSOdoTrip"],
    () => authorizedFetch(fmsTripsUrl)
  );

  const vmsTripUrl = `${RETAIL_URL}/trips/${fmsTripsData?.data?.trips[0]?.tripId}?vin=${fmsTripsData?.data?.trips[0]?.vin}`;
  const { isLoading: vmsTripLoading, data: vmsTripData } = useQuery(
    ["getVMSOdoTrip"],
    () => authorizedFetch(vmsTripUrl),
    {
      enabled: !fmsTripsLoading,
    }
  );

  if (isTripsPage) {
    voltage = tripsPageData?.voltage;
    soc = tripsPageData?.soc;
    // mileage = tripsPageData?.mileage;
    mileage = tripData?.mileage;
    battery = tripsPageData?.battery;
    batteryCurrent = tripsPageData?.current;
    equivalentCycles = tripsPageData?.equivalentCycles;
    range = tripsPageData?.range;
    odometer = tripsPageData?.odometer;
    temperature = tripsPageData?.temperature;
  }
  if (!isTripsPage) {
    temperature = isSmartVehicle
      ? vehicleLogs?.can && vehicleLogs?.can?.length !== 0
        ? vehicleLogs?.can[0]?.controllerTemperature
        : vehicleLogs?.uart && vehicleLogs?.uart?.length !== 0
        ? vehicleLogs?.uart[0]?.controllerTemperature
        : "N/A"
      : vehicleLogs?.uart && vehicleLogs?.uart?.length !== 0
      ? vehicleLogs?.uart[0]?.controllerTemperature
      : "N/A";
    voltage =
      vehicleLogs?.can && vehicleLogs?.can?.length !== 0
        ? vehicleLogs?.can[0]?.batteryVoltage
        : vehicleLogs?.uart && vehicleLogs?.uart[0]?.batteryVoltage
        ? vehicleLogs?.uart[0]?.batteryVoltage
        : vehicleLogs?.battery[0]?.batteryVoltageAdc;

    battery =
      vehicleLogs?.can && vehicleLogs?.can?.length !== 0
        ? vehicleLogs?.can[0]?.batterySOC
        : vehicleLogs?.uart && vehicleLogs?.uart[0]?.batterySOC
        ? vehicleLogs?.uart[0]?.batterySOC
        : vehicleLogs?.battery[0]?.batterySOC
        ? vehicleLogs?.battery[0]?.batterySOC
        : "N/A";

    batteryCurrent =
      vehicleLogs?.can && vehicleLogs?.can?.length !== 0
        ? vehicleLogs?.can[0]?.batteryCurrent
        : vehicleLogs?.uart && vehicleLogs?.uart[0]?.batteryCurrent
        ? vehicleLogs?.uart[0]?.batteryCurrent
        : vehicleLogs?.battery[0]?.batteryCurrent;

    const socFormula =
      ((vehicleLogs?.battery[0]?.batteryVoltageAdc - batteryMinVoltage) * 100) /
      (batteryMaxVoltage - batteryMinVoltage);

    soc =
      vehicleLogs?.can && vehicleLogs?.can?.length !== 0
        ? vehicleLogs?.can?.[0]?.batterySOC
        : vehicleLogs?.uart && vehicleLogs?.uart[0]?.batterySOC
        ? vehicleLogs?.uart[0]?.batterySOC
        : vehicleLogs?.battery[0]?.batterySOC
        ? vehicleLogs?.battery[0]?.batterySOC
        : socFormula > 0
        ? socFormula.toFixed(2)
        : voltage
        ? 0
        : "N/A";

    mileage = vmsTripData?.data[0]?.mileage || "N/A";

    equivalentCycles = vehicleLogs?.can?.[0]?.EQCycles || "N/A";

    range = vehicleLogs?.can?.[0]?.rangeLeft || "N/A";

    odometer = tripsPageData?.odometer;
  }

  useEffect(() => {
    if (!tripData) return;

    function findClosest(array: any[]) {
      return array?.length === 0
        ? null
        : array?.reduce((a: any, b: any) => {
            return Math.abs(moment(b.timestamp).valueOf() - activeTime) <
              Math.abs(moment(a.timestamp).valueOf() - activeTime)
              ? b
              : a;
          });
    }

    if (tripData?.can && tripData?.can?.length !== 0) {
      const voltage = Number(
        findClosest(tripData?.can)?.batteryVoltage?.toFixed(2)
      );
      const soc = findClosest(tripData?.can)?.batterySOC || "N/A";
      // const mileage = findClosest(tripData?.can)?.mileage?.toFixed(2);

      const equivalentCycles = findClosest(tripData?.can)?.EQCycles;
      const range = findClosest(tripData?.can)?.rangeLeft;
      const odometer = findClosest(tripData?.can)?.odometer;

      const current = findClosest(tripData?.can)?.batteryCurrent;

      setTripsPageData({
        ...tripsPageData,
        voltage,
        soc,
        current,
        equivalentCycles,
        range,
        odometer,
      });
    } else {
      const voltage = findClosest(
        tripData?.batteryVoltageAdc
      )?.batteryVoltageAdc?.toFixed(2);
      const battery =
        findClosest(tripData?.batteryVoltageAdc)?.batterySOC?.toFixed(2) ||
        "N/A";
      const current = findClosest(
        tripData?.batteryVoltageAdc
      )?.batteryCurrent?.toFixed(2);
      const temperature = findClosest(tripData?.metrics)?.controllerTemperature;
      const socFormula =
        ((findClosest(tripData?.batteryVoltageAdc)?.batteryVoltageAdc -
          batteryMinVoltage) *
          100) /
        (batteryMaxVoltage - batteryMinVoltage);
      const soc = socFormula > 0 ? socFormula.toFixed(2) : 0;

      setTripsPageData({
        ...tripsPageData,
        voltage,
        battery,
        current,
        temperature,
        soc,
      });
    }

    // eslint-disable-next-line
  }, [tripData, activeTime]);

  useEffect(() => {
    if (isTripsPage) return;
    const ignitionOn =
      parseInt(vehicleSnapshot?.ignition?.ignition) === 1 ? true : false;

    if (
      vehicleLogs?.can?.[0]?.odometer <= 5000 ||
      vehicleLogs?.can?.[0]?.odometer === 0 ||
      vehicleLogs?.can?.[0]?.odometer === null
    ) {
      if (ignitionOn) {
        const odometerValues = vehicleLogs?.can?.map(
          (entry: any) => entry?.odometer
        );
        const highestOdometer = Math.max(...odometerValues);
        setTripsPageData({
          ...tripsPageData,
          odometer: highestOdometer,
        });
      } else {
        if (vmsTripLoading) {
          setTripsPageData({
            ...tripsPageData,
            odometer: 0,
          });
        } else {
          const odometerValue = vmsTripData?.data[0]?.can?.find(
            (item: any) => item.odometer > 5000
          );

          setTripsPageData({
            ...tripsPageData,
            odometer: odometerValue?.odometer || 0,
          });
        }
      }
    } else {
      setTripsPageData({
        ...tripsPageData,
        odometer: vehicleLogs?.can?.[0]?.odometer,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    vehicleSnapshot,
    isTripsPage,
    vehicleLogs,

    // fmsTripsData,
    vmsTripData,
    // tripsPageData,
  ]);

  //   if (vehicleLogs?.can && vehicleLogs?.can[0]) {
  //     stats = {
  //       status:
  //       // parseInt(vehicleSnapshot?.ignition?.lock) === 1 ||
  //       parseInt(vehicleSnapshot?.ignition?.ignition) === 1
  //         ? "Unlocked"
  //         : // : parseInt(vehicleSnapshot?.ignition?.lock) === 0 ||
  //         parseInt(vehicleSnapshot?.ignition?.ignition) === 0
  //           ? "Locked"
  //           : "N/A",
  //       mileage: vehicleLogs?.can?.mileage,

  //   }
  //  } else {

  let stats = {
    altitude:
      typeof vehicleLogs?.location?.altitude === "number"
        ? parseInt(vehicleLogs.location.altitude) + "m"
        : "0m",
    temperature,
    status:
      // parseInt(vehicleSnapshot?.ignition?.lock) === 1 ||
      parseInt(vehicleSnapshot?.ignition?.ignition) === 1
        ? "Unlocked"
        : // : parseInt(vehicleSnapshot?.ignition?.lock) === 0 ||
        parseInt(vehicleSnapshot?.ignition?.ignition) === 0
        ? "Locked"
        : "N/A",
    battery,
    voltage,
    batteryCurrent,
    mileage,
    batteryIndex: vehicleLogs?.can?.[0]?.BIN || "N/A",
    soc,
    equivalentCycles,
    range,
    odometer,
    // odometer:
    //   // isSmartVehicle
    //   //   ? vehicleSnapshot?.uart?.odometer
    //   //     ? (parseFloat(vehicleSnapshot.uart.odometer) / 1000).toFixed(2)
    //   //     : "N/A"
    //   //   :
    //   vehicle.metrics?.odometer
    //     ? (parseFloat(vehicle.metrics.odometer) / 1000).toFixed(2)
    //     : "N/A",
  };

  return (
    <Box
      sx={{
        width: 1,
        // display: "grid",
        // gridTemplateColumns: "3fr 2fr",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr 1fr",
            lg: "repeat(4, 1fr)",
          },
          gap: { xs: 1.75, md: 4 },
          gridColumn: "1 / -1",
          "& .card": {
            height: "auto !important",
            width: 1,
            // borderRadius: 0,
            py: { xs: 1.25, md: 2 },
            px: { xs: 1.25, md: 3 },
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "& .icon": {
              borderRadius: 50,
              height: { xs: 32, md: 72, xl: 98 },
              width: { xs: 32, md: 72, xl: 98 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.2),
              color: (theme) => theme.palette.primary.main,
              fontSize: { xs: 18, md: 32, xl: 44 },
            },
            "& .description": {
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              "& .label": {
                mb: { xs: 1, md: 1.5 },
                textAlign: "end",
                fontSize: { xs: "12px", md: 16, xl: 18 },
                fontWeight: 600,
                lineHeight: { xs: "16px", md: "24.55px" },
                color: (theme) => theme.customColors.text.grey,
              },
              "& .value": {
                textAlign: "end",
                fontSize: { xs: "16px", md: "24px", xl: "32px" },
                fontWeight: 700,
                lineHeight: { xs: "19.1px", md: "44px" },
                letterSpacing: "0em",
              },
              "& .greyed": {
                color: (theme) => theme.palette.text.disabled,
                userSelect: "none",
              },
            },
          },
        }}
      >
        {!isVeghAccount && isSmartVehicle ? (
          <Paper className="card">
            <Box className="icon">
              <DeviceThermostatOutlined fontSize="inherit" />
            </Box>
            <Box className="description">
              <span className="label">Temperature</span>
              <span
                className={`value${
                  stats?.temperature === "N/A" ? " greyed" : ""
                }`}
              >
                {loading ? (
                  <Skeleton width={60} />
                ) : stats?.temperature === "N/A" ? (
                  "N/A"
                ) : (
                  `${stats.temperature} °C`
                )}
              </span>
            </Box>
          </Paper>
        ) : null}

        <Paper className="card">
          <Box className="icon">
            {stats.status === "Unlocked" ? (
              <LockOpenOutlined fontSize="inherit" />
            ) : (
              <LockOutlined fontSize="inherit" />
            )}
          </Box>
          <Box className="description">
            <span className="label">Status</span>
            <span className={`value${stats.status === "N/A" ? " greyed" : ""}`}>
              {loading ? <Skeleton width={60} /> : stats.status}
            </span>
          </Box>
        </Paper>
        {isVeghAccount ? (
          <Paper className="card">
            <Box className="icon">
              {/* <DeviceThermostatOutlined fontSize="inherit" /> */}
              <img src={SOCIcon} alt="soc icon" style={{ width: 36 }}></img>
            </Box>
            <Box className="description">
              <span className="label">State of Charge (SOC)</span>
              <span className={`value${stats?.soc === "N/A" ? " greyed" : ""}`}>
                {vehicleLogs === null ? (
                  <Skeleton width={60} />
                ) : stats?.soc === "N/A" ? (
                  "N/A"
                ) : (
                  stats.soc + "%"
                )}
              </span>
            </Box>
          </Paper>
        ) : vehicleLogs?.can && vehicleLogs?.can[0] ? (
          <Paper className="card">
            <Box className="icon">
              {/* <DeviceThermostatOutlined fontSize="inherit" /> */}
              <img src={SOCIcon} alt="soc icon" style={{ width: 36 }}></img>
            </Box>
            <Box className="description">
              <span className="label">State of Charge (SOC)</span>
              <span className={`value${stats?.soc === "N/A" ? " greyed" : ""}`}>
                {vehicleLogs === null ? (
                  <Skeleton width={60} />
                ) : stats?.soc === "N/A" ? (
                  "N/A"
                ) : (
                  stats.soc + "%"
                )}
              </span>
            </Box>
          </Paper>
        ) : (
          ""
        )}
        <Paper className="card">
          <Box className="icon">
            <Battery5Bar fontSize="inherit" />
          </Box>
          <Box className="description">
            <span className="label">Voltage</span>
            <span
              className={`value${
                stats?.voltage === undefined || stats?.voltage === null
                  ? " greyed"
                  : ""
              }`}
            >
              {vehicleLogs === null ? (
                <Skeleton width={60} />
              ) : stats?.voltage === undefined || stats?.voltage === null ? (
                "N/A"
              ) : (
                formatValue(stats.voltage) + " V"
              )}
            </span>
          </Box>
        </Paper>
        {!isVeghAccount && vehicleLogs?.can && vehicleLogs?.can[0] ? (
          <Paper className="card">
            <Box className="icon">
              {/* <DeviceThermostatOutlined fontSize="inherit" /> */}
              <img src={Mileage} alt="mileage icon" style={{ width: 36 }}></img>
            </Box>
            <Box className="description">
              <span className="label">Mileage</span>
              <span
                className={`value${stats?.mileage === "N/A" ? " greyed" : ""}`}
              >
                {vehicleLogs === null ? (
                  <Skeleton width={60} />
                ) : stats?.mileage === "N/A" ? (
                  "N/A"
                ) : (
                  formatValue(stats.mileage) + " km"
                )}
              </span>
            </Box>
          </Paper>
        ) : (
          ""
        )}
        {!isVeghAccount && vehicleLogs?.can && vehicleLogs?.can[0] ? (
          <Paper className="card">
            <Box className="icon">
              {/* <DeviceThermostatOutlined fontSize="inherit" /> */}
              <img src={BIIcon} alt="BI icon" style={{ width: 36 }}></img>
            </Box>
            <Box className="description">
              <span className="label">Battery Index</span>
              <span
                className={`value${
                  stats?.batteryIndex === "N/A" ? " greyed" : ""
                }`}
              >
                {vehicleLogs === null ? (
                  <Skeleton width={60} />
                ) : stats?.batteryIndex === "N/A" ? (
                  "N/A"
                ) : (
                  stats.batteryIndex
                )}
              </span>
            </Box>
          </Paper>
        ) : (
          ""
        )}
        {!isVeghAccount && vehicleLogs?.can && vehicleLogs?.can[0] ? (
          <Paper className="card">
            <Box className="icon">
              {/* <DeviceThermostatOutlined fontSize="inherit" /> */}
              <img src={cycleIcon} alt="BI icon" style={{ width: 36 }}></img>
            </Box>
            <Box className="description">
              <span className="label">Equivalent Cycles</span>
              <span
                className={`value${
                  stats?.equivalentCycles === "N/A" ? " greyed" : ""
                }`}
              >
                {vehicleLogs === null ? (
                  <Skeleton width={60} />
                ) : stats?.equivalentCycles === "N/A" ? (
                  "N/A"
                ) : (
                  formatValue(stats.equivalentCycles)
                )}
              </span>
            </Box>
          </Paper>
        ) : (
          ""
        )}
        {!isVeghAccount && vehicleLogs?.can && vehicleLogs?.can[0] ? (
          <Paper className="card">
            <Box className="icon">
              {/* <DeviceThermostatOutlined fontSize="inherit" /> */}
              <img src={RangeIcon} alt="range icon" style={{ width: 36 }}></img>
            </Box>
            <Box className="description">
              <span className="label">Range</span>
              <span
                className={`value${stats?.range === "N/A" ? " greyed" : ""}`}
              >
                {vehicleLogs === null ? (
                  <Skeleton width={60} />
                ) : stats?.range === "N/A" ? (
                  "N/A"
                ) : (
                  formatValue(stats.range) + " km"
                )}
              </span>
            </Box>
          </Paper>
        ) : (
          ""
        )}
        {!isVeghAccount && vehicleLogs?.can && vehicleLogs?.can[0] ? (
          <Paper className="card">
            <Box className="icon">
              {/* <DeviceThermostatOutlined fontSize="inherit" /> */}
              <img src={Odometer} alt="range icon" style={{ width: 36 }}></img>
            </Box>
            <Box className="description">
              <span className="label">Cumulative Odometer</span>
              <span
                className={`value${stats?.odometer === "N/A" ? " greyed" : ""}`}
              >
                {vehicleLogs === null || vmsTripLoading || fmsTripsLoading ? (
                  <Skeleton width={60} />
                ) : stats?.odometer === "N/A" ? (
                  "N/A"
                ) : (
                  Number(stats.odometer) / 1000 + " km"
                )}
              </span>
            </Box>
          </Paper>
        ) : (
          ""
        )}
        {isVeghAccount ? (
          <Paper className="card">
            <Box className="icon">
              <Battery5Bar fontSize="inherit" />
            </Box>
            <Box className="description">
              <span className="label">Current</span>
              <span
                className={`value${
                  stats.batteryCurrent === undefined ||
                  stats.batteryCurrent === null ||
                  user?._id === "649bc26a02dfe12cb2ef1d27"
                    ? " greyed"
                    : ""
                }`}
              >
                {vehicleLogs === null ? (
                  <Skeleton width={60} />
                ) : user?._id === "649bc26a02dfe12cb2ef1d27" ? (
                  "N/A"
                ) : stats.batteryCurrent === undefined ||
                  stats.batteryCurrent === null ? (
                  "N/A"
                ) : (
                  formatValue(stats.batteryCurrent) + " A"
                )}
              </span>
            </Box>
          </Paper>
        ) : vehicleLogs?.can && vehicleLogs?.can[0] ? (
          ""
        ) : (
          <>
            <Paper className="card">
              <Box className="icon">
                {/* <DeviceThermostatOutlined fontSize="inherit" /> */}
                <img src={SOCIcon} alt="soc icon" style={{ width: 36 }}></img>
              </Box>
              <Box className="description">
                <span className="label">State of Charge (SOC)</span>
                <span
                  className={`value${stats?.soc === "N/A" ? " greyed" : ""}`}
                >
                  {vehicleLogs === null ? (
                    <Skeleton width={60} />
                  ) : stats?.soc === "N/A" ? (
                    "N/A"
                  ) : (
                    formatValue(stats.soc) + " %"
                  )}
                </span>
              </Box>
            </Paper>
            <Paper className="card">
              <Box className="icon">
                <Battery5Bar fontSize="inherit" />
              </Box>
              <Box className="description">
                <span className="label">Current</span>
                <span
                  className={`value${
                    stats.batteryCurrent === undefined ||
                    stats.batteryCurrent === null ||
                    user?._id === "649bc26a02dfe12cb2ef1d27"
                      ? " greyed"
                      : ""
                  }`}
                >
                  {vehicleLogs === null ? (
                    <Skeleton width={60} />
                  ) : user?._id === "649bc26a02dfe12cb2ef1d27" ? (
                    "N/A"
                  ) : stats.batteryCurrent === undefined ||
                    stats.batteryCurrent === null ? (
                    "N/A"
                  ) : (
                    formatValue(stats.batteryCurrent) + " A"
                  )}
                </span>
              </Box>
            </Paper>
          </>
        )}

        {!isVeghAccount &&
        isTripsPage &&
        tripData?.can &&
        tripData?.can?.length !== 0 ? (
          <Paper className="card">
            <Box className="icon">
              {/* <DeviceThermostatOutlined fontSize="inherit" /> */}
              <img src={SOCIcon} alt="soc icon" style={{ width: 36 }}></img>
            </Box>
            <Box className="description">
              <span className="label">Efficiency</span>
              <span
                className={`value${
                  tripData?.efficiency === "N/A" ? " greyed" : ""
                }`}
              >
                {vehicleLogs === null ? (
                  <Skeleton width={60} />
                ) : tripData?.efficiency ? (
                  formatValue(tripData?.efficiency) + " Wh/km"
                ) : tripData?.efficiency === 0 ? (
                  formatValue(tripData?.efficiency) + " Wh/km"
                ) : (
                  "NA"
                )}
              </span>
            </Box>
          </Paper>
        ) : (
          ""
        )}

        {!isVeghAccount &&
        vehicle?.model?.protocol === "PNP" &&
        (companyId === "63a41162d4423ec4f741df0d" ||
          companyId === "5cfa443da7b11b00073f9657") ? (
          <>
            <Paper className="card">
              <Box className="icon">
                <img src={CO2Icon} alt="co2 icon" style={{ width: 36 }}></img>
                {/* <Co2Outlined fontSize="inherit" /> */}
              </Box>
              <Box className="description">
                <span className="label">CO2 Emission Saved</span>
                <span
                  className={`value${
                    emmissionsData?.data?.co2Emission ? "" : " greyed"
                  }`}
                >
                  {emmissionsLoading ? (
                    <Skeleton width={60} />
                  ) : emmissionsData?.data?.co2Emission ? (
                    emmissionsData?.data?.co2Emission.toFixed(2) + "Kg"
                  ) : (
                    "N/A"
                  )}
                </span>
              </Box>
            </Paper>
            <Paper className="card">
              <Box className="icon">
                <LocalGasStationOutlined fontSize="inherit" />
              </Box>
              <Box className="description">
                <span className="label">Fuel Saved</span>
                <span
                  className={`value${
                    emmissionsData?.data?.fuelSaved ? "" : " greyed"
                  }`}
                >
                  {emmissionsLoading ? (
                    <Skeleton width={60} />
                  ) : emmissionsData?.data?.fuelSaved ? (
                    emmissionsData?.data?.fuelSaved.toFixed(2) + "L"
                  ) : (
                    "N/A"
                  )}
                </span>
              </Box>
            </Paper>
          </>
        ) : null}
      </Box>
      {/* <Paper
        sx={{
          // p: 1.5,
          display: "grid",
          gap: 1.5,
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          boxShadow: "none",
          border: 0,
          "& > div": {
            p: 2,
            pb: 1,
            border: 1,

            boxShadow: (theme) => theme.customShadows.small,

            borderColor: (theme) => theme.customColors.border,
            borderRadius: 1,
            "& .icon": {
              width: 38,
              height: 38,
              borderRadius: 50,
              mb: 2.5,
            },
            "& .value": {
              fontWeight: 700,
              fontSize: 24,
              color: "text.primary",
              lineHeight: "1em",
              mb: 1,
              mt: 2,
            },
            "& .greyed": {
              color: (theme) => theme.palette.text.disabled,
              userSelect: "none",
            },
            "& .title": {
              fontSize: 14,
              lineHeight: "18px",
              color: "text.secondary",
            },
          },
        }}
      >
        {loading ? (
          <Loading />
        ) : (
          <Box>
            <Avatar variant="icon" className="icon">
              <Height />
            </Avatar>
            <Typography
              className={`value${stats.altitude === "N/A" ? " greyed" : ""}`}
            >
              {stats.altitude}
            </Typography>
            <Typography className="title">Altitude</Typography>
          </Box>
        )}

        {isSmartVehicle ? (
          loading ? (
            <Loading />
          ) : (
            <Box>
              <Avatar variant="icon" className="icon">
                <DeviceThermostatOutlined />
              </Avatar>
              <Typography
                className={`value${
                  stats.temperature === "N/A" ? " greyed" : ""
                }`}
              >
                {stats.temperature}
                {stats.temperature !== "N/A" && <sup>°C</sup>}
              </Typography>
              <Typography className="title">Temperature</Typography>
            </Box>
          )
        ) : null}
        {loading ? (
          <Loading />
        ) : (
          <Box>
            <Avatar variant="icon" className="icon">
              {stats.status === "Unlocked" ? (
                <LockOpenOutlined />
              ) : (
                <LockOutlined />
              )}
            </Avatar>
            <Typography
              className={`value${stats.status === "N/A" ? " greyed" : ""}`}
            >
              {stats.status}
            </Typography>
            <Typography className="title">Status</Typography>
          </Box>
        )}
        {loading ? (
          <Loading />
        ) : (
          <Box>
            <Avatar variant="icon" className="icon">
              <Battery5Bar />
            </Avatar>
            <Typography
              className={`value${stats.battery === "N/A" ? " greyed" : ""}`}
            >
              {Number(stats.battery.slice(0, -1)) > 100
                ? "100%"
                : Number(stats.battery.slice(0, -1)) < 0
                ? "0%"
                : stats.battery}
            </Typography>
            <Typography className="title">Battery</Typography>
          </Box>
        )}

        {vehicle?.model?.protocol === "PNP" ? (
          <>
            {emmissionsLoading ? (
              <Loading />
            ) : (
              <Box>
                <Avatar variant="icon" className="icon">
                  <Co2Outlined />
                </Avatar>
                <Typography
                  className={`value${stats.battery === "N/A" ? " greyed" : ""}`}
                >
                  {emmissionsData?.data?.co2Emission
                    ? emmissionsData?.data?.co2Emission.toFixed(2) + "Kg"
                    : "N/A"}
                </Typography>
                <Typography className="title">CO2 Emission Saved</Typography>
              </Box>
            )}

            {emmissionsLoading ? (
              <Loading />
            ) : (
              <Box>
                <Avatar variant="icon" className="icon">
                  <LocalGasStationOutlined />
                </Avatar>
                <Typography
                  className={`value${stats.battery === "N/A" ? " greyed" : ""}`}
                >
                  {emmissionsData?.data?.fuelSaved
                    ? emmissionsData?.data?.fuelSaved.toFixed(2) + "L"
                    : "N/A"}
                </Typography>
                <Typography className="title">Fuel Saved</Typography>
              </Box>
            )}
          </>
        ) : (
          ""
        )}
      </Paper> */}
      {/* <Paper>
        <Box height={150}>
          <GaugeChart
            nrOfLevels={13}
            percent={speed / (maxSpeed || 80)}
            animate={false}
            hideText
            arcPadding={0.12}
            cornerRadius={0}
            arcWidth={0.15}
            needleBaseColor={theme.customColors.green}
            needleColor={theme.customColors.green}
            colors={[theme.customColors.green + "20", theme.customColors.green]}
            style={{
              height: 150,
              width: "100%",
              margin: "28px auto 0",
            }}
          />
        </Box>
        <Box
          sx={{
            margin: "0 auto",
            width: 1,
            maxWidth: "65%",
            display: "flex",
            justifyContent: "space-between",
            transform: "translateY(-16px) translateX(4px)",
          }}
        >
          <Typography>0</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={700}>{speed}</Typography>
            <Typography fontSize={14}>km/h</Typography>
          </Box>
          <Typography>{maxSpeed || 80}</Typography>
        </Box>
        <Box sx={{ mt: -1.5, textAlign: "center" }}>
          <Typography
            sx={{
              color: "text.secondary",
              lineHeight: "0.9em",
            }}
            variant="overline"
          >
            Speedometer
          </Typography>
        </Box>
        <Box
          sx={{
            m: 1.5,
            bgcolor: (theme) => theme.customColors.header,
            borderRadius: 1,
            p: 2.5,
            "& .value": {
              fontSize: 24,
              fontWeight: 700,
              lineHeight: "1em",
              color: "text.primary",
              "& span": {
                fontSize: "0.7em",
                fontWeight: 400,
                color: "text.primary",
              },
            },
            "& .greyed": {
              color: (theme) => theme.palette.text.disabled,
              userSelect: "none",
            },
          }}
        >
          <Typography
            mb={1}
            className={`value${stats.odometer === "N/A" ? " greyed" : ""}`}
          >
            {stats.odometer}
            {stats.odometer !== "N/A" && <span> km</span>}
          </Typography>
          <Typography
            sx={{ color: "text.secondary", lineHeight: "0.9em" }}
            variant="overline"
          >
            Odometer
          </Typography>
        </Box>
      </Paper> */}
    </Box>
  );
};

export default Stats;
