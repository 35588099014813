import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { queryClient } from "index";
import { useMutation } from "react-query";

import { snackbar, setLoader, authorizedFetch } from "utils";
import { BOLT_URL } from "utils/constants";

const UnAssignChargers = ({ open, handleClose, vendor, selected }: any) => {
  const mutation = useMutation(
    ["unassignChargers", vendor?._id],
    () =>
      authorizedFetch(
        `${BOLT_URL}/company/vendor/${vendor?._id}/unassignChargers`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            stage: "prod",
          },
          body: {
            chargerIds: selected,
          },
        }
      ),
    {
      onSuccess: () => {
        setLoader(false);
        snackbar.success(`Chargers unassigned`);
        [["getVendorChargers", vendor?._id], "getVendors"].forEach((key) => {
          queryClient.resetQueries(key, { exact: true });
        });
        handleClose();
      },
      onError: () => {
        setLoader(false);
        snackbar.error(`Error unassigning chargers`);
      },
    }
  );
  function confirm() {
    setLoader(true);
    mutation.mutate();
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Unassign Chargers</DialogTitle>
      <DialogContent className="py-1">
        Unassign selected chargers?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={confirm}
        >
          Unassign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnAssignChargers;
