import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { queryClient } from "index";
import { useEffect, useState } from "react";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { BOLT_URL } from "utils/constants";

const SettlementDialog = ({
  open,
  handleClose,
  data,
  setSelectedRows,
  forHost = false,
}: any) => {
  const [frequency, setFrequency] = useState<string | null>(null);

  const selectedChargers = forHost ? data : [];
  const s = selectedChargers?.length > 1 ? "s" : "";

  const hostName =
    `${data?.firstName || ""} ${data?.lastName || ""}`.trim() || "Host";

  useEffect(() => {
    if (!open) return;
    setFrequency(null);
  }, [open]);

  function handleSave() {
    setLoader(true);

    authorizedFetch(`${BOLT_URL}/company/chargers/settlementcycle/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        chargerIds: selectedChargers,
        settlementCycle: frequency,
      },
    }).then((res) => {
      setLoader(false);
      if (res.status === 200) {
        snackbar.success(
          forHost
            ? "Settlement cycle changes saved"
            : `Settlement cycle changes saved for ${selectedChargers.length} selected charger${s}`,
        );
        setSelectedRows([]);
        handleClose();
        queryClient.resetQueries("getCharger");
        // queryClient.resetQueries("getChargers");
      } else {
        snackbar.error("An error occurred");
      }
    });
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        Edit Settlement Cycle
        <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
        <Typography sx={{ color: (theme) => theme.palette.text.secondary }}>
          {forHost
            ? `${hostName}`
            : `${selectedChargers?.length} charger${s} selected`}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <FormControl sx={{ mt: 3, mb: 4 }}>
          <Typography color="text.secondary">Frequency</Typography>
          <RadioGroup
            row
            value={frequency}
            onChange={(e) => setFrequency(e.target.value)}
          >
            {["Biweekly", "Weekly", "Monthly"].map((el, i) => (
              <FormControlLabel
                key={i}
                value={el.toUpperCase()}
                control={<Radio />}
                label={el}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Alert severity="info">
          Changes will take effect in the next settlement cycle.
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave} disabled={!frequency}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SettlementDialog;
