import { FilterAltOutlined } from "@mui/icons-material";
import { Box, Hidden, IconButton, Typography } from "@mui/material";
import RangeSelector from "components/RangeSelector";
import { useState } from "react";
import FiltersDrawer from "./FiltersDrawer";

const AppBar = ({ filters, setFilters, filterLabels }: any) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Box
      sx={{
        width: 1,
        py: 3,
        px: { xs: 2, md: 7 },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h2">Overview</Typography>
      <Hidden implementation="css" mdUp>
        <IconButton onClick={() => setDrawerOpen(true)}>
          {/* <Badge
            color="primary"
            variant="dot"
            invisible={
              ![filters.access, filters.health, filters.locations].some(
                (el) => el.length > 0
              )
            }
          > */}
          <FilterAltOutlined />
          {/* </Badge> */}
        </IconButton>
        <FiltersDrawer
          {...{
            open: drawerOpen,
            onClose: () => setDrawerOpen(false),
            filters,
            setFilters,
            filterLabels,
          }}
        />
      </Hidden>
      <Hidden implementation="css" mdDown>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-end",
            alignItems: "center",
            "& > :not(:first-of-type)": {
              ml: 3,
            },
          }}
        >
          <RangeSelector
            setRange={(range: any) => {
              setFilters((prev: any) => ({
                ...prev,
                time: range,
              }));
            }}
          />
        </Box>
      </Hidden>
    </Box>
  );
};

export default AppBar;
