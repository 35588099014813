import { Box, Tooltip } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "utils";
import Cards from "./Cards";
import FiltersBar from "./Filters";
import Highlights from "./Highlights";
import { InfoOutlined } from "@mui/icons-material";

// prettier-ignore
export const filterOptions = {
  vehicleType: [
    { label: "All Vehicles", value: "all", presentationOnly: true },
    { label: "2 Wheeler", value: "TWO_WHEELER" },
    { label: "3 Wheeler", value: "THREE_WHEELER" },
    { label: "4 Wheeler", value: "FOUR_WHEELER" },
  ],
  chargerType: [
    { label: "All Chargers", value: "all", presentationOnly: true },
    { label: "Level 1", value: "header-level-1", type: "header", presentationOnly: true },
    { label: "Bolt.Earth 16A Lite", value: "BOLT_16A_LITE", parent: "header-level-1" },
    { label: "Bolt.Earth 16A SIM", value: "BOLT_16A_SIM", parent: "header-level-1" },
    { label: "Bolt.Earth 16A WIFI", value: "BOLT_16A_WIFI", parent: "header-level-1" },
    { label: "Bolt.Earth Lite BLE", value: "BOLT_LITE_BLE", parent: "header-level-1" },
    { label: "Bolt.Earth Lite SIM", value: "BOLT_LITE_SIM", parent: "header-level-1" },
    { label: "Bolt.Earth Lite WIFI", value: "BOLT_LITE_WIFI", parent: "header-level-1" },
    { label: "OCPP Level 1", value: "OCPP_LEVEL_1", parent: "header-level-1" },
    { label: "OCPP 2WFC 1 3KW TYPE6 WIFI", value: "OCPP_2WFC_1_3KW_TYPE6_WIFI", parent: "header-level-1" },
    { label: "OCPP 2WFC 1 3KW TYPE7 WIFI", value: "OCPP_2WFC_1_3KW_TYPE7_WIFI", parent: "header-level-1" },
    { label: "LEVAC BLE Level 1", value: "LEVAC_BLE_LEVEL_1", parent: "header-level-1" },
    { label: "OCPP Level 2", value: "OCPP_LEVEL_2" },
    { label: "OCPP Level 3", value: "OCPP_LEVEL_3" },

    // { label: "Bolt.Earth Lite", value: "BOLT_LITE" },
    // { label: "Bolt.Earth Pro", value: "BOLT_PRO" },
    // { label: "Bolt.Earth Levac", value: "LEVAC_BLE_LEVEL_1" },
    // { label: "Bolt.Earth V2", value: "BOLT_V2" },
    // { label: "Bolt.Earth Level 2", value: "BOLT_LEVEL_2" },
    // { label: "Bolt.Earth Level 3", value: "BOLT_LEVEL_3" },
  ],
  // vendor: [
  //   { label: "All Vendors", value: "all" },
  //   // Fetch vendors from API
  // ],
  locations: [], // Fetch from API
  accessType: [
    { label: "All Access Types", value: "all", presentationOnly: true },
    { label: "Public Free", value: "PUBLIC_FREE" },
    { label: "Public Paid", value: "PUBLIC_PAID" },
    { label: "Private", value: "PRIVATE" },
    { label: "Restricted Free", value: "RESTRICTED_FREE" },
    { label: "Restricted Paid", value: "RESTRICTED_PAID" },
  ],
};

export const filterValues = {
  vehicleType: [
    ...filterOptions.vehicleType
      .filter((el) => !el.presentationOnly)
      .map((el: any) => el.value),
  ],
  chargerType: [
    ...filterOptions.chargerType
      .filter((el) => !el.presentationOnly)
      .map((el: any) => el.value),
  ],
  accessType: [
    ...filterOptions.accessType
      .filter((el) => !el.presentationOnly)
      .map((el: any) => el.value),
  ],
};

export const filtersObj = {
  vehicleType: filterValues.vehicleType,
  chargerType: filterValues.chargerType,
  accessType: filterValues.accessType,
  locations: [],
  time: [moment().subtract({ days: 30 }), moment()],
};

const Overview = () => {
  const { masterView } = useSelector((state: GlobalState) => state.global);

  const [filters, setFilters] = useState<any>({ ...filtersObj });
  const [dateRangeInWords, setDateRangeInWords] = useState<any>("Last 30 days");

  const startTime = filters.time[0].toISOString();
  const endTime = filters.time[1].toISOString();

  const vehicleType = filters.vehicleType.length
    ? filters.vehicleType.map((el: string) => `&vehicle_type=${el}`).join("")
    : "";

  const chargerType = filters.chargerType.length
    ? filters.chargerType.map((el: string) => `&charger_type=${el}`).join("")
    : "";

  const accessType = filters.accessType.length
    ? filters.accessType.map((el: string) => `&access_type=${el}`).join("")
    : "";

  const city = filters.locations.length
    ? filters.locations.map((el: string) => `&city=${el}`).join("")
    : "";

  const params = {
    startTime,
    endTime,
    vehicleType,
    chargerType,
    accessType,
    city,
    masterView,
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          px: { xs: 2, md: 4 },
          pt: { xs: 2, md: 4 },
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            mt: { xs: -2, md: -4 },
            mx: { xs: -2, md: -4 },
            display: "flex",
            justifyContent: "space-between",
            px: { xs: 2, md: 4 },
            py: { xs: 2, md: 3 },
            bgcolor: (theme) => theme.palette.background.paper,
            boxShadow: (theme) => theme.customShadows.medium,
            zIndex: 8,
          }}
        >
          <Box
            sx={{
              // position: "absolute",
              // top: { xs: 22, md: 34 },
              fontSize: { xs: 18, sm: 20, md: 24 },
              color: (theme) => theme.customColors.text.plain,
              fontWeight: 700,
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>Overview</span>
            <Tooltip
              title="The highlights shown below are updated every 4 hours"
              placement="top"
              arrow
            >
              <InfoOutlined
                sx={{
                  ml: 1,
                  fontSize: { xs: 16, lg: 20 },
                  color: (theme) => theme.customColors.action,
                }}
              />
            </Tooltip>
          </Box>
          <FiltersBar
            {...{
              filters,
              setFilters,
              filterOptions,
              setDateRangeInWords,
            }}
          />
        </Box>
        <Highlights params={params} dateRangeInWords={dateRangeInWords} />
        <Cards params={params} />
      </Box>
    </>
  );
};

export default Overview;
