import { Avatar, Box, Hidden, IconButton, Paper } from "@mui/material";
import Search from "components/Search";
import Table from "components/Table";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { authorizedFetch, titleCase, drawer, appBar, snackbar } from "utils";
// import { LEASE_URL } from "utils/constants";
import DrawerContent from "./DrawerContent";
import AppBar from "./AppBar";
import LocationMap from "./LocationMap";
import { InfoOutlined } from "@mui/icons-material";
import AddHubsDialog from "./AddHubsDialog";
import { LEASE_URL } from "utils/constants";

const Hubs = () => {
  const [tab, setTab] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [createDialog, setCreateDialog] = useState(false);

  const [location, setLocation] = useState<any>({
    loaded: false,
    coordinates: { lat: null, lng: null },
    isFirstTime: false,
  });

  const [markerPosition, setMarkerPosition] = useState<{
    lat: number | null;
    lng: number | null;
  }>({ lat: 12.9121181, lng: 77.6445548 });

  const { isFirstTime } = location;
  const { lat, lng } = location.coordinates;

  const url = `${LEASE_URL}/company/hubs/all?first=${pageSize}&skip=${
    pageSize * (page - 1)
  }${
    tab === 0
      ? "&status=ACTIVE"
      : tab === 1
      ? "&status=INACTIVE"
      : "&status=DELETED"
  }${search ? `&search=${search}` : ""}&lat=${lat}&long=${lng}`;
  const {
    isLoading: hubsLoading,
    data: hubsData,
    refetch: refetchAllHubs,
  } = useQuery(
    ["getAllHubs", page, pageSize, tab, search, location],
    () => authorizedFetch(url),
    { enabled: location.loaded }
  );

  const onSuccess = (location: any) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },

      isFirstTime: true,
    });
    // setMarkerPosition({
    //   lat: location.coords.latitude,
    //   lng: location.coords.longitude,
    // });
  };

  const onError = () => {
    setLocation({
      loaded: true,
      coordinates: { lat: 12.9121181, lng: 77.6445548 },
      isFirstTime: true,
    });
    // setMarkerPosition({ lat: 12.9121181, lng: 77.6445548 });
  };

  useEffect(() => {
    if (!("geolocation" in navigator)) {
      snackbar.error("Geolocation is not supported by your browser");
    }
    const defaultLocation = "Default location set to HSR, Bangalore.";
    navigator.geolocation.getCurrentPosition(onSuccess, (error) => {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          snackbar.error(
            `Location permission is necessary to see hubs near you. ${defaultLocation}`
          );
          onError();
          break;
        case error.POSITION_UNAVAILABLE:
          snackbar.error(
            `Location information is unavailable. ${defaultLocation}`
          );
          onError();
          break;
        case error.TIMEOUT:
          snackbar.error(
            `The request to get user location timed out. ${defaultLocation}`
          );
          onError();
          break;
        default:
          snackbar.error(`Error fetching location. ${defaultLocation}`);
          onError();
          break;
      }
    });
  }, []);

  useEffect(() => {
    appBar.open(
      <AppBar
        {...{
          hubsData,
          tab,
          hubsLoading,
          page,
          pageSize,
          search,
          setTab,
          lat,
          lng,
          setLocation,
          location,
          isFirstTime,
          setCreateDialog,
        }}
      />
    );
    return () => {
      appBar.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubsData, tab, page, pageSize, search]);

  return (
    <>
      <Paper
        sx={{
          width: 1,
          boxShadow: (theme) => theme.customShadows.small,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            width: 1,
            p: 3,
            // pb: 2.75,
            // mb: 3,
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
          }}
        >
          <Box display="flex">
            <Hidden implementation="css" mdDown>
              <Box>
                <Search
                  handleSearch={(value) => {
                    setSearch(value);
                  }}
                  persist
                  enableClear
                  placeholder="Search by Name..."
                />
              </Box>
            </Hidden>
          </Box>
        </Box>
        <Table
          loading={hubsLoading || !location.loaded}
          rows={hubsData?.data?.documents || []}
          rowCount={hubsData?.data?.count?.totalCount || 0}
          serverSidePagination
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          columns={[
            {
              key: "name",
              label: "Name",
            },

            {
              key: "location",
              label: "Location",
              Render: ({ location }: any) => (
                <LocationMap
                  lat={location?.coordinates?.[1]}
                  lng={location?.coordinates?.[0]}
                  type="table"
                />
              ),
            },
            {
              key: "totalVehicle",
              label: "Total Vehicles",
              Render: ({ vehicles }: any) =>
                vehicles.length ? vehicles.length : 0,
            },

            {
              key: "user.phone",
              label: "SPOC Number",
              // Render: ({ vehicles }: any) =>
              //   vehicles.length ? vehicles.length : 0,
            },

            {
              key: "status",
              label: "Status",
              Render: ({ status }: any) => (
                <Avatar
                  variant="status"
                  className={status === "ACTIVE" ? "green" : "grey"}
                >
                  {titleCase(status ? status.split("_").join(" ") : "")}
                </Avatar>
              ),
            },

            {
              key: "actions",
              label: "Actions",
              Render: (row) => (
                <IconButton
                  size="small"
                  sx={{
                    ml: 0.5,
                    color: (theme) => theme.customColors.action,
                  }}
                  children={<InfoOutlined fontSize="small" />}
                  onClick={() =>
                    drawer.open(
                      <DrawerContent
                        hub={row}
                        refetchAllHubs={refetchAllHubs}
                      />
                    )
                  }
                />
              ),
            },
          ]}
        />
      </Paper>

      <AddHubsDialog
        open={createDialog}
        handleClose={() => setCreateDialog(false)}
        refetchHubs={refetchAllHubs}
        setMarkerPosition={setMarkerPosition}
        markerPosition={markerPosition}
      />
    </>
  );
};

export default Hubs;
