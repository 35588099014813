import { InfoOutlined } from "@mui/icons-material";
import UpdateIcon from "@mui/icons-material/Update";
import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import Search from "components/Search";
import Table from "components/Table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { authorizedFetch, drawer } from "utils";
import { KYC_URL } from "utils/constants";
import DrawerContent from "./DrawerContent";
import UpdateKYC from "./UpdateKYC";

const KYC = () => {
  // const { canWrite } = getPermissions("charger:chargers");
  const [tab, setTab] = useState(0);
  const [search, setSearch] = useState("");

  const [tabCounts, setTabCounts] = useState({
    all: 0,
    submitted: 0,
    approved: 0,
    rejected: 0,
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { all, submitted, approved, rejected } = tabCounts;

  const first = pageSize;
  const skip = pageSize * (page - 1);
  const status =
    tab === 0
      ? "ALL"
      : tab === 1
        ? "SUBMITTED"
        : tab === 2
          ? "REJECTED"
          : tab === 3
            ? "APPROVED"
            : "";

  const { isLoading, data } = useQuery(
    ["getChargersKYC", status, first, skip, search],
    () =>
      authorizedFetch(
        `${KYC_URL}/company/product/chargers?tab=${status}&first=${first}&skip=${skip}&search=${search}`,
      ),
  );

  const statsUrl = `${KYC_URL}/company/product/chargers/stats`;

  const { data: statsData } = useQuery(["getChargerKycStats", tab, data], () =>
    authorizedFetch(statsUrl),
  );

  useEffect(() => {
    return () => {
      drawer.close();
    };
  }, []);

  useEffect(() => {
    if (statsData) {
      setTabCounts({
        all: statsData?.data?.totalCount,
        submitted: statsData?.data?.submittedCount,
        approved: statsData?.data?.approvedCount,
        rejected: statsData?.data?.rejectedCount,
      });
    }
  }, [statsData]);

  useEffect(() => {
    setPage(1);
  }, [tab, search]);

  const [updateDialog, setUpdateDialog] = useState({ open: false, user: [] });

  return (
    <Box
      sx={{
        px: { xs: 2, md: 4 },
        pt: { xs: 2, md: 4 },
        pb: { xs: 3, md: 6 },
      }}
    >
      <Paper
        sx={{
          width: 1,
          boxShadow: (theme) => theme.customShadows.card,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            width: 1,
            p: 3,
            pb: 2.75,
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { md: "space-between" },
            alignItems: { md: "center" },
          }}
        >
          {/* <Box width="fit-content"> */}
          <Tabs
            sx={{
              mr: { md: 2 },
              mb: { xs: 2, md: 0 },
            }}
            variant="scrollable"
            className="dense"
            value={tab}
            onChange={(e, tab) => setTab(tab)}
          >
            <Tab
              label="All"
              className="hasCount"
              sx={{
                "&:after": {
                  content: `"${all}"`,
                },
              }}
            />
            <Tab
              label="Submitted"
              className="hasCount"
              sx={{
                "&:after": {
                  content: `"${submitted}"`,
                },
              }}
            />
            <Tab
              label="Rejected"
              className="hasCount"
              sx={{
                "&:after": {
                  content: `"${rejected}"`,
                },
              }}
            />
            <Tab
              label="Approved"
              className="hasCount"
              sx={{
                "&:after": {
                  content: `"${approved}"`,
                },
              }}
            />
          </Tabs>
          {/* </Box> */}
          <Search
            value={search}
            handleSearch={(value) => {
              setSearch(value);
            }}
            persist
            enableClear
          />
          {/* <FilterBy /> */}
        </Box>
        <Table
          loading={isLoading}
          rowCount={data?.data?.count}
          rows={data?.data?.chargers || []}
          serverSidePagination
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
          columns={[
            { key: "chargerId", label: "Charger UID" },
            {
              key: "userName",
              label: "Name",
              Render: (row) =>
                row.userName
                  ? row.userName
                  : row.userEmail
                    ? row.userEmail
                    : "-",
            },
            { key: "userEmail", label: "Email" },
            { key: "userPhone", label: "Phone" },
            // { key: "phone", label: "Phone" },
            {
              key: "createdAt",
              label: "Added On",
              Render: (row) => (
                <Box>{moment(row.createdAt).format("ddd, MMM DD, YYYY")}</Box>
              ),
            },
            {
              key: "status",
              label: "Status",
              Render: (row) => (
                <Avatar
                  variant="status"
                  className={
                    row.status === "REJECTED"
                      ? "red"
                      : row.status === "SUBMITTED"
                        ? "yellow"
                        : ""
                  }
                >
                  {row.status}
                </Avatar>
              ),
            },

            {
              key: "actions",
              label: "Actions",
              Render: (row: any) => {
                return (
                  <Box display="flex">
                    <Tooltip title="Info" followCursor>
                      <IconButton
                        size="small"
                        sx={{ mr: 1 }}
                        onClick={() =>
                          drawer.open(<DrawerContent user={row} />)
                        }
                      >
                        <InfoOutlined fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Update KYC" followCursor>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setUpdateDialog({ open: true, user: row });
                        }}
                      >
                        <UpdateIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                );
              },
            },
          ]}
          // toolbar={() => (
          //   <>
          //     <Button startIcon={<DeleteOutline />}>Delete</Button>
          //   </>
          // )}
        />
      </Paper>
      <UpdateKYC
        open={updateDialog.open}
        handleClose={() => {
          setUpdateDialog({ ...updateDialog, open: false });
        }}
        user={updateDialog.user}
      />
    </Box>
  );
};

export default KYC;
