import {
  EditOutlined,
  ErrorOutline,
  HighlightOff,
  InfoOutlined,
  PersonAddAltRounded,
  PersonSearchRounded,
} from "@mui/icons-material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { LEASE_URL } from "utils/constants";

import RedAsterisk from "components/RedAsterisk";
import { queryClient } from "index";
import PaymentDialogJuspay from "./PaymentDialogJuspay";

interface Props {
  open: boolean;
  handleClose: () => void;
  refetchBookings: () => void;
  refetchStats: () => void;
}

type inputData = {
  vehicle: string;
  type: string;
  amount: number | string;

  pricingName: string;
  pricingUnit: string;
  baseAmount: number;
  costPerUnit: number;
  paymentAccount: string;
};

const AddDialog: React.FC<Props> = ({
  open,
  handleClose,
  refetchBookings,
  refetchStats,
}) => {
  function resetQueries() {
    ["GET FMS /company/bookings", "GET FMS /company/bookings/stats"].forEach(
      (el) => {
        queryClient.resetQueries(el);
      },
    );
  }

  const [step, setStep] = useState(0);
  const steps = ["User Info", "Lease Info", "Finish"];

  const [timeDifferenceEnd, setTimeDifferenceEnd] = useState<any>(30);
  const [timeDifferenceStart, setTimeDifferenceStart] = useState<any>();

  const [startTime, setStartTime] = useState<Date | null>(new Date());

  const [endTime, setEndTime] = useState<Date | null>(new Date());

  useEffect(() => {
    if (open) {
      let newStartDate = new Date();
      newStartDate.setTime(newStartDate.getTime() + 10 * 60 * 1000);
      setStartTime(newStartDate);
      let newEndDate = new Date();
      newEndDate.setTime(newStartDate.getTime() + 30 * 60 * 1000);
      setEndTime(newEndDate);
    }
  }, [open]);

  useEffect(() => {
    let e: any = endTime?.getTime();
    let s: any = startTime?.getTime();
    let n: any = new Date().getTime();
    let diffEnd = Math.floor((e - s) / 1000 / 60);
    setTimeDifferenceEnd(diffEnd);
    let diffStart = Math.floor((s - n) / 1000 / 60);
    setTimeDifferenceStart(diffStart);
  }, [endTime, startTime]);

  const [input, setInput] = useState<inputData>({
    vehicle: "",
    type: "",
    amount: 0,
    pricingName: "",
    pricingUnit: "",
    baseAmount: 0,
    costPerUnit: 0,
    paymentAccount: "",
  });

  const { vehicle, type, amount } = input;

  const [verifiedUser, setVerifiedUser] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    status: 1,
  });

  const [paymentDialog, setPaymentDialog] = useState({
    open: false,
    data: {},
  });

  const { firstName, lastName, phone, email, status } = verifiedUser;

  // Verify User

  const [verifyPhone, setVerifyPhone] = useState("");

  const verifyUser = `${LEASE_URL}/company/users/verify`;

  const {
    data: userData,
    mutate,
    isLoading: userLoading,
  } = useMutation(
    `verifyUser`,
    () =>
      authorizedFetch(verifyUser, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          user: {
            phone: `+91${verifyPhone}`,
          },
        },
      }),
    {
      onSuccess: () => {
        setLoader(false);
      },
    },
  );

  // Get Vehicles

  const vehiclesUrl = `${LEASE_URL}/company/vehicles?all=true`;
  const { data: vehiclesData } = useQuery(["getVehicles"], () =>
    authorizedFetch(vehiclesUrl),
  );

  // Get Amount

  const getAmount = `${LEASE_URL}/company/booking/amount`;

  const {
    data: amountData,
    mutate: amountMutation,
    isLoading: amountLoading,
  } = useMutation([`addVehicleBooking`, vehicle], () =>
    authorizedFetch(getAmount, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        vin: vehicle,
        startTime: moment(startTime).toISOString().toString(),
        endTime: moment(endTime).toISOString().toString(),
      },
    }),
  );

  // Get Availability

  const getAvailability = `${LEASE_URL}/company/vehicle/${vehicle}/availability?starttime=${moment(
    startTime,
  ).toISOString()}&endtime=${moment(endTime).toISOString()}`;

  const {
    data: availabilityData,
    mutate: availabilityMutation,
    isLoading: availabilityLoading,
  } = useMutation(`addVehicleBooking`, () =>
    authorizedFetch(getAvailability, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }),
  );

  useEffect(() => {
    if (vehicle) {
      availabilityMutation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle, startTime, endTime]);

  // Create Lease

  const addLease = `${LEASE_URL}/company/vehicle/${vehicle}/v2/booking/create`;
  // const addLease = `${LEASE_URL}/company/vehicle/${vehicle}/booking/create`;

  const { mutate: leaseMutation } = useMutation(
    `addVehicleBooking`,
    () =>
      authorizedFetch(addLease, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          userInfo: {
            phone: `+91${phone}`,
            firstName: firstName,
            lastName: lastName,
            email: email,
          },
          type: type,
          startTime: `${moment(startTime).toISOString()}`,
          endTime: `${moment(endTime).toISOString()}`,
        },
      }),
    {
      onSuccess: (data) => {
        if (
          data?.message &&
          data?.message === "Booking created & payment initialised" &&
          data?.message.constructor === String
        ) {
          snackbar.success(data.message);
          console.log("data", data);
          setPaymentDialog({ open: true, data: data.data });
          refetchStats();
          refetchBookings();
          setLoader(false);
        } else {
          if (data?.message && data?.message.constructor === String) {
            snackbar.error(data?.message);
          } else {
            snackbar.error("Error creating booking");
          }
          setLoader(false);
        }
      },
    },
  );

  const disabled =
    [
      firstName,
      lastName,
      phone,
      email,
      vehicle,
      type,
      startTime,
      endTime,
    ].includes("") || amount === "No Pricing Found";

  function isComplete(step: number) {
    switch (step) {
      case 0:
        return ![firstName, lastName, phone, email].includes("");
      case 1:
        return ![vehicle, type, startTime, endTime].includes("");

      default:
        break;
    }
  }

  function handleChange(key: string, value: string) {
    setInput((prevInput: inputData) => ({ ...prevInput, [key]: value }));
  }

  const handleStartTime = (newValue: Date | null) => {
    setStartTime(newValue);
  };

  const handleEndTime = (newValue: Date | null) => {
    setEndTime(newValue);
  };

  useEffect(() => {
    if (open) {
      let newStartDate = new Date();
      newStartDate.setTime(newStartDate.getTime() + 10 * 60 * 1000);
      setStartTime(newStartDate);
      let newEndDate = new Date();
      newEndDate.setTime(newStartDate.getTime() + 30 * 60 * 1000);
      setEndTime(newEndDate);
    }
  }, [open]);

  useEffect(() => {
    if (!open) return;
    setInput({
      vehicle: "",
      type: "",
      amount: 0,
      pricingName: "",
      pricingUnit: "",
      baseAmount: 0,
      costPerUnit: 0,
      paymentAccount: "",
    });
    setStep(0);
    setAddUser(false);
  }, [open]);

  function handleVerification() {
    mutate();
  }

  useEffect(() => {
    if (userData && userData?.msg === "User details") {
      setVerifiedUser({
        firstName: userData?.data?.firstName,
        lastName: userData?.data?.lastName,
        phone:
          userData?.data?.phone[0] === "+"
            ? userData?.data?.phone.substring(3)
            : userData?.data?.phone,
        email: userData?.data?.email,
        status: 200,
      });
    } else {
      setVerifiedUser({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        status: 404,
      });
    }
  }, [userData]);

  useEffect(() => {
    if (!open) {
      setVerifiedUser({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        status: 1,
      });
      setVerifyPhone("");
      setStartTime(new Date());
      setEndTime(new Date());
      setPaymentDialog({
        open: false,
        data: {},
      });
    }
  }, [open]);

  const handleSave = () => {
    setLoader(true);

    leaseMutation();
    handleClose();
  };

  function handleNext() {
    if (step === steps.length - 1) {
      handleSave();
    } else setStep(step + 1);
  }
  function handleBack() {
    setStep(step - 1);
  }

  useEffect(() => {
    if (amountData && Object.keys(amountData.data).length !== 0) {
      if (amountData?.msg?.includes("Cannot read properties")) {
        setInput({ ...input, amount: "No Pricing Found" });
      } else {
        setInput({ ...input, amount: amountData?.data?.amount });
      }
    } else {
      setInput({ ...input, amount: "No Pricing Found" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountData]);

  useEffect(() => {
    if (
      open &&
      timeDifferenceStart > 0 &&
      timeDifferenceEnd > 0 &&
      vehicle !== ""
    ) {
      amountMutation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, timeDifferenceStart, timeDifferenceEnd, vehicle]);

  const [addUser, setAddUser] = useState(false);

  useEffect(() => {
    setVerifiedUser({
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      status: 1,
    });
  }, [addUser]);

  // get fields

  return <>
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          maxWidth: 800,
          width: 1,
          "& .MuiInputBase-root": {
            fontSize: 14,
            borderRadius: 1,
            p: "3.5px 5px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box>
          Add Lease{" "}
          {/* <span
            style={{ fontSize: "14px", color: "#00000060", fontWeight: 800 }}
          >
            : Create Booking
          </span> */}
        </Box>
        <IconButton
          children={<HighlightOff />}
          color="inherit"
          onClick={handleClose}
          sx={{ transform: "translate(8px, -8px)" }}
        />
      </DialogTitle>
      <DialogContent sx={{ pb: "16px !important" }}>
        <Stepper
          sx={{ my: 4, mx: "auto", maxWidth: 534 }}
          activeStep={step}
          nonLinear
          alternativeLabel
        >
          {steps.map((label, i) => (
            <Step key={i}>
              <StepButton onClick={() => setStep(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
        {step === 0 && (
          <Box
            sx={{
              maxWidth: { xs: 280, sm: 560 },
              mx: "auto",
              display: "grid",
              gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
              gap: 2,
              mt: 2,
            }}
          >
            {!addUser ? (
              <Box
                sx={{
                  gridColumn: { sm: "span 2" },
                }}
              >
                <Typography className="label">Verify User</Typography>
                <Box display="flex" gap={2}>
                  <TextField
                    type="tel"
                    autoComplete="off"
                    fullWidth
                    size="small"
                    value={verifyPhone}
                    placeholder="Phone Number"
                    onChange={(e: any) => {
                      if (e.target.value.length <= 10)
                        setVerifyPhone(
                          e.target.value?.length > 0
                            ? e.target.value.replace(/[^0-9]/, "")
                            : e.target.value,
                        );
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" sx={{ ml: 1 }}>
                          +91
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    variant="contained"
                    sx={{ width: 200 }}
                    onClick={handleVerification}
                    disabled={verifyPhone.length !== 10}
                  >
                    Verify
                  </Button>
                </Box>
              </Box>
            ) : (
              ""
            )}

            {userLoading ? (
              <Box
                sx={{
                  gridColumn: { sm: "span 2" },
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : status === 200 || addUser ? (
              <>
                <Box>
                  <Typography sx={{ fontSize: "0.875rem" }}>
                    Fields with ( * ) are required
                  </Typography>
                </Box>
                <Box sx={{ gridColumn: "span 2", mt: 2, mb: -1 }}>
                  <Typography className="label">User Info</Typography>
                </Box>
                <Box
                  sx={{
                    "& .required": {
                      color: "red",
                    },
                  }}
                >
                  <Typography className="label" sx={{ fontSize: 14 }}>
                    First Name <span className="required">&#x2a;</span>
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    value={firstName}
                    placeholder="First Name"
                    autoComplete="off"
                    disabled={!addUser}
                    required
                    onChange={(e: any) => {
                      setVerifiedUser({
                        ...verifiedUser,
                        firstName:
                          e.target.value?.length > 0
                            ? e.target.value.replace(/[^a-zA-Z ]/, "")
                            : e.target.value,
                      });
                    }}
                    onInput={(e: any) => {
                      e.target.value = e.target.value
                        ? e.target.value.toString().slice(0, 15)
                        : "";
                    }}
                  />
                </Box>

                <Box>
                  <Typography className="label" sx={{ fontSize: 14 }}>
                    Last Name
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    value={lastName}
                    placeholder="Last Name"
                    autoComplete="off"
                    disabled={!addUser}
                    onChange={(e: any) => {
                      setVerifiedUser({
                        ...verifiedUser,
                        lastName:
                          e.target.value?.length > 0
                            ? e.target.value.replace(/[^a-zA-Z ]/, "")
                            : e.target.value,
                      });
                    }}
                    onInput={(e: any) => {
                      e.target.value = e.target.value
                        ? e.target.value.toString().slice(0, 15)
                        : "";
                    }}
                  />
                </Box>
                <Box>
                  <Typography className="label" sx={{ fontSize: 14 }}>
                    Phone Number
                  </Typography>
                  <TextField
                    type="tel"
                    fullWidth
                    size="small"
                    value={phone}
                    autoComplete="off"
                    placeholder="Phone Number"
                    disabled={!addUser}
                    inputProps={{ maxLength: 10 }}
                    onChange={(e: any) => {
                      setVerifiedUser({
                        ...verifiedUser,
                        phone:
                          e.target.value?.length > 0
                            ? e.target.value.replace(/[^0-9]/, "")
                            : e.target.value,
                      });
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" sx={{ ml: 1 }}>
                          +91
                        </InputAdornment>
                      ),
                    }}
                    onInput={(e: any) => {
                      e.target.value = e.target.value
                        ? Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 10)
                        : "";
                    }}
                  />
                </Box>
                <Box>
                  <Typography className="label" sx={{ fontSize: 14 }}>
                    Email
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    value={email}
                    placeholder="Email"
                    autoComplete="off"
                    disabled={!addUser}
                    onChange={(e: any) => {
                      setVerifiedUser({
                        ...verifiedUser,
                        email: e.target.value,
                      });
                    }}
                  />
                </Box>
              </>
            ) : status === 404 ? (
              <Box
                sx={{
                  gridColumn: "span 2",
                  display: "flex",
                  justifyContent: "center",
                  mt: 3,
                }}
              >
                <Typography
                  className="label"
                  sx={{
                    fontSize: 14,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <InfoOutlined sx={{ fontSize: 16, mr: 0.5 }} /> User Not
                  Found. Try Again.
                </Typography>
              </Box>
            ) : (
              ""
            )}
            <Box
              sx={{
                gridColumn: { sm: "span 2" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                my: 3,
              }}
            >
              <Divider sx={{ width: 0.4 }} />
              <Typography
                sx={{ mx: 2, color: "#00000030", fontWeight: "medium" }}
              >
                OR
              </Typography>
              <Divider sx={{ width: 0.4 }} />
            </Box>
            {!addUser ? (
              <Box
                sx={{
                  gridColumn: { sm: "span 2" },
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: 2,
                    minHeight: 150,
                    minWidth: 150,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  component="label"
                  onClick={() => {
                    setAddUser(true);
                  }}
                >
                  <PersonAddAltRounded sx={{ fontSize: 50 }} />
                  <Typography
                    sx={{
                      mt: 2,
                      fontSize: 12,
                      fontWeight: "bold",
                      opacity: 0.6,
                    }}
                  >
                    Add User
                  </Typography>
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  gridColumn: { sm: "span 2" },
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: 2,
                    minHeight: 150,
                    minWidth: 150,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  component="label"
                  onClick={() => {
                    setAddUser(false);
                  }}
                >
                  <PersonSearchRounded sx={{ fontSize: 50 }} />
                  <Typography
                    sx={{
                      mt: 2,
                      fontSize: 12,
                      fontWeight: "bold",
                      opacity: 0.6,
                    }}
                  >
                    Verify User
                  </Typography>
                </Button>
              </Box>
            )}
          </Box>
        )}
        {step === 1 && (
          <>
            <Box sx={{ maxWidth: { xs: 280, sm: 560 }, mx: "auto" }}>
              <Typography sx={{ fontSize: "0.875rem" }}>
                Fields marked with ( <RedAsterisk /> ) are required
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: { xs: 280, sm: 560 },
                mx: "auto",
                display: "grid",
                gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                gap: 3,
                mt: 2,
                "& .required": {
                  color: "red",
                },
              }}
            >
              <Box
              // sx={{
              //   gridColumn: { sm: "span 2" },
              // }}
              >
                <Typography className="label">
                  Vehicle <span className="required">&#x2a;</span>
                </Typography>

                <Select
                  fullWidth
                  size="small"
                  value={vehicle}
                  onChange={(e: any) => {
                    handleChange("vehicle", e.target.value);
                  }}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>Select</em>
                  </MenuItem>
                  {(vehiclesData?.data?.vehicles?.constructor === Array
                    ? vehiclesData.data.vehicles
                    : []
                  )
                    .filter((el: any) => el.rentalStatus === "AVAILABLE")
                    .map((el: any) => (
                      <MenuItem key={el.id} value={el.vin}>
                        {el.vin}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
              <Box
              // sx={{
              //   gridColumn: { sm: "span 2" },
              // }}
              >
                <Typography className="label">
                  Type <span className="required">&#x2a;</span>
                </Typography>
                <Select
                  size="small"
                  fullWidth
                  value={type}
                  onChange={(e: any) => {
                    handleChange("type", e.target.value);
                  }}
                  displayEmpty
                >
                  <MenuItem disabled value="">
                    <em>Select</em>
                  </MenuItem>
                  {["SHORT_TERM", "LONG_TERM"].map((option, i) => (
                    <MenuItem key={i} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <Box>
                <Typography className="label">
                  Start Time <span className="required">&#x2a;</span>
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    value={startTime}
                    onChange={handleStartTime}
                    // renderInput={(params) => (
                    //   <TextField
                    //     {...params}
                    //     fullWidth
                    //     size="small"
                    //     sx={{
                    //       "& .MuiIconButton-root": {
                    //         marginRight: 1,
                    //       },
                    //     }}
                    //     error={timeDifferenceStart <= 0}
                    //     helperText={
                    //       timeDifferenceStart <= 0
                    //         ? "Start Time should be more than the Current Time"
                    //         : ""
                    //     }
                    //   />
                    // )}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        sx: {
                          "& .MuiIconButton-root": {
                            marginRight: 1,
                          },
                        },
                        error: timeDifferenceStart <= 0,
                        helperText:
                          timeDifferenceStart <= 0
                            ? "Start Time should be more than the Current Time"
                            : "",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <Box>
                <Typography className="label">
                  End Time <span className="required">&#x2a;</span>
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    value={endTime}
                    onChange={handleEndTime}
                    // renderInput={(params) => (
                    //   <TextField
                    //     {...params}
                    //     fullWidth
                    //     size="small"
                    //     sx={{
                    //       "& .MuiIconButton-root": {
                    //         marginRight: 1,
                    //       },
                    //     }}
                    //     error={timeDifferenceEnd <= 0}
                    //     helperText={
                    //       timeDifferenceEnd <= 0
                    //         ? "End Time should be more than Start Time"
                    //         : ""
                    //     }
                    //   />
                    // )}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        sx: {
                          "& .MuiIconButton-root": {
                            marginRight: 1,
                          },
                        },
                        error: timeDifferenceEnd <= 0,
                        helperText:
                          timeDifferenceEnd <= 0
                            ? "End Time should be more than Start Time"
                            : "",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>

              <Box gridColumn={{ sm: "span 2" }}>
                <Typography className="label">Details</Typography>
                <Box
                  sx={{
                    border: "1px solid #00000040",
                    borderRadius: 1,
                    height: 55,
                    display: "flex",

                    alignItems: "center",
                  }}
                >
                  <Box width="50%" sx={{ pl: 2, display: "flex" }}>
                    <Typography sx={{ fontSize: 14 }}>Amount : </Typography>
                    {amountLoading ? (
                      <Box
                        sx={{
                          display: "flex",
                          gap: 5,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress
                          color="primary"
                          sx={{ maxWidth: 15, maxHeight: 15, ml: 2 }}
                        />
                      </Box>
                    ) : (
                      <Typography
                        sx={{ fontSize: 13, ml: 2, fontWeight: 600 }}
                      >
                        {timeDifferenceStart > 0 && timeDifferenceEnd > 0 ? (
                          vehicle ? (
                            // eslint-disable-next-line
                            amount === "No Pricing Found" ? (
                              amount
                            ) : (
                              // eslint-disable-next-line
                              "₹" + " " + amount
                            )
                          ) : (
                            <Typography
                              sx={{
                                fontSize: 12,

                                fontWeight: 600,
                                opacity: 0.65,
                              }}
                            >
                              Select a Vehicle
                            </Typography>
                          )
                        ) : (
                          "Invalid Time"
                        )}
                      </Typography>
                    )}
                  </Box>
                  <Box width="50%" sx={{ pl: 2, display: "flex" }}>
                    <Typography sx={{ fontSize: 14 }}>
                      Booking Availability :
                    </Typography>
                    {availabilityLoading ? (
                      <Box
                        sx={{
                          display: "flex",
                          gap: 5,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress
                          color="primary"
                          sx={{ maxWidth: 15, maxHeight: 15, ml: 2 }}
                        />
                      </Box>
                    ) : (
                      <>
                        {timeDifferenceStart > 0 && timeDifferenceEnd > 0 ? (
                          vehicle ? (
                            <Avatar
                              sx={{
                                ml: 2,
                                background: availabilityData?.data?.available
                                  ? ""
                                  : "#FF000020",
                                color: availabilityData?.data?.available
                                  ? ""
                                  : "#FF000070",
                                border: "none",
                              }}
                              variant="status"
                            >
                              {availabilityData?.data?.available
                                ? "Available"
                                : "Unavailable"}
                            </Avatar>
                          ) : (
                            <Typography
                              sx={{
                                fontSize: 12,
                                ml: 1.5,
                                fontWeight: 600,
                                opacity: 0.65,
                              }}
                            >
                              Select a Vehicle
                            </Typography>
                          )
                        ) : (
                          <Typography
                            sx={{ fontSize: 13, ml: 2, fontWeight: 600 }}
                          >
                            Invalid Time
                          </Typography>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}

        {step === 2 && (
          <Box
            sx={{
              maxWidth: 560,
              mx: "auto",
              "& .table": {
                borderCollapse: "collapse",
                width: 1,
                fontSize: 14,
                lineHeight: "16px",
                "& td": {
                  py: 1.25,
                  px: 2,
                },
                "& .bold": {
                  fontWeight: 500,
                },
                "& .header": {
                  px: 2,
                  py: 1,
                  position: "relative",
                  "& td": {
                    position: "absolute",
                    verticalAlign: "middle",
                    bgcolor: (theme) => theme.customColors.header,
                    width: 1,
                    borderRadius: "4px",
                    fontSize: 16,
                    fontWeight: 600,
                    "& span": {
                      display: "inline-block",
                      transform: "translateY(1px)",
                    },
                  },
                },
                "& .first > td": {
                  pt: 9,
                },
                "& .last > td": {
                  pb: 3,
                },
              },
            }}
          >
            <table className="table">
              <tbody>
                {[
                  { header: "User Info", onEdit: () => setStep(0) },
                  { label: "First Name", value: firstName, required: true },
                  { label: "Last Name", value: lastName, required: true },
                  { label: "Phone Number", value: phone, required: true },
                  { label: "Email", value: email, required: true },

                  { header: "Lease Info", onEdit: () => setStep(1) },
                  { label: "Vehicle", value: vehicle, required: true },
                  { label: "Type", value: type, required: true },

                  {
                    label: "Start Time",
                    value: moment(startTime).format("MMM DD, hh:mm A"),
                    required: true,
                  },
                  {
                    label: "End Time",
                    value: moment(endTime).format("MMM DD, hh:mm A"),
                    required: true,
                  },

                  {
                    label: "Amount (Rs)",
                    value: amount.toString(),
                    required: true,
                  },
                ].map(
                  ({ header, onEdit, label, value, required }, i, arr) => {
                    const isFirst = arr[i - 1]?.header;
                    const isLast = !arr[i + 1] || arr[i + 1].header;

                    return (
                      <tr
                        key={i}
                        className={
                          header
                            ? "header"
                            : `${isFirst ? "first" : ""} ${
                                isLast ? "last" : ""
                              }`
                        }
                      >
                        {header ? (
                          <td colSpan={2}>
                            <span>{header}</span>
                            <IconButton
                              sx={{ ml: 1.5 }}
                              children={<EditOutlined />}
                              color="primary"
                              size="small"
                              onClick={onEdit}
                            />
                          </td>
                        ) : (
                          <>
                            <td>{label}</td>
                            <td className="bold">
                              {value ||
                                (required && (
                                  <Box display="flex" alignItems="center">
                                    <ErrorOutline
                                      fontSize="small"
                                      color="error"
                                      style={{ marginRight: 8 }}
                                    />
                                    Required
                                  </Box>
                                ))}
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  },
                )}
              </tbody>
            </table>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        {step !== 0 && (
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        )}
        {step === 0 && (
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        )}
        <Button
          onClick={handleNext}
          variant={
            isComplete(step) || step === steps.length - 1
              ? "contained"
              : "outlined"
          }
          disableElevation
          disabled={
            (step === 0 && [firstName].includes("")) ||
            (step === 1 &&
              ([vehicle, type].includes("") ||
                amount === "No Pricing Found")) ||
            (step === steps.length - 1 && disabled)
          }
        >
          {step === steps.length - 1 ? "Save" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
    <PaymentDialogJuspay
      open={paymentDialog.open}
      handleClose={() => {
        setPaymentDialog({ ...paymentDialog, open: false });
      }}
      data={paymentDialog.data}
      amount={amount}
      resetQueries={resetQueries}
    />
  </>;
};

export default AddDialog;
