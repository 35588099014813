import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import getSymbolFromCurrency from "currency-symbol-map";
import { useEffect, useState } from "react";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { BOLT_URL } from "utils/constants";

const inputObj = {
  amount: "",
  refundType: "GENERAL", // GENERAL | BOOKING_REFUND
  bookingId: "",
};

const RefundDialog = ({
  open,
  handleClose,
  data,
  refetchTransactions,
  refetchWallet,
}: any) => {
  const user = data?.user;
  const currency = data?.stats?.currency;

  const [input, setInput] = useState({ ...inputObj });

  useEffect(() => {
    if (!open) return;
    setInput({ ...inputObj });
  }, [open]);

  const { amount, refundType, bookingId } = input;

  function onSend() {
    setLoader(true);
    authorizedFetch(
      `${BOLT_URL}/company/wallet/refund?customerId=${
        user?._id
      }&amount=${amount}&refundType=${refundType}${
        !!bookingId ? `&bookingId=${bookingId}` : ""
      }`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
      .then((res) => {
        setLoader(false);
        if (res.status === 200) {
          snackbar.success(`Refund successful`);
          refetchTransactions();
          refetchWallet();
          handleClose();
        } else {
          snackbar.error(res.message || "Error sending refund");
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error(err);
        snackbar.error(`Error sending refund`);
      });
  }

  function isDisabled() {
    if (!amount) return true;
    if (currency === "INR" && parseFloat(amount) > 1500) return true;
    if (refundType === "BOOKING_REFUND" && !bookingId) return true;
    return false;
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          Refund to Wallet
          <IconButton
            children={<HighlightOff />}
            color="inherit"
            onClick={handleClose}
            sx={{ transform: "translate(8px, -8px)" }}
          />
        </Box>
        <Typography sx={{ fontSize: 13, mt: -1.5 }}>
          {user?.firstName || user?.lastName
            ? `${user?.firstName || ""} ${user?.lastName || ""}`
            : "-"}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ my: 2, display: "grid", gap: 2.5 }}>
          <Box>
            <Typography className="label">Amount</Typography>
            <TextField
              fullWidth
              size="small"
              placeholder="0"
              type="number"
              value={amount}
              error={currency === "INR" ? parseFloat(amount) > 1500 : false}
              helperText={currency === "INR" ? "Max amount: ₹1,500" : undefined}
              onChange={(e: any) => {
                setInput({ ...input, amount: e.target.value });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {getSymbolFromCurrency(currency) || "?"}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <Typography className="label">Refund Type</Typography>
            <Select
              fullWidth
              size="small"
              value={refundType}
              onChange={(e) =>
                setInput({ ...input, refundType: e.target.value })
              }
            >
              <MenuItem value="GENERAL">General</MenuItem>
              <MenuItem value="BOOKING_REFUND">Booking Refund</MenuItem>
            </Select>
          </Box>
          <Collapse in={refundType === "BOOKING_REFUND"}>
            <Box>
              <Typography className="label">Booking ID</Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Booking ID"
                value={bookingId}
                onChange={(e: any) => {
                  setInput({ ...input, bookingId: e.target.value });
                }}
              />
            </Box>
          </Collapse>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={isDisabled()} variant="contained" onClick={onSend}>
          Refund
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RefundDialog;
