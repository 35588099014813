import {
  // InfoOutlined,
  MonitorOutlined,
  // SaveAlt,
  SellOutlined,
  // Sort,
  SystemUpdateAlt,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  // Chip,
  IconButton,
  // MenuItem,
  Paper,
  // Select,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import TableComponent from "components/Table";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { authorizedFetch, drawer, snackbar } from "utils";
import { RETAIL_URL } from "utils/constants";
import SellDialog from "./components/SellDialog";
import VehicleDrawer from "./components/VehicleDrawer";
import OTAUpdateDialog from "../../Common/Actions/OTAUpdateDialog";
import saveAs from "file-saver";
import { useHistory } from "react-router-dom";
import UploadHistoryDrawer from "./components/UploadHistoryDrawer";
import Search from "components/Search";

const List = ({
  search,
  model,
  view,
  tab,
  setTab,
  setSearch,
  updateStatus,
}: any) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  // const [sortByAscending, setSortByAscending] = useState("false");

  const [otaUpdateDialog, setOTAUpdateDialog] = useState({
    open: false,
    data: null,
  });

  const history = useHistory();

  const [sellDialog, setSellDialog] = useState({ open: false, vehicle: null });

  const statusParam = tab === 1 ? "UNASSIGNED" : tab === 2 ? "SOLD" : undefined;
  const statusQueryString = statusParam ? `&status=${statusParam}` : "";

  const url = `${RETAIL_URL}/assembly/vehicle?first=${pageSize}&skip=${
    pageSize * (page - 1)
  }&search=${search}&orderBy=createdAt&ascending=${false}${
    model ? `&model=${model._id}` : ""
  }${statusQueryString}&updateStatus=${
    updateStatus === 0 ? "" : updateStatus === 1 ? "UPDATED" : "UPDATE_REQUIRED"
  }`;
  const {
    isLoading,
    data,
    refetch: refetchVehicles,
  } = useQuery(
    [
      "getRetailVehicles",
      page,
      pageSize,
      search,
      model?._id,
      // sortByAscending,
      tab,
      updateStatus,
    ],
    () => authorizedFetch(url),
    { enabled: view === "list" },
  );

  const historyUrl = `${RETAIL_URL}/assembly/vehicle/bulkUpload/history?first=${pageSize}&skip=${
    pageSize * (page - 1)
  }`;
  const { isLoading: historyLoading, data: historyData } = useQuery(
    [
      "getVehiclesBulkUploadHistory",
      page,
      pageSize,
      search,
      model?._id,
      // sortByAscending,
    ],
    () => authorizedFetch(historyUrl),
    { enabled: tab === 3 },
  );

  useEffect(() => {
    return () => {
      drawer.close();
    };
  }, []);

  useEffect(() => {
    setPage(1);
  }, [search, updateStatus]);

  let rows: any[] = data?.data?.constructor === Array ? data.data : [],
    rowCount = data?.meta?.totalCount || 0;

  let historyRows: any[] =
      historyData?.data?.constructor === Array ? historyData.data : [],
    historyRowCount = historyData?.meta?.totalCount || 0;

  return (
    <>
      <OTAUpdateDialog
        open={otaUpdateDialog.open}
        data={otaUpdateDialog.data}
        handleClose={() =>
          setOTAUpdateDialog((prev) => ({ ...prev, open: false }))
        }
      />
      <Paper
        sx={{
          width: 1,
          pt: 3,
          boxShadow: (theme) => theme.customShadows.small,
          borderRadius: 2,
          // overflow: "hidden",
        }}
      >
        <SellDialog
          open={sellDialog.open}
          handleClose={() =>
            setSellDialog((prev) => ({ ...prev, open: false }))
          }
          vehicle={sellDialog.vehicle}
          onSave={() => drawer.close()}
        />
        <Box
          sx={{
            mb: 2,
            px: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "auto", mb: 2 }}>
            <Tabs
              className="dense"
              value={tab}
              onChange={(e, value) => setTab(value)}
              sx={{
                "& .MuiTab-root": {
                  fontSize: 16,
                  fontWeight: 600,
                  letterSpacing: "normal",
                },
              }}
            >
              <Tab label="All" />
              <Tab label="Instock" />
              <Tab label="Sold" />
              <Tab label="Upload History" />
            </Tabs>
          </Box>
          <Box sx={{ mt: -2 }}>
            <Search
              handleSearch={(input) => setSearch(input)}
              enableClear
              persist
            />
          </Box>
          {/* {tab !== 3 && (
            <Box>
              <Tooltip title="Sort By" placement="top" disableInteractive>
                <Select
                  size="small"
                  sx={{ mr: 2 }}
                  value={sortByAscending}
                  onChange={(e) => setSortByAscending(e.target.value)}
                  startAdornment={<Sort sx={{ mr: 1 }} />}
                  renderValue={(selected) =>
                    selected === "false" ? "New to Old" : "Old to New"
                  }
                >
                  <MenuItem value="false">Created On: New to Old</MenuItem>
                  <MenuItem value="true">Created On: Old to New</MenuItem>
                </Select>
              </Tooltip>
            </Box>
          )} */}

          {/* {model && <Chip label={model.name} onDelete={clearFilter} />} */}
        </Box>
        {tab !== 3 && (
          <TableComponent
            loading={isLoading}
            rowCount={rowCount}
            serverSidePagination={true}
            activePage={page}
            activePageSize={pageSize}
            onPageChange={(value) => setPage(value)}
            onPageSizeChange={(value) => setPageSize(value)}
            rows={rows}
            // selectable
            idKey="_id"
            onRowClick={(row) =>
              drawer.open(
                <VehicleDrawer
                  vehicle={row}
                  refetchVehicles={refetchVehicles}
                />,
              )
            }
            columns={[
              { key: "vin", label: "VIN" },
              // { key: "model.name", label: "Model" },
              {
                key: "serialNumber",
                label: "Serial Number",
                Render: (row) => row?.components?.[0]?.serialNumber || "N/A",
              },
              {
                key: "hardware",
                label: "Hardware Version",
                Render: (row) => row?.components[0]?.hardware || "N/A",
              },
              {
                key: "primaryFirmware",
                label: "Primary F/W Version",
                Render: (row) =>
                  row?.components[0]?.currentOta?.primary?.version || "N/A",
              },
              {
                key: "secondaryFirmware",
                label: "Secondary F/W Version",
                Render: (row) =>
                  row?.components[0]?.currentOta?.secondary?.version || "N/A",
              },
              ...(tab === 0
                ? [
                    {
                      key: "status",
                      label: "Status",
                      Render: (row: any) =>
                        (
                          <Avatar
                            variant="status"
                            className={
                              row?.status === "INSTOCK" ||
                              row?.status === "UNASSIGNED"
                                ? "blue"
                                : row?.status === "SOLD"
                                  ? "green"
                                  : "grey"
                            }
                          >
                            {row?.status === "UNASSIGNED"
                              ? "In Stock"
                              : row?.status.charAt(0).toUpperCase() +
                                row?.status.slice(1).toLowerCase()}
                          </Avatar>
                        ) || "N/A",
                    },
                  ]
                : []),
              {
                key: "updates",
                label: "Updates",
                Render: (row) => {
                  const controller = row?.components?.find(
                    (component: any) => component.type === "CONTROLLER",
                  );
                  return (
                    <Avatar
                      variant="status"
                      className={
                        controller?.updateStatus === "UPDATED"
                          ? "blue"
                          : controller?.updateStatus === "UPDATE_REQUIRED"
                            ? "yellow"
                            : !controller?.updateStatus
                              ? "grey"
                              : ""
                      }
                    >
                      {controller?.updateStatus
                        ? controller?.updateStatus === "UPDATED"
                          ? "Up to Date"
                          : controller?.updateStatus === "UPDATE_REQUIRED"
                            ? "Update Pending"
                            : controller?.updateStatus
                        : "N/A"}
                    </Avatar>
                  );
                },
              },
              // { key: "model.protocol", label: "Protocol" },
              // {
              //   key: "createdAt",
              //   label: "Created On",
              //   format: (value) => moment(value).format("MMM DD, YYYY HH:mm"),
              // },
              {
                key: "actions",
                label: "Actions",
                Render: (row) => (
                  <Box display="flex">
                    {/* <Tooltip title="Info" followCursor>
                      <IconButton
                        size="small"
                        sx={{
                          mr: 0.5,
                          color: (theme) => theme.customColors.action,
                        }}
                        children={<InfoOutlined fontSize="small" />}
                        onClick={() =>
                          drawer.open(<VehicleDrawer vehicle={row} />)
                        }
                      />
                    </Tooltip> */}
                    {row.status === "UNASSIGNED" && (
                      <Tooltip title="Sell">
                        <IconButton
                          size="small"
                          sx={{
                            mr: 0.5,
                            color: (theme) => theme.customColors.action,
                          }}
                          children={<SellOutlined fontSize="small" />}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSellDialog({ open: true, vehicle: row });
                          }}
                        />
                      </Tooltip>
                    )}

                    {row.status === "SOLD" && (
                      <Tooltip title="Monitor">
                        <IconButton
                          size="small"
                          sx={{
                            mr: 0.5,
                            color: (theme) => theme.customColors.action,
                          }}
                          children={<MonitorOutlined fontSize="small" />}
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push("/retail-vehicles/" + row._id);
                          }}
                        />
                      </Tooltip>
                    )}

                    {row?.components?.some(
                      (obj: any) => obj.type === "CONTROLLER",
                    ) &&
                      ["SMART", "SMART_PLUS"].includes(
                        row?.model?.protocol,
                      ) && (
                        <Tooltip title={"Mark for OTA Update"}>
                          <IconButton
                            size="small"
                            sx={{
                              color: (theme: any) => theme.customColors.action,
                              mr: 0.5,
                              transform: "rotate(180deg)",
                            }}
                            children={<SystemUpdateAlt fontSize="small" />}
                            onClick={(e) => {
                              e.stopPropagation();
                              setOTAUpdateDialog({ open: true, data: row });
                            }}
                          />
                        </Tooltip>
                      )}
                  </Box>
                ),
              },
            ]}
          />
        )}
        {tab === 3 && (
          <TableComponent
            loading={historyLoading}
            rowCount={historyRowCount}
            serverSidePagination={true}
            activePage={page}
            activePageSize={pageSize}
            onPageChange={(value) => setPage(value)}
            onPageSizeChange={(value) => setPageSize(value)}
            rows={historyRows}
            // selectable
            idKey="_id"
            onRowClick={(row) =>
              drawer.open(<UploadHistoryDrawer data={row} />)
            }
            columns={[
              {
                key: "fileKey",
                label: "Uploaded File",
                Render: (row) => (
                  <Box sx={{ py: 1 }}>
                    <Typography>{row.fileKey}</Typography>
                    <Typography fontSize={12} color="text.secondary">
                      {moment(row.createdAt).format("DD-MMM-YYYY, hh:mm A")}
                    </Typography>
                  </Box>
                ),
              },
              {
                key: "status",
                label: "Status",
                Render: (row) => (
                  <Avatar
                    variant="status"
                    className={`outlined ${
                      row.passedEntries === 0 ? "red" : "green"
                    }`}
                  >
                    {row.passedEntries === 0 ? "Failed" : "Success"}
                  </Avatar>
                ),
              },
              {
                key: "total",
                label: "Total Vehicles",
                Render: (row) => (
                  <Box>{row.passedEntries + row.failedEntries}</Box>
                ),
              },
              {
                key: "passedEntries",
                label: "Success",
                format: (value) => value || 0,
              },
              {
                key: "failedEntries",
                label: "Failed",
                format: (value) => value || 0,
                Render: (row) => (
                  <Typography color="error.light">
                    {row.failedEntries}
                  </Typography>
                ),
              },
              {
                key: "actions",
                label: "Reports",
                Render: (row) => (
                  <Box>
                    <Tooltip title="Download File" followCursor>
                      <Button
                        variant="outlined"
                        color="inherit"
                        size="small"
                        sx={{ fontSize: 10 }}
                        onClick={() => {
                          saveAs(row.fileURL, row.fileKey);
                          snackbar.success(
                            "Your file has been successfully downloaded",
                          );
                        }}
                      >
                        Download
                      </Button>
                      {/* <IconButton
                        size="small"
                        color="primary"
                        onClick={() => {
                          saveAs(row.fileURL, row.fileKey);
                          snackbar.success(
                            "Your file has been successfully downloaded"
                          );
                        }}
                      >
                        <SaveAlt />
                      </IconButton> */}
                    </Tooltip>
                  </Box>
                ),
              },
            ]}
          />
        )}
      </Paper>
    </>
  );
};

export default List;
