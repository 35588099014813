import {
  CloudUploadOutlined,
  DeleteOutline,
  HighlightOff,
  PersonAddOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import Search from "components/Search";
import TableComponent from "components/Table";
import { queryClient } from "index";
import React, { useEffect, useRef, useState } from "react";
import { useQuery, useQuery as useReactQuery } from "react-query";
import { authorizedFetch, setLoader, snackbar } from "utils";
import { BOLT_URL, REPORTS_URL } from "utils/constants";

interface Props {
  open: boolean;
  handleClose: () => void;
  charger: any;
}

const RestrictedUsersDialog: React.FC<Props> = ({
  open,
  handleClose,
  charger,
}) => {
  const [key, setKey] = useState(0);
  const [selected, setSelected] = useState<any>([]);
  const [addUsersDialog, setAddUsersDialog] = useState(false);
  const [deleteUsersDialog, setDeleteUsersDialog] = useState<any>(false);

  const getUsersUrl = `${BOLT_URL}/charger/restricted/${charger?.id}/getUsers`;
  const {
    isLoading,
    data,
    refetch: restrictedUsers,
  } = useReactQuery(
    ["getRestrictedUsers", charger?.id],
    () => authorizedFetch(getUsersUrl),
    { enabled: !!charger?.id }
  );

  useEffect(() => {
    setSelected([]);
    setKey((prev) => prev + 1);
  }, [isLoading, open]);

  const fileRef = useRef<any>(null);
  const postReportUrl = `${REPORTS_URL}/chargers/restricted/addUsers?chargerId=${charger?.id}`;
  const handleFileUpload = (e: any) => {
    setLoader(true);
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      const formData = new FormData();
      formData.append("xlsx", file, file.name);

      authorizedFetch(postReportUrl, {
        method: "POST",
        onlyBody: formData,
      }).then((data) => {
        if (data.status === 200) {
          restrictedUsers();
          setLoader(false);
          snackbar.success("File Uploading Successful");
        } else {
          setLoader(false);
          snackbar.error("Unable to upload file");
        }
      });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box display="flex" alignItems="center">
          <Box color="text.secondary">{charger?.id || "-"}</Box>
          <Divider flexItem orientation="vertical" sx={{ mx: 1 }} />
          Assigned Users
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            transform: "translate(8px, -8px)",
          }}
        >
          <Button
            variant="outlined"
            component="label"
            startIcon={<CloudUploadOutlined />}
            sx={{ borderRadius: 10, textTransform: "none" }}
          >
            Upload File
            <input
              ref={fileRef}
              type="file"
              hidden
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={handleFileUpload}
            />
            <button
              style={{ display: "none" }}
              onClick={() => fileRef.current.click()}
            />
          </Button>
          <Tooltip title="Add Users">
            <span>
              <IconButton
                sx={{ ml: 1 }}
                disabled={isLoading}
                children={<PersonAddOutlined />}
                color="inherit"
                onClick={() => setAddUsersDialog(true)}
              />
            </span>
          </Tooltip>
          <IconButton
            sx={{ ml: 1 }}
            children={<HighlightOff />}
            color="inherit"
            onClick={handleClose}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <TableComponent
          idKey="userId"
          // key={key}
          px={0}
          height={440}
          selectable
          selectedRows={selected}
          setSelectedRows={setSelected}
          selectOnClick
          small
          loading={isLoading}
          rows={data?.data?.constructor === Array ? data.data : []}
          columns={[
            {
              key: "name",
              label: "Name",
              Render: (row) => {
                let { firstName, lastName } = row;
                return firstName || lastName
                  ? `${firstName || ""} ${lastName || ""}`
                  : "-";
              },
            },
            { key: "userEmail", label: "Email" },
            { key: "userPhone", label: "Phone" },
          ]}
          toolbar={() => (
            <Button
              startIcon={<DeleteOutline />}
              onClick={() => setDeleteUsersDialog(true)}
            >
              Delete
            </Button>
          )}
        />
        <AddUsersDialog
          key={key}
          open={addUsersDialog}
          handleClose={() => setAddUsersDialog(false)}
          charger={charger}
          // existingUsers={data?.data || []}
        />
        <DeleteUsersDialog
          open={deleteUsersDialog}
          handleClose={() => setDeleteUsersDialog(false)}
          data={{ charger, selected }}
        />
      </DialogContent>
    </Dialog>
  );
};

interface AddDialogProps {
  open: boolean;
  handleClose: () => void;
  charger: any;
  // existingUsers: any;
}

const AddUsersDialog: React.FC<AddDialogProps> = ({
  open,
  handleClose,
  charger,
  // existingUsers,
}) => {
  const [type, setType] = useState<"APP_USER" | "COMPANY_USER">("APP_USER");
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const first = pageSize;
  const skip = pageSize * (page - 1);

  const usersUrl = `${BOLT_URL}/company/users?type=${type}&first=${first}&skip=${skip}&search=${search}`;
  const { isLoading: usersLoading, data: usersData } = useQuery(
    ["getUsers", type, first, skip, search],
    () => authorizedFetch(usersUrl)
  );

  useEffect(() => {
    if (!open) {
      setSearch("");
    }
  }, [open]);

  useEffect(() => {
    setSelected([]);
    setPage(1);
  }, [type]);

  function handleSave() {
    setLoader(true);
    authorizedFetch(`${BOLT_URL}/charger/restricted/${charger?.id}/addUsers`, {
      method: "POST",
      headers: {
        stage: "prod",
        "Content-Type": "application/json",
      },
      body: { userIds: selected },
    })
      .then((res) => {
        setLoader(false);
        if (res.status === 200) {
          snackbar.success(`Assigned users to ${charger?.id}`);
          queryClient.resetQueries("getRestrictedUsers");
          handleClose();
        } else {
          snackbar.error(
            `Error${res.message ? ": " + res.message : " assigning users"}`
          );
        }
      })
      .catch((err: any) => {
        console.log(err);
        snackbar.error(err.message || "Error assigning users");
      });
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        Add Users
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Search handleSearch={setSearch} enableClear persist />
          <ToggleButtonGroup
            sx={{ ml: 1 }}
            exclusive
            size="small"
            color="primary"
            onChange={(e, value) => value && setType(value)}
            value={type}
          >
            <ToggleButton value="APP_USER">App Users</ToggleButton>
            <ToggleButton value="COMPANY_USER">Employees</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </DialogTitle>
      <DialogContent className="py-1">
        <TableComponent
          loading={usersLoading}
          rows={
            usersData?.data?.users?.constructor === Array
              ? usersData.data.users.map((el: any) => ({
                  id: el.user._id,
                  ...el,
                }))
              : []
          }
          rowCount={usersData?.data?.count || 0}
          serverSidePagination
          activePage={page}
          activePageSize={pageSize}
          onPageChange={(page) => setPage(page)}
          onPageSizeChange={(pageSize) => setPageSize(pageSize)}
          px={0}
          height={376}
          selectable
          selectOnClick
          selectedRows={selected}
          setSelectedRows={setSelected}
          columns={[
            {
              key: "name",
              label: "Name",
              Render: (row) => {
                let { firstName, lastName } = row.user;
                return firstName || lastName
                  ? `${firstName || ""} ${lastName || ""}`
                  : "-";
              },
            },
            { key: "user.email", label: "Email" },
            { key: "user.phone", label: "Phone" },
          ]}
        />
      </DialogContent>
      <DialogActions className="dense">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={selected.length === 0}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface DeleteDialogProps {
  open: boolean;
  handleClose: () => void;
  data: {
    charger: any;
    selected: any;
  };
}

const DeleteUsersDialog: React.FC<DeleteDialogProps> = ({
  open,
  data,
  handleClose,
}) => {
  const { charger, selected } = data;

  function handleDelete() {
    setLoader(true);
    authorizedFetch(
      `${BOLT_URL}/charger/restricted/${charger?.id}/deleteUsers`,
      {
        method: "POST",
        headers: {
          stage: "prod",
          "Content-Type": "application/json",
        },
        body: { userIds: selected },
      }
    )
      .then((res) => {
        setLoader(false);
        if (res.status === 200) {
          snackbar.success(`Removed users from ${charger?.id}`);
          queryClient.resetQueries("getRestrictedUsers");
          handleClose();
        } else {
          snackbar.error(
            `Error${res.message ? ": " + res.message : " removing users"}`
          );
        }
      })
      .catch((err: any) => {
        console.log(err);
        snackbar.error(err.message || "Error removing users");
      });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Remove Users</DialogTitle>
      <DialogContent className="py-1">
        Remove selected users from restricted list?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleDelete}
          color="primary"
          variant="contained"
          disableElevation
          disabled={false}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RestrictedUsersDialog;
