import {
  login as loginAction,
  loginFail,
  loginSuccess,
  // signUpSuccess,
  signUpFail,
} from "actions";
import { call, all, put, takeEvery } from "redux-saga/effects";
import { setLoader, snackbar } from "./utils";
import {
  // firebaseLoginRequest,
  // getTokenId,
  resetPasswordRequest,
  revosLogin,
  revosUserSignUp,
  // firebaseSignUpRequest,
  onTokenFail,
} from "./utils/request";

function getPayload(data) {
  let { company = {}, permissionMatrix } = data?.data?.user;

  // if (!company?.permissions || company.permissions?.length === 0)
  //   // TODO: Remove once data migration for auth API is complete
  //   company.permissions = ["dashboard:*"];

  // const { permissions } = data.user;
  // const canAccessCMS = getSectionPermissions("charger", permissions);
  // const canAccessFMS = getSectionPermissions("rental", permissions);
  // const canAccessVMS = getSectionPermissions("retail", permissions);

  const canAccessCMS =
    company?.permissionMatrix?.cms?.plan !== "NONE" &&
    permissionMatrix.some((item) => item.product === "cms");
  const canAccessFMS =
    company?.permissionMatrix?.fms?.plan !== "NONE" &&
    permissionMatrix.some((item) => item.product === "fms");
  const canAccessVMS =
    company?.permissionMatrix?.vms?.plan !== "NONE" &&
    permissionMatrix.some((item) => item.product === "vms");
  const canAccessHousekeeping =
    company?.permissionMatrix?.housekeeping?.plan !== "NONE" &&
    permissionMatrix.some((item) => item.product === "housekeeping");

  if (
    !canAccessCMS &&
    !canAccessFMS &&
    !canAccessVMS &&
    !canAccessHousekeeping
  ) {
    onTokenFail("User doesn't have the appropriate permissions.");
    return null;
  }

  return {
    // firebase: { ...firebaseResponse },
    token: data.data.token,
    user: data.data.user,
    company,
    activeSection: canAccessCMS
      ? "charger"
      : canAccessFMS
        ? "rental"
        : canAccessVMS
          ? "retail"
          : "housekeeping",
  };
}

function* login(action) {
  // const response = yield call(revosLogin, action.payload);
  // const fireBaseResponse = yield call(firebaseLoginRequest, action.payload);

  // console.log(action.payload);

  let { shouldSignUp, signUpForm, code, email, password } = action.payload;

  if (shouldSignUp) {
    const signUpResponse = yield call(revosUserSignUp, signUpForm, code);
    console.log("signUpResponse ==> ", signUpResponse);

    if (signUpResponse.status < 400) {
      // yield put(signUpSuccess(payload));
      const response = yield call(revosLogin, { email, password });
      let payload = getPayload(response);
      if (payload !== null) {
        yield put(loginSuccess(payload));
        snackbar.success("Account created");
      } else {
        yield put(loginFail(response));
      }
    } else {
      // snackbar.error("Error signing in");
      yield put(signUpFail({ signUpResponse: { ...signUpResponse } }));
    }
  } else {
    const response = yield call(revosLogin, action.payload);
    if (response?.status === 200) {
      let payload = getPayload(response);
      if (payload !== null) {
        yield put(loginSuccess(payload));
        snackbar.success("You have successfully logged in!");
      } else {
        yield put(loginFail(response));
      }
    } else {
      snackbar.error("Error signing in");
      yield put(loginFail(response));
    }
  }

  // const response = yield call(firebaseLoginRequest, action.payload);
  // console.log(action);
  // if (response.user) {
  //   const firebaseToken = yield call(getTokenId, true);
  //   let { shouldSignUp, signUpForm, code } = action.payload;
  //   const loginResponse = yield call(
  //     revosLogin,
  //     firebaseToken,
  //     shouldSignUp ? code : null
  //   );
  //   console.log("revoslogin ==> ", loginResponse);
  //   if (loginResponse.status < 400 || shouldSignUp) {
  //     if (shouldSignUp) {
  //       const signUpResponse = yield call(revosUserSignUp, {
  //         ...signUpForm,
  //         uid: response.user.uid,
  //       });
  //       if (signUpResponse.status < 400) {
  //         let payload = getPayload(signUpResponse.data, response);
  //         if (payload !== null) {
  //           yield put(loginSuccess(payload));
  //           snackbar.success("Account created");
  //         }
  //       } else {
  //         // snackbar.error("Error signing in");
  //         yield put(loginFail(response));
  //         yield put(signUpFail({ signUpResponse: { ...signUpResponse } }));
  //       }
  //     } else {
  //       let payload = getPayload(loginResponse.data, response);
  //       if (payload !== null) {
  //         yield put(loginSuccess(payload));
  //         snackbar.success("You have successfully logged in!");
  //       }
  //     }
  //   } else {
  //     snackbar.error("Error signing in");
  //     yield put(loginFail(response));
  //   }
  // } else yield put(loginFail(response));
}

function* signUp(action) {
  let { signUpForm } = action.payload;

  let { firstName, lastName, email, phone, address, password, code } =
    signUpForm;
  const payload = {
    email,
    shouldSignUp: true,
    signUpForm: {
      firstName,
      lastName,
      address,
      phone,
      password,
    },
    password,
    code,
  };
  yield put(loginAction(payload));
}

function* resetPassword(action) {
  let response = {};
  response = yield call(resetPasswordRequest, action.payload);
  setLoader(false);
  if (response.code) {
    snackbar.error(`${response.message}`);
  } else {
    snackbar.success("Password reset link sent to email");
  }
}

function* loginSaga() {
  yield takeEvery("LOGIN", login);
}

function* signUpSaga() {
  yield takeEvery("SIGNUP", signUp);
}

function* resetPasswordSaga() {
  yield takeEvery("RESET_PASSWORD", resetPassword);
}

export default function* rootSaga() {
  yield all([loginSaga(), signUpSaga(), resetPasswordSaga()]);
}
