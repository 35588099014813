import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import LocationMap from "../LocationMap";
import { AddRounded, LocationOnOutlined } from "@mui/icons-material";
import { useState } from "react";
import { getPermissions } from "utils";

const AppBar = ({
  hubsData,
  hubsLoading,
  tab,
  setTab,
  lat,
  lng,
  isFirstTime,
  setLocation,
  location,
  setCreateDialog,
}: any) => {
  const [address, setAddress] = useState("");
  const { canWrite } = getPermissions("rental:hubs");

  return (
    <Box
      sx={{
        width: 1,
        py: 3,
        px: { xs: 2, md: 7 },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box display="flex" alignItems="center">
        <Typography variant="h2">Hubs</Typography>
        {!hubsLoading && (
          <Typography
            sx={{
              ml: 1,
              fontSize: { xs: 16, md: 20 },
              fontWeight: 600,
              color: (theme) => theme.customColors.text.grey,
            }}
          >
            ({(hubsData?.data?.count?.totalCount || 0).toLocaleString()})
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            ml: 4,
            backgroundColor: "#E8F1F890",

            width: "auto",
            pr: 2,
            pl: 1.5,
            height: 40,
            borderRadius: 10,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LocationOnOutlined color="primary" />
          <Typography sx={{ ml: 0.5 }}>
            {address || "Fetching Location..."}
          </Typography>
        </Box>
      </Box>

      <Box display="flex">
        <LocationMap
          lat={lat}
          lng={lng}
          setAddress={setAddress}
          address={address}
          setLocation={setLocation}
          location={location}
          isFirstTime={isFirstTime}
          type="search"
        />
        <Tooltip title="Hubs Status" placement="top-start">
          <FormControl sx={{ mr: 2 }}>
            <Select
              size="small"
              className="shadow rounded"
              sx={{ width: "auto" }}
              value={tab}
              onChange={(e: any) => setTab(e.target.value)}
              // startAdornment={<Sort sx={{ mr: 1 }} />}
              renderValue={(selected) =>
                selected === 0
                  ? "Status: Active"
                  : selected === 1
                  ? "Status: Inactive"
                  : "Status: Deleted"
              }
            >
              <MenuItem value={0}>Active </MenuItem>
              <MenuItem value={1}>Inactive </MenuItem>
              <MenuItem value={2}>Deleted </MenuItem>
            </Select>
          </FormControl>
        </Tooltip>

        {canWrite && (
          <Button
            sx={{
              // width: { md: 300 },
              px: 6,
              py: 1,
              borderRadius: 10,
              borderWidth: "1.5px !important",
              borderColor: (theme) => theme.palette.primary.main,
              textTransform: "none",
              fontWeight: 500,
            }}
            variant="outlined"
            startIcon={<AddRounded />}
            onClick={() => setCreateDialog(true)}
          >
            Add New
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AppBar;
