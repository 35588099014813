import {
  AccountTree,
  AccountTreeOutlined,
  AdminPanelSettings,
  AdminPanelSettingsOutlined,
  // Assessment,
  // AssessmentOutlined,
  // // AttachMoney,
  Badge,
  BadgeOutlined,
  // Build,
  // BuildOutlined,
  CarRental,
  CarRentalOutlined,
  // ConfirmationNumber,
  // ConfirmationNumberOutlined,
  Dashboard,
  DashboardOutlined,
  ElectricBike,
  ElectricBikeOutlined,
  EventAvailable,
  EventAvailableOutlined,
  Inventory,
  Inventory2Outlined,
  NearMe,
  NearMeOutlined,
  // EvStation,
  // Payments,
  // PaymentsOutlined,
  NoCrash,
  NoCrashOutlined,
  // NotificationAdd,
  // NotificationAddOutlined,
  PeopleAlt,
  PeopleAltOutlined,
  PinDrop,
  PinDropOutlined,
  Power,
  PowerOutlined,
  // PrecisionManufacturing,
  // PrecisionManufacturingOutlined,
  Sell,
  SellOutlined,
  SettingsRemote,
  SettingsRemoteOutlined,
  Speed,
  SpeedOutlined,
  SystemUpdateAlt,
  DeliveryDining,
  DeliveryDiningOutlined,
  Hub,
  HubOutlined,
  ShoppingCart,
  ShoppingCartOutlined,
  PendingActions,
  PendingActionsOutlined,
  // Build,
  // BuildOutlined,
} from "@mui/icons-material";
import { ReactElement } from "react";
import { PageID } from "utils/constants";

// Common pages:
import Admin from "views/Common/Admin";
import Developer from "views/Common/Developer";
import Reports from "views/Common/Reports";

// VMS pages:
import Assembly from "views/Retail/Vehicles";
// import SCM from "views/Retail/SCM";
import RetailInventory from "views/Retail/Inventory";
import AssignedVehicles from "views/Retail/AssignedVehicles";
import Distribution from "views/Retail/Distribution";
import RetailOverview from "views/Retail/Overview";
import SoldVehicles from "views/Retail/SoldVehicles";
// import RetailInvoices from "views/Retail/Invoices";

// FMS pages:
import Geofence from "views/Rental/Geofence";
import RentalKYC from "views/Rental/KYC";
import Leases from "views/Rental/Leases";
import Hubs from "views/Rental/Hubs";
import RentalOverview from "views/Rental/Overview";
import RemoteControl from "views/Rental/RemoteControl";
import RentalTrips from "views/Rental/Trips";
import RentalUsers from "views/Rental/Users";
import RentalVendors from "views/Rental/Vendors";
import Vehicles from "views/Rental/Vehicles";
// import RentalInvoices from "views/Rental/Invoices";

// CMS pages:
import Bookings from "views/Charger/Bookings";
import Chargers from "views/Charger/Chargers";
import ChargerOverview from "views/Charger/Overview";
// import Subscriptions from "views/Charger/Subscriptions";
import Account from "views/Account";
// import Coupons from "views/Charger/Coupons";
import ChargerKYC from "views/Charger/KYC";
// import Notifications from "views/Charger/Notifications";
// import LoadManagment from "views/Charger/LoadManagment";
import ChargerUsers from "views/Charger/Users";
import Vendors from "views/Charger/Vendors";

// import ChargerTickets from "views/Housekeeping/ChargerTickets";

// HOUSEKEEPING pages:
import ChargerTickets from "views/Housekeeping/ChargerTickets";
import SCM from "views/Housekeeping/SCM";

import OTA from "views/Housekeeping/OTA";
import Orders from "views/Housekeeping/Orders";
import Models from "views/Housekeeping/Models";

export interface NavigationLink {
  name: string;
  path: string;
  icon: {
    default: JSX.Element;
    active: JSX.Element;
  };
}

type Route = {
  id: PageID;
  path: string;
  name: string;
  component: React.ComponentType<any>;
  description: string;
  hasNestedPages?: boolean;
};

type Routes = {
  [key: string]: Route[];
};

export const commonRoutes = [
  {
    path: "/account",
    name: "Account",
    component: Account,
    description: "Your Profile | Company Details",
  },
  {
    path: "/developer",
    name: "Developer",
    component: Developer,
    description: "Developer",
  },
];

export const routes: Routes = {
  retail: [
    {
      id: "retail:overview",
      path: "/overview",
      name: "Overview",
      component: RetailOverview,
      description: "",
    },
    {
      id: "retail:inventory",
      path: "/inventory",
      name: "Inventory",
      component: RetailInventory,
      description: "",
    },
    {
      id: "retail:vehicles",
      path: "/retail-vehicles",
      name: "Vehicles",
      component: Assembly,
      description: "",
      hasNestedPages: true,
    },
    {
      id: "retail:distribution",
      path: "/distribution",
      name: "Distribution",
      component: Distribution,
      description: "",
    },
    {
      id: "retail:assigned_vehicles",
      path: "/assigned-vehicles",
      name: "Assigned Vehicles",
      component: AssignedVehicles,
      description: "",
      hasNestedPages: true,
    },
    {
      id: "retail:sold_vehicles",
      path: "/sold-vehicles",
      name: "Sold Vehicles",
      component: SoldVehicles,
      description: "",
      hasNestedPages: true,
    },
    // {
    //   id: "retail:invoices",
    //   path: "/invoices",
    //   name: "Invoices",
    //   component: RetailInvoices,
    //   description: "",
    // },
    {
      id: "retail:reports",
      path: "/reports",
      name: "Reports",
      component: Reports,
      description: "",
    },
    {
      id: "retail:admin",
      path: "/admin",
      name: "Admin",
      component: Admin,
      description: "",
    },
  ],
  rental: [
    {
      id: "rental:overview",
      path: "/overview",
      name: "Overview",
      component: RentalOverview,
      description: "",
    },
    {
      id: "rental:vehicles",
      path: "/vehicles",
      name: "Vehicles",
      component: Vehicles,
      description: "",
      hasNestedPages: true,
    },
    {
      id: "rental:leases",
      path: "/leases",
      name: "Leases",
      component: Leases,
      description: "",
    },
    {
      id: "rental:hubs",
      path: "/hubs",
      name: "Hubs",
      component: Hubs,
      description: "",
    },
    {
      id: "rental:trips",
      path: "/trips",
      name: "Trips",
      component: RentalTrips,
      description: "",
    },
    {
      id: "rental:users",
      path: "/users",
      name: "Users",
      component: RentalUsers,
      description: "",
    },
    {
      id: "rental:vendors",
      path: "/vendors",
      name: "Vendors",
      component: RentalVendors,
      description: "Vendors Stats | Vendor Insights",
    },
    {
      id: "rental:geofence",
      path: "/geofence",
      name: "Geofence",
      component: Geofence,
      description: "",
    },
    {
      id: "rental:kyc",
      path: "/kyc",
      name: "KYC",
      component: RentalKYC,
      description: "",
    },
    {
      id: "rental:reports",
      path: "/reports",
      name: "Reports",
      component: Reports,
      description: "",
    },
    {
      id: "rental:assetControl",
      path: "/asset-control",
      name: "Asset Control",
      component: RemoteControl,
      description: "",
    },
    // {
    //   id: "rental:invoices",
    //   path: "/invoices",
    //   name: "Invoices",
    //   component: RentalInvoices,
    //   description: "",
    // },
    {
      id: "rental:admin",
      path: "/admin",
      name: "Admin",
      component: Admin,
      description: "",
    },
  ],
  charger: [
    {
      id: "charger:overview",
      path: "/overview",
      name: "Overview",
      component: ChargerOverview,
      description:
        "Performance Highlights | Top Locations | Top Hosts | New Users | Active Users | Map | Charger Availability | Operational Status | Charger Access",
    },
    // { path: "/settings", name: "Settings", component: Home },
    {
      id: "charger:chargers",
      path: "/chargers",
      name: "Chargers",
      component: Chargers,
      description: "Charger List | Booking List",
    },
    {
      id: "charger:bookings",
      path: "/bookings",
      name: "Bookings",
      component: Bookings,
      description:
        "Total Revenue | Total Bookings | Booking Duration | Energy Consumed | Bookings List",
    },
    {
      id: "charger:users",
      path: "/users",
      name: "Users",
      component: ChargerUsers,
      description:
        "Total Users | Active Users | Total New Users | Average Daily New Users | Users List | Hosts List | User Groups",
    },
    // {
    //   id: "charger:loadManagment",
    //   path: "/loadmanagment",
    //   name: "Load Managment",
    //   component: LoadManagment,
    //   description:
    //     "Total Bookings | TimeRange Stats | Weekly Stats | Pincode Stats",
    // },
    // {
    //   id: "charger:powerControl",
    //   path: "/powercontrol",
    //   name: "Power Control",
    //   component: PowerControl,
    //   description:
    //     "Charger Availability | Charger Assignment | Bookings vs Earnings | Energy Consumption",
    // },
    {
      id: "charger:vendors",
      path: "/vendors",
      name: "Vendors",
      component: Vendors,
      description:
        "Total Vendors | Total Assigned Chargers | Vendor Stats | Vendors List",
    },
    // {
    //   id: "charger:payments",
    //   path: "/payments",
    //   name: "Payments",
    //   component: Payments,
    //   description: "Payments Stats | Transactions | Earnings",
    // },
    // {
    //   id: "charger:subscriptions",
    //   path: "/subscriptions",
    //   name: "Subscriptions",
    //   component: Subscriptions,
    //   description: "Subscriptions",
    // },
    // {
    //   id: "charger:coupons",
    //   path: "/coupons",
    //   name: "Coupons",
    //   component: Coupons,
    //   description: "Coupons",
    // },
    {
      id: "charger:kyc",
      path: "/kyc",
      name: "KYC",
      component: ChargerKYC,
      description: "",
    },
    {
      id: "charger:reports",
      path: "/reports",
      name: "Reports",
      component: Reports,
      description:
        "Charger Reports | Vendor Reports | Previously Downloaded Reports",
    },
    // {
    //   id: "charger:notifications",
    //   name: "Notifications",
    //   path: "/notifications",
    //   component: Notifications,
    //   description: "Notifications",
    // },
    // {
    //   id: "charger:invoices",
    //   path: "/invoices",
    //   name: "Invoices",
    //   component: ChargerInvoices,
    //   description: "Invoices",
    // },
    {
      id: "charger:admin",
      path: "/admin",
      name: "Admin",
      component: Admin,
      description: "",
    },
  ],
  // housekeeping: [
  //   {
  //     id: "housekeeping:chargerTickets",
  //     path: "/charger-tickets",
  //     name: "Charger Tickets",
  //     component: ChargerTickets,
  //     description:
  //       "Create Charger Tickets | Update Charger Tickets | Track Charger Tickets",
  //   },
  // ],
  housekeeping: [
    {
      id: "housekeeping:components",
      path: "/components",
      name: "Components",
      component: SCM,
      description: "",
    },
    {
      id: "housekeeping:ota_management",
      path: "/ota",
      name: "Files",
      component: OTA,
      description: "",
    },
    {
      id: "housekeeping:charger_tickets",
      path: "/charger-tickets",
      name: "Charger Tickets",
      component: ChargerTickets,
      description:
        "Create Charger Tickets | Update Charger Tickets | Track Charger Tickets",
    },
    {
      id: "housekeeping:models",
      path: "/housekeeping-models",
      name: "Models",
      component: Models,
      description: "",
    },
    {
      id: "housekeeping:orders",
      path: "/orders",
      name: "Orders",
      component: Orders,
      description: "Bolt.Earth Orders",
    },
  ],
};

type NavLink = {
  id: PageID;
  name: string;
  path: string;
  icon: {
    active: ReactElement;
    default: ReactElement;
  };
};

export const navigationLinks: { [key: string]: NavLink[] } = {
  retail: [
    {
      id: "retail:overview",
      name: "Overview",
      path: "/overview",
      icon: { active: <Dashboard />, default: <DashboardOutlined /> },
    },
    {
      id: "retail:inventory",
      name: "Inventory",
      path: "/inventory",
      icon: { active: <Inventory />, default: <Inventory2Outlined /> },
    },
    {
      id: "retail:assigned_vehicles",
      name: "Assigned Vehicles",
      path: "/assigned-vehicles",
      icon: { active: <NoCrash />, default: <NoCrashOutlined /> },
    },
    {
      id: "retail:vehicles",
      name: "Vehicles",
      path: "/retail-vehicles",
      icon: {
        active: <DeliveryDining />,
        default: <DeliveryDiningOutlined />,
      },
    },
    {
      id: "retail:distribution",
      name: "Distribution",
      path: "/distribution",
      icon: { active: <AccountTree />, default: <AccountTreeOutlined /> },
    },
    {
      id: "retail:sold_vehicles",
      name: "Sold Vehicles",
      path: "/sold-vehicles",
      icon: { active: <Sell />, default: <SellOutlined /> },
    },
    // {
    //   id: "retail:invoices",
    //   name: "Invoices",
    //   path: "/invoices",
    //   icon: { active: <Description />, default: <DescriptionOutlined /> },
    // },
    {
      id: "retail:reports",
      name: "Reports",
      path: "/reports",
      icon: { active: <PendingActions />, default: <PendingActionsOutlined /> },
    },
    {
      id: "retail:admin",
      name: "Admin",
      path: "/admin",
      icon: {
        active: <AdminPanelSettings />,
        default: <AdminPanelSettingsOutlined />,
      },
    },
  ],
  rental: [
    {
      id: "rental:overview",
      name: "Overview",
      path: "/overview",
      icon: { active: <Dashboard />, default: <DashboardOutlined /> },
    },
    {
      id: "rental:vehicles",
      name: "Vehicles",
      path: "/vehicles",
      icon: {
        active: <ElectricBike />,
        default: <ElectricBikeOutlined />,
      },
    },
    {
      id: "rental:leases",
      name: "Leases",
      path: "/leases",
      icon: { active: <CarRental />, default: <CarRentalOutlined /> },
    },
    {
      id: "rental:hubs",
      name: "Hubs",
      path: "/hubs",
      icon: { active: <Hub />, default: <HubOutlined /> },
    },
    {
      id: "rental:trips",
      name: "Trips",
      path: "/trips",
      icon: { active: <NearMe />, default: <NearMeOutlined /> },
    },
    {
      id: "rental:users",
      name: "Users",
      path: "/users",
      icon: { active: <PeopleAlt />, default: <PeopleAltOutlined /> },
    },
    {
      id: "rental:vendors",
      name: "Vendors",
      path: "/vendors",
      icon: { active: <AccountTree />, default: <AccountTreeOutlined /> },
    },
    {
      id: "rental:geofence",
      name: "Geofence",
      path: "/geofence",
      icon: { active: <PinDrop />, default: <PinDropOutlined /> },
    },
    {
      id: "rental:kyc",
      name: "KYC",
      path: "/kyc",
      icon: { active: <Badge />, default: <BadgeOutlined /> },
    },
    {
      id: "rental:reports",
      name: "Reports",
      path: "/reports",
      icon: { active: <PendingActions />, default: <PendingActionsOutlined /> },
    },
    {
      id: "rental:assetControl",
      name: "Asset Control",
      path: "/asset-control",
      icon: {
        active: <SettingsRemote />,
        default: <SettingsRemoteOutlined />,
      },
    },
    // {
    //   id: "rental:invoices",
    //   name: "Invoices",
    //   path: "/invoices",
    //   icon: { active: <Description />, default: <DescriptionOutlined /> },
    // },
    {
      id: "rental:admin",
      name: "Admin",
      path: "/admin",
      icon: {
        active: <AdminPanelSettings />,
        default: <AdminPanelSettingsOutlined />,
      },
    },
  ],
  charger: [
    {
      id: "charger:overview",
      name: "Overview",
      path: "/overview",
      icon: { active: <Dashboard />, default: <DashboardOutlined /> },
    },
    {
      id: "charger:chargers",
      name: "Chargers",
      path: "/chargers",
      icon: { active: <Power />, default: <PowerOutlined /> },
    },
    // {
    //   id: "charger:loadManagment",
    //   name: "Load Managment",
    //   path: "/loadmanagment",
    //   icon: { active: <EvStation />, default: <EvStation /> },
    // },
    {
      id: "charger:bookings",
      name: "Bookings",
      path: "/bookings",
      icon: { active: <EventAvailable />, default: <EventAvailableOutlined /> },
    },
    // {
    //   id: "charger:payments",
    //   name: "Payments",
    //   path: "/payments",
    //   icon: { active: <Payments />, default: <PaymentsOutlined /> },
    // },
    {
      id: "charger:users",
      name: "Users",
      path: "/users",
      icon: { active: <PeopleAlt />, default: <PeopleAltOutlined /> },
    },
    {
      id: "charger:vendors",
      name: "Vendors",
      path: "/vendors",
      icon: { active: <AccountTree />, default: <AccountTreeOutlined /> },
    },
    // {
    //   id: "charger:subscriptions",
    //   name: "Subscriptions",
    //   path: "/subscriptions",
    //   icon: { active: <AttachMoney />, default: <AttachMoney /> },
    // },
    // {
    //   id: "charger:coupons",
    //   name: "Coupons",
    //   path: "/coupons",
    //   icon: {
    //     active: <ConfirmationNumber />,
    //     default: <ConfirmationNumberOutlined />,
    //   },
    // },
    {
      id: "charger:kyc",
      name: "KYC",
      path: "/kyc",
      icon: { active: <Badge />, default: <BadgeOutlined /> },
    },
    {
      id: "charger:reports",
      name: "Reports",
      path: "/reports",
      icon: { active: <PendingActions />, default: <PendingActionsOutlined /> },
    },
    // {
    //   id: "charger:notifications",
    //   name: "Notifications",
    //   path: "/notifications",
    //   icon: {
    //     active: <NotificationAdd />,
    //     default: <NotificationAddOutlined />,
    //   },
    // },
    // {
    //   id: "charger:invoices",
    //   name: "Invoices",
    //   path: "/invoices",
    //   icon: { active: <Description />, default: <DescriptionOutlined /> },
    // },
    {
      id: "charger:admin",
      name: "Admin",
      path: "/admin",
      icon: {
        active: <AdminPanelSettings />,
        default: <AdminPanelSettingsOutlined />,
      },
    },
  ],

  housekeeping: [
    {
      id: "housekeeping:components",
      name: "Components",
      path: "/components",
      icon: { active: <Speed />, default: <SpeedOutlined /> },
    },
    {
      id: "housekeeping:ota_management",
      name: "Files",
      path: "/ota",
      icon: {
        active: <SystemUpdateAlt sx={{ transform: "rotate(180deg)" }} />,
        default: <SystemUpdateAlt sx={{ transform: "rotate(180deg)" }} />,
      },
    },
    {
      id: "housekeeping:charger_tickets",
      name: "Charger Tickets",
      path: "/charger-tickets",
      icon: { active: <Power />, default: <PowerOutlined /> },
    },
    {
      id: "housekeeping:models",
      name: "Models",
      path: "/housekeeping-models",
      icon: {
        active: <DeliveryDining />,
        default: <DeliveryDiningOutlined />,
      },
    },
    {
      id: "housekeeping:orders",
      name: "Orders",
      path: "/orders",
      icon: { active: <ShoppingCart />, default: <ShoppingCartOutlined /> },
    },
  ],
};
